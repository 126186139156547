import { type IPositionDetails } from "~/pages/Headcount/entity/types";
import {
  ExpenseDriverEnum,
  ExpenseFrequencyEnum,
  IExpense,
} from "../components/Expenses/types";
import { isBefore, isSameMonth } from "date-fns";
import { getActivePositionsInMonthPerExpense } from "./getActivePositionsInMonthPerExpense";

export const calculateExpenseAmount = ({
  expense,
  positions,
  month,
}: {
  expense: IExpense;
  positions: IPositionDetails[];
  month: Date;
}): number => {
  switch (expense.context.driver) {
    case ExpenseDriverEnum.SetCost:
      return expense.context.amount;
    case ExpenseDriverEnum.HeadcountFixed:
      return (
        expense.context.amount *
        getActivePositionsInMonthPerExpense({
          positions,
          month,
          expense,
          isOnHire: expense.context.frequency === ExpenseFrequencyEnum.OnHire,
        }).length
      );
    case ExpenseDriverEnum.HeadcountPercentCompensation: {
      let totalMonthlyWages = 0;
      getActivePositionsInMonthPerExpense({
        positions,
        month,
        expense,
        isOnHire: expense.context.frequency === ExpenseFrequencyEnum.OnHire,
      }).forEach((position) => {
        const currentPayRate = position.currentPayRate;
        let payRate = 0;
        if (isBefore(currentPayRate.effectiveAt, month)) {
          payRate = currentPayRate.value as number;
        } else {
          const mostRecentHistory = position.payRates.reduce(
            (mostRecent, current) => {
              return new Date(mostRecent.effectiveAt) >
                new Date(current.effectiveAt)
                ? mostRecent
                : current;
            },
          );
          payRate = mostRecentHistory.value as number;
        }
        const MONTHS = 12;
        const PAY_RATE_TO_DOLLARS = 100;
        const PERCENT_TO_DECIMAL = 100000;
        payRate =
          (payRate / MONTHS / PAY_RATE_TO_DOLLARS) *
          (expense.context.amount / PERCENT_TO_DECIMAL);
        if (isSameMonth(month, new Date(position.hireDate))) {
          const daysInMonth = new Date(
            month.getFullYear(),
            month.getMonth() + 1,
            0,
          ).getDate();
          const hireDate = new Date(position.hireDate);
          const daysActive = daysInMonth - hireDate.getDate() + 1;
          const percentOfMonthActive = daysActive / daysInMonth;
          payRate = payRate * percentOfMonthActive;
        }
        totalMonthlyWages += payRate;
      });
      return totalMonthlyWages * expense.context.amount;
    }
    default:
      return 0;
  }
};

import React from "react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

interface Props {
  timeseriesValue?: string;
  updateTimeModifier: (
    timeModifier:
      | "This Month"
      | "Last Month"
      | "2 Months Ago"
      | "3 Months Ago"
      | "12 Months Ago",
  ) => void;
}

const TimeseriesOptions = ({
  timeseriesValue,
  updateTimeModifier,
}: Props): React.ReactElement => {
  return (
    <Popover>
      {({ open, close }) => (
        <>
          <PopoverButton
            as="div"
            className="flex flex-row gap-x-1 items-center cursor-pointer !w-fit "
          >
            {timeseriesValue}
            <ChevronDownIcon className="size-3 stroke-2" />
          </PopoverButton>
          <PopoverPanel
            anchor="bottom end"
            as="div"
            className={`${
              open ? "block" : "hidden"
            } absolute bg-white shadow-md rounded-md z-10 border-none outline-none`}
          >
            <div className="flex flex-col">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (timeseriesValue !== "This Month") {
                    updateTimeModifier("This Month");
                  }
                  close();
                }}
                className="px-5 pt-3 pb-2 hover:bg-neutral-25 cursor-pointer"
                data-testid={`${timeseriesValue}-this-month`}
              >
                This Month
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (timeseriesValue !== "Last Month") {
                    updateTimeModifier("Last Month");
                  }
                  close();
                }}
                className="px-5 py-2 hover:bg-neutral-25 cursor-pointer"
                data-testid={`${timeseriesValue}-last-month`}
              >
                Last Month
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (timeseriesValue !== "2 Months Ago") {
                    updateTimeModifier("2 Months Ago");
                  }
                  close();
                }}
                className="px-5 py-2 hover:bg-neutral-25 cursor-pointer"
                data-testid={`${timeseriesValue}-two-months-ago`}
              >
                2 Months Ago
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (timeseriesValue !== "3 Months Ago") {
                    updateTimeModifier("3 Months Ago");
                  }
                  close();
                }}
                className="px-5 py-2 hover:bg-neutral-25 cursor-pointer"
                data-testid={`${timeseriesValue}-three-months-ago`}
              >
                3 Months Ago
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (timeseriesValue !== "12 Months Ago") {
                    updateTimeModifier("12 Months Ago");
                  }
                  close();
                }}
                className="px-5 py-2 hover:bg-neutral-25 cursor-pointer"
                data-testid={`${timeseriesValue}-twelve-months-ago`}
              >
                12 Months Ago
              </div>
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};

export default TimeseriesOptions;

import React, { useEffect, useRef } from "react";
import { IScenario } from "../../entity/types";
import { useInput } from "~/components/Input";
import InputWrapper from "~/components/Input/InputWrapper";
import { useDispatch, useSelector } from "react-redux";
import { State } from "~/store";
import request from "~/utils/request";
import { IAPIResponse } from "~/utils/types";
import { updateActiveScenarioData } from "~/store/scenarioSlice";

const SelectedScenarioTextEdit = ({
  scenario,
}: {
  scenario: IScenario;
}): React.ReactElement => {
  const dispatch = useDispatch();
  const { activeScenarioData, activeScenarioUuid } = useSelector(
    (state: State) => state.scenario,
  );
  const organizationUuid = useSelector(
    (state: State) => state.organization.uuid,
  );
  const textRef = useRef<HTMLInputElement>(null);
  const [newTitle, setNewTitle] = useInput({
    value: scenario.changeDescription,
    validation: /.*/,
  });

  useEffect(() => {
    if (activeScenarioData?.changeDescription === "Untitled Scenario") {
      textRef.current?.select();
    }
  }, []);

  const handleOnBlur = async (): Promise<void> => {
    try {
      if (
        activeScenarioData?.changeDescription !== newTitle.value &&
        newTitle.value !== ""
      ) {
        const originalTitle = activeScenarioData?.changeDescription;
        const scenarioData = activeScenarioData ?? scenario;
        dispatch(
          updateActiveScenarioData({
            ...scenarioData,
            changeDescription: newTitle.value,
          }),
        );
        const response = (await request({
          method: "PATCH",
          url: `/organizations/${organizationUuid}/scenarios/${activeScenarioUuid}`,
          body: {
            changeDescription: newTitle.value,
          },
        })) as IAPIResponse;
        if (response.status >= 400) {
          dispatch(
            updateActiveScenarioData({
              ...scenarioData,
              changeDescription: originalTitle ?? "Untitled Scenario",
            }),
          );
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <div className="w-[160px] h-[34px] border-[1px] border-neutral-75 flex items-center justify-center gap-1 px-0 py-1 rounded">
      <InputWrapper
        id="scenario-title"
        state={newTitle}
        setState={setNewTitle}
        className="!border-none !text-sm !text-blue-500 !max-h-[30px]"
        onBlur={handleOnBlur}
        textRef={textRef}
        placeholder="Untitled Scenario"
      />
    </div>
  );
};

export default SelectedScenarioTextEdit;

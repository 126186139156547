import Lottie from "lottie-react";
import React from "react";
import logoAnimation from "./logoAnimation.json";

const LoadingSpinner = (): React.ReactElement => {
  return (
    <Lottie
      animationData={logoAnimation}
      className="w-[24px] h-[24px] m-auto"
    />
  );
};

export default LoadingSpinner;

import * as harness from "@harnessio/ff-react-client-sdk";

// Put VITE_ and then the name of your feature flag in the env and set it equal to true to turn the feature flag on locally
export const useFeatureFlag = (featureFlagValue: string): boolean => {
  const harnessIsOn = Boolean(harness.useFeatureFlag(featureFlagValue));
  const envFeatureFlag = import.meta.env[`VITE_${featureFlagValue}`] as
    | string
    | undefined;

  if (envFeatureFlag) {
    if (envFeatureFlag.toLowerCase() === "true") {
      return true;
    }
    return false;
  }

  return harnessIsOn;
};

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Typography from "~/components/Typography";
import logos from "~/assets/logos";
import Button from "~/components/Button";
import useQueryParams from "~/utils/hooks/useQueryParams";

import { requestTokens } from "../Login";

const LinkSent = (): React.ReactNode => {
  const [queryParams] = useQueryParams();
  const emailValue = queryParams.get("emailValue");

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [timeLeft, setTimeLeft] = useState(30);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isButtonDisabled) {
      timer = setInterval(() => {
        setTimeLeft((time) => {
          if (time === 1) {
            setIsButtonDisabled(false);
            clearInterval(timer);
            return 30; // Reset countdown
          }
          return time - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isButtonDisabled]);

  const handleSendAgain = (): void => {
    setIsButtonDisabled(true);
    if (emailValue === null) throw new Error("Email value is null");
    requestTokens({ methodType: "passwordless", email: emailValue });
  };

  return (
    <div className="w-screen h-screen bg-green-15 pt-[10vh]">
      <div className="flex flex-col m-auto max-w-[600px] gap-2 max-sm:max-w-[95vw]">
        <Typography tag="h1" size="xl" weight="bold">
          Your login link has been sent to your email
        </Typography>
        <Typography color="empty">
          Check your email{" "}
          <span className="text-neutral-400 font-semibold">{emailValue}</span>{" "}
          for a link to login. This saves you from having to create, update &
          reset passwords.
        </Typography>
        <div className="flex flex-row justify-between mt-2 max-w-[360px]">
          <a
            href='https://mail.google.com/mail/u/0/#search/from%3A"control%40getparallel.com"'
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-row justify-center items-center bg-white hover:bg-neutral-15 border border-neutral-100 hover:border-neutral-200 rounded py-2 px-5 gap-2"
          >
            <img
              src={logos.gmailLogo}
              alt="gmail_logo"
              className="w-[16px] h-[12px]"
            />
            Open Gmail
          </a>
          <a
            href="https://outlook.live.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-row justify-center items-center bg-white hover:bg-neutral-15 border border-neutral-100 hover:border-neutral-200 rounded py-2 px-5 gap-2"
          >
            <img
              src={logos.outlookLogo}
              alt="outlook_logo"
              className="w-[16px] h-[15px]"
            />
            Open Outlook
          </a>
        </div>
        <div className="flex flex-row gap-2 mt-2 max-w-[600px] max-sm:flex-col">
          <Typography>{`Didn't receive?`}</Typography>
          <div className="flex">
            <Button
              fill="clear"
              className="!w-fit !h-fit !p-0 max-sm:mr-2"
              id="resend-login-link"
              onClick={handleSendAgain}
              disabled={isButtonDisabled}
            >
              <span className="underline underline-offset-4 max-sm:mr-2">
                Send Again{" "}
              </span>
              <span
                className={`${isButtonDisabled ? "max-sm:mr-2" : "hidden"}`}
              >{`(available in ${isButtonDisabled ? timeLeft : ""}s)`}</span>
            </Button>
          </div>
          <div className="flex">
            <Typography className="mr-2 max-sm:hidden">Or</Typography>
            <Link to="/auth/login?method=passwordless">
              <Button
                fill="clear"
                className="!w-fit !h-fit !p-0 underline max-sm:mt-2"
                id="resend-login-link"
              >
                Enter Different Email
              </Button>
            </Link>
          </div>
        </div>
        <Link
          to={`/auth/otp?emailValue=${encodeURIComponent(emailValue ?? "")}`}
          data-testid="use-password-to-login"
          className="text-green-400 hover:text-green-500 mt-4 underline max-sm:mt-2"
        >
          Use One-time code
        </Link>
      </div>
    </div>
  );
};

export default LinkSent;

import { store } from "~/store";
import { IAPIResponse } from "~/utils/types";
import waitForStoreRehydration from "~/utils/waitForStoreRehydration";
import serverRequest from "~/utils/request";
import { toZonedTime } from "date-fns-tz";
import { createMonthArrayBetweenDates } from "~/utils/dates/createMonthArrayBetweenDates";
import {
  IIntegration,
  IIntegrationMapping,
} from "~/utils/schemas/integrations";
import { IFormula, ISorting } from "./entity/types";

export default async (): Promise<unknown> => {
  await waitForStoreRehydration(store);
  const { uuid: organizationUuid } = store.getState().organization;
  const { activeScenarioUuid } = store.getState().scenario;
  const { defaultGraphStartDate, defaultGraphEndDate } =
    store.getState().user.preferences;
  const startDate = defaultGraphStartDate;
  const endDate = defaultGraphEndDate;

  const formulaListPromise = serverRequest({
    url: "/formulas",
    method: "GET",
    params: {
      startDate,
      endDate,
      includes: ["calculations"],
      scenarioUuid: activeScenarioUuid ?? undefined,
    },
    headers: { "Organization-Uuid": organizationUuid },
  });

  const sortingPromise = serverRequest({
    url: "/formulas/sorting",
    method: "GET",
    headers: { "Organization-Uuid": organizationUuid },
    params: {
      scenarioUuid: activeScenarioUuid ?? undefined,
    },
  });

  const [formulaList, sortingResponse]: [
    IAPIResponse<IFormula[]>,
    IAPIResponse<ISorting[]>,
  ] = await Promise.all([formulaListPromise, sortingPromise]);

  const monthsBetweenDates = createMonthArrayBetweenDates(
    toZonedTime(startDate, "UTC"),
    toZonedTime(endDate, "UTC"),
  );

  let dataSources: IIntegrationMapping[] = [];
  const integrations: IAPIResponse<IIntegration[]> = await serverRequest({
    url: `/integrations`,
    method: "GET",
    headers: { "Organization-Uuid": organizationUuid },
  });

  if (integrations.status >= 400)
    throw new Error("Failed to fetch integrations");

  const accountingIntegration = integrations.data.data.find(
    (integration) => integration.category === "accounting",
  );

  if (accountingIntegration) {
    const mappingsResponse: IAPIResponse<IIntegrationMapping[]> =
      await serverRequest({
        url: `/integrations/${accountingIntegration.uuid}/mappings`,
        method: "GET",
        headers: { "Organization-Uuid": organizationUuid },
      });

    if (mappingsResponse.data.data.length > 0) {
      dataSources = mappingsResponse.data.data;
    }
  }

  return {
    dataSources,
    list: formulaList.data.data,
    sorting: sortingResponse.data.data.groups,
    monthsBetweenDates,
  };
};

import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import utc from "dayjs/plugin/utc";
import ScenariosContainer from "./components/ScenariosContainer";
import {
  DashboardPageContext,
  DashboardPageContextProvider,
} from "./context/DashboardContext";
import { useDispatch, useSelector } from "react-redux";
import { State } from "~/store";
import request from "~/utils/request";
import {
  IFormulaExpensesByMonth,
  IScenario,
} from "~/pages/OldDashboard/entity/types";
import { update } from "~/store/scenarioSlice";
import { closeConversationBox } from "~/store/chatSlice";
import { ILockedIndex } from "~/components/LineGraph/entity/types";
import LockedIcon from "./components/expenseDrilldown/LockedIcon";
import IndicateDrilldownClickable from "./components/expenseDrilldown/IndicateDrilldownClickable";
import useHover from "~/utils/hooks/useHover";
import { fetchGroupedExpenses } from "./utils/fetchGroupedExpenses";
import { StatusCodes } from "http-status-codes";
import { IFormula } from "../FinancialModel/entity/types";
import date from "~/utils/dates/date";
import ExpenseDrilldownContainer from "./components/expenseDrilldown";
import { addMonths, isSameMonth, subMonths } from "date-fns";
import { toZonedTime } from "date-fns-tz";

dayjs.extend(quarterOfYear);
dayjs.extend(utc);

interface ICreateScenarioResponse {
  status: number;
  data: {
    data: IScenario;
  };
}

const DashboardContainer = (): React.ReactNode => {
  const { reports, scenarios, reload, setScenarioCreationLoading } =
    useContext(DashboardPageContext);
  const dispatch = useDispatch();
  const { activeScenarioUuid, selectedScenarioUuids } = useSelector(
    (state: State) => state.scenario,
  );
  const { isOpen } = useSelector((state: State) => state.chat);
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const { defaultGraphStartDate, defaultGraphEndDate } = useSelector(
    (state: State) => state.user.preferences,
  );
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
    window.innerWidth < 2600,
  );

  const [expensesLockedIndex, setExpensesLockedIndex] = useState<
    ILockedIndex[]
  >([]);
  const [selectedMonthsGroupedExpenses, setSelectedMonthsGroupedExpenses] =
    useState<IFormulaExpensesByMonth[]>([]);
  const [groupedExpenses, setGroupedExpenses] = useState<IFormula[] | null>(
    null,
  );

  useEffect(() => {
    const getGroupedExpenses = async (): Promise<void> => {
      if (
        !organizationUuid ||
        !defaultGraphStartDate ||
        !defaultGraphEndDate ||
        Boolean(activeScenarioUuid) ||
        Boolean(selectedScenarioUuids.length)
      ) {
        setGroupedExpenses(null);
        return;
      }
      const parsedResponse = await fetchGroupedExpenses({
        organizationUuid,
        startDate: defaultGraphStartDate,
        endDate: defaultGraphEndDate,
        activeScenarioUuid,
      });
      setGroupedExpenses(parsedResponse);
    };
    getGroupedExpenses();
  }, [
    organizationUuid,
    defaultGraphStartDate,
    defaultGraphEndDate,
    activeScenarioUuid,
    selectedScenarioUuids.length,
  ]);

  useEffect(() => {
    const handleResize = (): void => {
      setIsSmallScreen(window.innerWidth < 2600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCreateQuickScenario = async (): Promise<void> => {
    try {
      setScenarioCreationLoading(true);
      const response = (await request({
        method: "POST",
        url: `/organizations/${organizationUuid}/scenarios`,
        body: {
          type: "default",
        },
      })) as ICreateScenarioResponse;

      if (response.status === StatusCodes.CREATED) {
        dispatch(
          update({
            activeScenarioUuid: response.data.data.uuid,
            activeScenarioData: response.data.data,
            activeScenarioHasChanges: false,
            leverChanges: [],
            cashBalanceLockedIndexes: [],
            cashBalanceSelectedPoint: null,
            selectedScenarioUuids,
            isTrayCollapsed: false,
          }),
        );
        await reload();
        dispatch(closeConversationBox());
      } else {
        throw Error("Failed to create scenario");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setScenarioCreationLoading(false);
    }
  };

  const handleClickFormulaExpenses = (index: number): void => {
    if (index < 0 || !groupedExpenses) return;
    const isFormulaLocked = expensesLockedIndex.some(
      (item) => item.index === index,
    );
    setExpensesLockedIndex(
      isFormulaLocked ? [] : [{ index: index, icon: <LockedIcon /> }],
    );
    setSelectedMonthsGroupedExpenses(
      isFormulaLocked
        ? []
        : groupedExpenses.map((formula) => {
            const dateCalc = formula.calculations?.find((calc) =>
              isSameMonth(
                addMonths(toZonedTime(defaultGraphStartDate, "UTC"), index),
                toZonedTime(calc.date, "UTC"),
              ),
            );
            const previousDateCalc = formula.calculations?.find((calc) =>
              isSameMonth(
                subMonths(
                  addMonths(toZonedTime(defaultGraphStartDate, "UTC"), index),
                  1,
                ),
                toZonedTime(calc.date, "UTC"),
              ),
            );
            const actualValue = formula.actuals?.find(
              (actual) => actual.date === dateCalc?.date,
            )?.value;
            const prevActualValue = formula.actuals?.find(
              (actual) => actual.date === previousDateCalc?.date,
            )?.value;
            const overrideValue = formula.overrides?.find(
              (override) => override.date === dateCalc?.date,
            )?.value;
            const prevOverrideValue = formula.overrides?.find(
              (override) => override.date === previousDateCalc?.date,
            )?.value;

            let impactValue = 0;
            if (actualValue !== undefined) {
              impactValue = actualValue * 100;
            } else if (overrideValue !== undefined) {
              impactValue = overrideValue * 100;
            } else if (dateCalc?.value !== undefined) {
              impactValue = dateCalc.value ?? 0;
            }

            let prevImpactValue = 0;
            if (prevActualValue !== undefined) {
              prevImpactValue = prevActualValue * 100;
            } else if (prevOverrideValue !== undefined) {
              prevImpactValue = prevOverrideValue * 100;
            } else if (previousDateCalc?.value !== undefined) {
              prevImpactValue = previousDateCalc.value ?? 0;
            }

            const monthOverMonthChange = impactValue - prevImpactValue;
            const percentChange =
              (monthOverMonthChange / prevImpactValue) * 100;

            return {
              name: formula.recipe.name,
              impact: impactValue,
              monthOverMonthDollar: monthOverMonthChange,
              monthOverMonthPercent: percentChange,
              date: dateCalc?.date ?? date(),
            };
          }),
    );
  };

  const [expenseGraphRef, expenseGraphRefHovering] = useHover();

  return (
    <div
      className={`transition-all duration-500 ${
        isOpen && isSmallScreen ? "pr-[25%]" : ""
      } w-full flex flex-col max-sm:items-center max-sm:pb-16`}
    >
      <div
        className={`flex flex-col gap-4 ${isOpen ? "w-[100%-360px]" : "w-full"} max-sm:ml-2`}
      >
        <div>
          <ScenariosContainer
            cashBalanceReport={reports.cashBalance}
            cashflowReport={reports.cashflow}
            expensesReport={reports.expenses}
            revenueReport={reports.revenue}
            runway={reports.runway}
            title="Dashboard"
            scenarios={scenarios}
            canQuickScenario
            createQuickScenario={handleCreateQuickScenario}
            expensesLockedIndex={expensesLockedIndex}
            handleClickExpenses={
              groupedExpenses && !activeScenarioUuid
                ? handleClickFormulaExpenses
                : undefined
            }
            expenseGraphRef={expenseGraphRef}
          />
        </div>
        <div className="w-full flex justify-center items-center">
          <div
            className={
              "w-[80%] max-w-[1442px] min-w-[720px] max-sm:min-w-[95vw] max-sm:max-w-[95vw]"
            }
          >
            {!selectedMonthsGroupedExpenses.length &&
              expenseGraphRefHovering &&
              !activeScenarioUuid &&
              groupedExpenses && <IndicateDrilldownClickable />}
            {selectedMonthsGroupedExpenses.length > 0 &&
              !activeScenarioUuid &&
              groupedExpenses && (
                <ExpenseDrilldownContainer
                  selectedMonthsGroupedExpenses={selectedMonthsGroupedExpenses}
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Dashboard = (): React.ReactNode => {
  return (
    <DashboardPageContextProvider>
      <DashboardContainer />
    </DashboardPageContextProvider>
  );
};

export default Dashboard;

import React, { useContext, useEffect, useMemo, useState } from "react";
import Modal from "~/components/Modal";
import Typography from "~/components/Typography";
import { RatiosContext } from "../context/RatiosContext";
import Divider from "~/components/Divider";
import Input from "~/components/Input/InputWrapper";
import Button from "~/components/Button";
import RadioInput from "~/components/RadioInput";
import FormulaList from "~/pages/FinancialModel/components/FormulaBuilder/FormulaList";
import { IFormula } from "~/pages/FinancialModel/entity/types";
import createRatio from "../utils/createRatio";
import { useSelector } from "react-redux";
import { State } from "~/store";
import { IRatio } from "../entity/types";
import request from "~/utils/request";
import { IAPIResponse } from "~/utils/types";
import toast from "react-hot-toast";
import { useFeatureFlag } from "~/utils/hooks/useFeatureFlag";

const CreateRatioModal = (): React.ReactNode => {
  const {
    isCreateRatioModalOpen,
    setIsCreateRatioModalOpen,
    positionNeeded,
    amount,
    drivenByValue,
    setPositionNeeded,
    setAmount,
    setDrivenByValue,
    driverType,
    setDriverType,
    resetFormState,
    formulasList,
    ratiosList,
    setRatiosList,
    editRatioUuid,
    setEditRatioUuid,
    setFormStateFromIRatio,
  } = useContext(RatiosContext);

  const scenarioDiffing = useFeatureFlag("scenarioDiffing");

  const { defaultGraphStartDate, defaultGraphEndDate } = useSelector(
    (state: State) => state.user.preferences,
  );
  const organizationUuid = useSelector(
    (state: State) => state.organization.uuid,
  );
  const activeScenarioUuid = useSelector(
    (state: State) => state.scenario.activeScenarioUuid,
  );
  const [highlightedFormula, setHighlightedFormula] = useState<{
    formula?: IFormula;
    index: number;
  }>({
    formula:
      driverType.selected?.value === "modelAttribute" && drivenByValue.value
        ? formulasList[0]
        : undefined,
    index: 0,
  });

  const filteredFormulaList = useMemo(() => {
    if (!drivenByValue.value.length) return [];
    const filteredList = formulasList.filter((formula) =>
      formula.recipe.name
        .toLowerCase()
        .includes(drivenByValue.value.trim().toLowerCase()),
    );
    if (
      filteredList.length === 1 &&
      drivenByValue.value.length &&
      drivenByValue.value === filteredList[0].recipe.name
    ) {
      return [];
    }
    return filteredList;
  }, [drivenByValue.value, formulasList]);

  const amountHasError = !amount.valid && amount.touched && !amount.pristine;
  const drivenByValueHasError =
    !drivenByValue.valid && drivenByValue.touched && !drivenByValue.pristine;

  const downArrowPressed = (e: React.KeyboardEvent<Element>): void => {
    e.preventDefault();
    if (filteredFormulaList.length) {
      setHighlightedFormula((prev) => ({
        formula:
          drivenByValue.value.length &&
          prev.index < filteredFormulaList.length - 1
            ? filteredFormulaList[prev.index + 1]
            : filteredFormulaList[0],
        index:
          drivenByValue.value.length &&
          prev.index < filteredFormulaList.length - 1
            ? prev.index + 1
            : 0,
      }));
    }
  };

  const upArrowPressed = (e: React.KeyboardEvent<Element>): void => {
    e.preventDefault();
    if (filteredFormulaList.length) {
      setHighlightedFormula((prev) => ({
        formula:
          drivenByValue.value.length && prev.index > 0
            ? filteredFormulaList[prev.index - 1]
            : filteredFormulaList[filteredFormulaList.length - 1],
        index:
          drivenByValue.value.length && prev.index > 0
            ? prev.index - 1
            : filteredFormulaList.length - 1,
      }));
    }
  };

  const enterPressed = (e: React.KeyboardEvent<Element>): void => {
    e.preventDefault();
    if (filteredFormulaList[highlightedFormula.index]) {
      setDrivenByValue((prevState) => ({
        ...prevState,
        value: filteredFormulaList[highlightedFormula.index].recipe.name,
      }));
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<Element>): void => {
    if (e.key === "ArrowDown") {
      downArrowPressed(e);
    } else if (e.key === "ArrowUp") {
      upArrowPressed(e);
    } else if (e.key === "Enter") {
      enterPressed(e);
    }
  };

  const onSelectAttribute = (attribute: IFormula): void => {
    setDrivenByValue((prevState) => ({
      ...prevState,
      value: attribute.recipe.name,
    }));
    setHighlightedFormula({
      formula:
        driverType.selected?.value === "modelAttribute" && drivenByValue.value
          ? formulasList[0]
          : undefined,
      index: 0,
    });
  };

  const successCallback = ({
    createdRatio,
    editedRatioUuid,
  }: {
    createdRatio: IRatio;
    editedRatioUuid: string | null;
  }): void => {
    setIsCreateRatioModalOpen(false);
    resetFormState();
    if (editedRatioUuid) {
      setRatiosList((prevState) =>
        prevState.map((ratio) =>
          ratio.uuid === editedRatioUuid ? createdRatio : ratio,
        ),
      );
    } else {
      setRatiosList((prevState) => [...prevState, createdRatio]);
    }
    setEditRatioUuid(null);
  };

  useEffect(() => {
    const assignFormState = async (): Promise<void> => {
      const ratio = ratiosList.find((ratio) => ratio.uuid === editRatioUuid);
      if (ratio) {
        setFormStateFromIRatio(ratio);
      }
    };
    if (editRatioUuid) {
      assignFormState();
    }
  }, [editRatioUuid]);

  const handleDeleteRatio = async (): Promise<void> => {
    try {
      const response = (await request({
        method: "DELETE",
        url: `/ratios/${editRatioUuid}`,
        params: {
          scenarioUuid: activeScenarioUuid ?? undefined,
        },
        headers: {
          "Organization-Uuid": organizationUuid,
        },
      })) as IAPIResponse<unknown>;

      if (response.status === 204) {
        setIsCreateRatioModalOpen(false);
        setEditRatioUuid(null);
        setRatiosList((prevState) =>
          prevState.filter((ratio) => ratio.uuid !== editRatioUuid),
        );
        resetFormState();
        toast.success("Ratio deleted successfully");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <Modal
      isOpen={isCreateRatioModalOpen}
      onClose={() => {
        setIsCreateRatioModalOpen(false);
        setEditRatioUuid(null);
      }}
      size="md"
      title={editRatioUuid ? "Edit Ratio" : "New Ratio"}
    >
      <div
        data-testid="create-ratio-modal"
        className="flex flex-col gap-4 w-full"
      >
        <div>
          <Input
            state={positionNeeded}
            setState={setPositionNeeded}
            label="Position Needed"
            subLabel="This will tally every position that contains the specified text in its title"
            id="positionNeeded"
          />
        </div>
        <div className="flex w-full gap-4 items-center justify-center">
          <Divider className="flex-grow w-full" />
          <Typography size="xs" color="empty" className="text-nowrap">
            For Every
          </Typography>
          <Divider className="flex-grow w-full" />
        </div>
        <div className="flex flex-row gap-4 text-nowrap items-center justify-start">
          <Typography size="xs">Driven by:</Typography>
          <RadioInput
            id="driverType"
            state={driverType}
            setState={setDriverType}
            flexDirection="flex-row"
            className="!w-fit !justify-start !items-start"
          />
        </div>
        <div className="flex flex-row w-full justify-between items-end gap-4">
          <div
            className={`w-[50%] ${drivenByValueHasError && !amountHasError ? "mb-[29px]" : ""}`}
          >
            <Input
              state={amount}
              setState={setAmount}
              label="Amount"
              id="amount"
              type="currency"
            />
          </div>
          <div
            className={`${amountHasError || drivenByValueHasError ? "mb-[41px]" : "mb-3"}`}
          >
            <Typography size="xs" color="empty">
              of
            </Typography>
          </div>
          <div
            className={`w-[50%] ${!drivenByValueHasError && amountHasError ? "mb-[29px]" : ""}`}
          >
            <Input
              label={driverType.selected?.label}
              state={drivenByValue}
              setState={setDrivenByValue}
              id="drivenByValue"
              type={
                driverType.selected?.value === "modelAttribute"
                  ? "search"
                  : "text"
              }
              placeholder={
                driverType.selected?.value === "modelAttribute" ? "Search" : ""
              }
              onKeyDown={
                driverType.selected?.value === "modelAttribute"
                  ? handleKeyDown
                  : undefined
              }
            />
            {driverType.selected?.value === "modelAttribute" && (
              <FormulaList
                value={drivenByValue.value}
                formulaList={filteredFormulaList}
                onSelectAttribute={onSelectAttribute}
                highlightedFormula={highlightedFormula}
                setHighlightedFormula={setHighlightedFormula}
              />
            )}
          </div>
        </div>
        <div className="flex flex-row justify-between gap-4">
          <Button
            fill="clear"
            className="!w-fit !px-0"
            onClick={() => {
              setIsCreateRatioModalOpen(false);
              setEditRatioUuid(null);
              resetFormState();
            }}
          >
            Cancel
          </Button>
          <div className="flex flex-row gap-4">
            {editRatioUuid && (
              <Button
                id="delete-ratio-button"
                fill="destructiveOutline"
                className="!w-fit !px-4"
                onClick={handleDeleteRatio}
              >
                Delete
              </Button>
            )}
            <Button
              className="!w-fit !px-4"
              id="submit-ratio-button"
              onClick={() =>
                createRatio({
                  startDate: defaultGraphStartDate,
                  endDate: defaultGraphEndDate,
                  organizationUuid,
                  activeScenarioUuid,
                  positionNeeded,
                  amount,
                  drivenByValue,
                  driverType,
                  formulasList,
                  setPositionNeeded,
                  setAmount,
                  setDrivenByValue,
                  setDriverType,
                  successCallback,
                  editRatioUuid,
                  scenarioDiffing,
                })
              }
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateRatioModal;

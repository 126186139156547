import store from "~/store";

export const getDepartmentsSelected = async ({
  departmentUuids,
  scenarioDiffing,
}: {
  departmentUuids: string[];
  scenarioDiffing: boolean;
}): Promise<{ label: string; value: string }[]> => {
  const departments = store.getState().organization.departments;

  let selected: { label: string; value: string }[] = [];
  selected =
    departmentUuids.length === 1 && departmentUuids[0] === "ALL"
      ? [{ label: "All Departments", value: "ALL" }]
      : departments.reduce(
          (acc, department) => {
            if (
              departmentUuids.includes(
                scenarioDiffing ? department.departmentUuid : department.uuid,
              )
            ) {
              acc.push({
                label: department.name,
                value: scenarioDiffing
                  ? department.departmentUuid
                  : department.uuid,
              });
            }
            return acc;
          },
          [] as { label: string; value: string }[],
        );

  return selected;
};

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import FormulaList from "./FormulaList";
import {
  IFormula,
  IFormulaSegment,
  IUpdateCalculationModifier,
  IUpdateTimeModifier,
  IValueToAdd,
  IVariables,
} from "../../entity/types";
import { VariableTypeEnum } from "../../entity/schemas";
import Typography from "~/components/Typography";
import validateFormula from "../../utils/validateFormula";
import { z } from "zod";
import FormulaBuilderContentEditable from "./FormulaBuilderContentEditable";
import shiftFormulaIndexes from "../../utils/shiftFormulaIndexes";
import { FormulaBuilderContext } from "./FormulaBuilderContext";
import updateFormulaAndVariables from "../../utils/updateFormulaAndVariables";

const FormulaBuilderInput = ({
  formulaUuid,
  formulaList,
  handleUpdateCalculationModifier,
  handleUpdateTimeModifier,
  formulaTitle,
}: {
  formulaUuid?: string;
  formulaList: IFormula[];
  handleUpdateCalculationModifier?: ({
    calculationModifier,
    formulaForUpdate,
    formulaTextValue,
    refToUpdate,
  }: IUpdateCalculationModifier) => void;
  handleUpdateTimeModifier: ({
    timeModifier,
    formulaForUpdate,
    formulaTextValue,
    refToUpdate,
  }: IUpdateTimeModifier) => void;
  formulaTitle?: string;
}): ReactElement => {
  const {
    variables,
    setVariables,
    displayFormulaError,
    setDisplayFormulaError,
    updatedFormula,
    setUpdatedFormula,
    value,
    setValue,
    inputPosition,
    setInputPosition,
    setFormula,
    segmentToDelete,
    setSegmentToDelete,
    inputRef,
    setEnteredConstantFrom,
  } = useContext(FormulaBuilderContext);
  const isDisabled = Boolean(
    formulaTitle &&
      [
        "Headcount",
        "New Hires",
        "Headcount Related",
        "Software Expenses",
        "Salary and Wages",
        "Other Expenses",
      ].includes(formulaTitle),
  );

  const prevLengthRef = useRef(updatedFormula.length);

  useEffect(() => {
    if (prevLengthRef.current === 0 && updatedFormula.length > 0) {
      setInputPosition(updatedFormula.length);
    }
    prevLengthRef.current = updatedFormula.length;
  }, [updatedFormula.length]);

  const filteredFormulaList = useMemo(() => {
    if (!value.length) return [];
    const filteredList = formulaList.filter((formula) =>
      formula.recipe.name.toLowerCase().includes(value.trim().toLowerCase()),
    );
    return filteredList;
  }, [value, formulaList]);

  const [highlightedFormula, setHighlightedFormula] = useState<{
    formula?: IFormula;
    index: number;
  }>({ formula: value.length ? filteredFormulaList[0] : undefined, index: 0 });

  useEffect(() => {
    const formulaToValidate = [...updatedFormula];
    let expression = updatedFormula.map((f) => f.textValue).join("");
    const recipeVariables: IVariables = { ...variables };
    if (value.length) {
      expression += `$${Number(Object.keys(variables).length) + 1}`;
      formulaToValidate.push({
        element: <span key={value}>{value}</span>,
        ref: null,
        textValue: `$${Number(Object.keys(variables).length) + 1}`,
        type: "constant",
      });
      recipeVariables[`$${Number(Object.keys(variables).length) + 1}`] = {
        type: VariableTypeEnum.Constant,
        constantValue: Number(value),
        formulaUuid: null,
        timeModifier: {},
        calculationType: null,
      };
    }
    const { validated } = validateFormula({
      formulaToValidate: formulaToValidate,
      formulaList: formulaList,
      expression,
      recipeVariables,
    });
    if (validated) {
      setDisplayFormulaError({
        isDisplayed: false,
        message: "",
      });
    }
  }, [value, updatedFormula, variables]);

  useEffect(() => {
    if (filteredFormulaList.length) {
      setHighlightedFormula({
        formula: filteredFormulaList[0],
        index: 0,
      });
    } else {
      setHighlightedFormula({ formula: undefined, index: 0 });
    }
  }, [filteredFormulaList]);

  const onSelectAttribute = (selectedFormula: IFormula): void => {
    const valuesToAdd: IValueToAdd[] = [];

    const isCalculated = Boolean(
      (formulaTitle &&
        [
          "Headcount",
          "New Hires",
          "Headcount Related",
          "Software Expenses",
          "Salary and Wages",
          "Other Expenses",
        ].includes(formulaTitle)) ||
        ["Headcount", "New Hires"].includes(selectedFormula.recipe.name),
    );

    valuesToAdd.push({
      type: isCalculated ? "calculated" : "formula",
      value: selectedFormula.recipe.name,
      newIndex: inputPosition,
      associatedFormula: selectedFormula,
    });

    const { newFormula, newVariables } = updateFormulaAndVariables({
      valuesToAdd,
      formulaCopy: [...updatedFormula],
      variablesCopy: { ...variables },
      formulaUuid,
      handleUpdateCalculationModifier,
      handleUpdateTimeModifier,
    });

    const { formulaWithShiftedIndexes, variablesWithShiftedIndexes } =
      shiftFormulaIndexes({
        oldFormula: newFormula,
        oldVariables: newVariables,
      });

    setFormula(formulaWithShiftedIndexes);
    setVariables(variablesWithShiftedIndexes);

    setUpdatedFormula(formulaWithShiftedIndexes);

    setVariables(variablesWithShiftedIndexes);
    setInputPosition(inputPosition + 1);

    setValue("");
    inputRef.current?.focus();
  };

  const handleAddOperator = ({
    operator,
    desiredInputIndex,
    splitValueAtIndex,
  }: {
    operator: string | null;
    desiredInputIndex?: number;
    splitValueAtIndex?: number;
  }): void => {
    const valuesToAdd: IValueToAdd[] = [];
    let amountToAdd = 0;
    if (value.length && operator && splitValueAtIndex !== undefined) {
      const firstValue = value.substring(0, splitValueAtIndex).trim();
      const subsequentValue = value.substring(splitValueAtIndex).trim();

      if (firstValue) {
        const isValidNumber = z.number().safeParse(Number(firstValue));
        valuesToAdd.push({
          type: isValidNumber.success ? "constant" : "invalid",
          value: firstValue,
          newIndex: inputPosition,
        });
        amountToAdd++;
      }

      valuesToAdd.push({
        type: "operator",
        value: operator,
        newIndex: firstValue ? inputPosition + 1 : inputPosition,
      });
      amountToAdd++;

      if (subsequentValue) {
        const isValidNumber = z.number().safeParse(Number(subsequentValue));
        valuesToAdd.push({
          type: isValidNumber.success ? "constant" : "invalid",
          value: subsequentValue,
          newIndex: firstValue ? inputPosition + 2 : inputPosition + 1,
        });
      }
    } else if (value.length) {
      const isValidNumber = z.number().safeParse(Number(value.trim()));
      valuesToAdd.push({
        type: isValidNumber.success ? "constant" : "invalid",
        value: value.trim(),
        newIndex: inputPosition,
      });
      amountToAdd++;
    } else if (operator) {
      const indexForOperator = value.length ? inputPosition + 1 : inputPosition;
      valuesToAdd.push({
        type: "operator",
        value: operator,
        newIndex: indexForOperator,
      });
      amountToAdd++;
    }

    const { newFormula, newVariables } = updateFormulaAndVariables({
      valuesToAdd,
      formulaCopy: [...updatedFormula],
      variablesCopy: { ...variables },
    });

    const { formulaWithShiftedIndexes, variablesWithShiftedIndexes } =
      shiftFormulaIndexes({
        oldFormula: newFormula,
        oldVariables: newVariables,
      });

    setFormula(formulaWithShiftedIndexes);

    setVariables(variablesWithShiftedIndexes);
    setUpdatedFormula(formulaWithShiftedIndexes);
    const newInputIndex = desiredInputIndex ?? inputPosition + amountToAdd;
    setInputPosition(newInputIndex);
    setValue("");
    inputRef.current?.focus();
  };

  const handleSegmentDeletion = (newFormula: IFormulaSegment[]): void => {
    if (newFormula.length === 0) {
      setVariables({});
      setUpdatedFormula([]);
      setFormula([]);
    }
  };

  const removeSegmentToDelete = (): void => {
    if (segmentToDelete) {
      updatedFormula[
        segmentToDelete.segmentIndex
      ].ref.current?.classList.remove(
        "!bg-green-25",
        "!border-green-400",
        "text-green-500",
      );
      setSegmentToDelete(undefined);
    }

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const commonOperations = ({
    indexToDelete,
    variableKeyToDelete,
    enteredFrom,
    updateInputPosition,
    formulaToUse,
    variablesToUse,
  }: {
    indexToDelete: number;
    variableKeyToDelete: string;
    enteredFrom?: "left" | "right";
    updateInputPosition?: (prevPosition: number) => number;
    formulaToUse?: IFormulaSegment[];
    variablesToUse?: IVariables;
  }): void => {
    const newFormula = formulaToUse ? [...formulaToUse] : [...updatedFormula];
    newFormula.splice(indexToDelete, 1);
    const newVariables = variablesToUse
      ? { ...variablesToUse }
      : { ...variables };
    delete newVariables[variableKeyToDelete];

    const { formulaWithShiftedIndexes, variablesWithShiftedIndexes } =
      shiftFormulaIndexes({
        oldFormula: newFormula,
        oldVariables: newVariables,
      });

    setFormula(formulaWithShiftedIndexes);
    setVariables(variablesWithShiftedIndexes);

    setUpdatedFormula(formulaWithShiftedIndexes);

    setValue((variables[variableKeyToDelete]?.constantValue ?? "").toString());
    inputRef.current?.focus();
    handleSegmentDeletion(formulaWithShiftedIndexes);

    if (enteredFrom) {
      setEnteredConstantFrom(enteredFrom);
    }

    if (updateInputPosition) {
      setInputPosition(updateInputPosition);
    }
  };

  const handleDeleteAndBackspace = ({
    e,
    position,
  }: {
    e: React.KeyboardEvent<HTMLDivElement>;
    position?: "beginning" | "end";
  }): void => {
    const newInputPosition =
      e.key === "Backspace" ? inputPosition - 1 : inputPosition;

    if (segmentToDelete) {
      if (value.length) {
        const isValidNumber = z.number().safeParse(Number(value.trim()));
        const { newFormula, newVariables } = updateFormulaAndVariables({
          valuesToAdd: [
            {
              type: isValidNumber.success ? "constant" : "invalid",
              value: value.trim(),
              newIndex: inputPosition,
            },
          ],
          formulaCopy: [...updatedFormula],
          variablesCopy: { ...variables },
        });
        commonOperations({
          indexToDelete: segmentToDelete.segmentIndex,
          variableKeyToDelete: segmentToDelete.segmentValue,
          updateInputPosition: () => Math.max(segmentToDelete.segmentIndex, 0),
          formulaToUse: newFormula,
          variablesToUse: newVariables,
        });
        setSegmentToDelete(undefined);
      } else {
        commonOperations({
          indexToDelete: segmentToDelete.segmentIndex,
          variableKeyToDelete: segmentToDelete.segmentValue,
          updateInputPosition: () => Math.max(segmentToDelete.segmentIndex, 0),
        });
        setSegmentToDelete(undefined);
      }
    } else {
      if (position) {
        const isValidNumber = z.number().safeParse(Number(value.trim()));
        const { newFormula, newVariables } = updateFormulaAndVariables({
          valuesToAdd: [
            {
              type: isValidNumber.success ? "constant" : "invalid",
              value: value.trim(),
              newIndex: inputPosition,
            },
          ],
          formulaCopy: [...updatedFormula],
          variablesCopy: { ...variables },
        });
        const segmentToSelectIndex =
          position === "beginning" ? inputPosition - 1 : inputPosition + 1;
        const selectedSegment = newFormula[segmentToSelectIndex];
        if (selectedSegment.type === "operator") {
          newFormula.splice(segmentToSelectIndex, 1);
          const { formulaWithShiftedIndexes, variablesWithShiftedIndexes } =
            shiftFormulaIndexes({
              oldFormula: newFormula,
              oldVariables: newVariables,
            });

          setFormula(formulaWithShiftedIndexes);
          setVariables(variablesWithShiftedIndexes);

          setUpdatedFormula(formulaWithShiftedIndexes);
          setValue("");
          setInputPosition(segmentToSelectIndex);
        } else if (
          selectedSegment.type === "constant" ||
          selectedSegment.type === "invalid"
        ) {
          commonOperations({
            indexToDelete: segmentToSelectIndex,
            variableKeyToDelete: selectedSegment.textValue,
            enteredFrom: position === "beginning" ? "right" : "left",
            updateInputPosition: () => segmentToSelectIndex,
            formulaToUse: newFormula,
            variablesToUse: newVariables,
          });
        } else if (
          selectedSegment.type === "formula" ||
          selectedSegment.type === "calculated"
        ) {
          if (selectedSegment.ref.current) {
            selectedSegment.ref.current.classList.add(
              "!bg-green-25",
              "!border-green-400",
              "text-green-500",
            );
          }

          setSegmentToDelete({
            segmentRef: selectedSegment.ref,
            segmentIndex: segmentToSelectIndex,
            segmentValue: selectedSegment.textValue,
          });
        }
      } else {
        const selectedSegment = updatedFormula[newInputPosition];
        if (selectedSegment.type === "operator") {
          const newFormula = [...updatedFormula];
          newFormula.splice(newInputPosition, 1);
          setUpdatedFormula(newFormula);
          setInputPosition((prev) =>
            Math.max(e.key === "Backspace" ? prev - 1 : prev, 0),
          );
        } else if (
          selectedSegment.type === "constant" ||
          selectedSegment.type === "invalid"
        ) {
          commonOperations({
            indexToDelete: newInputPosition,
            variableKeyToDelete: selectedSegment.textValue,
            enteredFrom: e.key === "Backspace" ? "right" : "left",
            updateInputPosition: (prev) =>
              Math.max(e.key === "Backspace" ? prev - 1 : prev, 0),
          });
        } else {
          // Handle other segment types (e.g., variables)
          if (selectedSegment.ref.current) {
            selectedSegment.ref.current.classList.add(
              "!bg-green-25",
              "!border-green-400",
              "text-green-500",
            );
          }

          setSegmentToDelete({
            segmentRef: selectedSegment.ref,
            segmentIndex: newInputPosition,
            segmentValue: selectedSegment.textValue,
          });
        }
      }
    }
  };

  const upArrowPressed = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    e.preventDefault();
    if (filteredFormulaList.length) {
      setHighlightedFormula((prev) => ({
        formula:
          value.length && prev.index > 0
            ? filteredFormulaList[prev.index - 1]
            : filteredFormulaList[filteredFormulaList.length - 1],
        index:
          value.length && prev.index > 0
            ? prev.index - 1
            : filteredFormulaList.length - 1,
      }));
    } else if (!value.length) {
      setInputPosition(0);
    }
    removeSegmentToDelete();
  };

  const downArrowPressed = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    e.preventDefault();
    if (filteredFormulaList.length) {
      setHighlightedFormula((prev) => ({
        formula:
          value.length && prev.index < filteredFormulaList.length - 1
            ? filteredFormulaList[prev.index + 1]
            : filteredFormulaList[0],
        index:
          value.length && prev.index < filteredFormulaList.length - 1
            ? prev.index + 1
            : 0,
      }));
    } else if (!value.length) {
      setInputPosition(updatedFormula.length);
    }
    removeSegmentToDelete();
  };

  const leftArrowPressed = ({
    position,
  }: {
    position?: "beginning" | "end";
  }): void => {
    if (inputPosition > 0 && !segmentToDelete && !position) {
      const selectedSegment = updatedFormula[inputPosition - 1];
      if (
        selectedSegment.type === "constant" ||
        selectedSegment.type === "invalid"
      ) {
        commonOperations({
          indexToDelete: inputPosition - 1,
          variableKeyToDelete: selectedSegment.textValue,
          enteredFrom: "right",
          updateInputPosition: (prevPosition) => Math.max(prevPosition - 1, 0),
        });
      } else {
        setInputPosition((prevPosition) => Math.max(prevPosition - 1, 0));
      }
    } else if (segmentToDelete && !position) {
      setInputPosition(() => Math.max(segmentToDelete.segmentIndex, 0));
    } else if (position === "beginning") {
      const isValidNumber = z.number().safeParse(Number(value.trim()));
      const { newFormula, newVariables } = updateFormulaAndVariables({
        valuesToAdd: [
          {
            type: isValidNumber.success ? "constant" : "invalid",
            value: value.trim(),
            newIndex: inputPosition,
          },
        ],
        formulaCopy: [...updatedFormula],
        variablesCopy: { ...variables },
      });
      const segmentToSelectIndex = inputPosition - 1;
      const selectedSegment = newFormula[segmentToSelectIndex];
      if (
        selectedSegment.type === "constant" ||
        selectedSegment.type === "invalid"
      ) {
        commonOperations({
          indexToDelete: segmentToSelectIndex,
          variableKeyToDelete: selectedSegment.textValue,
          enteredFrom: "right",
          updateInputPosition: () => segmentToSelectIndex,
          formulaToUse: newFormula,
          variablesToUse: newVariables,
        });
      } else {
        handleAddOperator({ operator: null, desiredInputIndex: inputPosition });
      }
    }
    removeSegmentToDelete();
  };

  const rightArrowPressed = ({
    position,
  }: {
    position?: "beginning" | "end";
  }): void => {
    if (
      inputPosition < updatedFormula.length &&
      !segmentToDelete &&
      !position
    ) {
      const selectedSegment = updatedFormula[inputPosition];
      if (
        selectedSegment.type === "constant" ||
        selectedSegment.type === "invalid"
      ) {
        commonOperations({
          indexToDelete: inputPosition,
          variableKeyToDelete: selectedSegment.textValue,
          enteredFrom: "left",
          updateInputPosition: (prevPosition) =>
            Math.min(prevPosition, updatedFormula.length),
        });
      } else {
        setInputPosition((prevPosition) =>
          Math.min(prevPosition + 1, updatedFormula.length),
        );
      }
    } else if (segmentToDelete && !position) {
      setInputPosition(() =>
        Math.min(
          Math.max(segmentToDelete.segmentIndex + 1, 0),
          updatedFormula.length,
        ),
      );
    } else if (position === "end") {
      const isValidNumber = z.number().safeParse(Number(value.trim()));
      const { newFormula, newVariables } = updateFormulaAndVariables({
        valuesToAdd: [
          {
            type: isValidNumber.success ? "constant" : "invalid",
            value: value.trim(),
            newIndex: inputPosition,
          },
        ],
        formulaCopy: [...updatedFormula],
        variablesCopy: { ...variables },
      });
      const segmentToSelectIndex = inputPosition + 1;
      const selectedSegment = newFormula[segmentToSelectIndex];
      if (
        selectedSegment &&
        (selectedSegment.type === "constant" ||
          selectedSegment.type === "invalid")
      ) {
        commonOperations({
          indexToDelete: segmentToSelectIndex,
          variableKeyToDelete: selectedSegment.textValue,
          enteredFrom: "left",
          updateInputPosition: () => segmentToSelectIndex,
          formulaToUse: newFormula,
          variablesToUse: newVariables,
        });
      } else {
        handleAddOperator({ operator: null });
      }
    }
    removeSegmentToDelete();
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent): void => {
      if (value.length) {
        handleAddOperator({ operator: null });
      }
      if (segmentToDelete?.segmentRef.current) {
        const clickedElement = e.target as Node;
        if (
          !updatedFormula[segmentToDelete.segmentIndex].ref.current?.contains(
            clickedElement,
          ) &&
          !inputRef.current?.contains(clickedElement)
        ) {
          removeSegmentToDelete();
        }
      }
      if (inputRef.current && !segmentToDelete) {
        inputRef.current.focus();
      }
    };
    if (Boolean(segmentToDelete) || value.length) {
      document.addEventListener("mouseup", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [segmentToDelete, updatedFormula, value]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent): void => {
      if (
        (!document.activeElement ||
          document.activeElement !== inputRef.current) &&
        (e.key === "Delete" || e.key === "Backspace")
      ) {
        handleDeleteAndBackspace({
          e: e as unknown as React.KeyboardEvent<HTMLDivElement>,
        });
      } else if (e.key === "ArrowLeft") {
        leftArrowPressed({});
      } else if (e.key === "ArrowRight") {
        rightArrowPressed({});
      } else if (e.key === "ArrowUp") {
        upArrowPressed(e as unknown as React.KeyboardEvent<HTMLDivElement>);
      } else if (e.key === "ArrowDown") {
        downArrowPressed(e as unknown as React.KeyboardEvent<HTMLDivElement>);
      }
    };

    if (Boolean(segmentToDelete) || value.length) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [segmentToDelete, updatedFormula, variables, value]);

  const handleInputKeyDown = ({
    e,
    position,
  }: {
    e: React.KeyboardEvent<HTMLDivElement>;
    position?: "beginning" | "end";
  }): void => {
    e.stopPropagation();
    if (
      (e.key === "Delete" || e.key === "Backspace") &&
      (!value.length ||
        (position === "beginning" && e.key === "Backspace") ||
        (position === "end" && e.key === "Delete"))
    ) {
      handleDeleteAndBackspace({ e, position });
    } else if (e.key === "ArrowUp") {
      upArrowPressed(e);
    } else if (e.key === "ArrowDown") {
      downArrowPressed(e);
    } else if (
      e.key === "ArrowLeft" &&
      (!value.length || position === "beginning")
    ) {
      e.preventDefault();
      leftArrowPressed({ position });
    } else if (
      e.key === "ArrowRight" &&
      (!value.length || position === "end")
    ) {
      e.preventDefault();
      rightArrowPressed({ position });
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (highlightedFormula.formula && value.length) {
        onSelectAttribute(highlightedFormula.formula);
      } else if (value.length) {
        handleAddOperator({ operator: null });
      }
    } else {
      removeSegmentToDelete();
    }
  };

  const renderContentEditable = ({
    positionCheck,
    leftPadding,
    rightPadding,
    isInputDisabled,
  }: {
    positionCheck: boolean;
    leftPadding?: boolean;
    rightPadding?: boolean;
    isInputDisabled?: boolean;
  }): React.ReactNode | null => {
    return positionCheck ? (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`relative ${rightPadding ? "mr-2" : ""} ${leftPadding ? "ml-2" : ""} ${updatedFormula.length === 0 && "flex-grow"}`}
        data-testid="content-editable-parent"
      >
        <FormulaBuilderContentEditable
          handleKeyDown={handleInputKeyDown}
          handleAddOperator={handleAddOperator}
          ref={inputRef}
          isDisabled={isInputDisabled}
        />
        <FormulaList
          value={value.trim()}
          onSelectAttribute={onSelectAttribute}
          formulaList={filteredFormulaList}
          highlightedFormula={highlightedFormula}
          setHighlightedFormula={setHighlightedFormula}
        />
      </div>
    ) : null;
  };

  return (
    <div className="flex flex-col">
      <Typography size="xs" className="mb-1">
        Formula
      </Typography>
      <div
        onClick={(e) => {
          e.stopPropagation();
          if (value.length) {
            handleAddOperator({ operator: null });
          } else {
            if (updatedFormula.length) {
              setInputPosition(updatedFormula.length);
              if (inputRef.current) {
                inputRef.current.focus();
              }
            } else {
              setInputPosition(0);
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }
          }
          removeSegmentToDelete();
        }}
        className={`relative pl-[0.57rem] py-[5px] flex flex-wrap gap-y-1 items-center border cursor-text ${
          displayFormulaError.isDisplayed ? "border-red-300" : "border-gray-300"
        } ${isDisabled ? "text-neutral-200 pointer-events-none" : ""} rounded`}
        data-testid="formula-builder-input"
      >
        =
        {renderContentEditable({
          positionCheck: inputPosition === 0 || updatedFormula.length === 0,
          leftPadding: true,
          isInputDisabled: isDisabled,
        })}
        {updatedFormula.map((f, index) => (
          <div key={f.element.key} className="flex items-center">
            {f.element}
            {index === inputPosition - 1 &&
              inputPosition !== updatedFormula.length &&
              renderContentEditable({
                positionCheck: true,
                leftPadding: f.type !== "operator" || Boolean(value.length),
                rightPadding: f.type === "operator" && !value.length,
                isInputDisabled: isDisabled,
              })}
          </div>
        ))}
        {renderContentEditable({
          positionCheck:
            inputPosition === updatedFormula.length &&
            updatedFormula.length > 0,
          leftPadding: true,
          isInputDisabled: isDisabled,
        })}
        {isDisabled && (
          <div className="absolute right-0 bg-neutral-50 opacity-45 w-full h-full pointer-events-none" />
        )}
      </div>
      {displayFormulaError.isDisplayed && (
        <p className="text-red-500 text-xs italic p-1">
          {displayFormulaError.message}
        </p>
      )}
    </div>
  );
};

export default FormulaBuilderInput;

import React, { ReactElement, useState } from "react";
import { IInitialValue } from "../HeadcountTable/Cell/entities/types";
import { CellContext } from "@tanstack/react-table";
import ChangePayRateModal from "./ChangePayRateModal";
import formatCurrency from "~/utils/formatCurrency";
import UpcomingChangePopover from "./UpcomingChangePopover";
import useHover from "~/utils/hooks/useHover";
import { z } from "zod";
import { ZTemporalValue } from "../../entity/schemas";

const ZPayRateValue = z.object({
  value: z.number({ coerce: true }),
  positionUuid: z.string(),
  effectiveAt: z.union([z.string(), z.literal("onHire")]),
  upcomingChange: z.boolean().optional(),
  changeHistory: z.array(
    ZTemporalValue.extend({
      effectiveAt: z.union([z.string(), z.literal("onHire")]),
      value: z.number({ coerce: true }),
    }),
  ),
});

const CellPayRate = ({
  initialValue,
  cellContext,
  disabled,
}: {
  initialValue: IInitialValue;
  cellContext?: CellContext<Record<string, IInitialValue>, IInitialValue>;
  disabled?: boolean;
}): ReactElement => {
  const [ref, hovering] = useHover();
  const {
    value,
    positionUuid,
    effectiveAt: positionEffectiveAt,
    changeHistory,
    upcomingChange,
  } = ZPayRateValue.parse(initialValue);

  const [isOpen, setIsOpen] = useState(false);

  let isActive = !disabled;
  if (cellContext)
    isActive = cellContext.row.original.isActive.value === "true";

  const disabledStyles = "text-neutral-100 cursor-default";
  return (
    <div
      title={formatCurrency(value, false)}
      className={`flex flex-row gap-4 items-center justify-end select-none ${isActive ? "cursor-pointer" : disabledStyles}`}
      onClick={() => {
        if (!disabled && isActive) setIsOpen(true);
      }}
      ref={ref}
      data-testid={`${cellContext?.column.id ?? ""}-${cellContext?.row.index ?? ""}`}
    >
      {isOpen && (
        <ChangePayRateModal
          id={cellContext?.column.id ?? ""}
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          positionUuid={positionUuid}
          positionEffectiveAt={positionEffectiveAt}
          changeHistory={changeHistory}
        />
      )}
      {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
      {upcomingChange && (
        <UpcomingChangePopover
          id={`${cellContext?.column.id ?? ""}-${cellContext?.row.index}-upcoming-change`}
        />
      )}
      <div
        className={`${hovering ? "text-green-400 underline underline-offset-4" : ""}`}
      >
        {formatCurrency(value, false)}
      </div>
    </div>
  );
};

export default CellPayRate;

import React from "react";
import { Link, useLocation, useRouteError } from "react-router-dom";
import Typography from "~/components/Typography";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Button from "~/components/Button";
import { datadogRum } from "@datadog/browser-rum";
import { useSelector } from "react-redux";
import { State } from "~/store";

interface IProps {
  is404?: boolean;
}

const ErrorBoundary = ({ is404 }: IProps): React.ReactNode => {
  const error = useRouteError();
  const location = useLocation();
  const userUuid = useSelector((state: State) => state.user.uuid);
  const organizationUuid = useSelector(
    (state: State) => state.organization.uuid,
  );

  console.error(error);

  datadogRum.addError(error, {
    page: location.pathname,
    userUuid,
    organizationUuid,
  });

  return (
    <div className="w-screen h-screen absolute z-50 top-0 left-0 bg-white flex justify-center items-center">
      <div className="flex flex-col text-center items-center">
        <ExclamationTriangleIcon className="h-8 w-8 text-center text-neutral-200 mb-1" />
        <Typography size="lg" weight="bold">
          {!is404 ? "We ran into a little bug" : "This page does not exist"}
        </Typography>
        {!is404 && (
          <>
            <Typography size="md" color="empty" className="mb-5">
              Our engineering team has been notified
            </Typography>
            <Typography size="md" color="empty">
              If the issue persists, feel free to reach out to
            </Typography>
          </>
        )}
        <Typography size="md" color="empty" className="mb-2">
          {!is404
            ? "support@getparallel.com"
            : "Click below to return to the dashboard."}
        </Typography>
        <Link to="/dashboard" className="w-fit">
          <Button>Go to Dashboard</Button>
        </Link>
      </div>
    </div>
  );
};

export default ErrorBoundary;

import React, { RefObject, useRef, useState } from "react";
import { IFormulaSegment, IVariables } from "../../entity/types";
import { useSelect } from "~/components/Select";
import { SelectState } from "~/components/Select/Select.types";
import { useInput } from "~/components/Input";
import { useFinancialModelData } from "../../useFinancialModelData";
import { IIntegrationMapping } from "~/utils/schemas/integrations";
import { IFormattingEnum } from "../../entity/schemas";

interface IFormulaBuilderContext {
  dataSources: IIntegrationMapping[];
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  variables: IVariables;
  setVariables: React.Dispatch<React.SetStateAction<IVariables>>;
  displayFormulaError: {
    isDisplayed: boolean;
    message: string;
  };
  setDisplayFormulaError: React.Dispatch<
    React.SetStateAction<{
      isDisplayed: boolean;
      message: string;
    }>
  >;
  inputPosition: number;
  setInputPosition: React.Dispatch<React.SetStateAction<number>>;
  formula: IFormulaSegment[];
  setFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  updatedFormula: IFormulaSegment[];
  setUpdatedFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  showRounding: boolean;
  setShowRounding: React.Dispatch<React.SetStateAction<boolean>>;
  roundingDirection: string;
  setRoundingDirection: React.Dispatch<React.SetStateAction<string>>;
  roundingPrecisionState: SelectState;
  setRoundingPrecisionState: React.Dispatch<React.SetStateAction<SelectState>>;
  segmentToDelete?: {
    segmentRef: RefObject<HTMLDivElement>;
    segmentIndex: number;
    segmentValue: string;
  };
  setSegmentToDelete: React.Dispatch<
    React.SetStateAction<
      | {
          segmentRef: RefObject<HTMLDivElement>;
          segmentIndex: number;
          segmentValue: string;
        }
      | undefined
    >
  >;
  attributeTitle: Types.InputState;
  setAttributeTitle: React.Dispatch<React.SetStateAction<Types.InputState>>;
  resetAttributeTitle: () => void;
  resetForm: () => void;
  revalidate: () => void;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  inputRef: RefObject<HTMLDivElement>;
  enteredConstantFrom: "left" | "right" | undefined;
  setEnteredConstantFrom: React.Dispatch<
    React.SetStateAction<"left" | "right" | undefined>
  >;
  dataSourceState: SelectState;
  setDataSourceState: React.Dispatch<React.SetStateAction<SelectState>>;
  revalidateLoading: boolean;
  formatting: IFormattingEnum;
  setFormatting: React.Dispatch<React.SetStateAction<IFormattingEnum>>;
}

export const FormulaBuilderContext = React.createContext(
  {} as IFormulaBuilderContext,
);

export const FormulaBuilderProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode => {
  const {
    data: dataSources,
    revalidate,
    revalidateLoading,
  } = useFinancialModelData();
  const [isLoading, setIsLoading] = useState(revalidateLoading);
  const [value, setValue] = useState("");
  const [variables, setVariables] = useState<IVariables>({});
  const [displayFormulaError, setDisplayFormulaError] = useState<{
    isDisplayed: boolean;
    message: string;
  }>({
    isDisplayed: false,
    message: "",
  });
  const [attributeTitle, setAttributeTitle, resetAttributeTitle] = useInput({
    validation: /.+/,
    errorMessage: "Attribute title is required",
  });
  const [dataSourceState, setDataSourceState] = useSelect({
    options: [],
    isNullable: true,
    isLoading: false,
  });
  const [inputPosition, setInputPosition] = useState(0);
  const [formula, setFormula] = useState<IFormulaSegment[]>([]);
  const [updatedFormula, setUpdatedFormula] = useState<IFormulaSegment[]>([]);
  const [showRounding, setShowRounding] = useState<boolean>(false);
  const [roundingDirection, setRoundingDirection] = useState<string>("nearest");
  const [roundingPrecisionState, setRoundingPrecisionState] = useSelect({
    options: [
      {
        label: "Whole Number",
        value: "1",
      },
      {
        label: "Nearest 10",
        value: "10",
      },
      {
        label: "Nearest 100",
        value: "100",
      },
      {
        label: "Nearest 1000",
        value: "1000",
      },
      {
        label: "1 Decimal Place",
        value: "0.1",
      },
      {
        label: "2 Decimal Places",
        value: "0.01",
      },
    ],
    selected: { label: "Whole Number", value: "1" },
  });
  const [segmentToDelete, setSegmentToDelete] = useState<{
    segmentRef: RefObject<HTMLDivElement>;
    segmentIndex: number;
    segmentValue: string;
  }>();
  const [enteredConstantFrom, setEnteredConstantFrom] = useState<
    "left" | "right" | undefined
  >();
  const [formatting, setFormatting] = useState<IFormattingEnum>(
    IFormattingEnum.Number,
  );

  const inputRef = useRef<HTMLDivElement>(null);

  const resetForm = (): void => {
    resetAttributeTitle();
    setValue("");
    setUpdatedFormula([]);
    setFormula([]);
    setDisplayFormulaError({
      isDisplayed: false,
      message: "",
    });
    setVariables({});
    setSegmentToDelete(undefined);
    setShowRounding(false);
    setRoundingDirection("nearest");
    setRoundingPrecisionState((prev) => ({
      ...prev,
      selected: { label: "Whole Number", value: "1" },
    }));
    setDataSourceState((prev) => ({
      ...prev,
      selected: { label: null, value: null },
    }));
  };

  return (
    <FormulaBuilderContext.Provider
      value={{
        dataSources: dataSources.dataSources,
        value,
        setValue,
        variables,
        setVariables,
        displayFormulaError,
        setDisplayFormulaError,
        inputPosition,
        setInputPosition,
        formula,
        setFormula,
        updatedFormula,
        setUpdatedFormula,
        showRounding,
        setShowRounding,
        roundingDirection,
        setRoundingDirection,
        roundingPrecisionState,
        setRoundingPrecisionState,
        segmentToDelete,
        setSegmentToDelete,
        attributeTitle,
        setAttributeTitle,
        resetAttributeTitle,
        resetForm,
        revalidate,
        isLoading,
        setIsLoading,
        inputRef,
        enteredConstantFrom,
        setEnteredConstantFrom,
        dataSourceState,
        setDataSourceState,
        revalidateLoading,
        formatting,
        setFormatting,
      }}
    >
      {children}
    </FormulaBuilderContext.Provider>
  );
};

export default FormulaBuilderProvider;

const ONE_HUNDRED_THOUSAND_WITH_CENTS = 100000000;
const TEN_THOUSAND_WITH_CENTS = 1000000;
const NEAREST_FIVE_HUNDRED = 50000;
const NEAREST_FIVE_THOUSAND = 500000;
const NEAREST_FIVE_HUNDRED_THOUSAND = 50000000;

const roundMiddleValues = ({
  intervalValue,
  tickInterval,
}: {
  intervalValue: number;
  tickInterval: number;
}): number => {
  return tickInterval / ONE_HUNDRED_THOUSAND_WITH_CENTS > 1
    ? Math.round(intervalValue / NEAREST_FIVE_HUNDRED_THOUSAND) *
        NEAREST_FIVE_HUNDRED_THOUSAND
    : tickInterval / TEN_THOUSAND_WITH_CENTS > 1
      ? Math.round(intervalValue / NEAREST_FIVE_THOUSAND) *
        NEAREST_FIVE_THOUSAND
      : Math.round(intervalValue / NEAREST_FIVE_HUNDRED) * NEAREST_FIVE_HUNDRED;
};

const standardTickValues = ({
  yMin,
  yMax,
  tickCount,
}: {
  yMin: number;
  yMax: number;
  tickCount: number;
}): number[] => {
  if (yMax < 10) {
    tickCount = 2;
  }
  const adjustedTickCount = tickCount - 1;
  const tickValues = new Array<number>(tickCount).fill(0);
  tickValues[0] = yMin;
  tickValues[tickValues.length - 1] = yMax;
  const tickInterval = (yMax - yMin) / adjustedTickCount;
  for (let i = 1; i <= adjustedTickCount - 1; i++) {
    tickValues[i] = parseFloat((yMin + tickInterval * i).toFixed(0));
  }
  return Array.from(new Set(tickValues)).sort((a, b) => a - b);
};

const generateMoneyTicks = ({
  yMin,
  yMax,
  tickCount,
  isCashGraph,
}: {
  yMin: number;
  yMax: number;
  tickCount: number;
  isCashGraph?: boolean;
}): number[] => {
  const adjustedTickCount = tickCount - 1;

  const roundedMax =
    Math.ceil(yMax / TEN_THOUSAND_WITH_CENTS) * TEN_THOUSAND_WITH_CENTS;

  const roundedMin =
    yMin < 0
      ? Math.floor(yMin / TEN_THOUSAND_WITH_CENTS) * TEN_THOUSAND_WITH_CENTS
      : 0;

  const tickValues = new Array(tickCount).fill(0);
  tickValues[0] = roundedMin;
  tickValues[tickValues.length - 1] = roundedMax;
  const tickInterval = (roundedMax - roundedMin) / adjustedTickCount;

  for (let i = 1; i <= adjustedTickCount - 1; i++) {
    const intervalValue = roundedMin + tickInterval * i;
    tickValues[i] = roundMiddleValues({ intervalValue, tickInterval });
  }

  if (
    isCashGraph &&
    tickValues[0] < 0 &&
    tickValues[tickValues.length - 1] > 0
  ) {
    const middleIndex = Math.floor(tickValues.length / 2);
    tickValues[middleIndex] = 0;
  }

  /**
   * Recharts depends on the tick value as a key at some point
   * so duplicate values must be removed to avoid duplicate key
   * errors which cause the ticks to overlap on the graph when
   * the ticks are updated
   */
  return Array.from(new Set(tickValues)).sort((a, b) => a - b);
};

const generateStandardTicks = ({
  yMin,
  yMax,
  tickCount,
  roundToMoney,
  isCashGraph,
}: {
  yMin: number;
  yMax: number;
  tickCount: number;
  roundToMoney: boolean;
  isCashGraph?: boolean;
}): number[] => {
  if (roundToMoney) {
    return generateMoneyTicks({ yMin, yMax, tickCount, isCashGraph });
  }
  return standardTickValues({ yMin, yMax, tickCount });
};

export default generateStandardTicks;

import { toZonedTime } from "date-fns-tz";
import { store } from "~/store";
import { createMonthArrayBetweenDates } from "~/utils/dates/createMonthArrayBetweenDates";
import request from "~/utils/request";
import waitForStoreRehydration from "~/utils/waitForStoreRehydration";

interface IAPIResponse {
  data: {
    data: unknown;
  };
  status: number;
}

export default async (): Promise<unknown> => {
  await waitForStoreRehydration(store);
  const activeScenarioUuid = store.getState().scenario.activeScenarioUuid;
  const { defaultGraphStartDate, defaultGraphEndDate } =
    store.getState().user.preferences;
  const startDate = defaultGraphStartDate;
  const endDate = defaultGraphEndDate;
  const {
    organization: { uuid: organizationUuid },
  } = store.getState();

  const dataPromises: Promise<unknown>[] = [];

  const positionsResponse = request({
    url: `/organizations/${organizationUuid}/positions`,
    method: "GET",
    params: {
      scenarioUuid: activeScenarioUuid ?? undefined,
    },
  });

  dataPromises.push(positionsResponse);

  const formulaListPromise = request({
    url: "/formulas",
    method: "GET",
    params: {
      startDate,
      endDate,
      includes: ["calculations"],
      scenarioUuid: activeScenarioUuid ?? undefined,
    },
    headers: { "Organization-Uuid": organizationUuid },
  });

  dataPromises.push(formulaListPromise);

  const ratiosListPromise = request({
    url: "/ratios",
    method: "GET",
    params: {
      startDate,
      endDate,
      scenarioUuid: activeScenarioUuid ?? undefined,
    },
    headers: { "Organization-Uuid": organizationUuid },
  });

  dataPromises.push(ratiosListPromise);

  const allResponses = (await Promise.all(dataPromises)) as IAPIResponse[];

  const monthsBetweenDates = createMonthArrayBetweenDates(
    toZonedTime(startDate, "UTC"),
    toZonedTime(endDate, "UTC"),
  );

  return {
    positions: allResponses[0].data.data,
    formulas: allResponses[1].data.data,
    ratios: allResponses[2].data.data,
    monthsBetweenDates,
  };
};

import React, { ReactElement, useContext, useMemo } from "react";
import BaseTable from "~/components/Table/Base/BaseTable";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { format, startOfMonth } from "date-fns";
import { RatiosContext } from "../../context/RatiosContext";
import { toZonedTime } from "date-fns-tz";
import { TableRow } from "../../entity/types";
import TitleCell from "./TitleCell";
import DriverCell from "./DriverCell";
import RatioCell from "./RatioCell";
import Button from "~/components/Button";

const RatiosTable = (): ReactElement => {
  const {
    ratiosList,
    monthsBetweenDates,
    emptyTableState,
    setIsCreateRatioModalOpen,
  } = useContext(RatiosContext);
  /*
   *   const activeScenarioUuid = useSelector(
   *     (state: State) => state.scenario.activeScenarioUuid
   *   );
   */
  const columnHelper = createColumnHelper<TableRow>();

  const { tableData, tableColumns } = useMemo(() => {
    const desiredColumns = [
      {
        id: "position",
        label: "Position",
        className: "w-full text-left text-nowrap",
      },
      {
        id: "drivenBy",
        label: "Driven By",
        className: "w-full text-left text-nowrap",
      },
    ];

    const tableColumns = desiredColumns.map((col) =>
      columnHelper.accessor(col.id, {
        enableResizing: false,
        size: col.id === "drivenBy" ? 260 : 310,
        enablePinning: true,
        header: () => (
          <div className={col.className}>{col.label.toUpperCase()}</div>
        ),
        cell: (info) => {
          return col.id === "drivenBy" ? (
            <DriverCell info={info} />
          ) : (
            <TitleCell info={info} />
          );
        },
        enableSorting: false,
      }),
    );

    const monthColumns = monthsBetweenDates.map((date) => ({
      id: format(startOfMonth(toZonedTime(date, "UTC")), "yyyy-MM-dd"),
      label: format(startOfMonth(toZonedTime(date, "UTC")), "MMM yyyy"),
      className: "w-[100px] max-w-[100px] text-right text-nowrap",
    }));

    monthColumns.forEach((month) => {
      tableColumns.push(
        columnHelper.accessor(month.id, {
          enableResizing: false,
          enablePinning: false,
          header: () => (
            <div className={month.className}>{month.label.toUpperCase()}</div>
          ),
          cell: (cellContext) => {
            return (
              <RatioCell
                key={`${month.id}-${cellContext.row.original.ratioUuid}`}
                cellContext={cellContext}
              />
            );
          },
          enableSorting: false,
        }),
      );
    });

    const tableData = ratiosList.map((ratio) => {
      const row = {
        position: {
          positionTitle: ratio.drivenElement.title,
          ratioUuid: ratio.uuid,
        },
        drivenBy: {
          drivingValue: ratio.drivingElements[0].value,
          drivingElementTitle: ratio.drivingElements[0].title,
          ratioUuid: ratio.uuid,
        },
      };
      monthColumns.forEach((month) => {
        const matchedMonth = ratio.byMonth.find((monthData) => {
          const a = format(
            startOfMonth(toZonedTime(monthData.month, "UTC")),
            "yyyy-MM-dd",
          );
          return a === month.id;
        });
        if (matchedMonth) {
          row[month.id] = {
            positionTitle: ratio.drivenElement.title,
            ratioUuid: ratio.uuid,
            drivingValue: ratio.drivingElements[0].value,
            drivingElementTitle: ratio.drivingElements[0].title,
            needed: matchedMonth.needed,
            planned: matchedMonth.planned,
            actualDrivingValue: matchedMonth.driving[0],
            relevantDate: month.id,
          };
        }
      });
      return row;
    });

    return { tableData, tableColumns };
  }, [ratiosList, monthsBetweenDates]);

  const table = useReactTable({
    columns: tableColumns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className={`max-w-full h-full overflow-scroll hide-scrollbar`}>
      {ratiosList.length > 0 ? (
        <BaseTable
          styles={{
            table: "w-full h-full",
            tHead: "sticky top-0 z-[11] bg-white !shadow-sm ",
            th: "px-4 py-2 bg-white text-xs font-normal text-neutral-200 text-nowrap overflow-hidden whitespace-nowrap",
            td: "h-14 py-0 my-0 border-t border-b border-gray-200 bg-white text-nowrap text-nowrap overflow-hidden whitespace-nowrap",
            tRow: "financialModelRow",
          }}
          table={table}
          id="ratios-table"
        />
      ) : (
        <div className="h-full w-full pt-[20vh] flex flex-col justify-center items-center">
          {emptyTableState}
          <Button
            onClick={() => setIsCreateRatioModalOpen(true)}
            className="bg-primary-500 !w-fit mt-4 !px-4"
            id="create-ratio-button"
          >
            Create a Ratio
          </Button>
        </div>
      )}
    </div>
  );
};

export default RatiosTable;

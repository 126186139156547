import React from "react";

export interface IPeriodPickerState {
  startDate: Date | null;
  endDate: Date | null;
  mode: "month" | "quarter" | "year";
}

const usePeriodPicker = ({
  startDate,
  endDate,
  mode,
}: IPeriodPickerState): [
  IPeriodPickerState,
  React.Dispatch<React.SetStateAction<IPeriodPickerState>>,
] =>
  React.useState<IPeriodPickerState>({
    startDate,
    endDate,
    mode,
  });

export default usePeriodPicker;

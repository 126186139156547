import React, { ReactElement } from "react";
import Modal from "~/components/Modal";
import Button from "~/components/Button";
import Typography from "~/components/Typography";

interface Props {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  deleteIsLoading: boolean;
}

const DeletePositionModal = ({
  id,
  isOpen,
  onClose,
  onConfirm,
  deleteIsLoading,
}: Props): ReactElement => {
  return (
    <Modal
      id={id}
      title={"Delete Position & Purge History"}
      isOpen={isOpen}
      size="xxs"
    >
      <div
        className="flex flex-col w-full"
        data-testid="delete-position-container"
      >
        <div className="flex flex-col gap-6 mb-4">
          <Typography size="xs" color="secondary">
            Deleting this position will remove all related data, including all
            historical impact.
          </Typography>
          <Typography size="xs" color="secondary">
            To close the position and preserve its impact on the budget, assign
            an appropriate termination date.
          </Typography>
        </div>
        <div className="flex flex-row w-full justify-between">
          <Button
            id={"cancel-delete-position"}
            onClick={onClose}
            fill="clear"
            className="!w-fit !px-0"
          >
            Cancel
          </Button>
          <Button
            id={"confirm-delete-position"}
            fill="destructive"
            className="!w-auto"
            onClick={onConfirm}
            loading={deleteIsLoading}
          >
            Confirm, Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeletePositionModal;

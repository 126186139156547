import { ExpandedState } from "@tanstack/react-table";

export const buildExpand = ({
  sorting,
}: {
  sorting: { name: string; sortOrder: string[] }[];
}): { name: string; expanded: boolean }[] => {
  return sorting.reduce(
    (result, { name }) => {
      result.push({ name, expanded: true });
      return result;
    },
    [] as { name: string; expanded: boolean }[],
  );
};

export const convertDictToGroups = ({
  sortOrder,
  expanded,
}: {
  sortOrder: { name: string; sortOrder: string[] }[];
  expanded: ExpandedState;
}): { name: string; expanded: boolean }[] => {
  if (typeof expanded === "boolean") {
    return buildExpand({ sorting: sortOrder });
  }
  return sortOrder.map(({ name }, index) => ({
    name,
    expanded: index in expanded ? expanded[index] : false,
  }));
};

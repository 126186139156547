import React from "react";
import Button from "~/components/Button";
import Modal from "~/components/Modal";
import Typography from "~/components/Typography";

const DeleteScenarioModal = ({
  isOpen,
  onClose,
  onDelete,
}: {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}): React.ReactNode => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Delete Scenario"
      size="xs"
      id="delete-scenario-modal"
    >
      <div>
        <Typography size="sm" color="secondary">
          Deleting a scenario cannot be undone. Are you sure you want to do this
          action?
        </Typography>
        <div className="flex justify-between mt-5">
          <Button fill="clear" onClick={onClose} className="!w-auto !px-0">
            Close
          </Button>
          <Button
            fill="destructive"
            onClick={onDelete}
            className="!w-auto"
            id="confirm-delete-scenario"
          >
            Confirm Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteScenarioModal;

export default (
  index: number,
): {
  text: "blue" | "orange" | "purple" | "green" | undefined;
  bg: "bg-blue-500" | "bg-orange" | "bg-purple" | "bg-green-500" | undefined;
} => {
  if (index === 0) return { text: "green", bg: "bg-green-500" };
  if (index === 1) return { text: "blue", bg: "bg-blue-500" };
  if (index === 2) return { text: "orange", bg: "bg-orange" };
  if (index === 3) return { text: "purple", bg: "bg-purple" };
  return { text: undefined, bg: undefined };
};

export default ({
  value,
  abbreviation,
  decimal,
  currency,
}: {
  value: number | string;
  abbreviation?: "K" | "M" | "B" | "T";
  decimal?: number;
  currency?: string;
}): string => {
  if (!currency) {
    currency = "$";
  }

  const TRILLIONS = 100000000000000;
  const BILLIONS = 100000000000;
  const MILLIONS = 100000000;
  const THOUSANDS = 100000;

  let numValue: number;
  if (typeof value === "string") {
    numValue = parseFloat(value);
  } else {
    numValue = value;
  }

  let result: string;

  const absNumValue = Math.abs(numValue);

  const sign = numValue < 0 ? "-" : "";

  if (abbreviation === "T" || (!abbreviation && absNumValue >= TRILLIONS)) {
    result =
      sign +
      currency +
      Number((absNumValue / TRILLIONS).toFixed(decimal ?? 0)) +
      "T";
  } else if (
    abbreviation === "B" ||
    (!abbreviation && absNumValue >= BILLIONS)
  ) {
    result =
      sign +
      currency +
      Number((absNumValue / BILLIONS).toFixed(decimal ?? 0)) +
      "B";
  } else if (
    abbreviation === "M" ||
    (!abbreviation && absNumValue >= MILLIONS)
  ) {
    result =
      sign +
      currency +
      Number((absNumValue / MILLIONS).toFixed(decimal ?? 0)) +
      "M";
  } else {
    result =
      sign +
      currency +
      Number((absNumValue / THOUSANDS).toFixed(decimal ?? 0)) +
      "K";
  }

  return result;
};

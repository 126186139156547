import React from "react";

const RemoveLockIcon = (): React.ReactNode => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="white" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#BCBCBC" />
      <path
        d="M12 20L20 12M12 12L20 20"
        stroke="#BCBCBC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RemoveLockIcon;

import { store } from "~/store";
import serverRequest from "~/utils/request";
import waitForStoreRehydration from "~/utils/waitForStoreRehydration";
import { ZGraphData } from "./entity/schemas";

interface IAPIResponse {
  data: {
    data: unknown[];
  };
  status: number;
}

export default async (): Promise<unknown> => {
  await waitForStoreRehydration(store);
  const { defaultGraphStartDate, defaultGraphEndDate } =
    store.getState().user.preferences;
  const startDate = defaultGraphStartDate;
  const endDate = defaultGraphEndDate;
  const { activeScenarioUuid, selectedScenarioUuids } =
    store.getState().scenario;
  const { uuid: organizationUuid } = store.getState().organization;

  const promises = [];
  const reportsPromises = [];
  const formulasToInclude = [
    "Cash Balance",
    "Cash Flow",
    "Expenses",
    "Revenue",
  ];

  const additionalReports = ["runway"];

  reportsPromises.push(
    serverRequest({
      url: `/reports/consolidate`,
      method: "GET",
      params: {
        scenarioUuid: activeScenarioUuid,
        formulasToInclude,
        scenariosToInclude: selectedScenarioUuids,
        startDate,
        endDate,
        additionalReports,
      },
      headers: { "Organization-Uuid": organizationUuid },
    }),
  );

  promises.push(
    serverRequest({
      url: `/organizations/${organizationUuid}/scenarios`,
      method: "GET",
    }),
  );

  const result = await Promise.all(promises);

  const [scenariosResponse] = result as unknown as [IAPIResponse];
  const reportsResult = (await Promise.all(reportsPromises)) as unknown as [
    IAPIResponse,
  ];
  const parsedReportsResult = ZGraphData.parse(reportsResult[0].data.data);

  return {
    reportData: parsedReportsResult,
    scenarios: scenariosResponse.data.data,
  };
};

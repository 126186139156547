export const parseSpreadsheetData = ({
  pasteData,
}: {
  pasteData: string;
}): string[][] => {
  const rows = pasteData.split("\n");
  const data = rows.map((row) =>
    row.split("\t").map((cell) => cell.replace(/[^0-9.]/g, "")),
  );
  return data;
};

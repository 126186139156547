import React, { useMemo } from "react";
import Typography from "~/components/Typography";
import {
  TrashIcon,
  PencilSquareIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import { IScenario } from "~/pages/OldDashboard/entity/types";
import { useSelector } from "react-redux";
import { State } from "~/store";
import HoverPopover from "~/components/HoverPopover";
import { format } from "date-fns";

const DeleteButton = ({
  scenario,
  onDelete,
  anchorDirection = "bottom",
}: {
  scenario: IScenario;
  onDelete: (scenario: IScenario) => void;
  anchorDirection?: "top" | "bottom";
}): React.ReactNode => {
  return (
    <HoverPopover
      buttonContent={
        <TrashIcon
          data-testid={`${scenario.uuid}-scenario-delete`}
          className="text-neutral-200 cursor-pointer size-5"
          onClick={() => onDelete(scenario)}
        />
      }
      panelContent={
        <div className="bg-black px-2 py-1 rounded text-white">Delete</div>
      }
      anchor={anchorDirection}
      panelClassName="z-20"
    />
  );
};

const EditButton = ({
  scenario,
  onEdit,
  anchorDirection = "bottom",
}: {
  scenario: IScenario;
  onEdit: (scenario: IScenario) => void;
  anchorDirection?: "top" | "bottom";
}): React.ReactNode => {
  return (
    <HoverPopover
      buttonContent={
        <PencilSquareIcon
          data-testid={`${scenario.uuid}-scenario-edit`}
          className="text-neutral-200 cursor-pointer size-5"
          onClick={() => onEdit(scenario)}
        />
      }
      panelContent={
        <div className="bg-black px-2 py-1 rounded text-white">Edit</div>
      }
      anchor={anchorDirection}
      panelClassName="z-20"
    />
  );
};

const StaleIcon = ({
  scenario,
  anchorDirection = "bottom",
}: {
  scenario: IScenario;
  anchorDirection?: "top" | "bottom";
}): React.ReactNode => {
  return (
    <HoverPopover
      buttonContent={
        <ClockIcon
          data-testid={`${scenario.uuid}-scenario-info`}
          className="text-neutral-200 cursor-pointer size-5"
        />
      }
      panelClassName="shadow-xl rounded-xl"
      panelContent={
        <div className="p-4 rounded bg-white max-w-56">
          <Typography weight="bold" size="sm">
            Scenario Outdated
          </Typography>
          <br />
          <Typography size="xs" color="lightGray">
            {`Outdated as of ${format(
              new Date(scenario.markedAsStaleAt as string),
              "MM/dd/yyyy",
            )}`}
          </Typography>
          <br />
          <Typography size="xs">
            Modifications have been made to the base model since this scenario
            was created
          </Typography>
        </div>
      }
      anchor={anchorDirection}
    />
  );
};

const ScenarioItem = ({
  scenario,
  onSelect,
  onEdit,
  onDelete,
  anchorDirection = "bottom",
}: {
  scenario: IScenario;
  onSelect: (scenarioUuid: string, close?: () => void) => void;
  onEdit: (scenario: IScenario) => void;
  onDelete: (scenario: IScenario) => void;
  anchorDirection?: "top" | "bottom";
}): React.ReactNode => {
  const { activeScenarioUuid, selectedScenarioUuids } = useSelector(
    (state: State) => state.scenario,
  );

  const shouldBeDisabled = useMemo((): boolean => {
    if (activeScenarioUuid === scenario.uuid) {
      return true;
    } else if (selectedScenarioUuids.length < 3) {
      return false;
    } else if (selectedScenarioUuids.includes(scenario.uuid)) {
      return false;
    } else {
      return true;
    }
  }, [activeScenarioUuid, selectedScenarioUuids, scenario.uuid]);

  return (
    <div
      className={`w-full flex px-4 justify-between items-center py-2 group ${shouldBeDisabled ? "" : "hover:bg-neutral-25"} ${selectedScenarioUuids.includes(scenario.uuid) && "bg-neutral-25"}`}
      key={scenario.uuid}
    >
      <div
        className={`flex justify-start align-middle items-center gap-[8px] ${shouldBeDisabled ? "cursor-default" : " cursor-pointer "} flex-grow`}
        onClick={() => {
          if (!shouldBeDisabled) {
            onSelect(scenario.uuid);
          }
        }}
      >
        <input
          data-testid={`scenario-input-${scenario.uuid}`}
          value={`scenario-input-${scenario.uuid}`}
          id={`scenario-input-${scenario.uuid}`}
          type="checkbox"
          name="role"
          checked={
            selectedScenarioUuids.includes(scenario.uuid) ||
            activeScenarioUuid === scenario.uuid
          }
          className="hover:bg-neutral-15 hover:border-green-500 
                              checked:bg-green-400 checked:hover:bg-green-500 checked:focus:bg-green-400 checked:focus:hover:bg-green-500
                              focus:ring-green-400 focus:hover:ring-green-500 cursor-pointer rounded disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:border-neutral-100 disabled:checked:bg-neutral-75"
          disabled={shouldBeDisabled}
          readOnly
        />
        <Typography
          color={shouldBeDisabled ? "empty" : "primary"}
          size="sm"
          className="sm:group-hover:truncate sm:group-hover:max-w-[225px] sm:truncate"
        >
          {scenario.changeDescription}
        </Typography>
        {scenario.markedAsStaleAt && (
          <StaleIcon scenario={scenario} anchorDirection={anchorDirection} />
        )}
      </div>
      {activeScenarioUuid === scenario.uuid ? (
        <div>
          <Typography color="blue">{`(In Progress)`}</Typography>
        </div>
      ) : (
        <div className="flex justify-end items-center gap-1 opacity-0 group-hover:opacity-100 max-sm:hidden">
          {!scenario.markedAsStaleAt && !shouldBeDisabled && (
            <EditButton
              scenario={scenario}
              onEdit={onEdit}
              anchorDirection={anchorDirection}
            />
          )}
          {!shouldBeDisabled && (
            <DeleteButton
              scenario={scenario}
              onDelete={onDelete}
              anchorDirection={anchorDirection}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ScenarioItem;

/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CellContext } from "@tanstack/react-table";
import Input, { useInput } from "~/components/Input/InputWrapper";
import { IFormulaOverride, IFormulaActual } from "../../entity/types";
import { addMonths, endOfMonth, isBefore, startOfMonth } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import date from "~/utils/dates/date";
import { FinancialModelContext } from "../../context/FinancialModelContext";
import { parseSpreadsheetData } from "~/utils/parseSpreadsheetData";
import { formatDateToISO } from "~/utils/dates/formatDateToISO";
import { IFormattingEnum } from "../../entity/schemas";
import { NumericFormat } from "react-number-format";

const MonthCell = ({
  cellContext,
}: {
  cellContext: CellContext<
    Record<
      string,
      {
        date?: string;
        value: string;
        overrides?: IFormulaOverride[] | null;
        actuals?: IFormulaActual[] | null;
        uuid?: string;
        colIndex?: number;
        rowIndex?: number;
        formatting?: IFormattingEnum | null;
      }
    >,
    {
      date?: string;
      value: string;
      overrides?: IFormulaOverride[] | null;
      actuals?: IFormulaActual[] | null;
      uuid?: string;
      colIndex?: number;
      rowIndex?: number;
      formatting?: IFormattingEnum | null;
    }
  >;
}): ReactElement => {
  const {
    overridesList,
    setOverridesList,
    selectedMonthCell,
    setSelectedMonthCell,
    actualsList,
    setActualsList,
  } = useContext(FinancialModelContext);
  const [isLoading, setIsLoading] = useState(true);
  const initialValue = cellContext.getValue();

  const initialValueIsActual = useMemo(() => {
    let foundActual = false;
    if (initialValue && "actuals" in initialValue) {
      const valuesActual = initialValue.actuals?.find(
        (actual) => actual.date === initialValue.date,
      );
      if (valuesActual) {
        foundActual = true;
      }
    }
    return !!foundActual;
  }, [initialValue]);

  const isActual = useMemo(() => {
    let foundActual = false;
    if (initialValueIsActual) {
      foundActual = true;
    }
    if (
      initialValue &&
      "uuid" in initialValue &&
      initialValue.uuid &&
      actualsList?.[initialValue.uuid]
    ) {
      const actual = actualsList[initialValue.uuid].find(
        (actual) => actual.date === initialValue.date,
      );
      foundActual = !!actual;
    }
    return !!foundActual;
  }, [initialValue, actualsList]);

  const initialValueIsOverride = useMemo(() => {
    let foundOverride = false;
    if (initialValue && "overrides" in initialValue) {
      const valuesOverride = initialValue.overrides?.find(
        (override) => override.date === initialValue.date,
      );
      if (valuesOverride) {
        foundOverride = true;
      }
    }
    return !!foundOverride && !initialValueIsActual;
  }, [initialValue]);

  const isOverride = useMemo(() => {
    let foundOverride = false;
    if (initialValueIsOverride) {
      foundOverride = true;
    }
    if (
      initialValue &&
      "uuid" in initialValue &&
      initialValue.uuid &&
      overridesList?.[initialValue.uuid]
    ) {
      const override = overridesList[initialValue.uuid].find(
        (override) => override.date === initialValue.date,
      );
      foundOverride = !!override;
    }
    return !!foundOverride && !isActual;
  }, [initialValue, overridesList]);

  const getInputValue = ({
    value,
    formatting,
    isActualOrOverride,
  }: {
    value: string;
    formatting?: IFormattingEnum | null;
    isActualOrOverride?: boolean;
  }): string => {
    let numToFormat = Number(value);

    if (isActualOrOverride) {
      numToFormat = numToFormat * 100;
    }

    switch (formatting) {
      case IFormattingEnum.Percent:
        return parseFloat(numToFormat.toFixed(2)).toString();
      case IFormattingEnum.Currency:
        return (numToFormat / 100).toFixed(2);
      default:
        return parseFloat((numToFormat / 100).toFixed(2)).toString();
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useInput({
    value: getInputValue({
      value: initialValue?.value ?? "0",
      formatting: initialValue?.formatting,
    }),
    validation: /^.*$/,
    valid: true,
  });

  useEffect(() => {
    if (initialValue && "uuid" in initialValue && initialValue.uuid) {
      const foundActual = actualsList[initialValue.uuid]?.find(
        (actual) => actual.date === initialValue.date,
      );
      const foundOverride = overridesList[initialValue.uuid]?.find(
        (override) => override.date === initialValue.date,
      );
      if (foundActual) {
        setValue((prev) => ({
          ...prev,
          value: getInputValue({
            value: foundActual.value.toString(),
            formatting: initialValue.formatting,
            isActualOrOverride: true,
          }),
        }));
        setIsLoading(false);
        return;
      } else if (foundOverride) {
        setValue((prev) => ({
          ...prev,
          value: getInputValue({
            value: foundOverride.value.toString(),
            formatting: initialValue.formatting,
            isActualOrOverride: true,
          }),
        }));
        setIsLoading(false);
        return;
      }
    }
    if (
      !initialValueIsActual &&
      !initialValueIsOverride &&
      initialValue &&
      "value" in initialValue &&
      getInputValue({
        value: initialValue.value,
        formatting: initialValue.formatting,
      }) !==
        getInputValue({
          value: value.value,
          formatting: initialValue.formatting,
        })
    ) {
      setValue((prev) => ({
        ...prev,
        value: getInputValue({
          value: initialValue.value,
          formatting: initialValue.formatting,
        }),
      }));
      setIsLoading(false);
      return;
    }

    if (initialValue !== undefined) setIsLoading(false);
  }, [initialValue, overridesList, actualsList]);

  useEffect(() => {
    if (
      selectedMonthCell &&
      selectedMonthCell?.colIndex === initialValue?.colIndex &&
      selectedMonthCell?.rowIndex === initialValue?.rowIndex
    ) {
      inputRef.current?.focus();
      inputRef.current?.select();
    }
  }, [selectedMonthCell]);

  const handleUpdateOverrideList = (): void => {
    setOverridesList((prevOverridesList) => {
      const overrides: IFormulaOverride[] =
        initialValue &&
        "uuid" in initialValue &&
        initialValue.uuid &&
        Object.keys(prevOverridesList).includes(initialValue.uuid) &&
        prevOverridesList[initialValue.uuid].length
          ? [...prevOverridesList[initialValue.uuid]]
          : [];

      const foundOverrideIndex = overrides.findIndex(
        (override) => override.date === initialValue.date,
      );

      if (initialValueIsOverride) {
        if (!value.value) {
          overrides.splice(foundOverrideIndex, 1);
        } else if (
          value.value ===
            getInputValue({
              value: initialValue.value,
              formatting: initialValue.formatting,
            }) &&
          foundOverrideIndex === -1
        ) {
          overrides.push({
            date: initialValue.date as string,
            value:
              initialValue.formatting === IFormattingEnum.Percent
                ? Number(value.value) / 100
                : Number(value.value),
          });
        } else if (foundOverrideIndex >= 0) {
          overrides[foundOverrideIndex].value =
            initialValue.formatting === IFormattingEnum.Percent
              ? Number(value.value) / 100
              : Number(value.value);
        } else {
          overrides.push({
            date: initialValue.date as string,
            value:
              initialValue.formatting === IFormattingEnum.Percent
                ? Number(value.value) / 100
                : Number(value.value),
          });
        }
      } else {
        if (foundOverrideIndex !== -1) {
          if (!value.value) {
            overrides.splice(foundOverrideIndex, 1);
          } else if (
            value.value ===
            getInputValue({
              value: initialValue.value,
              formatting: initialValue.formatting,
            })
          ) {
            overrides.splice(foundOverrideIndex, 1);
          } else {
            overrides[foundOverrideIndex].value =
              initialValue.formatting === IFormattingEnum.Percent
                ? Number(value.value) / 100
                : Number(value.value);
          }
        } else {
          if (
            value.value &&
            initialValue?.value &&
            value.value !==
              getInputValue({
                value: initialValue.value,
                formatting: initialValue.formatting,
              })
          ) {
            overrides.push({
              date: initialValue.date as string,
              value:
                initialValue.formatting === IFormattingEnum.Percent
                  ? Number(value.value) / 100
                  : Number(value.value),
            });
          } else if (
            initialValue !== undefined &&
            value.value ===
              getInputValue({
                value: initialValue.value,
                formatting: initialValue.formatting,
              }) &&
            foundOverrideIndex !== -1
          ) {
            overrides.splice(foundOverrideIndex, 1);
          }
        }
      }

      const newOverridesList = { ...prevOverridesList };
      if (initialValue?.uuid) {
        newOverridesList[initialValue.uuid] = overrides;
      }

      return newOverridesList;
    });
  };

  const handleUpdateActualList = (): void => {
    setActualsList((prevActualsList) => {
      const actuals: IFormulaActual[] =
        initialValue?.uuid &&
        Object.keys(prevActualsList).includes(initialValue.uuid) &&
        prevActualsList[initialValue.uuid].length
          ? [...prevActualsList[initialValue.uuid]]
          : [];
      const foundActualIndex = actuals.findIndex(
        (actual) => actual.date === initialValue.date,
      );

      if (initialValueIsActual) {
        if (!value.value) {
          actuals.splice(foundActualIndex, 1);
        } else if (
          value.value ===
            getInputValue({
              value: initialValue.value,
              formatting: initialValue.formatting,
              isActualOrOverride: true,
            }) &&
          foundActualIndex === -1
        ) {
          actuals.push({
            date: initialValue.date as string,
            value:
              initialValue.formatting === IFormattingEnum.Percent
                ? Number(value.value) / 100
                : Number(value.value),
          });
        } else if (foundActualIndex >= 0) {
          actuals[foundActualIndex].value =
            initialValue.formatting === IFormattingEnum.Percent
              ? Number(value.value) / 100
              : Number(value.value);
        } else {
          actuals.push({
            date: initialValue.date as string,
            value:
              initialValue.formatting === IFormattingEnum.Percent
                ? Number(value.value) / 100
                : Number(value.value),
          });
        }
      } else {
        if (foundActualIndex !== -1) {
          if (!value.value) {
            actuals.splice(foundActualIndex, 1);
          } else if (
            value.value ===
            getInputValue({
              value: initialValue.value,
              formatting: initialValue.formatting,
            })
          ) {
            actuals.splice(foundActualIndex, 1);
          } else {
            actuals[foundActualIndex].value =
              initialValue.formatting === IFormattingEnum.Percent
                ? Number(value.value) / 100
                : Number(value.value);
          }
        } else {
          if (
            value.value &&
            value.value !==
              getInputValue({
                value: initialValue.value,
                formatting: initialValue.formatting,
              })
          ) {
            actuals.push({
              date: initialValue.date as string,
              value:
                initialValue.formatting === IFormattingEnum.Percent
                  ? Number(value.value) / 100
                  : Number(value.value),
            });
          } else if (
            value.value ===
              getInputValue({
                value: initialValue.value,
                formatting: initialValue.formatting,
              }) &&
            foundActualIndex !== -1
          ) {
            actuals.splice(foundActualIndex, 1);
          }
        }
      }

      const newActualsList = { ...prevActualsList };
      if (initialValue?.uuid) {
        newActualsList[initialValue.uuid] = actuals;
      }

      return newActualsList;
    });
  };

  const handleOnBlur = useCallback(async (): Promise<void> => {
    if (initialValueIsOverride && !value.value) {
      handleUpdateOverrideList();
    } else if (
      initialValue?.date &&
      isBefore(startOfMonth(initialValue.date), startOfMonth(date()))
    ) {
      handleUpdateActualList();
    } else {
      handleUpdateOverrideList();
    }
  }, [initialValue, value]);

  const moveFocus = ({
    colIndex,
    rowIndex,
  }: {
    colIndex: number;
    rowIndex: number;
  }): void => {
    setSelectedMonthCell((prev) => {
      if (!prev) return null;

      let newColIndex = colIndex;
      let newRowIndex = rowIndex;

      if (newRowIndex > prev.maxRow) {
        newRowIndex = prev.minRow;
        if (newColIndex + 1 > prev.maxCol) {
          newColIndex = prev.minCol;
        } else {
          newColIndex += 1;
        }
      }

      if (newRowIndex < prev.minRow) {
        newRowIndex = prev.maxRow;
        if (newColIndex - 1 < prev.minCol) {
          newColIndex = prev.maxCol;
        } else {
          newColIndex -= 1;
        }
      }

      if (newColIndex > prev.maxCol) {
        newColIndex = prev.minCol;
        if (newRowIndex + 1 > prev.maxRow) {
          newRowIndex = prev.minRow;
        } else {
          newRowIndex += 1;
        }
      }

      if (newColIndex < prev.minCol) {
        newColIndex = prev.maxCol;
        if (newRowIndex - 1 < prev.minRow) {
          newRowIndex = prev.maxRow;
        } else {
          newRowIndex -= 1;
        }
      }

      return {
        ...prev,
        colIndex: newColIndex,
        rowIndex: newRowIndex,
      };
    });
  };

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent): void => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleOnBlur();
        if (initialValue.colIndex && initialValue.rowIndex) {
          moveFocus({
            colIndex: initialValue.colIndex,
            rowIndex: event.shiftKey
              ? initialValue.rowIndex - 1
              : initialValue.rowIndex + 1,
          });
        }
      } else if (event.key === "Escape") {
        if ((Number(initialValue.value) / 100).toString() !== value.value) {
          setValue((prev) => ({
            ...prev,
            value: getInputValue({
              value: initialValue.value,
              formatting: initialValue.formatting,
            }),
          }));
          inputRef.current?.focus();
        } else {
          inputRef.current?.blur();
        }
      } else if (
        event.key === "Tab" ||
        (event.key === "Tab" && event.shiftKey)
      ) {
        event.preventDefault();
        handleOnBlur();
        if (initialValue.colIndex && initialValue.rowIndex) {
          moveFocus({
            colIndex: event.shiftKey
              ? initialValue.colIndex - 1
              : initialValue.colIndex + 1,
            rowIndex: initialValue.rowIndex,
          });
        }
      }
    },
    [initialValue, value],
  );

  const handlePaste = useCallback(
    (event: React.ClipboardEvent): void => {
      event.preventDefault();
      if (initialValue.uuid) {
        const currentRowOverrides = [
          ...(overridesList[initialValue.uuid] ?? []),
        ];
        const currentRowActuals = [...(actualsList[initialValue.uuid] ?? [])];
        const initialMonth = initialValue.date;
        if (!initialMonth) return;

        const spreadsheetData = parseSpreadsheetData({
          pasteData: event.clipboardData.getData("text"),
        });

        const today = startOfMonth(date());

        spreadsheetData[0].forEach((value, index) => {
          const month = endOfMonth(addMonths(date(initialMonth), index));
          const formattedMonth = formatDateToISO({ date: month });

          if (isBefore(startOfMonth(month), today)) {
            const actualIndex = currentRowActuals.findIndex(
              (actual) => actual.date === formattedMonth,
            );
            if (actualIndex !== -1) {
              currentRowActuals[actualIndex] = {
                ...currentRowActuals[actualIndex],
                value:
                  initialValue.formatting === IFormattingEnum.Percent
                    ? parseFloat(value) / 100
                    : parseFloat(value),
              };
            } else {
              currentRowActuals.push({
                date: formattedMonth,
                value:
                  initialValue.formatting === IFormattingEnum.Percent
                    ? parseFloat(value) / 100
                    : parseFloat(value),
              });
            }
          } else {
            const overrideIndex = currentRowOverrides.findIndex(
              (override) => override.date === formattedMonth,
            );
            if (overrideIndex !== -1) {
              currentRowOverrides[overrideIndex] = {
                ...currentRowOverrides[overrideIndex],
                value:
                  initialValue.formatting === IFormattingEnum.Percent
                    ? parseFloat(value) / 100
                    : parseFloat(value),
              };
            } else {
              currentRowOverrides.push({
                date: formattedMonth,
                value:
                  initialValue.formatting === IFormattingEnum.Percent
                    ? parseFloat(value) / 100
                    : parseFloat(value),
              });
            }
          }
        });

        const newOverridesList = {
          ...overridesList,
          [initialValue.uuid]: currentRowOverrides,
        };
        const newActualsList = {
          ...actualsList,
          [initialValue.uuid]: currentRowActuals,
        };

        setOverridesList(newOverridesList);
        setActualsList(newActualsList);
      }
    },
    [initialValue, overridesList, actualsList],
  );

  const inputClassName = ((): string => {
    if (initialValue?.overrides)
      if (
        isActual ||
        initialValueIsActual ||
        Boolean(
          initialValue?.date &&
            isBefore(startOfMonth(initialValue.date), startOfMonth(date())) &&
            !initialValueIsOverride &&
            "value" in initialValue &&
            ((!initialValue.value && value.value) ||
              getInputValue({
                value: initialValue.value,
                formatting: initialValue.formatting,
              }) !== value.value),
        )
      ) {
        return "text-orange";
      } else if (
        isOverride ||
        initialValueIsOverride ||
        (initialValue &&
          "value" in initialValue &&
          getInputValue({
            value: initialValue.value,
            formatting: initialValue.formatting,
          }) !== value.value)
      ) {
        return "text-[#3C98C0]";
      }
    if (
      isBefore(
        startOfMonth(
          toZonedTime(
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            initialValue && "date" in initialValue
              ? initialValue.date ?? date()
              : date(),
            "UTC",
          ),
        ),
        startOfMonth(date()),
      )
    ) {
      return "text-neutral-200";
    }
    return "";
  })();

  if (isLoading) return <></>;
  return (
    <div
      className="w-[130px] max-w-[130px] text-right px-1"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (initialValue?.colIndex && initialValue?.rowIndex) {
          moveFocus({
            colIndex: initialValue.colIndex,
            rowIndex: initialValue.rowIndex,
          });
        }
      }}
      onPaste={(e) => handlePaste(e as unknown as React.ClipboardEvent)}
    >
      {initialValue.formatting === IFormattingEnum.Percent ? (
        <NumericFormat
          getInputRef={inputRef}
          data-testid={`${cellContext.column.id}-${cellContext.row.index}-input`}
          className={`${inputClassName} !border-transparent hover:!border hover:!border-neutral-100 !shadow-none text-right 
          px-[0.57rem] h-[42px] w-full border border-solid border-gray-300  disabled:bg-neutral-25 disabled:text-neutral-75 
          focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 
          focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 rounded shadow-sm`}
          disabled={value.disabled}
          allowLeadingZeros
          allowNegative={false}
          value={value.value}
          onValueChange={(values) => {
            setValue((prev) => ({
              ...prev,
              value: values.value,
            }));
          }}
          onBlur={handleOnBlur}
          suffix="%"
        />
      ) : (
        <Input
          id={`${cellContext.column.id}-${cellContext.row.index}`}
          type={"currency"}
          state={value}
          setState={setValue}
          ref={inputRef}
          onKeyDown={handleKeyDown}
          onBlur={handleOnBlur}
          className={`!border-transparent hover:!border hover:!border-neutral-100 !shadow-none text-right ${inputClassName}`}
          decimalsLimit={10}
          includeDollarSign={
            initialValue.formatting === IFormattingEnum.Currency
          }
        />
      )}
    </div>
  );
};

export default MonthCell;

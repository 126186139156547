import {
  ZRatioElement,
  ZRatio,
  ZRatioByMonth,
  ZRatiosDataSchema,
  ZUserNotification,
} from "./schemas";
import { z } from "zod";

export enum IRatioTypeEnum {
  POSITION = "position",
  FORMULA = "formula",
}

export type IRatioElement = z.infer<typeof ZRatioElement>;
export type IRatio = z.infer<typeof ZRatio>;
export type IRatioByMonth = z.infer<typeof ZRatioByMonth>;
export type IRatiosData = z.infer<typeof ZRatiosDataSchema>;

export interface ICellData {
  positionTitle: string;
  ratioUuid: string;
  drivingValue: number;
  drivingElementTitle: string;
  needed: number;
  planned: number;
  actualDrivingValue: number;
  relevantDate: string;
}

export type TableRow = Record<string, ICellData>;

export type IUserNotification = z.infer<typeof ZUserNotification>;

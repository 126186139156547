import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { State } from "~/store";
import useInput from "~/components/Input/useInput";
import { useSelect } from "~/components/Select";
import { SelectState } from "~/components/Select/Select.types";
import { fetchSinglePosition } from "~/pages/Headcount/utils/fetchSinglePosition";
import { IPositionDetails } from "~/pages/Headcount/entity/types";

const useEditPositionFormState = (
  editPositionUuid: string | null,
): {
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  organizationUuid: string;
  employeeName: Types.InputState;
  setEmployeeName: React.Dispatch<React.SetStateAction<Types.InputState>>;
  title: Types.InputState;
  setTitle: React.Dispatch<React.SetStateAction<Types.InputState>>;
  selectDepartment: SelectState;
  setSelectDepartment: React.Dispatch<React.SetStateAction<SelectState>>;
  resetFormState: () => void;
} => {
  const [errorMessage, setErrorMessage] = useState("");
  const { uuid: organizationUuid, departments } = useSelector(
    (state: State) => state.organization,
  );
  const activeScenarioUuid = useSelector(
    (state: State) => state.scenario.activeScenarioUuid,
  );
  const [employeeName, setEmployeeName] = useInput({
    validation: /^.*$/,
    valid: true,
  });
  const [title, setTitle] = useInput({
    validation: /.+/,
    errorMessage: "Job title is required",
  });
  const [selectDepartment, setSelectDepartment] = useSelect({
    options: departments.map((department) => ({
      value: department.uuid,
      label: department.name,
    })),
  });

  const getSinglePositionData = async (
    positionUuid: string,
  ): Promise<IPositionDetails | null> => {
    try {
      const singlePositionData = await fetchSinglePosition({
        organizationUuid,
        positionUuid,
        scenarioUuid: activeScenarioUuid ?? undefined,
      });
      return singlePositionData;
    } catch (error) {
      return null;
    }
  };

  const setFormState = (singlePositionData: IPositionDetails): void => {
    setTitle({
      ...title,
      value: singlePositionData.title,
      valid: true,
      pristine: false,
      touched: true,
      disabled: false,
    });
    setEmployeeName({
      ...employeeName,
      value: singlePositionData.employeeName ?? "",
      valid: true,
      pristine: false,
      touched: true,
      disabled: false,
    });
    setSelectDepartment({
      ...selectDepartment,
      selected: {
        value: singlePositionData.currentDepartment.uuid,
        label: singlePositionData.currentDepartment.name,
      },
      options: departments.map((department) => ({
        value: department.uuid,
        label: department.name,
      })),
      valid: true,
      pristine: false,
      touched: true,
      disabled: false,
    });
  };

  useEffect(() => {
    if (editPositionUuid) {
      getSinglePositionData(editPositionUuid).then((singlePositionData) => {
        if (singlePositionData) {
          setFormState(singlePositionData);
        }
      });
    }
  }, [editPositionUuid]);

  const resetFormState = (): void => {
    setTitle({
      ...title,
      value: "",
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setEmployeeName({
      ...employeeName,
      value: "",
      valid: true,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setSelectDepartment({
      ...selectDepartment,
      selected: undefined,
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
  };

  return {
    errorMessage,
    setErrorMessage,
    organizationUuid,
    employeeName,
    setEmployeeName,
    title,
    setTitle,
    selectDepartment,
    setSelectDepartment,
    resetFormState,
  };
};

export default useEditPositionFormState;

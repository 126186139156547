import React, { useContext, useEffect, useState } from "react";
import ExportData from "~/components/ExportData";
import Header from "~/components/Header";
import HeadcountProvider, {
  HeadcountContext,
} from "./context/HeadcountContext";
import Button from "~/components/Button";
import CreatePosition from "./components/CreatePosition";
import * as HeadcountTable from "./components/HeadcountTable";
import date from "~/utils/dates/date";
import { format, isBefore } from "date-fns";
import SegmentedControl from "~/components/SegmentedControl";
import { ChartBarIcon, ViewColumnsIcon } from "@heroicons/react/24/outline";
import useQueryParams from "~/utils/hooks/useQueryParams";
import { useExitScenarioListener } from "~/utils/hooks/useExitScenarioListener";
import UserDateRange from "~/components/UserDateRange";
import SelectDepartment from "~/components/SelectDepartment";
import { useSelect } from "~/components/Select";
import Checkbox from "~/components/Checkbox";
import * as HeadcountTimeline from "./components/HeadcountTimeline";

const HeadcountPageContainer = (): React.ReactNode => {
  const [queryParams, setQueryParams] = useQueryParams();
  const viewQueryParam = queryParams.get("view");
  const {
    csvToExport,
    createPositionModalIsOpen,
    setCreatePositionModalIsOpen,
    reload,
    pageLoading,
    positionFormState,
    showTermedPositions,
    setShowTermedPositions,
    setHasReloaded,
    positions,
  } = useContext(HeadcountContext);

  const [headcountView, setHeadcountView] = useState(viewQueryParam ?? "table");
  const [departments, setDepartments] = useSelect({
    options: [],
    selected: { label: "All Departments", value: null },
  });

  useEffect(() => {
    const currentParams = Object.fromEntries(queryParams.entries());
    if (headcountView !== viewQueryParam) {
      currentParams.view = headcountView;
      setQueryParams(currentParams);
    }
  }, [headcountView]);

  useEffect(() => {
    const currentParams = Object.fromEntries(queryParams.entries());
    if (departments.selected) {
      if (!departments.selected.value) {
        currentParams.departments = "all";
      } else {
        currentParams.departments = departments.selected.value;
      }
    }
    setQueryParams(currentParams);
  }, [departments]);

  useExitScenarioListener(reload);

  const hasPastEmployees = positions?.some(
    (position) => position.termDate && isBefore(position.termDate, date()),
  );

  return (
    <div className="max-sm:min-h-screen max-sm:pb-32">
      <CreatePosition
        isOpen={createPositionModalIsOpen}
        setModal={setCreatePositionModalIsOpen}
        positionFormState={positionFormState}
        reload={reload}
        setHasReloaded={setHasReloaded}
      />
      <Header
        title="Headcount"
        zIndex="z-30"
        endChildren={
          <div className="flex w-full justify-end items-center gap-2">
            {!pageLoading && <UserDateRange pickerAlignment="right" />}
          </div>
        }
      />
      <div className="flex flex-col max-w-full h-full items-end justify-center w-full pt-8 max-sm:px-0">
        <div className="flex w-full px-10 justify-between items-center ">
          <div className="max-sm:hidden flex items-center gap-2">
            <SegmentedControl
              name="headcount-view"
              value={headcountView}
              setValue={setHeadcountView}
              segments={[
                {
                  label: (
                    <div className="px-2 py-1">
                      <ViewColumnsIcon className="size-5" />
                    </div>
                  ),
                  value: "table",
                },
                {
                  label: (
                    <div className="px-2 py-1">
                      <ChartBarIcon className="size-5 -rotate-90" />
                    </div>
                  ),
                  value: "timeline",
                },
              ]}
            />
            <div className="min-w-[200px] z-[29]">
              <SelectDepartment
                id="department-filter"
                departmentOptions={departments}
                setDepartmentOptions={setDepartments}
                allowSelectAll
              />
            </div>
            {hasPastEmployees && (
              <div className="flex text-nowrap items-center gap-2">
                <Checkbox
                  checked={showTermedPositions}
                  toggleValue={() =>
                    setShowTermedPositions(!showTermedPositions)
                  }
                  label="Show Past Employees"
                  id="show-past-employees"
                />
              </div>
            )}
          </div>
          <div className="gap-4 flex max-sm:hidden">
            <Button
              id="create-position-button"
              onClick={() => {
                setCreatePositionModalIsOpen(true);
              }}
            >
              Create New Position
            </Button>
            <ExportData
              id={`download-csv`}
              data={csvToExport}
              filename={`positions-${format(date(), "MM-dd-yyyy")}.csv`}
              className="max-sm:hidden"
            />
          </div>
        </div>
        <div
          className={`w-full h-full mt-10 ${headcountView === "table" ? "" : "px-10"}`}
        >
          {headcountView === "table" ? (
            <HeadcountTable.component />
          ) : (
            <HeadcountTimeline.component />
          )}
        </div>
      </div>
    </div>
  );
};

const HeadcountPage = (): React.ReactNode => {
  return (
    <HeadcountProvider>
      <HeadcountPageContainer />
    </HeadcountProvider>
  );
};

export default HeadcountPage;

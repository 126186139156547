import { createContext, useContext } from "react";
import date from "~/utils/dates/date";

export interface ITimelineContext {
  startDate: Date;
  endDate: Date;
  scrollPosition: number;
  setScrollPosition?: (scrollPosition: number) => void;
  onChange: ({
    elementId,
    startDate,
    endDate,
    refreshData,
  }: {
    elementId: string;
    startDate?: Date;
    endDate?: Date;
    refreshData?: boolean;
  }) => void;
}

export const TimelineContext = createContext<ITimelineContext>({
  startDate: date(),
  endDate: date(),
  onChange: () => {},
  scrollPosition: 0,
  setScrollPosition: () => {},
});

export const useTimelineContext = (): ITimelineContext =>
  useContext(TimelineContext);

import { useState } from "react";
import { useSelector } from "react-redux";
import { State } from "~/store";
import useInput from "~/components/Input/useInput";
import { useDatePicker } from "~/components/DatePicker";
import { IDatePickerState } from "~/components/DatePicker/useDatePicker";
import { useSelect } from "~/components/Select";
import { SelectState } from "~/components/Select/Select.types";

const usePositionFormState = (): {
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  organizationUuid: string;
  hireDate: IDatePickerState;
  setHireDate: React.Dispatch<React.SetStateAction<IDatePickerState>>;
  employeeName: Types.InputState;
  setEmployeeName: React.Dispatch<React.SetStateAction<Types.InputState>>;
  title: Types.InputState;
  setTitle: React.Dispatch<React.SetStateAction<Types.InputState>>;
  payRate: Types.InputState;
  setPayRate: React.Dispatch<React.SetStateAction<Types.InputState>>;
  selectDepartment: SelectState;
  setSelectDepartment: React.Dispatch<React.SetStateAction<SelectState>>;
  resetFormState: () => void;
  numberToCreate: Types.InputState;
  setNumberToCreate: React.Dispatch<React.SetStateAction<Types.InputState>>;
  isChecked: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
} => {
  const [errorMessage, setErrorMessage] = useState("");
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const [hireDate, setHireDate] = useDatePicker({
    errorMessage: "Hire date is required",
  });
  const [employeeName, setEmployeeName] = useInput({
    validation: /^.*$/,
    valid: true,
  });
  const [title, setTitle] = useInput({
    validation: /.+/,
    errorMessage: "Job title is required",
  });
  const [payRate, setPayRate] = useInput({
    validation: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    errorMessage: "Annual Pay is required",
  });
  const [selectDepartment, setSelectDepartment] = useSelect({
    options: [],
  });
  const [numberToCreate, setNumberToCreate] = useInput({
    validation: /^(?:100|[1-9][0-9]?)$/, // Regex to match numbers 1-100
    valid: true,
    value: "1",
    errorMessage: "Number to create must be a number between 1 and 100",
  });
  const [isChecked, setIsChecked] = useState(false);

  const resetFormState = (): void => {
    setTitle({
      ...title,
      value: "",
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setHireDate({
      ...hireDate,
      value: {
        startDate: null,
        endDate: null,
      },
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setPayRate({
      ...payRate,
      value: "",
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setEmployeeName({
      ...employeeName,
      value: "",
      valid: true,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setSelectDepartment({
      ...selectDepartment,
      selected: undefined,
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setNumberToCreate({
      ...numberToCreate,
      value: "1",
      valid: true,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setIsChecked(false);
  };

  return {
    errorMessage,
    setErrorMessage,
    organizationUuid,
    hireDate,
    setHireDate,
    employeeName,
    setEmployeeName,
    title,
    setTitle,
    payRate,
    selectDepartment,
    setSelectDepartment,
    setPayRate,
    resetFormState,
    numberToCreate,
    setNumberToCreate,
    isChecked,
    setIsChecked,
  };
};

export default usePositionFormState;

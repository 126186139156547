import { createSlice } from "@reduxjs/toolkit";

export interface HydrationState {
  hydrated: boolean;
}

const initialState = {
  hydrated: false,
} as HydrationState;

export const hydrationSlice = createSlice({
  name: "_hydration",
  initialState,
  reducers: {
    setAsHydrated: () => ({
      hydrated: true,
    }),
    reset: () => initialState,
  },
});

export const { setAsHydrated, reset } = hydrationSlice.actions;
export const hydration = hydrationSlice.actions;
export default hydrationSlice.reducer;

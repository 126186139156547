import React, {
  forwardRef,
  useContext,
  useLayoutEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { FormulaBuilderContext } from "./FormulaBuilderContext";
import { OPERATOR_LIST } from "../../entity/types";

interface Props {
  handleKeyDown: ({
    e,
    position,
  }: {
    e: React.KeyboardEvent<HTMLDivElement>;
    position?: "beginning" | "end";
  }) => void;
  handleAddOperator: ({
    operator,
    splitValueAtIndex,
  }: {
    operator: string | null;
    splitValueAtIndex?: number;
  }) => void;
  isDisabled?: boolean;
}

const OPERATORS_REGEX = /[+\-*/()]/;

const FormulaBuilderContentEditable = forwardRef<HTMLDivElement, Props>(
  ({ handleKeyDown, handleAddOperator, isDisabled }, ref) => {
    const innerRef = useRef<HTMLDivElement>(null);
    const {
      value,
      setValue,
      segmentToDelete,
      enteredConstantFrom,
      setEnteredConstantFrom,
    } = useContext(FormulaBuilderContext);
    const cursorPositionRef = useRef<number | null>(null);

    useImperativeHandle(ref, () => innerRef.current!);

    const getCaretPosition = (): number => {
      const sel = window.getSelection();
      if (sel && sel.rangeCount > 0) {
        const range = sel.getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(innerRef.current!);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        return preCaretRange.toString().length;
      }
      return 0;
    };

    useLayoutEffect(() => {
      const el = innerRef.current;
      if (el) {
        const range = document.createRange();
        const sel = window.getSelection();
        el.textContent = value;

        if (enteredConstantFrom === "left") {
          range.setStart(el, 0);
          range.collapse(true);
        } else if (enteredConstantFrom === "right") {
          range.selectNodeContents(el);
          range.collapse(false);
        } else if (cursorPositionRef.current !== null) {
          let charCount = 0;
          let foundNode = false;
          for (const node of el.childNodes) {
            if (node.nodeType === Node.TEXT_NODE) {
              const length = node.textContent!.length;
              if (charCount + length >= cursorPositionRef.current) {
                range.setStart(node, cursorPositionRef.current - charCount);
                range.collapse(true);
                foundNode = true;
                break;
              }
              charCount += length;
            }
          }
          if (!foundNode) {
            range.selectNodeContents(el);
            range.collapse(false);
          }
        } else {
          range.selectNodeContents(el);
          range.collapse(false);
        }

        sel?.removeAllRanges();
        sel?.addRange(range);
        if (!isDisabled) {
          el.focus();
        }

        // Reset enteredConstantFrom after setting the caret
        if (enteredConstantFrom && value !== "") {
          setEnteredConstantFrom(undefined);
        }
      }
    }, [value, isDisabled]);

    useLayoutEffect(() => {
      const el = innerRef.current;
      if (el) {
        // Blur the element if disabled
        if (isDisabled) {
          el.blur();
        } else {
          if (!segmentToDelete) {
            el.focus();
          } else {
            el.blur();
          }
        }
      }
    }, [segmentToDelete, isDisabled]);

    const handleOnInput = (e: React.FormEvent<HTMLDivElement>): void => {
      const newValue = e.currentTarget.textContent ?? "";
      cursorPositionRef.current = getCaretPosition();

      if (OPERATOR_LIST.some((operator) => newValue.includes(operator))) {
        const operator = newValue.match(OPERATORS_REGEX)![0];
        handleAddOperator({
          operator: operator,
          splitValueAtIndex: newValue.indexOf(operator),
        });
        e.currentTarget.textContent = "";
      } else {
        setValue(newValue);
      }
    };

    const getPosition = ({
      caretPosition,
    }: {
      caretPosition: number;
    }): "beginning" | "end" | undefined => {
      if (caretPosition === 0 && value.length) {
        return "beginning";
      } else if (caretPosition === value.length && value.length) {
        return "end";
      } else {
        return undefined;
      }
    };

    return (
      <div
        className="border border-transparent focus:border-transparent py-0.5 px-0 focus:outline-none focus-visible:ring-0"
        onInput={handleOnInput}
        onKeyDown={(e) => {
          handleKeyDown({
            e,
            position: getPosition({ caretPosition: getCaretPosition() }),
          });
        }}
        ref={innerRef}
        contentEditable
        suppressContentEditableWarning
        data-testid="formula-builder-content-editable"
      />
    );
  },
);

FormulaBuilderContentEditable.displayName = "FormulaBuilderContentEditable";

export default FormulaBuilderContentEditable;

import React from "react";
import Datepicker from "react-tailwindcss-datepicker";
import Typography from "~/components/Typography";
import useDatePicker, { IDatePickerState } from "./useDatePicker";
import "./datePicker.css";
import { isValid, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";

interface Props {
  id: string;
  className?: string;
  labelPlacement?: "top" | "left";
  format?: string;
  state: IDatePickerState;
  setState: React.Dispatch<React.SetStateAction<IDatePickerState>>;
  label?: string;
  errorMessage?: string;
  minDate?: Date;
  maxDate?: Date;
  range?: boolean;
  required?: boolean;
  disabled?: boolean;
}

const DatePicker = ({
  id,
  className,
  labelPlacement = "top",
  format = "MM/DD/YYYY",
  state,
  setState,
  label,
  errorMessage,
  minDate,
  maxDate,
  range = false,
  required = false,
  disabled = false,
}: Props): React.ReactElement => {
  const handleValueChange = ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }): void => {
    setState((prevState) => {
      let valid;
      if (range) {
        valid = isValid(parseISO(startDate)) && isValid(parseISO(endDate));
      } else if (!startDate && required) {
        valid = false;
      } else if (!startDate && !required) {
        valid = true;
      } else {
        valid = isValid(parseISO(startDate));
      }
      return {
        ...prevState,
        value: { startDate, endDate: range ? endDate : startDate },
        valid,
        pristine: false,
        touched: true,
      };
    });
  };

  const showError = state.touched && !state.pristine && !state.valid;

  return (
    <label
      htmlFor={id}
      data-testid={id}
      className={`w-full ${className}${
        labelPlacement === "left" ? " flex items-center gap-1" : ""
      }`}
    >
      {label && (
        <div className="flex flex-row">
          <Typography
            tag="span"
            size="xs"
            className={`block truncate${
              state.value ? " font-medium" : " font-normal"
            }${label && " mb-1"}`}
          >
            {label}
          </Typography>
          {required && (
            <Typography
              tag="span"
              size="2xs"
              className={`${state.disabled ? " text-neutral-75" : ""}`}
            >
              *
            </Typography>
          )}
        </div>
      )}
      <div className="datepicker" data-testid={`${id}-datepicker-wrapper`}>
        <Datepicker
          /*
           * Tailwinds datepicker automatically chooses the selected color -500,
           * we don't use sky anywhere so we made an arbitrary sky-500 that maps to green-300
           */
          primaryColor={"sky"}
          inputId={id}
          minDate={
            state.minDate
              ? toZonedTime(new Date(state.minDate), "UTC")
              : minDate
          }
          maxDate={
            state.maxDate
              ? toZonedTime(new Date(state.maxDate), "UTC")
              : maxDate
          }
          asSingle={!range}
          useRange={range}
          value={state.value}
          startFrom={
            state.value.startDate ? new Date(state.value.startDate) : null
          }
          displayFormat={format}
          separator={range ? "to" : undefined}
          onChange={handleValueChange}
          disabled={disabled}
          popoverDirection="down"
          inputClassName={`rounded w-full border border-solid ${
            showError ? "border-red-300" : "border-gray-300"
          } p-[0.57rem] h-[42px] focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300`}
          readOnly
        />
      </div>
      {showError && (
        <p
          className="text-red-500 text-xs italic p-1"
          data-testid={`${id}-error`}
        >
          {state.errorMessage ?? errorMessage}
        </p>
      )}
    </label>
  );
};

export { useDatePicker };
export default DatePicker;

import React, { ReactElement } from "react";
import {
  IFormula,
  IFormulaSegment,
  IUpdateCalculationModifier,
  IUpdateTimeModifier,
  IVariables,
} from "../entity/types";
import FormulaElement from "../components/FormulaBuilder/FormulaElement";
import CalculatedFormulaElement from "../components/FormulaBuilder/CalculatedFormulaElement";
import ConstantElement from "../components/FormulaBuilder/ConstantElement";
import { v4 } from "uuid";

const calculationTypeEnumToRecipeName = {
  headcountNumber: "Headcount",
  newHireNumber: "New Hires",
  salariesAndWages: "Salary and Wages",
  softwareExpenses: "Software Expenses",
  headcountExpenses: "Headcount Related",
  otherExpenses: "Other Expenses",
};

const generateFormulaArray = ({
  topLevelFormulaUuid,
  formula,
  variables,
  formulaList,
  editable,
  handleUpdateCalculationModifier,
  handleUpdateTimeModifier,
}: {
  topLevelFormulaUuid?: string;
  formula: string;
  variables?: IVariables;
  formulaList: IFormula[];
  editable?: boolean;
  handleUpdateCalculationModifier?: ({
    calculationModifier,
    formulaForUpdate,
    formulaTextValue,
    refToUpdate,
  }: IUpdateCalculationModifier) => void;
  handleUpdateTimeModifier?: ({
    timeModifier,
    formulaForUpdate,
    formulaTextValue,
    refToUpdate,
  }: IUpdateTimeModifier) => void;
}): IFormulaSegment[] => {
  const result: {
    element: ReactElement;
    ref: React.RefObject<HTMLDivElement>;
    textValue: string;
    type: "constant" | "formula" | "operator" | "invalid" | "calculated";
  }[] = [];
  const operators = new Set(["+", "-", "*", "/", "(", ")", "="]);
  let currentSegment = "";
  let currentSegmentType: "operator" | "variable" | null = null;
  let index = 0;

  const pushCurrentSegment = (): void => {
    if (currentSegment) {
      const elementRef = React.createRef();
      if (currentSegmentType === "variable") {
        if (
          variables?.[currentSegment] &&
          "type" in variables[currentSegment] &&
          variables[currentSegment].type === "formula"
        ) {
          const { formulaUuid, timeModifier } = variables[currentSegment];
          const foundFormula = formulaList.find((f) => f.uuid === formulaUuid);
          if (foundFormula) {
            result.push({
              element: (
                <FormulaElement
                  selectedFormula={foundFormula}
                  key={`${topLevelFormulaUuid}-formula-${foundFormula.recipe.name}-${index}`}
                  handleUpdateTimeModifier={handleUpdateTimeModifier}
                  timeModifier={timeModifier}
                  selectable={editable}
                  formulaTextValue={currentSegment}
                  ref={elementRef}
                  formulaIndex={index}
                />
              ),
              ref: elementRef,
              textValue: currentSegment,
              type: "formula",
            });
          } else {
            result.push({
              element: React.cloneElement(
                <div key={`${topLevelFormulaUuid}-constant-${index}`}>
                  {currentSegment}
                </div>,
                { ref: elementRef }
              ),
              ref: elementRef,
              textValue: currentSegment,
              type: "constant",
            });
          }
        } else if (
          variables?.[currentSegment] &&
          "type" in variables[currentSegment] &&
          variables[currentSegment].type === "self"
        ) {
          const { timeModifier } = variables[currentSegment];
          const foundFormula = formulaList.find(
            (f) => f.uuid === topLevelFormulaUuid
          );
          if (foundFormula) {
            result.push({
              element: (
                <FormulaElement
                  selectedFormula={foundFormula}
                  key={`${topLevelFormulaUuid}-formula-${foundFormula.recipe.name}-${index}`}
                  handleUpdateTimeModifier={handleUpdateTimeModifier}
                  timeModifier={timeModifier}
                  selectable={editable}
                  formulaTextValue={currentSegment}
                  ref={elementRef}
                  formulaIndex={index}
                />
              ),
              ref: elementRef,
              textValue: currentSegment,
              type: "formula",
            });
          }
        } else if (
          variables?.[currentSegment] &&
          "type" in variables[currentSegment] &&
          variables[currentSegment].type === "calculated"
        ) {
          const { calculationModifier } = variables[currentSegment];
          const calculationType = variables[currentSegment].calculationType;

          if (calculationType) {
            const calculationName =
              calculationTypeEnumToRecipeName[calculationType];

            const foundFormula = formulaList.find(
              (f) => f.recipe.name === calculationName
            );
            if (foundFormula) {
              result.push({
                element: (
                  <CalculatedFormulaElement
                    selectedFormula={foundFormula}
                    key={`${topLevelFormulaUuid}-calculated-${index}`}
                    handleUpdateCalculationModifier={
                      handleUpdateCalculationModifier
                    }
                    calculationModifier={calculationModifier}
                    selectable={editable}
                    formulaTextValue={currentSegment}
                    ref={elementRef}
                    formulaIndex={index}
                  />
                ),
                ref: elementRef,
                textValue: currentSegment,
                type: "calculated",
              });
            }
          }
        } else {
          if (
            variables?.[currentSegment] &&
            "constantValue" in variables[currentSegment] &&
            (variables[currentSegment].constantValue !== undefined ||
              variables[currentSegment].constantValue !== null) &&
            variables[currentSegment].type === "constant"
          ) {
            result.push({
              element: (
                <ConstantElement
                  key={`${topLevelFormulaUuid}-constant-${index}`}
                  ref={elementRef}
                  renderedValue={variables[currentSegment].constantValue}
                  selectable={editable}
                  valid
                  formulaIndex={index}
                />
              ),
              ref: elementRef,
              textValue: currentSegment,
              type: "constant",
            });
          } else if (
            variables?.[currentSegment] &&
            "constantValue" in variables[currentSegment] &&
            (variables[currentSegment].constantValue !== undefined ||
              variables[currentSegment].constantValue !== null) &&
            variables[currentSegment].type === "invalid"
          ) {
            result.push({
              element: (
                <ConstantElement
                  key={`${topLevelFormulaUuid}-invalid-${index}`}
                  ref={elementRef}
                  formulaIndex={index}
                  renderedValue={variables[currentSegment].constantValue}
                  selectable={editable}
                  valid={false}
                />
              ),
              ref: elementRef,
              textValue: currentSegment,
              type: "invalid",
            });
          } else {
            result.push({
              element: React.cloneElement(
                <div key={`${topLevelFormulaUuid}-constant-${index}`}>
                  {currentSegment}
                </div>,
                { ref: elementRef }
              ),
              ref: elementRef,
              textValue: currentSegment,
              type: "constant",
            });
          }
        }
      } else {
        result.push({
          element: (
            <ConstantElement
              key={`${topLevelFormulaUuid}-operator-${index}`}
              ref={elementRef}
              formulaIndex={index}
              selectable={editable}
              renderedValue={currentSegment}
              valid
            />
          ),
          ref: React.createRef(),
          textValue: currentSegment,
          type: "operator",
        });
      }
      currentSegment = "";
      index += 1;
    }
  };

  for (const char of formula) {
    if (operators.has(char)) {
      pushCurrentSegment();
      const elementRef = React.createRef();
      result.push({
        element: (
          <ConstantElement
            key={`operator-${v4()}`}
            ref={elementRef}
            formulaIndex={index}
            selectable={editable}
            renderedValue={char}
            valid
          />
        ),
        ref: elementRef,
        textValue: char,
        type: "operator",
      });
      index += 1;
      currentSegmentType = null;
    } else if (char === "$") {
      pushCurrentSegment();
      currentSegment = char;
      currentSegmentType = "variable";
    } else if (/\d/.test(char) || char === "," || char === ".") {
      if (currentSegmentType !== "variable") {
        pushCurrentSegment();
      }
      currentSegment += char;
    } else if (char.trim() === "") {
      pushCurrentSegment();
      currentSegmentType = null;
    } else {
      if (currentSegmentType === "variable") {
        currentSegment += char;
      } else {
        pushCurrentSegment();
        currentSegment = char;
        currentSegmentType = "variable";
      }
    }
  }

  pushCurrentSegment();

  return result;
};

export default generateFormulaArray;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AuthState {
  isLoggedIn: boolean;
}

const initialState = {
  isLoggedIn: false,
} as AuthState;

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: () => ({
      isLoggedIn: true,
    }),
    update: (state, action: PayloadAction<AuthState>) => action.payload,
    reset: () => initialState,
  },
});

export const { update, reset } = authSlice.actions;
export default authSlice.reducer;

import React, { useState, useEffect, ForwardedRef } from "react";

interface Props {
  id: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  className: string;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  required?: boolean;
  showError?: boolean;
  decimalsLimit?: number;
  ref?: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>;
  includeDollarSign?: boolean;
}

const BaseCurrencyInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      placeholder = "Please enter an amount",
      value,
      onChange,
      onBlur,
      disabled = false,
      className,
      onKeyDown,
      required = false,
      showError = false,
      decimalsLimit = 2,
      includeDollarSign,
    },
    ref,
  ): React.ReactElement => {
    useEffect(() => {
      setDisplayValue(formatCurrency(value));
    }, [value]);

    const formatCurrency = (value: string): string => {
      if (value === "") return "";
      const hasDecimal = value.includes(".");
      let [integerPart, decimalPart]: (string | undefined)[] = value.split(".");
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (decimalPart && decimalPart.length > decimalsLimit) {
        decimalPart = decimalPart.substring(0, decimalsLimit);
      }
      if (hasDecimal) {
        return decimalPart
          ? `${integerPart}.${decimalPart}`
          : `${integerPart}.`;
      } else {
        return integerPart;
      }
    };

    const [displayValue, setDisplayValue] = useState<string>(
      formatCurrency(value),
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const rawValue = event.target.value.replace(/[^0-9.-]+/g, "");
      if (rawValue === "") {
        setDisplayValue("");
        onChange("");
        return;
      }
      setDisplayValue(event.target.value);
      onChange(rawValue);
    };

    const handleBlur = (): void => {
      setDisplayValue(formatCurrency(value));
      onBlur?.();
    };

    return (
      <input
        className={`${className} w-full p-[0.57rem] h-[42px] border border-solid border-gray-300 disabled:bg-neutral-25 disabled:text-neutral-75 rounded shadow-sm focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300${
          showError ? " border-red-300" : ""
        }`}
        type="text"
        value={includeDollarSign ? "$" + displayValue : displayValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
        required={required}
        disabled={disabled}
        id={id}
        data-testid={`${id}-input`}
        name={id}
        placeholder={placeholder}
        ref={ref}
      />
    );
  },
);

BaseCurrencyInput.displayName = "BaseCurrencyInput";

export default BaseCurrencyInput;

import {
  addMonths,
  differenceInCalendarMonths,
  format,
  getMonth,
  startOfMonth,
} from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import Typography from "~/components/Typography";
import TodayIndicator from "./TodayIndicator";
import { TimelineContext } from "./TimelineContext";
import { SPACE_PER_MONTH } from "./utils/constants";
import date from "~/utils/dates/date";

interface IProps {
  startDate: Date;
  endDate: Date;
  children: React.ReactNode[] | React.ReactNode;
  className?: string;
  onChange: ({
    elementId,
    startDate,
    endDate,
    refreshData,
  }: {
    elementId: string;
    startDate?: Date;
    endDate?: Date;
    refreshData?: boolean;
  }) => void;
}

const TimelineContainer = React.forwardRef(
  (
    { startDate, endDate, onChange, children, className = "" }: IProps,
    forwardedRef: React.Ref<HTMLDivElement>,
  ): React.ReactElement => {
    const containerRef = forwardedRef as React.MutableRefObject<HTMLDivElement>;
    const months = useMemo(() => {
      const monthCount = differenceInCalendarMonths(endDate, startDate) + 1;
      const dates = Array.from({ length: monthCount }).map((_, index) => {
        return addMonths(startOfMonth(startDate), index);
      });

      return dates;
    }, [startDate, endDate]);

    const PIXELS_PER_MONTH = SPACE_PER_MONTH * 16;
    const [scrollPosition, setScrollPosition] = useState(
      (differenceInCalendarMonths(date(), startDate) - 1) * PIXELS_PER_MONTH,
    );

    useEffect(() => {
      if (forwardedRef) {
        (
          forwardedRef as React.MutableRefObject<HTMLDivElement>
        ).current.scrollTo({
          left: scrollPosition,
        });
      }
    });

    const handleScroll = (event: React.UIEvent<HTMLDivElement>): void => {
      const newScrollPosition = event.currentTarget.scrollLeft;
      setScrollPosition(newScrollPosition);
    };

    useEffect(() => {
      const handleDrag = (event: MouseEvent): void => {
        const { clientX } = event;
        const { left, right } = containerRef.current.getBoundingClientRect();
        const scrollSpeed = 5; // Adjust scroll speed as needed

        if (clientX - left < 100) {
          containerRef.current.scrollLeft -= scrollSpeed;
        } else if (right - clientX < 100) {
          containerRef.current.scrollLeft += scrollSpeed;
        }
      };

      document.addEventListener("dragover", handleDrag);

      return () => {
        document.removeEventListener("dragover", handleDrag);
      };
    }, [containerRef]);

    return (
      <TimelineContext.Provider
        value={{
          startDate,
          endDate,
          onChange,
          scrollPosition,
        }}
      >
        <div
          data-testid="timeline-container"
          className={`h-full border rounded-lg overflow-auto flex flex-col bg-neutral-15  ${className}`}
          ref={containerRef}
          onScroll={handleScroll}
        >
          <header className="flex flex-nowrap border-b w-fit flex-shrink-0 h-14 sticky top-0 bg-white z-20">
            {months.map((month: Date, index: number) => (
              <div
                key={format(month, "yyyy-MMM")}
                className="relative flex justify-center items-center h-[3.25rem]"
                style={{
                  minWidth: `${SPACE_PER_MONTH}rem`,
                }}
              >
                {/* MONTH LABEL: short named */}
                <Typography size="xs">{format(month, "MMM")}</Typography>
                {/* YEAR LABEL: keyed off of January and moved to precede it */}
                {getMonth(month) === 0 && index !== 0 && (
                  <div className="absolute -left-2">
                    <Typography size="xs" color="disabled">
                      {`'${format(month, "yy")}`}
                    </Typography>
                  </div>
                )}
              </div>
            ))}
          </header>
          <section className="flex-grow relative bg-neutral-15 py-8 w-fit">
            {/* MONTH DIVIDERS */}
            {months.map((month: Date, index: number) => (
              <div
                key={`${format(month, "yyyy-MMM")}-divider`}
                className={`absolute z-0 top-0 h-full w-[1px] bg-neutral-50`}
                style={{
                  left: `${SPACE_PER_MONTH + SPACE_PER_MONTH * index}rem`,
                }}
              />
            ))}
            {/* TODAY INDICATOR */}
            <TodayIndicator startDate={startDate} />
            <div
              className="flex flex-col gap-1 relative min-w-full"
              data-testid="timeline-nodes"
            >
              {/* NODES */}
              {children}
            </div>
          </section>
        </div>
      </TimelineContext.Provider>
    );
  },
);

TimelineContainer.displayName = "TimelineContainer";

export default TimelineContainer;

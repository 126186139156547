import getTextDimensions from "~/utils/text/getTextDimensions";

export default ({
  ticks,
  yFormatter,
}: {
  ticks: number[];
  yFormatter: (value: number) => string;
}): number => {
  const EXTRA_PADDING = 35; // value was derived from trial and error of what width looked right
  let longestTickWidth = 0;
  ticks.forEach((tick) => {
    const tickWidth = getTextDimensions(yFormatter(tick), "Inter 16px").width;
    if (tickWidth > longestTickWidth) {
      longestTickWidth = tickWidth;
    }
  });
  return longestTickWidth + EXTRA_PADDING;
};

import Modal from "~/components/Modal";
import React from "react";
import Button from "~/components/Button";
import Typography from "~/components/Typography";

interface Props {
  isOpen: boolean;
  setModal: () => void;
  submitFunction: () => void;
}

const RevokeAccess = ({
  isOpen,
  setModal,
  submitFunction,
}: Props): React.ReactNode => (
  <Modal isOpen={isOpen} size="sm" title="Remove Employee Access">
    <div
      data-testid="revoke-access-modal"
      className="flex flex-col gap-4 justify-center"
    >
      <div>
        <Typography size="sm" color="secondary">
          This will disable their ability to access Parallel and all data and
          requested modifications from this user will be deleted.
        </Typography>
      </div>
      <div className="flex flex-row justify-between">
        <Button
          onClick={() => setModal()}
          fill="clear"
          className="!w-fit !px-0"
        >
          Cancel
        </Button>
        <Button
          id="submit-revoke-access"
          onClick={submitFunction}
          fill="destructive"
          className="!w-fit"
        >
          Revoke Access
        </Button>
      </div>
    </div>
  </Modal>
);

export default RevokeAccess;

import React from "react";
import { IFormula } from "../../entity/types";

const FormulaList = ({
  value,
  formulaList,
  onSelectAttribute,
  highlightedFormula,
}: {
  value: string;
  formulaList: IFormula[];
  onSelectAttribute: (formula: IFormula) => void;
  highlightedFormula: {
    formula?: IFormula;
    index: number;
  };
  setHighlightedFormula: React.Dispatch<
    React.SetStateAction<{
      formula?: IFormula;
      index: number;
    }>
  >;
}): React.ReactNode => {
  const getPaddingStyle = ({
    formulaListLength,
    formulaIndex,
  }: {
    formulaListLength: number;
    formulaIndex: number;
  }): string => {
    if (formulaListLength === 1) {
      return "py-3";
    } else if (formulaIndex === 0) {
      return "pt-3 pb-2.5";
    } else if (formulaIndex > 0 && formulaIndex === formulaListLength - 1) {
      return "pt-2.5 pb-3";
    } else {
      return "py-2.5";
    }
  };
  return (
    <div
      className={`absolute flex flex-col z-20 shadow-xl rounded-xl overflow-hidden text-nowrap ${value.length ? "" : "hidden"}`}
    >
      {formulaList.map((formula, index) => (
        <div
          className={`px-6 ${getPaddingStyle({ formulaListLength: formulaList.length, formulaIndex: index })} ${index === highlightedFormula.index ? "bg-neutral-15" : "bg-white"} hover:bg-neutral-15`}
          onClick={(e) => {
            e.stopPropagation();
            onSelectAttribute(formula);
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onMouseUp={(e) => {
            e.stopPropagation();
          }}
          key={formula.uuid}
        >
          {formula.recipe.name}
        </div>
      ))}
    </div>
  );
};

export default FormulaList;

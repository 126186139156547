import React, { Fragment, useContext, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addMonths, format, isSameMonth } from "date-fns";
import Typography from "~/components/Typography";
import {
  IConsolidatedReport,
  IScenario,
  IStaticReport,
  IStaticReportData,
} from "~/pages/OldDashboard/entity/types";
import date from "~/utils/dates/date";
import { State } from "~/store";
import { ILineProps, ILockedIndex } from "~/components/LineGraph/entity/types";
import ScenarioMenuDropdown from "./ScenarioMenuDropdown";
import SelectedScenarioContainer from "./SelectedScenarioContainer";
import { v4 } from "uuid";
import getRunwayColor from "../../utils/getRunwayColor";
import { reassignSelectedIndexesForNewDate } from "~/store/scenarioSlice";
import UnlockedIcon from "../expenseDrilldown/UnlockedIcon";
import RemoveLockIcon from "../expenseDrilldown/RemoveLockIcon";
import { DashboardPageContext } from "../../context/DashboardContext";
import HoverPopover from "~/components/HoverPopover";
import UserDateRange from "~/components/UserDateRange";
import SelectedScenarioTextEdit from "./SelectedScenarioTextEdit";
import EmptyGraph from "./EmptyGraph";
import Spinner from "~/components/Spinner";
import LineGraph from "~/components/LineGraph";

const ScenariosContainer = ({
  cashBalanceReport,
  cashflowReport,
  expensesReport,
  revenueReport,
  runway,
  title = "Scenarios",
  scenarios,
  canQuickScenario,
  expensesLockedIndex,
  expenseGraphRef,
  handleClickExpenses,
  createQuickScenario,
}: {
  cashBalanceReport?: IConsolidatedReport;
  cashflowReport?: IConsolidatedReport;
  expensesReport?: IConsolidatedReport;
  revenueReport?: IConsolidatedReport;
  runway?: IStaticReport[];
  title?: string;
  scenarios: IScenario[];
  canQuickScenario?: boolean;
  expensesLockedIndex: ILockedIndex[];
  expenseGraphRef: React.RefObject<HTMLElement>;
  handleClickExpenses?: (index: number) => void;
  createQuickScenario?: () => Promise<void>;
}): React.ReactNode => {
  const dispatch = useDispatch();
  const { activeScenarioUuid, selectedScenarioUuids } = useSelector(
    (state: State) => state.scenario,
  );
  const userUuid = useSelector((state: State) => state.user.uuid);

  const { pageLoading, scenarioCreationLoading } =
    useContext(DashboardPageContext);

  const { preferences } = useSelector((state: State) => state.user);
  const isNotInitialRender = useRef(false);

  const dataKeys = ["date", "report0", "report1", "report2", "report3"].splice(
    0,
    cashBalanceReport?.data.length
      ? Object.keys(cashBalanceReport.data[0]).length
      : 0,
  );
  const lines: ILineProps[] = [
    { dataKey: "report0", stroke: "#64755C" },
    { dataKey: "report1", stroke: "#406F83", isDashed: true },
    { dataKey: "report2", stroke: "#EBA61E", isDashed: true },
    { dataKey: "report3", stroke: "#8A6190", isDashed: true },
  ].splice(
    0,
    cashBalanceReport?.data.length
      ? Object.keys(cashBalanceReport.data[0]).length - 1
      : 0,
  );
  const [userActiveIndex, setUserActiveIndex] = React.useState<number>(-1);

  useEffect(() => {
    if (isNotInitialRender.current) {
      if (preferences.defaultGraphStartDate)
        dispatch(
          reassignSelectedIndexesForNewDate(
            new Date(preferences.defaultGraphStartDate),
          ),
        );
    } else {
      isNotInitialRender.current = true;
    }
  }, [preferences.defaultGraphStartDate, preferences.defaultGraphEndDate]);

  const scenariosToDisplay = useMemo(() => {
    if (activeScenarioUuid) {
      return [activeScenarioUuid, ...selectedScenarioUuids];
    } else {
      return selectedScenarioUuids;
    }
  }, [selectedScenarioUuids, activeScenarioUuid]);

  const getRunwayValue = ({
    runwayData,
  }: {
    runwayData: IStaticReportData;
  }): string | number => {
    if (runwayData.value !== null) return runwayData.value;

    if (runwayData.isTrendingPositive) return "∞";

    return "36+";
  };

  const currentMonthIndex = useMemo(() => {
    if (cashBalanceReport?.data.length) {
      const currentDate = date();
      return cashBalanceReport.data.findIndex((item) =>
        isSameMonth(new Date(item.date), currentDate),
      );
    }
    return -1;
  }, [cashBalanceReport]);

  return (
    <div className="w-full flex flex-col items-center">
      <div className="flex flex-col w-[80%] max-w-[1442px] min-w-[720px] max-sm:w-[95vw] mt-24 max-sm:max-w-[95vw] max-sm:min-w-[95vw] max-sm:mt-5">
        <Typography size="xl" weight="bold">
          {title}
        </Typography>
        <div className="flex w-full items-center justify-between">
          <div
            className="flex w-full mb-2 items-end justify-between max-sm:flex-col max-sm:items-end max-sm:mb-0 max-sm:w-full max-sm:gap-2"
            data-testid="runway-container"
          >
            <div className="flex flex-col w-full">
              <div className="mb-2 -ml-2.5">
                {userUuid && <UserDateRange />}
              </div>
              {canQuickScenario && (
                <div className="flex items-center justify-start max-sm:max-w-full max-sm:flex-wrap max-sm:items-start max-sm:gap-1 gap-1">
                  {scenariosToDisplay.length < 3 && (
                    <ScenarioMenuDropdown
                      scenarios={scenarios}
                      createQuickScenario={createQuickScenario}
                    />
                  )}
                  {scenarioCreationLoading && (
                    <div
                      className="flex justify-center items-center gap-2"
                      data-testid="scenario-creation-loading"
                    >
                      <Typography size="sm" color="secondary">
                        Generating Scenario
                      </Typography>
                      <Spinner strokeColor="#5A8496" />
                    </div>
                  )}
                  {scenariosToDisplay.map((scenario, index) => {
                    const scenarioForUuid = scenarios.find(
                      (s) => s.uuid === scenario,
                    );
                    if (activeScenarioUuid && index === 0 && scenarioForUuid) {
                      return (
                        <SelectedScenarioTextEdit
                          key={scenario}
                          scenario={scenarioForUuid}
                        />
                      );
                    }

                    if (!scenarioForUuid) {
                      return null;
                    }

                    return (
                      <SelectedScenarioContainer
                        scenario={scenarioForUuid}
                        key={scenario}
                        index={index}
                      />
                    );
                  })}
                </div>
              )}
            </div>
            <div className="mb-[9px] flex items-center sm:min-w-fit">
              <Typography size="sm" color="disabled" className="mr-1">
                🛫 Runway
              </Typography>
              {runway?.map((item, index) => {
                const runwayValue = getRunwayValue({ runwayData: item.data });
                return (
                  <Fragment key={v4()}>
                    <HoverPopover
                      buttonContent={
                        <Typography
                          size="lg"
                          weight="bold"
                          className="mr-1 leading-5"
                          color={getRunwayColor(index).text}
                        >
                          {runwayValue}
                        </Typography>
                      }
                      panelContent={
                        <div
                          className={`${getRunwayColor(index).bg ? getRunwayColor(index).bg : "bg-black"} px-2 py-1 rounded`}
                        >
                          <Typography color="white">
                            {typeof runwayValue === "number"
                              ? format(
                                  addMonths(date(), runwayValue),
                                  "MMM yyyy",
                                )
                              : runwayValue}
                          </Typography>
                        </div>
                      }
                      anchor="top"
                    />
                    {index !== runway.length - 1 && (
                      <Typography size="xs" color="disabled" className="mr-1">
                        |
                      </Typography>
                    )}
                  </Fragment>
                );
              })}
              <Typography size="xs" color="disabled" className="mr-1">
                MO.
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between w-[80%] max-w-[1442px] min-w-[720px] max-sm:w-[95vw] h-[250px] gap-4 max-sm:flex-col max-sm:h-[480px] max-sm:max-w-[95vw] max-sm:min-w-[95vw]">
        <div className="w-1/2 max-sm:w-full max-sm:h-1/2">
          {cashBalanceReport?.data.length ? (
            <LineGraph
              id="cash-balance"
              data={cashBalanceReport.data}
              dataKeys={dataKeys}
              lines={lines}
              card={{
                title: "🏦 Cash Balance",
                figure:
                  userActiveIndex !== -1 || currentMonthIndex !== -1
                    ? cashBalanceReport.data[
                        userActiveIndex !== -1
                          ? userActiveIndex
                          : currentMonthIndex
                      ].report0?.toString() ?? "0"
                    : undefined,
                month:
                  userActiveIndex !== -1 || currentMonthIndex !== -1
                    ? format(
                        new Date(
                          cashBalanceReport.data[
                            userActiveIndex !== -1
                              ? userActiveIndex
                              : currentMonthIndex
                          ].date,
                        ),
                        "MMM",
                      ).toUpperCase()
                    : undefined,
              }}
              isCashGraph
              externalActiveIndex={userActiveIndex}
              setExternalActiveIndex={setUserActiveIndex}
            />
          ) : (
            <EmptyGraph loading={pageLoading} />
          )}
        </div>
        <div className="w-1/2 max-sm:w-full max-sm:h-1/2">
          {cashflowReport?.data.length ? (
            <LineGraph
              id="cashflow"
              data={cashflowReport.data}
              dataKeys={dataKeys}
              lines={lines}
              card={{
                title: "🌊 Cash Flow",
                figure:
                  userActiveIndex !== -1 || currentMonthIndex !== -1
                    ? cashflowReport.data[
                        userActiveIndex !== -1
                          ? userActiveIndex
                          : currentMonthIndex
                      ].report0?.toString() ?? "0"
                    : undefined,
                month:
                  userActiveIndex !== -1 || currentMonthIndex !== -1
                    ? format(
                        new Date(
                          cashflowReport.data[
                            userActiveIndex !== -1
                              ? userActiveIndex
                              : currentMonthIndex
                          ].date,
                        ),
                        "MMM",
                      ).toUpperCase()
                    : undefined,
              }}
              isCashGraph
              externalActiveIndex={userActiveIndex}
              setExternalActiveIndex={setUserActiveIndex}
            />
          ) : (
            <EmptyGraph loading={pageLoading} />
          )}
        </div>
      </div>

      <div className="flex max-sm:flex-col justify-between mt-4 gap-4 w-[80%] min-w-[720px] max-w-[1442px] max-sm:w-[95vw] h-[250px] max-sm:flex-col max-sm:h-[480px] max-sm:max-w-[95vw] max-sm:min-w-[95vw]">
        <div
          className="w-1/2 max-sm:w-full max-sm:h-1/2"
          data-testid="burn-card"
        >
          <div className="w-full h-full">
            {expensesReport?.data.length ? (
              <div ref={expenseGraphRef} className="w-full h-full">
                <LineGraph
                  id="expenses"
                  data={expensesReport.data}
                  dataKeys={dataKeys}
                  lines={lines}
                  card={{
                    title: "💰 Expenses",
                    figure:
                      userActiveIndex !== -1 || currentMonthIndex !== -1
                        ? expensesReport.data[
                            userActiveIndex !== -1
                              ? userActiveIndex
                              : currentMonthIndex
                          ].report0?.toString() ?? "0"
                        : undefined,
                    month:
                      userActiveIndex !== -1 || currentMonthIndex !== -1
                        ? format(
                            new Date(
                              expensesReport.data[
                                userActiveIndex !== -1
                                  ? userActiveIndex
                                  : currentMonthIndex
                              ].date,
                            ),
                            "MMM",
                          ).toUpperCase()
                        : undefined,
                  }}
                  lockedIndexes={expensesLockedIndex}
                  onClick={handleClickExpenses}
                  hoverCursorIcon={
                    handleClickExpenses ? <UnlockedIcon /> : undefined
                  }
                  hoverLockedIndexIcon={<RemoveLockIcon />}
                  externalActiveIndex={userActiveIndex}
                  setExternalActiveIndex={setUserActiveIndex}
                />
              </div>
            ) : (
              <EmptyGraph loading={pageLoading} />
            )}
          </div>
        </div>
        <div
          className="w-1/2 max-sm:w-full max-sm:h-1/2 max-sm:mb-28"
          data-testid="revenue-card"
        >
          {revenueReport?.data.length ? (
            <LineGraph
              id="revenue"
              data={revenueReport.data}
              dataKeys={dataKeys}
              lines={lines}
              card={{
                title: "💸 Revenue",
                figure:
                  userActiveIndex !== -1 || currentMonthIndex !== -1
                    ? revenueReport.data[
                        userActiveIndex !== -1
                          ? userActiveIndex
                          : currentMonthIndex
                      ].report0?.toString() ?? "0"
                    : undefined,
                month:
                  userActiveIndex !== -1 || currentMonthIndex !== -1
                    ? format(
                        new Date(
                          revenueReport.data[
                            userActiveIndex !== -1
                              ? userActiveIndex
                              : currentMonthIndex
                          ].date,
                        ),
                        "MMM",
                      ).toUpperCase()
                    : undefined,
              }}
              externalActiveIndex={userActiveIndex}
              setExternalActiveIndex={setUserActiveIndex}
            />
          ) : (
            <EmptyGraph loading={pageLoading} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ScenariosContainer;

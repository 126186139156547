import React from "react";
import { Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import { State } from "~/store";

interface Props {
  isOpen: boolean;
  children: React.ReactNode;
}
const Tray = ({ children, isOpen }: Props) => {
  const { sideMenuExpanded } = useSelector(
    (state: State) => state.user.preferences,
  );
  const className = `z-10 transition-all duration-500 fixed bottom-0 p-4 
  ${sideMenuExpanded ? "w-[calc(100%-275px)]" : "w-[calc(100%-72px)]"}`;

  return (
    <Transition
      className={className}
      show={isOpen}
      appear
      enter="transition-all ease-in-out duration-500"
      enterFrom="opacity-0 translate-y-6"
      enterTo="opacity-100 translate-y-0"
      leave="transition-all ease-in-out duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={`${isOpen ? " animate-fade-in-up" : " hidden"} flex justify-center`}
      >
        <div className="px-6 py-4 shadow-md shadow-neutral-100 rounded-xl bg-white w-[95%]">
          {children}
        </div>
      </div>
    </Transition>
  );
};

export default Tray;

import { store } from "~/store";
import request from "~/utils/request";
import waitForStoreRehydration from "~/utils/waitForStoreRehydration";

interface IAPIResponse {
  data: {
    data: unknown;
  };
  status: number;
}

export default async (): Promise<unknown> => {
  await waitForStoreRehydration(store);
  const activeScenarioUuid = store.getState().scenario.activeScenarioUuid;
  const currentPage = window.location.pathname;
  const includeFullyBurdened = currentPage !== "/new-dashboard";

  const {
    organization: { uuid: organizationUuid },
  } = store.getState();

  const positionsResponse = (await request({
    url: `/organizations/${organizationUuid}/positions`,
    method: "GET",
    params: {
      scenarioUuid: activeScenarioUuid ?? undefined,
      includeFullyBurdened,
    },
  })) as IAPIResponse;

  return {
    positions: positionsResponse.data.data,
  };
};

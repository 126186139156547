import React, { useEffect, useState } from "react";
import Card from "~/components/Card";
import Typography from "~/components/Typography";
import Button from "~/components/Button";
import UpdateBusinessContextModal from "./UpdateBusinessContextModal";
import request from "~/utils/request";
import { useSelector } from "react-redux";
import type { State } from "~/store";
import { ZBusinessContext } from "./entity/schemas";

interface IAPIResponse {
  data: {
    data: unknown;
  };
  status: number;
}

const Personalization = (): React.ReactNode => {
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );

  const [showBusinessContextModal, setShowBusinessContextModal] =
    useState(false);
  const [summary, setSummary] = useState("");

  useEffect(() => {
    const getBusinessContext = async (): Promise<void> => {
      const response = (await request({
        url: `/business-contexts`,
        method: "GET",
        headers: { "Organization-Uuid": organizationUuid },
      })) as IAPIResponse;

      if (response.status >= 400) return;
      const {
        context: { summary },
      } = ZBusinessContext.parse(response.data.data);
      setSummary(summary);
    };
    getBusinessContext();
  }, []);

  return (
    <div className="w-full flex flex-col mb-5 mt-10">
      <Typography tag="h1" size="xl" weight="bold" className="mb-5">
        Personalization
      </Typography>
      <Card className="w-full flex flex-col gap-1">
        <UpdateBusinessContextModal
          isOpen={showBusinessContextModal}
          onClose={() => {
            setShowBusinessContextModal(false);
          }}
          onSave={(summary: string) => {
            setSummary(summary);
            setShowBusinessContextModal(false);
          }}
        />
        <Typography size="sm" weight="semibold">
          Business Context
        </Typography>
        <Typography color="secondary" size="sm">
          Parallel is specifically tuned to your business. This information is
          continually refined over time as we learn more about your business
          details and preferences.
        </Typography>
        {Boolean(summary.length) && (
          <React.Fragment>
            <Typography color="secondary" size="sm" className="mt-2">
              Summary of what we know:
            </Typography>
            <Typography
              color="secondary"
              size="sm"
              className="italic mt-1 whitespace-pre-line"
            >
              {summary}
            </Typography>
          </React.Fragment>
        )}
        <Button
          fill="outline"
          className="!w-auto text-nowrap mt-3"
          onClick={() => setShowBusinessContextModal(true)}
        >
          Manage
        </Button>
      </Card>
    </div>
  );
};

export default Personalization;

import React, { useContext, useMemo } from "react";
import Timeline from "~/components/Timeline";
import { HeadcountContext } from "../../context/HeadcountContext";
import { IPositionDetailsWithOrderedDates } from "../../entity/types";
import HeadcountTimelineNodeContent from "./HeadcountTimelineNodeContent";
import date from "~/utils/dates/date";
import {
  addMonths,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  startOfMonth,
  subMonths,
} from "date-fns";
import request from "~/utils/request";
import { State } from "~/store";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import updateScenarioTray from "~/components/ScenarioTray/updateScenarioTray";
import { useRevalidator } from "react-router-dom";
import { toZonedTime } from "date-fns-tz";
import useQueryParams from "~/utils/hooks/useQueryParams";
import Button from "~/components/Button";
import { ZPositionDetailsWithOrderedDates } from "../../entity/schemas";
import Typography from "~/components/Typography";

const HeadcountTimeline = ({
  addNewPosition,
  onlyNewHires,
}: {
  addNewPosition?: () => void;
  onlyNewHires?: boolean;
}): React.ReactNode => {
  const {
    renderedPositions,
    setRenderedPositions,
    setPositions,
    addTermDateState,
  } = useContext(HeadcountContext);
  const revalidator = useRevalidator();
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const { activeScenarioUuid, isTrayCollapsed } = useSelector(
    (state: State) => state.scenario,
  );
  const [queryParams] = useQueryParams();
  const selectedDepartment = queryParams.get("departments");
  const parsedRenderedPositions =
    ZPositionDetailsWithOrderedDates.array().parse(renderedPositions);

  const handleDateChange = async ({
    elementId,
    startDate,
    endDate,
    refreshData,
  }: {
    elementId: string;
    startDate?: Date;
    endDate?: Date;
    refreshData?: boolean;
  }): Promise<void> => {
    try {
      const data: {
        oldHireDate?: string;
        hireDate?: string;
        termDate?: string;
      } = {};
      const position = parsedRenderedPositions.find(
        (position) => position.positionUuid === elementId,
      );
      if (!position) {
        return;
      }
      if (startDate) {
        data.oldHireDate = position.hireDate;
        data.hireDate = format(toZonedTime(startDate, "UTC"), "yyyy-MM-dd");
      }
      if (endDate) {
        data.termDate = data.termDate = format(
          toZonedTime(endDate, "UTC"),
          "yyyy-MM-dd",
        );
      }
      const updatePositionDatesResponse = await request({
        method: "PATCH",
        url: `/organizations/${organizationUuid}/positions/${elementId}`,
        params: {
          scenarioUuid: activeScenarioUuid ?? undefined,
        },
        body: data,
      });

      if (updatePositionDatesResponse.status !== 201)
        throw new Error("Failed to update position");

      updateScenarioTray();
      toast.success(`${startDate ? "Hire" : "Term"} date update`);

      setPositions((prev) => {
        return prev.map((position) => {
          const parsedPosition =
            ZPositionDetailsWithOrderedDates.parse(position);
          if (parsedPosition.positionUuid === elementId) {
            return {
              ...parsedPosition,
              hireDate: startDate
                ? format(toZonedTime(startDate, "UTC"), "yyyy-MM-dd")
                : parsedPosition.hireDate,
            };
          }
          return parsedPosition;
        });
      });
      setRenderedPositions((prev) => {
        return prev.map((position) => {
          const parsedPosition =
            ZPositionDetailsWithOrderedDates.parse(position);
          if (parsedPosition.positionUuid === elementId) {
            return {
              ...parsedPosition,
              hireDate: startDate
                ? format(toZonedTime(startDate, "UTC"), "yyyy-MM-dd")
                : parsedPosition.hireDate,
              termDate: endDate
                ? format(toZonedTime(endDate, "UTC"), "yyyy-MM-dd")
                : parsedPosition.termDate,
            };
          }
          return parsedPosition;
        });
      });
      const shouldRefresh = refreshData ?? true; // Default to true, allow override to false
      if (shouldRefresh) {
        revalidator.revalidate();
      }
    } catch (error) {
      toast.error("Failed to update position");
    }
  };

  let startBoundary: Date = useMemo(() => {
    if (onlyNewHires) {
      return startOfMonth(subMonths(date(), 1)); // Use today's date as the start boundary
    }

    return parsedRenderedPositions.length
      ? parsedRenderedPositions.reduce(
          (
            earliestDate: Date,
            { hireDate }: IPositionDetailsWithOrderedDates,
          ): Date => {
            return isBefore(date(hireDate), earliestDate)
              ? subMonths(date(hireDate), 1)
              : earliestDate;
          },
          subMonths(date(), 1),
        )
      : subMonths(date(), 1);
  }, [onlyNewHires, parsedRenderedPositions]);
  startBoundary = startOfMonth(startBoundary);

  const endBoundary: Date = endOfMonth(addMonths(new Date(), 36));

  const timelineHeight = useMemo(() => {
    if (activeScenarioUuid) {
      return isTrayCollapsed
        ? "max-h-[calc(100vh_-_294px)]"
        : "max-h-[calc(100vh_-_635px)]";
    } else {
      return "max-h-[calc(100vh_-_214px)]";
    }
  }, [activeScenarioUuid, isTrayCollapsed]);

  const timelineNodes = parsedRenderedPositions
    .sort((a, b) => {
      return isBefore(date(a.orderedDate), date(b.orderedDate)) ? -1 : 1;
    })
    .filter((position) => {
      if (onlyNewHires) {
        return isAfter(position.hireDate, date());
      }
      return true;
    })
    .filter((position) => {
      const parsedPosition = ZPositionDetailsWithOrderedDates.parse(position);
      if (selectedDepartment && selectedDepartment !== "all") {
        const positionDepartmentUuid = parsedPosition.currentDepartment.uuid;
        if (positionDepartmentUuid) {
          return positionDepartmentUuid === selectedDepartment;
        } else {
          return false;
        }
      } else {
        return true;
      }
    })
    .map((position) => {
      const parsedPosition = ZPositionDetailsWithOrderedDates.parse(position);
      return (
        <Timeline.Node
          key={parsedPosition.positionUuid}
          id={parsedPosition.positionUuid}
          startDate={date(parsedPosition.hireDate)}
          endDate={
            parsedPosition.termDate ? date(parsedPosition.termDate) : undefined
          }
        >
          <HeadcountTimelineNodeContent position={parsedPosition} />
        </Timeline.Node>
      );
    });

  const emptyTimeline = onlyNewHires ? (
    <div className="sticky top-1/2 left-1/2 transform translate-x-1/2 -translate-y-1/2 justify-center items-center flex gap-2 px-4 py-2 border border-neutral-50 rounded-lg bg-neutral-15">
      <Typography color="empty">No Future Hires</Typography>
    </div>
  ) : (
    <div className="sticky top-1/2 left-1/2 transform translate-x-1/2 -translate-y-1/2 justify-center items-center flex gap-2 px-4 py-2 border border-neutral-50 rounded-lg bg-neutral-15">
      <Typography color="empty">No Positions</Typography>
    </div>
  );

  return (
    <Timeline.Container
      startDate={startBoundary}
      endDate={endBoundary}
      onChange={handleDateChange}
      ref={addTermDateState.containerRef}
      className={`${timelineHeight}`}
    >
      {timelineNodes.length > 0 ? timelineNodes : emptyTimeline}
      {addNewPosition && (
        <Button
          id="create-position-button"
          onClick={addNewPosition}
          fill="outlineSolid"
          className="sticky bottom-8 left-5 ml-5 !w-fit z-10"
        >
          Create Position
        </Button>
      )}
    </Timeline.Container>
  );
};

export default HeadcountTimeline;

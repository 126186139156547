import { store } from "~/store";
import request from "~/utils/request";
import waitForStoreRehydration from "~/utils/waitForStoreRehydration";

interface IAPIResponse {
  data: {
    data: unknown[];
  };
  status: number;
}

export default async (): Promise<unknown> => {
  await waitForStoreRehydration(store);

  const {
    organization: { uuid: organizationUuid },
  } = store.getState();

  const promises = [
    await request({
      url: `/integrations/sources`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Organization-Uuid": organizationUuid,
      },
    }),
    await request({
      url: `/integrations`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Organization-Uuid": organizationUuid,
      },
    }),
  ];

  const [integrationSources, connectedIntegrations]: IAPIResponse[] =
    await Promise.all(promises);

  return {
    integrations: {
      sources: integrationSources.data.data,
      connected: connectedIntegrations.data.data,
    },
  };
};

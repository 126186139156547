import React from "react";
import { IInitialValue } from "../entities/types";
import { CellContext } from "@tanstack/react-table";
import Typography from "~/components/Typography";
import formatCurrency from "~/utils/formatCurrency";

const CellTotalCost = ({
  initialValue,
  cellContext,
}: {
  initialValue: IInitialValue;
  cellContext: CellContext<Record<string, IInitialValue>, IInitialValue>;
}): React.ReactNode => {
  const isActive = cellContext.row.original.isActive.value === "true";

  return (
    <div
      className="text-right"
      data-testid={`${cellContext.column.id}-${cellContext.row.index}`}
    >
      <Typography color={!isActive ? "disabled" : undefined}>
        {initialValue.value && isActive
          ? formatCurrency(initialValue.value, false)
          : "-"}
      </Typography>
    </div>
  );
};

export default CellTotalCost;

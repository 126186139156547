import { z } from "zod";
import { IRoundDirectionEnum } from "./types";

export enum ICalculationTypeEnum {
  HeadcountNumber = "headcountNumber",
  NewHireNumber = "newHireNumber",
  SalariesAndWages = "salariesAndWages",
  SoftwareExpenses = "softwareExpenses",
  HeadcountExpenses = "headcountExpenses",
  OtherExpenses = "otherExpenses",
}

export const ZCalculationTypeEnum = z.nativeEnum(ICalculationTypeEnum);

export enum VariableTypeEnum {
  Constant = "constant",
  Formula = "formula",
  Self = "self",
  Calculated = "calculated",
  Invalid = "invalid",
}

export enum TimeModifierFunctionEnum {
  Previous = "previous",
  Average = "average",
}

export enum CalculationTypeEnum {
  HeadcountNumber = "headcountNumber",
  SalariesAndWages = "salariesAndWages",
  SoftwareExpenses = "softwareExpenses",
  HeadcountExpenses = "headcountExpenses",
  OtherExpenses = "otherExpenses",
}

export enum IFormattingEnum {
  Number = "number",
  Currency = "currency",
  Percent = "percent",
}

export const ZCalculationModifier = z
  .object({
    jobTitle: z.string().optional(),
  })
  .optional();

export const ZVariable = z.object({
  type: z.nativeEnum(VariableTypeEnum),
  formulaUuid: z.string().nullable(),
  constantValue: z.union([z.number(), z.string()]).optional().nullable(),
  timeModifier: z
    .object({
      function: z.nativeEnum(TimeModifierFunctionEnum),
      period: z.number(),
    })
    .partial(),
  calculationType: ZCalculationTypeEnum.nullable(),
  calculationModifier: ZCalculationModifier,
});

export const ZConstantVariable = ZVariable.extend({
  type: z.literal(VariableTypeEnum.Constant),
  formulaUuid: z.literal(null),
  constantValue: z.number(),
});

export const ZFormulaVariable = ZVariable.extend({
  type: z.literal(VariableTypeEnum.Formula),
  formulaUuid: z.string(),
  constantValue: z.null(),
});

export const ZCalculationVariable = ZVariable.extend({
  type: z.literal(VariableTypeEnum.Calculated),
  calculationType: ZCalculationTypeEnum,
  formulaUuid: z.null(),
  constantValue: z.null(),
});

export const ZInvalidVariable = ZVariable.extend({
  type: z.literal(VariableTypeEnum.Self),
  formulaUuid: z.null(),
  constantValue: z.string(),
  calculationType: z.null(),
});

export const ZRoundingInstructions = z
  .object({
    direction: z.nativeEnum(IRoundDirectionEnum),
    precision: z.number(),
  })
  .nullable();

export const variablesSchema = z.record(z.string().regex(/^\$\d+$/), ZVariable);

export const ZFormulaRecipe = z.object({
  name: z.string(),
  expression: z.string(),
  variables: variablesSchema,
  roundingInstructions: ZRoundingInstructions.optional(),
});

export const ZFormulaCalculation = z.object({
  date: z.string(),
  value: z.number().nullable(),
});

export const ZFormulaOverride = z.object({
  date: z.string(),
  value: z.number(),
});

export const ZFormulaActual = z.object({
  date: z.string(),
  value: z.number(),
});

export const ZFormula = z.object({
  uuid: z.string(),
  formulaUuid: z.string(),
  dataSourceUuid: z.string().nullable(),
  organizationUuid: z.string(),
  scenarioUuid: z.string().nullable(),
  createdBy: z.string().nullable(),
  deletedBy: z.string().nullable(),
  createdAt: z.string(),
  deletedAt: z.string().nullable(),
  recipe: ZFormulaRecipe,
  overrides: z.array(ZFormulaOverride).nullable().optional(),
  actuals: z.array(ZFormulaActual).nullable().optional(),
  calculations: z.array(ZFormulaCalculation).optional(),
  isProtected: z.boolean(),
  formatting: z.nativeEnum(IFormattingEnum).nullable().optional(),
});

export const ZSorting = z.object({
  uuid: z.string(),
  name: z.string(),
  formulaUuids: z.array(z.string()),
});

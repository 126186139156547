import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { IPositionDetailsWithOrderedDates } from "../../entity/types";
import { HeadcountContext } from "~/pages/Headcount/context/HeadcountContext";
import DeletePositionModal from "../DeletePositionModal";
import { State } from "~/store";
import { useRevalidator } from "react-router-dom";
import ChangePayRateModal from "../CellPayRate/ChangePayRateModal";
import EllipsisDropdown from "~/components/EllipsisDropdown";
import Typography from "~/components/Typography";
import formatCurrency from "~/utils/formatCurrency";
import EditPosition from "./EditPosition/EditPosition";
import { fetchSinglePosition } from "../../utils/fetchSinglePosition";
import EditTermDate from "./EditTermDate/EditTermDate";

const HeadcountTimelineNodeContent = ({
  position,
}: {
  position: IPositionDetailsWithOrderedDates;
}): React.ReactNode => {
  const {
    renderedPositions,
    positions,
    setRenderedPositions,
    setPositions,
    editPositionUuid,
    setEditPositionUuid,
    editPositionFormState,
  } = useContext(HeadcountContext);
  const revalidator = useRevalidator();
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const { deletePosition } = useContext(HeadcountContext);
  const [isChangePayRateModalOpen, setIsChangePayRateModalOpen] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isTermDateModalOpen, setIsTermDateModalOpen] = useState(false);

  const handleClickDelete = (): void => {
    if (activeScenarioUuid) {
      deletePosition({
        positionUuid: position.positionUuid,
      });
    } else {
      setIsDeleteModalOpen(true);
    }
  };

  const handlePayChange = (): void => {
    setIsChangePayRateModalOpen(true);
  };

  const handleAddTerm = (): void => {
    setIsTermDateModalOpen(true);
  };

  const handleOptimisticUpdate = async (): Promise<void> => {
    const updatedPosition = await fetchSinglePosition({
      positionUuid: position.positionUuid,
      organizationUuid,
      scenarioUuid: activeScenarioUuid ?? undefined,
    });

    const updateIndex = renderedPositions.findIndex(
      (renderedPosition) =>
        renderedPosition.positionUuid === position.positionUuid,
    );

    const updatedPositionIndex = positions?.findIndex(
      (oldPosition) => oldPosition.positionUuid === position.positionUuid,
    );

    if (
      updatedPositionIndex !== undefined &&
      updatedPositionIndex !== -1 &&
      positions
    ) {
      const updatedPositions = [...positions];
      updatedPositions[updatedPositionIndex] = {
        ...updatedPosition,
        orderedDate: updatedPosition.hireDate,
      };
      setPositions(updatedPositions);
    }
    if (updateIndex !== -1) {
      const updatedPositions = [...renderedPositions];
      updatedPositions[updateIndex] = {
        ...updatedPosition,
        orderedDate: updatedPosition.hireDate,
      };
      setRenderedPositions(updatedPositions);
    }
  };

  return (
    <div className="w-full flex group text-nowrap pl-3 cursor-pointer">
      <div className="flex-grow flex relative">
        <div className="left-0 flex-nowrap flex sticky gap-2.5">
          {position.title && (
            <Typography weight="bold">{position.title}</Typography>
          )}
          {position.employeeName && (
            <Typography>{position.employeeName}</Typography>
          )}
          {position.currentPayRate.value && (
            <Typography>
              {formatCurrency(position.currentPayRate.value, false)}
            </Typography>
          )}
        </div>
      </div>
      <div className="sticky right-0 flex-none font-medium">
        <EllipsisDropdown
          options={[
            {
              label: "Edit Position",
              onClick: () => setEditPositionUuid(position.positionUuid),
            },
            {
              label: "Pay Change",
              onClick: handlePayChange,
            },
            {
              label: "Term",
              onClick: handleAddTerm,
            },
            {
              label: "Delete",
              onClick: () => handleClickDelete(),
              className: "text-red-500",
            },
          ]}
          id={`${position.positionUuid}-ellipsis-dropdown`}
        />
      </div>
      <DeletePositionModal
        id={`delete-position-modal-${position.positionUuid}`}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={() =>
          deletePosition({
            positionUuid: position.positionUuid,
          })
        }
        deleteIsLoading={false}
      />
      <ChangePayRateModal
        id={`change-pay-rate-modal-${position.positionUuid}`}
        isOpen={isChangePayRateModalOpen}
        onClose={() => setIsChangePayRateModalOpen(false)}
        positionUuid={position.positionUuid}
        positionEffectiveAt={position.currentPayRate.effectiveAt}
        changeHistory={position.payRates.map((payRate) => ({
          value: Number(payRate.value),
          effectiveAt: payRate.effectiveAt,
        }))}
      />
      {!!editPositionUuid && editPositionUuid === position.positionUuid && (
        <EditPosition
          id={`edit-position-modal-${position.positionUuid}`}
          isOpen
          onClose={() => {
            editPositionFormState.resetFormState();
            setEditPositionUuid(null);
          }}
          editPositionFormState={editPositionFormState}
          reload={() => revalidator.revalidate()}
          awaitCalculations
          editPositionUuid={editPositionUuid}
          handleOptimisticUpdate={handleOptimisticUpdate}
        />
      )}
      {isTermDateModalOpen && (
        <EditTermDate
          id={`term-date-modal-${position.positionUuid}`}
          isOpen={isTermDateModalOpen}
          onClose={() => setIsTermDateModalOpen(false)}
          positionUuid={position.positionUuid}
          currentTerminationDate={position.termDate}
          minTerminationDate={position.hireDate}
          handleOptimisticUpdate={handleOptimisticUpdate}
          organizationUuid={organizationUuid}
        />
      )}
    </div>
  );
};

export default HeadcountTimelineNodeContent;

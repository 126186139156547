import React from "react";
import Typography from "~/components/Typography";

export type IChipStatus = "syncing" | "linked" | "relinkNeeded";
export const IChipStatus = {
  SYNCING: "syncing",
  LINKED: "linked",
  RELINK_NEEDED: "relinkNeeded",
} as const;

interface StatusChipProps {
  status: IChipStatus;
}

const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
  const getStatusCase = (): {
    label: string;
    styles: {
      bgColor: string;
      textColor: string;
      circleColor: string;
    };
  } | null => {
    switch (status) {
      case "syncing":
        return {
          label: "Connecting...",
          styles: {
            bgColor: "!bg-orange-25",
            textColor: "!text-orange-400",
            circleColor: "!bg-orange-400",
          },
        };
      case "linked":
        return {
          label: "Connected",
          styles: {
            bgColor: "!bg-green-25",
            textColor: "!text-green-400",
            circleColor: "!bg-green-400",
          },
        };
      case "relinkNeeded":
        return {
          label: "Connection Error",
          styles: {
            bgColor: "!bg-red-25",
            textColor: "!text-red-400",
            circleColor: "!bg-red-400",
          },
        };
      default:
        return null;
    }
  };

  const statusCase = getStatusCase();
  if (!statusCase) return null;
  const { label, styles } = statusCase;

  return (
    <span
      className={`py-1 text-white text-sm rounded-2xl px-3 flex items-center gap-2 ${styles.bgColor}`}
    >
      <div className={`size-2 ${styles.circleColor} rounded-full`} />
      <Typography className={styles.textColor}>{label}</Typography>
    </span>
  );
};

export default StatusChip;

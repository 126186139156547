import { ReportDataTypeEnum, ZConsolidatedReportData } from "../entity/schemas";
import {
  IConsolidatedReportsData,
  IConsolidatedReport,
  IReportCollection,
  ITimeseriesReportData,
  IConsolidatedReportCollection,
  IStaticReport,
} from "../entity/types";

interface IConsolidatedReportsRawData extends ITimeseriesReportData {
  reportIndex: number;
}

export default ({
  reports,
}: {
  reports: IReportCollection[];
}): IConsolidatedReportCollection => {
  const cashBalanceReports: IConsolidatedReportsRawData[] = [];
  const cashflowReports: IConsolidatedReportsRawData[] = [];
  const expensesReports: IConsolidatedReportsRawData[] = [];
  const revenueReports: IConsolidatedReportsRawData[] = [];
  const runway: IStaticReport[] = [];
  const filteredExpensesReports: IConsolidatedReportsRawData[] = [];

  reports.forEach((report, index) => {
    report.cashBalance?.data.forEach((data) => {
      cashBalanceReports.push({ ...data, reportIndex: index });
    });
    report.cashflow?.data.forEach((data) => {
      cashflowReports.push({ ...data, reportIndex: index });
    });
    report.expenses?.data.forEach((data) => {
      expensesReports.push({ ...data, reportIndex: index });
    });
    report.revenue?.data.forEach((data) => {
      revenueReports.push({ ...data, reportIndex: index });
    });
    report.filteredExpenses?.data.forEach((data) => {
      filteredExpensesReports.push({ ...data, reportIndex: index });
    });

    runway.push(
      report.runway ?? {
        title: "runway",
        type: ReportDataTypeEnum.Static,
        data: { value: null },
      },
    );
  });

  const cashBalanceReport: IConsolidatedReport = {
    title: "cashBalance",
    type: ReportDataTypeEnum.Consolidated,
    data: consolidateReportsData(cashBalanceReports),
  };
  const cashflowReport: IConsolidatedReport = {
    title: "cashflow",
    type: ReportDataTypeEnum.Consolidated,
    data: consolidateReportsData(cashflowReports),
  };
  const expensesReport: IConsolidatedReport = {
    title: "expenses",
    type: ReportDataTypeEnum.Consolidated,
    data: consolidateReportsData(expensesReports),
  };
  const revenueReport: IConsolidatedReport = {
    title: "revenue",
    type: ReportDataTypeEnum.Consolidated,
    data: consolidateReportsData(revenueReports),
  };
  const filteredExpensesReport: IConsolidatedReport = {
    title: "filteredExpenses",
    type: ReportDataTypeEnum.Consolidated,
    data: consolidateReportsData(filteredExpensesReports),
  };

  return {
    cashBalance: cashBalanceReport,
    cashflow: cashflowReport,
    expenses: expensesReport,
    revenue: revenueReport,
    runway,
    filteredExpenses: filteredExpensesReport,
  };
};

const consolidateReportsData = (
  data: IConsolidatedReportsRawData[],
): IConsolidatedReportsData[] => {
  const consolidatedData = data.reduce(
    (acc: Record<string, IConsolidatedReportsData>, curr) => {
      const { date, value, reportIndex } = curr;
      if (!acc[date]) {
        acc[date] = { date };
      }
      acc[date][`report${reportIndex}`] = value;
      return acc;
    },
    {},
  );

  const consolidatedDataArray = Object.values(consolidatedData);
  const parsedConsolidatedData = ZConsolidatedReportData.array().parse(
    consolidatedDataArray,
  );
  return parsedConsolidatedData;
};

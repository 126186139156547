import React, { useEffect, useState } from "react";
import Typography from "../Typography";
import ScenarioInfoPopover from "./ScenarioInfoPopover";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { State } from "~/store";
import toast from "react-hot-toast";
import { update, updateActiveScenarioData } from "~/store/scenarioSlice";
import { useNavigate, useRevalidator } from "react-router-dom";
import exitScenarioModeEvent from "../ScenarioTray/exitScenarioModeEvent";
import request from "~/utils/request";
import { useInput } from "../Input/InputWrapper";
import { ZScenario } from "~/pages/OldDashboard/entity/schemas";
import CancelScenarioModal from "./components/CancelScenarioModal";
import NameScenarioModal from "./components/NameScenarioModal";
import { refreshDepartments } from "~/utils/refreshDepartments";

interface IAPIResponse {
  data: {
    data: unknown[];
  };
  status: number;
}

const ScenarioMode = (): React.ReactNode => {
  const revalidator = useRevalidator();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    activeScenarioUuid,
    selectedScenarioUuids,
    activeScenarioHasChanges,
    activeScenarioData,
  } = useSelector((state: State) => state.scenario);
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const [showDiscardModal, setShowDiscardModal] = useState<boolean>(false);
  const [showNameScenarioModal, setShowNameScenarioModal] =
    useState<boolean>(false);
  const [scenarioTitle, setScenarioTitle, resetScenarioTitle] = useInput({
    value: activeScenarioData?.changeDescription ?? "",
    valid: true,
  });

  useEffect(() => {
    refreshDepartments();

    return () => {
      refreshDepartments();
    };
  }, [activeScenarioUuid]);

  useEffect(() => {
    if (activeScenarioData) {
      setScenarioTitle({
        ...scenarioTitle,
        value: activeScenarioData.changeDescription,
        valid: scenarioTitle.validation.test(scenarioTitle.value),
      });
    }
  }, [activeScenarioData]);

  const saveActiveScenario = (): void => {
    toast.success(
      (t) => (
        <div
          data-testid="view-newly-created-scenario"
          className="flex flex-row gap-4 items-center"
        >
          <Typography size="sm" weight="normal" className="ml-3" color="white">
            Scenario saved
          </Typography>
          <button
            onClick={() => {
              if (activeScenarioUuid) {
                dispatch(
                  update({
                    activeScenarioUuid: null,
                    activeScenarioData: null,
                    activeScenarioHasChanges: false,
                    leverChanges: [],
                    cashBalanceLockedIndexes: [],
                    cashBalanceSelectedPoint: null,
                    selectedScenarioUuids: [
                      activeScenarioUuid,
                      ...selectedScenarioUuids,
                    ],
                    isTrayCollapsed: false,
                  }),
                );
              }
              navigate(`/dashboard`);
              toast.dismiss(t.id);
            }}
            className="px-2 border border-white rounded-md"
            id="view-newly-created-scenario-button"
          >
            <Typography size="sm" weight="normal" color="white">
              View
            </Typography>
          </button>
        </div>
      ),
      {
        position: "bottom-center",
        duration: 4000,
      },
    );
    exitScenarioModeEvent();
    revalidator.revalidate();
    dispatch(
      update({
        activeScenarioUuid: null,
        activeScenarioData: null,
        activeScenarioHasChanges: false,
        leverChanges: [],
        cashBalanceLockedIndexes: [],
        cashBalanceSelectedPoint: null,
        selectedScenarioUuids,
        isTrayCollapsed: false,
      }),
    );
  };

  const updateAndSaveScenario = async (): Promise<void> => {
    try {
      if (scenarioTitle.value.trim().length && scenarioTitle.valid) {
        if (activeScenarioData?.changeDescription !== scenarioTitle.value) {
          const response = (await request({
            method: "PATCH",
            url: `/organizations/${organizationUuid}/scenarios/${activeScenarioUuid}`,
            body: {
              changeDescription: scenarioTitle.value,
            },
          })) as IAPIResponse;
          if (response.status === 200) {
            const parsedUpdatedScenario = ZScenario.parse(response.data.data);
            dispatch(updateActiveScenarioData(parsedUpdatedScenario));
          }
        }
        saveActiveScenario();
      } else {
        setScenarioTitle({
          ...scenarioTitle,
          touched: true,
          pristine: false,
          valid: false,
        });
      }
    } catch (error) {
      toast.error("Failed to update scenario");
    }
  };

  const handleCompleteAction = (): void => {
    if (
      activeScenarioData &&
      (activeScenarioData.changeDescription.trim().toLowerCase() ===
        "untitled scenario" ||
        !activeScenarioData.changeDescription.trim())
    ) {
      setShowNameScenarioModal(true);
    } else if (activeScenarioData?.changeDescription.trim()) {
      saveActiveScenario();
    }
  };

  const deleteScenario = async (): Promise<void> => {
    const response = await request({
      method: "DELETE",
      url: `/organizations/${organizationUuid}/scenarios/${activeScenarioUuid}`,
    });
    if (response.status === 204) {
      if (
        location.pathname + location.search ===
        `/scenarios?selectedScenario=${activeScenarioUuid}`
      ) {
        navigate(`/scenarios`);
      }
      dispatch(
        update({
          activeScenarioUuid: null,
          activeScenarioData: null,
          activeScenarioHasChanges: false,
          leverChanges: [],
          cashBalanceLockedIndexes: [],
          cashBalanceSelectedPoint: null,
          selectedScenarioUuids,
          isTrayCollapsed: false,
        }),
      );
      exitScenarioModeEvent();
      setShowDiscardModal(false);
      revalidator.revalidate();
    } else {
      toast.error("Failed to delete scenario");
    }
  };

  const handleDiscardScenario = (): void => {
    if (activeScenarioHasChanges) {
      setShowDiscardModal(true);
    } else {
      deleteScenario();
    }
  };

  return (
    <div className="absolute top-0 left-0 w-full h-full pointer-events-none z-50 overflow-hidden">
      <div
        data-testid="scenario-mode-page-outline"
        className="absolute top-0 left-0 w-[calc(100%+10px)] h-[calc(100%+10px)] -m-[5px] border-[12px] border-blue-400 box-border pointer-events-none rounded-2xl"
      ></div>
      <div className="absolute top-0 left-0 w-full h-full flex items-start justify-center">
        <div className="relative bg-blue-400 size-4">
          <div className="absolute bottom-0 bg-white w-4 h-[9px] rounded-tr-md"></div>
        </div>
        <div className="py-1 px-2 pointer-events-auto bg-blue-400 rounded-b-lg flex justify-center items-center gap-2">
          <Typography className="text-white">Scenario Mode</Typography>
          <ScenarioInfoPopover id="scenario-mode-info" />
          <Button
            onClick={handleDiscardScenario}
            fill="blueDestructive"
            className="!w-fit !px-2 !py-1 cursor-pointer "
            id="discard-scenario-button"
          >
            Discard
          </Button>
          <Button
            onClick={handleCompleteAction}
            fill="darkBlueDefault"
            className="!w-fit !px-2 !py-1 cursor-pointer "
            id="complete-scenario-button"
          >
            Done
          </Button>
        </div>
        <div className="relative bg-blue-400 size-4">
          <div className="absolute bottom-0 bg-white w-4 h-[9px] rounded-tl-md"></div>
        </div>
      </div>
      <NameScenarioModal
        isOpen={showNameScenarioModal}
        cancel={() => {
          resetScenarioTitle();
          setShowNameScenarioModal(false);
        }}
        confirm={updateAndSaveScenario}
        scenarioTitle={scenarioTitle}
        setScenarioTitle={setScenarioTitle}
      />
      <CancelScenarioModal
        isOpen={showDiscardModal}
        cancel={() => setShowDiscardModal(false)}
        confirm={deleteScenario}
      />
    </div>
  );
};

export default ScenarioMode;

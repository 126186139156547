import React, { ReactElement, useContext, useEffect, useState } from "react";
import Modal from "~/components/Modal";
import Button from "~/components/Button";
import Typography from "~/components/Typography";
import { useDatePicker } from "~/components/DatePicker";
import { useInput } from "~/components/Input/InputWrapper";
import NewPayForm from "./NewPayForm";
import { useSelector } from "react-redux";
import { State } from "~/store";
import request from "~/utils/request";
import {
  ZPositionDetails,
  ZTemporalValue,
} from "~/pages/Headcount/entity/schemas";
import { z } from "zod";
import { HeadcountContext } from "~/pages/Headcount/context/HeadcountContext";
import { toZonedTime } from "date-fns-tz";
import PayRate from "./PayRate";
import { useBlocker } from "react-router-dom";
import ConfirmPrompt from "~/components/ConfirmPrompt";
import { CENTS_PER_DOLLAR } from "~/utils/constants/currency";
import updateScenarioTray from "~/components/ScenarioTray/updateScenarioTray";
import { StatusCodes } from "http-status-codes";
import toast from "react-hot-toast";
import { fetchSinglePosition } from "../../utils/fetchSinglePosition";
import { isSameDay } from "date-fns";

const ZUpdatePositionResponse = z.object({
  data: z.object({
    data: ZPositionDetails,
  }),
  status: z.number(),
});

const ZPayRateTemporalValue = ZTemporalValue.extend({
  effectiveAt: z.union([z.string(), z.literal("onHire")]),
  value: z.number({ coerce: true }),
});

type TPayRateTemporalValue = z.infer<typeof ZPayRateTemporalValue>;

interface Props {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  positionUuid: string;
  positionEffectiveAt: string;
  changeHistory: TPayRateTemporalValue[];
}

const ChangePayRateModal = ({
  id,
  isOpen,
  onClose,
  positionUuid,
  positionEffectiveAt,
  changeHistory,
}: Props): ReactElement => {
  const { renderedPositions, setRenderedPositions, positions, setPositions } =
    useContext(HeadcountContext);

  const [showBlocker, setShowBlocker] = useState(false);
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const [addNewPayRate, setAddNewPayRate] = useState(false);

  const [currentHireDate, setCurrentHireDate] = useState<string>("");

  const [effectiveDate, setEffectiveDate] = useDatePicker({
    errorMessage: "Effective date is required",
    minDate:
      positionEffectiveAt === "onHire" ? currentHireDate : positionEffectiveAt,
  });

  const [payRate, setPayRate] = useInput({
    // eslint-disable-next-line security/detect-unsafe-regex
    validation: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    errorMessage: "Annual Pay is required",
  });
  const [updatedPayRates, setUpdatedPayRates] =
    useState<{ value: number; effectiveAt: "onHire" | string }[]>(
      changeHistory,
    );
  const [payRatesRequireUpdate, setPayRatesRequireUpdate] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const resetPayRateForm = (): void => {
    setEffectiveDate({
      ...effectiveDate,
      value: {
        startDate: null,
        endDate: null,
      },
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
      minDate: toZonedTime(positionEffectiveAt, "UTC").toString(),
    });
    setPayRate({
      ...payRate,
      value: "",
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
  };

  const onCloseModal = (): void => {
    onClose();
    setTimeout(() => {
      resetPayRateForm();
      setAddNewPayRate(false);
      setUpdatedPayRates(changeHistory);
      setPayRatesRequireUpdate(false);
    }, 300);
  };

  useEffect(() => {
    if (renderedPositions.length) {
      const activePosition = renderedPositions.find(
        (position) => position.positionUuid === positionUuid,
      );
      if (activePosition) {
        setCurrentHireDate(activePosition.hireDate);
        setEffectiveDate({
          ...effectiveDate,
          minDate: activePosition.hireDate,
        });
      }
    }
  }, [renderedPositions.length]);

  const handleCreatePayChange = async (): Promise<void> => {
    try {
      setIsLoading(true);
      let payRatesForUpdate = [...updatedPayRates];
      if (addNewPayRate) {
        // Remove an existing pay rate if it is for the same effective date
        const isOverrideToInitialPayRate = isSameDay(
          currentHireDate,
          effectiveDate.value.startDate as string,
        );
        payRatesForUpdate = payRatesForUpdate.filter((payRateForUpdate) => {
          return (
            !isOverrideToInitialPayRate &&
            !isSameDay(
              payRateForUpdate.effectiveAt,
              new Date(effectiveDate.value.startDate as string),
            )
          );
        });
        // Add the new pay rate
        payRatesForUpdate.push({
          value: Number(payRate.value) * CENTS_PER_DOLLAR,
          effectiveAt: isOverrideToInitialPayRate
            ? "onHire"
            : (effectiveDate.value.startDate as string),
        });
      }
      if (payRatesRequireUpdate || addNewPayRate) {
        const newPayRateResponse = await request({
          method: "PATCH",
          url: `/organizations/${organizationUuid}/positions/${positionUuid}`,
          params: {
            scenarioUuid: activeScenarioUuid ?? undefined,
          },
          body: {
            payRate: payRatesForUpdate,
          },
        });

        const parsedResponse =
          ZUpdatePositionResponse.parse(newPayRateResponse);

        if (
          parsedResponse.status === StatusCodes.CREATED &&
          renderedPositions.length
        ) {
          const updatedPosition = await fetchSinglePosition({
            positionUuid: positionUuid,
            organizationUuid,
            scenarioUuid: activeScenarioUuid ?? undefined,
          });
          const updateIndex = renderedPositions.findIndex(
            (position) => position.positionUuid === positionUuid,
          );

          const updatedPositionIndex = positions?.findIndex(
            (position) => position.positionUuid === positionUuid,
          );

          if (
            updatedPositionIndex !== undefined &&
            updatedPositionIndex !== -1 &&
            positions
          ) {
            const updatedPositions = [...positions];
            updatedPositions[updatedPositionIndex] = {
              ...updatedPosition,
              orderedDate: updatedPosition.hireDate,
            };
            setPositions(updatedPositions);
          }
          if (updateIndex !== -1) {
            const updatedPositions = [...renderedPositions];
            updatedPositions[updateIndex] = {
              ...updatedPosition,
              orderedDate: updatedPosition.hireDate,
            };
            setRenderedPositions(updatedPositions);
          }
          updateScenarioTray();
          onCloseModal();
        } else {
          toast.error("Failed to make changes");
        }
      }
    } catch (error) {
      toast.error("Failed to make changes");
    } finally {
      setIsLoading(false);
    }
  };

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      payRatesRequireUpdate &&
      currentLocation.pathname !== nextLocation.pathname,
  );

  return (
    <Modal id={id} title="Pay Changes" isOpen={isOpen} size="xxs">
      <ConfirmPrompt
        isOpen={blocker.state === "blocked" || showBlocker}
        onClose={() =>
          blocker.state === "blocked" ? blocker.reset() : setShowBlocker(false)
        }
        onConfirm={() => {
          if (blocker.state === "blocked") {
            blocker.proceed();
          } else {
            setShowBlocker(false);
            onCloseModal();
          }
        }}
        title="Changes Will Not be Saved"
        message="Any changes made will not be saved. Are you sure you want to Cancel"
        cancelButtonText="Continue Editing"
        confirmButtonText="Yes, Cancel"
      />
      <div className="flex flex-col w-full" data-testid="pay-change-modal">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row mt-2 gap-2">
            <Typography color="secondary" className="w-[46%]">
              Annual Pay
            </Typography>
            <Typography color="secondary" className="w-[46%]">
              Effective On
            </Typography>
          </div>
          <div data-testid="pay-history-list" className="flex flex-col gap-2">
            {currentHireDate &&
              updatedPayRates
                .sort((versionA, versionB) => {
                  if (versionA.effectiveAt === "onHire") return -1;
                  if (versionB.effectiveAt === "onHire") return 1;
                  return (
                    new Date(versionA.effectiveAt).getTime() -
                    new Date(versionB.effectiveAt).getTime()
                  );
                })
                .map((version) => (
                  <PayRate
                    key={`${positionUuid}-${version.effectiveAt}`}
                    effectiveAt={
                      version.effectiveAt === "onHire"
                        ? "onHire"
                        : version.effectiveAt
                    }
                    hireDate={currentHireDate}
                    currentValue={version.value}
                    updatedPayRates={updatedPayRates}
                    setUpdatedPayRates={setUpdatedPayRates}
                    payRatesRequireUpdate={payRatesRequireUpdate}
                    setPayRatesRequireUpdate={setPayRatesRequireUpdate}
                  />
                ))}
          </div>
          <NewPayForm
            addNewPayRate={addNewPayRate}
            setAddNewPayRate={setAddNewPayRate}
            effectiveDate={effectiveDate}
            setEffectiveDate={setEffectiveDate}
            payRate={payRate}
            setPayRate={setPayRate}
            onCancel={() => {
              resetPayRateForm();
              setAddNewPayRate(false);
            }}
          />
        </div>
        <div className="flex flex-row w-full justify-between mt-2">
          <Button
            id="cancel-pay-change"
            onClick={() =>
              payRatesRequireUpdate ? setShowBlocker(true) : onCloseModal()
            }
            fill="clear"
            className="!w-fit !px-0"
          >
            Cancel
          </Button>
          <Button
            id="save-pay-change"
            className="!w-auto"
            onClick={() => {
              if (addNewPayRate) {
                if (
                  effectiveDate.valid &&
                  payRate.valid &&
                  effectiveDate.value.startDate
                ) {
                  handleCreatePayChange();
                } else {
                  setEffectiveDate({
                    ...effectiveDate,
                    pristine: false,
                    touched: true,
                  });
                  setPayRate({ ...payRate, pristine: false, touched: true });
                }
              } else {
                handleCreatePayChange();
              }
            }}
            disabled={!payRatesRequireUpdate && !addNewPayRate}
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePayRateModal;

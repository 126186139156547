import React, { useState } from "react";
import Modal from "~/components/Modal";
import { useSelector } from "react-redux";
import { State } from "~/store";
import { SelectState } from "~/components/Select/Select.types";
import EditPositionForm from "./EditPositionForm";
import { editPosition } from "~/pages/Headcount/utils/editPosition";

interface Props {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  modalSource?: string;
  editPositionFormState: {
    errorMessage: string;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    organizationUuid: string;
    employeeName: Types.InputState;
    setEmployeeName: React.Dispatch<React.SetStateAction<Types.InputState>>;
    title: Types.InputState;
    setTitle: React.Dispatch<React.SetStateAction<Types.InputState>>;
    selectDepartment: SelectState;
    setSelectDepartment: React.Dispatch<React.SetStateAction<SelectState>>;
    resetFormState: () => void;
  };
  reload: () => void;
  awaitCalculations?: boolean;
  setHasReloaded?: React.Dispatch<React.SetStateAction<boolean>>;
  editPositionUuid: string;
  handleOptimisticUpdate: () => Promise<void>;
}

const EditPosition = ({
  id,
  isOpen,
  onClose,
  editPositionFormState: {
    errorMessage,
    employeeName,
    setEmployeeName,
    title,
    setTitle,
    selectDepartment,
    setSelectDepartment,
    organizationUuid,
  },
  reload,
  awaitCalculations,
  setHasReloaded,
  editPositionUuid,
  handleOptimisticUpdate,
}: Props): React.ReactNode => {
  const activeScenarioUuid = useSelector(
    (state: State) => state.scenario.activeScenarioUuid,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Modal id={id} isOpen={isOpen} title="Edit Position" size="sm">
      <div data-testid="edit-position-modal" className="mt-4 w-full">
        <EditPositionForm
          onClose={onClose}
          employeeName={employeeName}
          setEmployeeName={setEmployeeName}
          title={title}
          setTitle={setTitle}
          selectDepartment={selectDepartment}
          setSelectDepartment={setSelectDepartment}
          errorMessage={errorMessage}
          isSubmitting={isSubmitting}
          createPosition={() => {
            editPosition({
              organizationUuid,
              title,
              setTitle,
              employeeName,
              setEmployeeName,
              selectDepartment,
              setSelectDepartment,
              successCallback: () => {
                handleOptimisticUpdate();
                onClose();
                setIsSubmitting(false);
                reload();
                if (setHasReloaded) {
                  setHasReloaded(true);
                }
              },
              scenarioUuid: activeScenarioUuid ?? undefined,
              setIsSubmitting,
              awaitCalculations,
              editPositionUuid,
            });
          }}
        />
      </div>
    </Modal>
  );
};

export default EditPosition;

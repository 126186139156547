import React, { useState, useEffect, useRef } from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  AreaChart,
  Area,
} from "recharts";
import { CategoricalChartState } from "recharts/types/chart/types";
import Typography from "../Typography";
import Card from "../Card";
import LineGraphTooltip from "./LineGraphTooltip";
import LineGraphDot from "./LineGraphDot";
import { format as formatDate, isSameMonth } from "date-fns";
import formatToTextAbbreviatedCurrency from "~/utils/formatToTextAbbreviatedCurrency";
import generateTickValues from "./utils/generateTickValues";
import calculateYAxisWidth from "./utils/calculateYAxisWidth";
import { IDataArrayDictionary, ILockedIndex, ILineProps } from "./entity/types";
import useIsMobile from "~/utils/hooks/useIsMobile";
import { useSelector } from "react-redux";
import { State } from "~/store";
import date from "~/utils/dates/date";
import "./styles.css";

interface IProps {
  id?: string;
  xFormatter?: (value: number | null) => string;
  yFormatter?: (value: number | null) => string;
  setExternalActiveIndex?: (value: number) => void; // used to be able to sync a single active index across multiple graphs
  data?: IDataArrayDictionary[];
  dataKeys?: string[]; // Array of all keys that may appear in a data object, should be ordered XAxis, first line, second line, etc.
  lines?: ILineProps[];
  externalActiveIndex?: number; // leave undefined for standard tooltip behavior, setting it allows the tooltip to be displayed and synced across multiple graphs
  card?: {
    title: string | React.ReactElement;
    month?: string;
    figure?: string;
  };
  height?: string;
  lockedIndexes?: ILockedIndex[];
  onClick?: (index: number) => void;
  hoverCursorIcon?: React.ReactElement;
  hoverLockedIndexIcon?: React.ReactElement;
  isCashGraph?: boolean;
  roundTicksToMoney?: boolean;
  customTooltip?: React.ReactElement;
  showGradient?: boolean;
}

/**
 * Height and width of component are determined by the parent div
 */
const LineGraph = ({
  id,
  xFormatter = (value: number | null): string => {
    if (!value) return "";
    return formatDate(value, "MMM ''yy");
  },
  yFormatter = (value: number | null): string => {
    return formatToTextAbbreviatedCurrency({ value: value ?? 0, decimal: 1 });
  },
  setExternalActiveIndex,
  data = [],
  dataKeys = ["date", "report0", "report1"],
  lines = [
    { dataKey: "report0", stroke: "black", isDashed: false },
    { dataKey: "report1", stroke: "#406F83", isDashed: true },
  ],
  card,
  lockedIndexes = [],
  onClick,
  hoverCursorIcon,
  hoverLockedIndexIcon,
  isCashGraph,
  roundTicksToMoney = true,
  customTooltip,
  showGradient = true,
  externalActiveIndex,
}: IProps): React.ReactElement => {
  const [internalActiveIndex, setInternalActiveIndex] =
    React.useState<number>(-1);
  const [ticks, setTicks] = React.useState<number[]>([]);
  const [yAxisWidth, setYAxisWidth] = React.useState<number>(60);
  const [opacity, setOpacity] = useState(0);
  const [isMouseOnRight, setIsMouseOnRight] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const sideMenuExpanded = useSelector(
    (state: State) => state.user.preferences.sideMenuExpanded,
  );
  const graphRef = useRef<HTMLDivElement>(null);
  const [numOfIndexesTakenByLabels, setNumOfIndexesTakenByLabels] =
    useState<number>(0);
  const [currentMonthIndex, setCurrentMonthIndex] = useState<number>(-1);

  const xKey = dataKeys[0];

  const xKeyDataWithoutNulls: number[] = data
    .filter((item) => item[xKey] !== null)
    .map((item) => item[xKey] as number);
  const minX = Math.min(
    ...(xKeyDataWithoutNulls.length ? xKeyDataWithoutNulls : [0]),
  );
  const maxX = Math.max(
    ...(xKeyDataWithoutNulls.length ? xKeyDataWithoutNulls : [0]),
  );
  const yMax = data.reduce((largestValue, item) => {
    const filteredKeys = Object.keys(item).filter((key) => key !== dataKeys[0]);
    const filteredValues = filteredKeys
      .map((key) => item[key])
      .filter((value): value is number => value !== null);
    const maxValue = Math.max(...filteredValues);
    return Math.max(largestValue, maxValue);
  }, 0);
  const yMin = data.reduce((smallestValue, item) => {
    const filteredKeys = Object.keys(item).filter((key) => key !== dataKeys[0]);
    const filteredValues = filteredKeys
      .map((key) => item[key])
      .filter((value): value is number => value !== null);
    const minValue = Math.min(...filteredValues);
    return Math.min(smallestValue, minValue);
  }, 0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (opacity < 1) {
        setOpacity(opacity + 0.1);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [opacity]);

  useEffect(() => {
    setTicks(
      generateTickValues({
        yMin,
        yMax,
        tickCount: 3,
        isCashGraph,
        roundToMoney: roundTicksToMoney,
      }),
    );
  }, [data, yMin, yMax, isCashGraph]);

  useEffect(() => {
    setYAxisWidth(calculateYAxisWidth({ ticks, yFormatter }));
  }, [data, ticks, yFormatter]);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent): void => {
      const WIDTH_OF_EXPANDED_SIDE_MENU = 250;
      const WIDTH_OF_COLLAPSED_SIDE_MENU = 72;
      const amountToAdd = sideMenuExpanded
        ? WIDTH_OF_EXPANDED_SIDE_MENU
        : WIDTH_OF_COLLAPSED_SIDE_MENU;
      const isOnRight = event.clientX > (window.innerWidth + amountToAdd) / 2;
      setIsMouseOnRight(isOnRight);
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [sideMenuExpanded]);

  const handleMouseChange = (value: CategoricalChartState): void => {
    if (value.activeLabel) {
      const activeLabelInt = parseInt(value.activeLabel);
      const activeLabelIndex = data
        .map((item) => item[xKey])
        .indexOf(activeLabelInt);
      if (setExternalActiveIndex) setExternalActiveIndex(activeLabelIndex);
      setInternalActiveIndex(activeLabelIndex);
    } else {
      if (setExternalActiveIndex) setExternalActiveIndex(-1);
      setInternalActiveIndex(-1);
    }
  };

  const handleMouseLeave = (): void => {
    if (setExternalActiveIndex) setExternalActiveIndex(-1);
    setInternalActiveIndex(-1);
  };

  const handleClick = (): void => {
    onClick && onClick(internalActiveIndex);
  };

  const getReferenceLineColor = (
    index: number,
    dateAsNumber: number,
    currentActiveIndex: number,
  ): string => {
    if (index === currentActiveIndex) return "#FFFFFF";
    return new Date(dateAsNumber).getMonth() === 11 ? "#E6E6E6" : "#F4F4F4";
  };

  useEffect(() => {
    const updateWidth = (): void => {
      if (graphRef.current) {
        const WIDTH_OF_LABELS = 100;
        const graphWidth = graphRef.current.offsetWidth;
        const singleIndexWidth = graphWidth / data.length;
        const numOfIndexesTakenByLabels = Math.floor(
          WIDTH_OF_LABELS / singleIndexWidth,
        );
        setNumOfIndexesTakenByLabels(numOfIndexesTakenByLabels);
      }
    };

    const resizeObserver = new ResizeObserver(updateWidth);
    if (graphRef.current) {
      resizeObserver.observe(graphRef.current);
    }

    updateWidth();

    return () => {
      if (graphRef.current) {
        resizeObserver.unobserve(graphRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const today = date();
    const currentMonthIndex = data.findIndex((item) => {
      const itemDate = new Date(item[xKey] ?? 0);
      return isSameMonth(today, itemDate);
    });

    setCurrentMonthIndex(currentMonthIndex);
  }, [data, xKey]);

  const graph = (
    <div
      className={`w-full flex flex-col flex-grow relative`}
      data-testid={`${id}-chart`}
      ref={graphRef}
    >
      <ResponsiveContainer minHeight={1} minWidth={1}>
        <AreaChart
          margin={{ top: 8, right: 8 }}
          onMouseMove={handleMouseChange}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
          // @ts-expect-error - recharts types are incorrect
          cursor={onClick ? "pointer" : "default"}
        >
          <defs>
            <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="-13.11%" stopColor="rgba(100, 117, 92, 0.18)" />
              <stop offset="100%" stopColor="rgba(100, 117, 92, 0.00)" />
            </linearGradient>
          </defs>
          {isCashGraph && <ReferenceLine y={0} stroke="#F4F4F4" />}
          {data.map((item, index) => (
            <ReferenceLine
              key={item[xKey] ?? Math.random()}
              x={item[xKey] ?? 0}
              stroke={getReferenceLineColor(
                index,
                item[xKey] ?? 0,
                internalActiveIndex,
              )}
              label={
                index > numOfIndexesTakenByLabels &&
                index < data.length - numOfIndexesTakenByLabels &&
                index !== data.length - 1 &&
                new Date(item.date ?? 0).getMonth() === 11
                  ? {
                      value: xFormatter(item[xKey] ?? 0),
                      position: "bottom",
                      fill: "#999999",
                      fontFamily: "inter",
                      fontSize: "16px",
                      dy: 13,
                    }
                  : false
              }
            />
          ))}
          <YAxis
            axisLine={false}
            domain={[ticks[0], ticks[ticks.length - 1]]}
            ticks={ticks}
            tickFormatter={(value) => yFormatter(value)}
            tickLine={false}
            tickMargin={0}
            tick={{
              fontWeight: 400,
              fill: "#999999",
              fontFamily: "inter",
              fontSize: "16px",
            }}
            opacity={opacity}
            width={yAxisWidth ? yAxisWidth : 150}
          />
          <XAxis
            axisLine={false}
            domain={[minX, maxX]}
            dataKey={xKey}
            type={"number"}
            tick={false}
          />
          <Tooltip
            content={
              customTooltip ?? (
                <LineGraphTooltip
                  xFormatter={xFormatter}
                  yFormatter={yFormatter}
                  lines={lines}
                />
              )
            }
            allowEscapeViewBox={{ x: !isMobile, y: !isMobile }}
            reverseDirection={{ x: isMouseOnRight, y: false }}
            wrapperStyle={{ zIndex: 2 }}
            cursor={false}
          />
          {lines.map((line, index) => (
            <Area
              key={line.dataKey}
              type="linear"
              data={data}
              dataKey={line.dataKey}
              baseValue={ticks[0]}
              stroke={line.stroke}
              isAnimationActive={false}
              opacity={opacity}
              strokeWidth={1.5}
              dot={
                <LineGraphDot
                  activeIndex={internalActiveIndex}
                  lockedIndexes={lockedIndexes}
                  hoverIcon={hoverCursorIcon}
                  stroke={line.stroke}
                  isPrimaryLine={!index}
                  hoverLockedIndexIcon={hoverLockedIndexIcon}
                  multipleLines={lines.length > 1}
                  currentMonthIndex={currentMonthIndex}
                  externalActiveIndex={externalActiveIndex}
                />
              }
              strokeDasharray={line.isDashed ? "8 4" : ""}
              activeDot={false}
              fillOpacity={lines.length <= 1 ? 1 : 0}
              fill={
                lines.length <= 1 && showGradient
                  ? "url(#gradient)"
                  : "rgba(255, 255, 255, 0)"
              }
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
      <div className="flex justify-between items-center pl-14 w-full mt-[-19px]">
        <Typography
          size="sm"
          color="empty"
          id={`${card?.title ?? "empty-title"}-${xFormatter(minX)}-min`}
        >
          {xFormatter(minX)}
        </Typography>
        <Typography
          size="sm"
          color="empty"
          id={`${card?.title ?? "empty-title"}-${xFormatter(maxX)}-max`}
        >
          {xFormatter(maxX)}
        </Typography>
      </div>
    </div>
  );

  if (card) {
    return (
      <Card className="!px-5 !md:px-5 !py-4 !md:py-4 h-full">
        <div className="flex items-center justify-between mb-3 w-full">
          {card.title}
          {card.month && card.figure && (
            <div className="flex items-center">
              <Typography
                size="xs"
                color="disabled"
                weight="thin"
                className="mr-2"
              >
                {card.month}
              </Typography>
              <Typography size="sm" weight="bold" color="green">
                {yFormatter(Number(card.figure))}
              </Typography>
            </div>
          )}
        </div>
        {graph}
      </Card>
    );
  }

  return graph;
};

export default LineGraph;

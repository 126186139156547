import React from "react";
import Modal from "~/components/Modal";
import Button from "~/components/Button";
import Input from "~/components/Input/InputWrapper";

interface Props {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  newDepartmentName: Types.InputState;
  setNewDepartmentName: React.Dispatch<React.SetStateAction<Types.InputState>>;
  attemptRenameDepartment: () => void;
}

const RenameDepartmentModal = ({
  id,
  isOpen,
  onClose,
  newDepartmentName,
  setNewDepartmentName,
  attemptRenameDepartment,
}: Props): React.ReactNode => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Rename Department"
      size="sm"
      id={id}
    >
      <div className="flex flex-col gap-4 w-full">
        <Input
          label="Department Name"
          className="w-full"
          state={newDepartmentName}
          setState={setNewDepartmentName}
          id="department-name"
          placeholder="Enter department name"
        />
        <div className="flex flex-row justify-between">
          <Button className="!w-auto !p-0" fill="clear" onClick={onClose}>
            Cancel
          </Button>
          <Button className="!w-auto" onClick={attemptRenameDepartment}>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RenameDepartmentModal;

import React from "react";

const LockedIcon = (): React.ReactNode => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="white" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#BCBCBC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9999 8.80005C14.0117 8.80005 12.3999 10.4118 12.3999 12.4V15.2H11.9999C11.1162 15.2 10.3999 15.9164 10.3999 16.8V21.6C10.3999 22.4837 11.1162 23.2 11.9999 23.2H19.9999C20.8836 23.2 21.5999 22.4837 21.5999 21.6V16.8C21.5999 15.9164 20.8836 15.2 19.9999 15.2H19.5999V12.4C19.5999 10.4118 17.9881 8.80005 15.9999 8.80005ZM18.3999 15.2V12.4C18.3999 11.0746 17.3254 10 15.9999 10C14.6744 10 13.5999 11.0746 13.5999 12.4V15.2H18.3999Z"
        fill="#BCBCBC"
      />
    </svg>
  );
};

export default LockedIcon;

import z from "zod";
import { ZExpense, ZExpenseContext } from "./entity/schemas";
import { ReactElement } from "react";

export type IExpense = z.infer<typeof ZExpense>;
export type IExpenseContext = z.infer<typeof ZExpenseContext>;

export const DEFAULT_TAGS = ["Headcount Related", "Software", "Other"] as const;

export enum ExpenseFrequencyEnum {
  Monthly = "monthly",
  OneTime = "oneTime",
  OnHire = "onHire",
  Quarterly = "quarterly",
  Annually = "annually",
}

export enum ExpenseDriverEnum {
  SetCost = "setCost",
  HeadcountFixed = "headcountFixed",
  HeadcountPercentCompensation = "headcountPercentCompensation",
}

export interface IExpenseTableRow {
  name: string;
  tag: string;
  driver: string;
  department: string;
  amount: string;
  frequency: string;
  startDate: string;
  endDate: string;
  ellipsisDropdown: ReactElement;
  [key: string]: string | ReactElement | boolean | undefined;
  isGrayedOut?: boolean;
}

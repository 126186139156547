import React, { useEffect, useMemo, useState } from "react";
import emptyRatiosStateIllustration from "~/assets/emptyRatiosState.svg";
import Typography from "~/components/Typography";
import useRatioFormState from "../components/useRatioFormState";
import useRatioData from "../useRatioData";
import { ZRatiosDataSchema } from "../entity/schemas";
import { IFormula } from "~/pages/FinancialModel/entity/types";
import { IRatio, IRatiosData } from "../entity/types";
import { IPositionDetails } from "~/pages/Headcount/entity/types";
import { RadioInputState } from "~/components/RadioInput/RadioInput.types";

export interface IRatiosContext {
  emptyTableState: React.ReactNode;
  isCreateRatioModalOpen: boolean;
  setIsCreateRatioModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ratiosList: IRatio[];
  setRatiosList: React.Dispatch<React.SetStateAction<IRatio[]>>;
  formulasList: IFormula[];
  setFormulasList: React.Dispatch<React.SetStateAction<IFormula[]>>;
  positionsList: IPositionDetails[];
  setPositionsList: React.Dispatch<React.SetStateAction<IPositionDetails[]>>;
  errorMessage: string;
  positionNeeded: Types.InputState;
  amount: Types.InputState;
  drivenByValue: Types.InputState;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setPositionNeeded: React.Dispatch<React.SetStateAction<Types.InputState>>;
  setAmount: React.Dispatch<React.SetStateAction<Types.InputState>>;
  setDrivenByValue: React.Dispatch<React.SetStateAction<Types.InputState>>;
  resetFormState: () => void;
  driverType: RadioInputState;
  setDriverType: React.Dispatch<React.SetStateAction<RadioInputState>>;
  monthsBetweenDates: Date[];
  pageLoading: boolean;
  editRatioUuid: string | null;
  setEditRatioUuid: React.Dispatch<React.SetStateAction<string | null>>;
  setFormStateFromIRatio: (ratio: IRatio) => Promise<void>;
  reloadRatios: () => void;
}

export const RatiosContext = React.createContext({} as IRatiosContext);

const emptyTableState = (
  <div
    data-testid="empty-ratios-state"
    className="flex flex-col max-w-[414px] text-center items-center gap-2 bg-white"
  >
    <img
      src={emptyRatiosStateIllustration}
      alt="Empty Ratios Illustration"
      className="w-42 h-auto"
    />
    <Typography weight="semibold">Headcount Driven Ratios</Typography>
    <Typography color="secondary">
      This feature ensures your workforce scales efficiently based on other
      factors of your model. Ensuring you make data-driven staffing decisions
      and understand the total impact of your decisions.
    </Typography>
  </div>
);

export const RatiosProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode => {
  const ratiosData = useRatioData();
  const [isCreateRatioModalOpen, setIsCreateRatioModalOpen] = useState(false);
  const [ratiosList, setRatiosList] = useState<IRatio[]>([]);
  const [formulasList, setFormulasList] = useState<IFormula[]>([]);
  const [positionsList, setPositionsList] = useState<IPositionDetails[]>([]);
  const [editRatioUuid, setEditRatioUuid] = useState<string | null>(null);
  const {
    errorMessage,
    setErrorMessage,
    setPositionNeeded,
    setAmount,
    setDrivenByValue,
    resetFormState,
    positionNeeded,
    amount,
    drivenByValue,
    driverType,
    setDriverType,
    setFormStateFromIRatio,
  } = useRatioFormState();

  const { positions, formulas, ratios, monthsBetweenDates } =
    useMemo((): IRatiosData => {
      if (ratiosData.data) {
        const parsedData = ZRatiosDataSchema.parse(ratiosData.data);
        return parsedData;
      }
      return {} as IRatiosData;
    }, [ratiosData.data]);

  useEffect(() => {
    if (positions?.length) {
      setPositionsList(positions);
    } else {
      setPositionsList([]);
    }
  }, [positions]);

  useEffect(() => {
    if (formulas?.length) {
      setFormulasList(formulas);
    } else {
      setFormulasList([]);
    }
  }, [formulas]);

  useEffect(() => {
    if (ratios?.length) {
      setRatiosList(ratios);
    } else {
      setRatiosList([]);
    }
  }, [ratios]);

  return (
    <RatiosContext.Provider
      value={{
        emptyTableState,
        isCreateRatioModalOpen,
        setIsCreateRatioModalOpen,
        ratiosList,
        setRatiosList,
        formulasList,
        setFormulasList,
        positionsList,
        setPositionsList,
        errorMessage,
        setErrorMessage,
        setPositionNeeded,
        setAmount,
        setDrivenByValue,
        resetFormState,
        positionNeeded,
        amount,
        drivenByValue,
        driverType,
        setDriverType,
        monthsBetweenDates,
        pageLoading: ratiosData.loading,
        editRatioUuid,
        setEditRatioUuid,
        setFormStateFromIRatio,
        reloadRatios: ratiosData.revalidate,
      }}
    >
      {children}
    </RatiosContext.Provider>
  );
};

export default RatiosProvider;

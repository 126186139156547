import quickbooks from "./quickbooks.png";
import gsheets from "./gsheets.png";
import excel from "./excel.png";
import gmailLogo from "./gmail.png";
import outlookLogo from "./outlook.png";

export default {
  quickbooks,
  gsheets,
  excel,
  gmailLogo,
  outlookLogo,
};

import { z } from "zod";

export const ZTemporalValue = z.object({
  value: z.union([z.number(), z.string()]),
  effectiveAt: z.union([z.string(), z.literal("onHire")]),
});

export const ZPositionDetails = z.object({
  positionUuid: z.string(),
  hireDate: z.string(),
  termDate: z.string().nullable(),
  departmentUuid: z.string(),
  currentDepartment: z.object({
    uuid: z.string(),
    name: z.string(),
  }),
  title: z.string(),
  employeeName: z.string().nullable(),
  currentPayRate: ZTemporalValue,
  payRates: z.array(ZTemporalValue),
  isActive: z.boolean(),
  fullyBurdenedCost: z.number().nullable().optional(),
});

export const ZPositionDetailsWithOrderedDates = ZPositionDetails.extend({
  orderedDate: z.string(),
});

export const headcountLoaderSchema = z.object({
  positions: z.array(ZPositionDetails).optional(),
});

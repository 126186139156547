import React from "react";
import { store } from "~/store";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { FFContextProvider } from "@harnessio/ff-react-client-sdk";
import { Toaster } from "react-hot-toast";

import router from "./Router";
import "./styles.css";

const App = (): React.ReactElement => {
  return (
    <Provider store={store}>
      <FFContextProvider
        apiKey={import.meta.env.VITE_HARNESS_API_KEY as string}
        target={{ name: "Parallel Web App", identifier: "reactclientsdk" }}
      >
        <Toaster
          toastOptions={{
            className: "toaster",
            style: {
              backgroundColor: "#383935",
              color: "#FFFFFF",
            },
          }}
        />
        <RouterProvider router={router} />
      </FFContextProvider>
    </Provider>
  );
};

export default App;

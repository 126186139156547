import React, { useEffect, useRef, useState } from "react";
import {
  endOfMonth,
  endOfQuarter,
  endOfYear,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from "date-fns";
import Button from "~/components/Button";
import Typography from "~/components/Typography";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { IPeriodPickerState } from "./usePeriodPicker";
import {
  getGridDisplayValues,
  getInputDisplayValue,
  getStartAndEndDatesFromSelection,
} from "./pickerModeHelpers";
import date from "~/utils/dates/date";
import { XMarkIcon } from "@heroicons/react/24/solid";

const PeriodPicker = ({
  label,
  minYear = 2015,
  maxYear = 2030,
  minDate,
  maxDate,
  clearable,
  optional,
  state,
  setState,
  pickerAlignment = "left",
  beBefore,
  beAfter,
  border,
}: {
  label?: string;
  minYear?: number;
  maxYear?: number;
  minDate?: Date;
  maxDate?: Date;
  clearable?: boolean;
  optional?: boolean;
  state: IPeriodPickerState;
  setState: React.Dispatch<React.SetStateAction<IPeriodPickerState>>;
  pickerAlignment?: "left" | "right";
  beBefore?: Date | null;
  beAfter?: Date | null;
  border?: "solid" | "hover";
}): React.ReactNode => {
  const pickerRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const [pendingYearDate, setPendingYearDate] = useState<number>(
    state.startDate ? getYear(state.startDate) : getYear(new Date()),
  );
  const [displayState, setDisplayState] = useState<{
    gridDisplayValues: (string | number)[];
    gridDisplay: string;
  }>({
    gridDisplayValues: [],
    gridDisplay: "",
  });

  const displayPickerReset = clearable && state.startDate && state.endDate;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    if (showPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPicker]);

  useEffect(() => {
    setDisplayState({
      gridDisplayValues: getGridDisplayValues(
        state.mode,
        pendingYearDate,
        minYear,
        maxYear,
      ),
      gridDisplay: getInputDisplayValue(state.mode, state.startDate),
    });
  }, [state.startDate, state.endDate, pendingYearDate]);

  useEffect(() => {
    if (!state.startDate || !state.endDate) return;
    if (state.startDate > state.endDate) {
      setState({
        ...state,
        endDate: state.startDate,
      });
    }
  }, [beBefore, beAfter]);

  useEffect(() => {
    if (!state.startDate) return;
    let { startDate, endDate } = state;
    switch (state.mode) {
      case "month": {
        startDate = startOfMonth(startDate);
        endDate = endOfMonth(startDate);
        break;
      }
      case "quarter": {
        startDate = startOfQuarter(startDate);
        endDate = endOfQuarter(startDate);
        break;
      }
      case "year": {
        startDate = startOfYear(startDate);
        endDate = endOfYear(startDate);
        break;
      }
      default:
        throw Error("Invalid mode");
    }
    if (
      Boolean(beBefore && isAfter(startDate, beBefore)) ||
      (beAfter && isBefore(endDate, beAfter))
    ) {
      setShowPicker(true); // Open the picker if outside bounds
    } else if (
      !isEqual(startDate, state.startDate) ||
      (state.endDate && !isEqual(endDate, state.endDate))
    ) {
      setState({ ...state, startDate, endDate });
    }
  }, [state.mode, beBefore, beAfter]);

  const togglePicker = (): void => {
    setShowPicker(!showPicker);
    setPendingYearDate(getYear(state.startDate ?? date()));
  };

  const handleGridSelection = (selection: string | number): void => {
    setShowPicker(false);
    const { startDate, endDate } = getStartAndEndDatesFromSelection(
      state.mode,
      selection,
      pendingYearDate,
    );
    setState({
      ...state,
      startDate,
      endDate,
    });
  };

  const pickerPositionLocation =
    pickerAlignment === "left" ? "left-0" : "right-0";

  const getDisplayGrid = (): React.ReactNode => {
    const gridColumns = ((): number => {
      switch (state.mode) {
        case "quarter":
          return 1;
        case "month":
        case "year":
        default:
          return 3;
      }
    })();

    const isDateDisabled = (gridValue: string | number): boolean => {
      let dateToCheck: Date;
      switch (state.mode) {
        case "month":
          dateToCheck = new Date(
            pendingYearDate,
            new Date(gridValue + " 1, 2020").getMonth(),
            1,
          );
          break;
        case "quarter":
          dateToCheck = new Date(
            pendingYearDate,
            (Number(gridValue) - 1) * 3,
            1,
          );
          break;
        case "year":
          dateToCheck = new Date(Number(gridValue), 0, 1);
          break;
        default:
          return false;
      }

      return Boolean(
        Boolean(minDate && isBefore(dateToCheck, startOfMonth(minDate))) ||
          (maxDate && isAfter(dateToCheck, endOfMonth(maxDate))),
      );
    };

    return (
      <div className={`grid grid-cols-${gridColumns} gap-2`}>
        {displayState.gridDisplayValues.map((gridValue) => {
          const disabled = isDateDisabled(gridValue);
          return (
            <Button
              key={`${state.mode}-key-${gridValue}`}
              fill="outline"
              className={`!w-full text-center p-2 border rounded cursor-pointer hover:bg-gray-200 !text-black !border-gray-300 !hover:text-black ${
                disabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
              id={`${gridValue}`}
              onClick={() => !disabled && handleGridSelection(gridValue)}
              disabled={disabled}
            >
              {gridValue}
            </Button>
          );
        })}
      </div>
    );
  };

  return (
    <div className="relative">
      {label && (
        <div className="flex flex-row">
          <label htmlFor="period-picker-button" className="inline-flex">
            <Typography tag="span" size="xs" className={`${label && " mb-1"}`}>
              {label}
            </Typography>
            {optional && (
              <Typography className="ml-1" tag="span" size="xs" color="empty">
                {"(optional)"}
              </Typography>
            )}
          </label>
        </div>
      )}
      <button
        id="period-picker-button"
        data-testid="period-picker-button"
        type="button"
        className={`w-full text-left rounded p-2 cursor-pointer flex items-center border ${
          border === "hover"
            ? "border-transparent hover:border-gray-300"
            : "border-gray-300"
        }`}
        onClick={togglePicker}
      >
        <CalendarIcon className="size-4 inline-block mr-2" />
        <span className="min-w-fit whitespace-nowrap">
          {displayState.gridDisplay}
        </span>
        {displayPickerReset && (
          <XMarkIcon
            type="button"
            className="flex-shrink-0 size-5 text-gray-500 hover:text-gray-700"
            onClick={(e) => {
              e.stopPropagation();
              setState({ ...state, startDate: null, endDate: null });
            }}
          />
        )}
      </button>

      {showPicker && (
        <div
          ref={pickerRef}
          className={`min-w-[280px] absolute z-10 top-full mt-2 ${pickerPositionLocation} bg-white border border-gray-300 rounded p-4 shadow-lg max-sm:min-w-[230px]`}
          id="PeriodPickerSelector"
        >
          <div
            className={`grid grid-cols-3 justify-around items-center ${
              state.mode === "year" ? "" : "mb-4"
            }`}
          >
            {/* 
              Year mode will display 3 years at a time. 
              The magic numbers provide the correct increments for this mode until we make them customizable.
             */}
            <Button
              fill="clear"
              className={`p-1 text-lg !text-black ${
                pendingYearDate - (state.mode === "year" ? 2 : 1) < minYear
                  ? "hidden"
                  : ""
              }`}
              onClick={() =>
                setPendingYearDate((prevDate) => {
                  const newYear = prevDate - 1;
                  if (newYear < minYear) return minYear;
                  return newYear;
                })
              }
            >
              -
            </Button>
            <span className="text-center col-start-2">{pendingYearDate}</span>
            <Button
              fill="clear"
              className={`p-1 text-lg !text-black ${
                pendingYearDate + (state.mode === "year" ? 2 : 1) > maxYear
                  ? "hidden"
                  : ""
              }`}
              onClick={() =>
                setPendingYearDate((prevDate) => {
                  const newYear = prevDate + 1;
                  if (newYear > maxYear) return maxYear;
                  return newYear;
                })
              }
            >
              +
            </Button>
          </div>
          {getDisplayGrid()}
        </div>
      )}
    </div>
  );
};

export default PeriodPicker;

import React from "react";
import Modal from "~/components/Modal";
import { useSelector } from "react-redux";
import { State } from "~/store";
import DatePicker, { useDatePicker } from "~/components/DatePicker";
import Button from "~/components/Button";
import { toZonedTime } from "date-fns-tz";
import { IAPIResponse } from "~/utils/types";
import request from "~/utils/request";
import { IDatePickerState } from "~/components/DatePicker/useDatePicker";

interface Props {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  positionUuid: string;
  handleOptimisticUpdate: () => Promise<void>;
  currentTerminationDate: string | null;
  minTerminationDate: string;
  organizationUuid: string;
}

const updateTerminationDate = async ({
  termDate,
  setTermDate,
  positionUuid,
  scenarioUuid,
  organizationUuid,
  successCallback,
}: {
  termDate: IDatePickerState;
  setTermDate: React.Dispatch<React.SetStateAction<IDatePickerState>>;
  positionUuid: string;
  scenarioUuid?: string;
  organizationUuid: string;
  successCallback: () => void;
}): Promise<void> => {
  try {
    if (termDate.value.startDate && termDate.valid) {
      const editPositionResponse = (await request({
        method: "PATCH",
        url: `/organizations/${organizationUuid}/positions/${positionUuid}`,
        body: { termDate: termDate.value.startDate },
        params: {
          scenarioUuid,
          awaitCalculations: true,
        },
      })) as IAPIResponse;

      if (editPositionResponse.status === 201) {
        successCallback();
      }
    } else {
      setTermDate((prev) => ({
        ...prev,
        touched: true,
        pristine: false,
        error: "Please select a date",
      }));
    }
  } catch (error) {
    console.error(error);
  }
};

const deleteTerminationDate = async ({
  positionUuid,
  scenarioUuid,
  organizationUuid,
  successCallback,
}: {
  positionUuid: string;
  scenarioUuid?: string;
  organizationUuid: string;
  successCallback: () => void;
}): Promise<void> => {
  try {
    const editPositionResponse = (await request({
      method: "PATCH",
      url: `/organizations/${organizationUuid}/positions/${positionUuid}`,
      body: { termDate: null },
      params: {
        scenarioUuid,
        awaitCalculations: true,
      },
    })) as IAPIResponse;

    if (editPositionResponse.status === 201) {
      successCallback();
    }
  } catch (error) {
    console.error(error);
  }
};

const EditTermDate = ({
  id,
  isOpen,
  onClose,
  positionUuid,
  currentTerminationDate,
  minTerminationDate,
  handleOptimisticUpdate,
  organizationUuid,
}: Props): React.ReactNode => {
  const activeScenarioUuid = useSelector(
    (state: State) => state.scenario.activeScenarioUuid,
  );
  const [termDate, setTermDate] = useDatePicker({
    value: {
      startDate: currentTerminationDate
        ? toZonedTime(currentTerminationDate, "UTC").toString()
        : null,
      endDate: currentTerminationDate
        ? toZonedTime(currentTerminationDate, "UTC").toString()
        : null,
    },
    minDate: minTerminationDate,
  });
  return (
    <Modal id={id} isOpen={isOpen} title="Term Position" size="sm">
      <div data-testid="term-position-modal" className="mt-4 w-full">
        <DatePicker
          id={`${id}-date-picker`}
          label="Termination Date"
          state={termDate}
          setState={setTermDate}
        />
        <div className="flex flex-row justify-between mt-6">
          <Button
            fill="clear"
            id="cancel-edit-term-date"
            onClick={onClose}
            className="!w-fit !px-0"
          >
            Cancel
          </Button>
          <div className="flex flex-row gap-8">
            {currentTerminationDate && (
              <Button
                id="delete-term-date"
                fill="destructiveClear"
                onClick={() => {
                  deleteTerminationDate({
                    positionUuid,
                    scenarioUuid: activeScenarioUuid ?? undefined,
                    organizationUuid,
                    successCallback: () => {
                      handleOptimisticUpdate();
                      onClose();
                    },
                  });
                }}
                className="!w-fit !px-0"
              >
                Delete Term
              </Button>
            )}
            <Button
              id="save-term-date"
              fill="solid"
              onClick={() => {
                updateTerminationDate({
                  termDate,
                  setTermDate,
                  positionUuid,
                  scenarioUuid: activeScenarioUuid ?? undefined,
                  organizationUuid,
                  successCallback: () => {
                    handleOptimisticUpdate();
                    onClose();
                  },
                });
              }}
              className="!w-fit !px-5"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditTermDate;

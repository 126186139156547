import React from "react";
import toast from "react-hot-toast";
import { IDatePickerState } from "~/components/DatePicker/useDatePicker";
import request from "~/utils/request";
import { SelectState } from "~/components/Select/Select.types";
import { CENTS_PER_DOLLAR } from "~/utils/constants/currency";
import updateScenarioTray from "~/components/ScenarioTray/updateScenarioTray";

interface IAPIResponse {
  data: {
    data: unknown[];
  };
  status: number;
}

export const createPosition = async ({
  organizationUuid,
  title,
  setTitle,
  employeeName,
  setEmployeeName,
  payRate,
  setPayRate,
  hireDate,
  setHireDate,
  selectDepartment,
  setSelectDepartment,
  successCallback,
  scenarioUuid,
  numberToCreate,
  setNumberToCreate,
  setIsSubmitting,
  awaitCalculations,
}: {
  organizationUuid: string;
  title: Types.InputState;
  setTitle: React.Dispatch<React.SetStateAction<Types.InputState>>;
  employeeName: Types.InputState;
  setEmployeeName: React.Dispatch<React.SetStateAction<Types.InputState>>;
  payRate: Types.InputState;
  setPayRate: React.Dispatch<React.SetStateAction<Types.InputState>>;
  hireDate: IDatePickerState;
  setHireDate: React.Dispatch<React.SetStateAction<IDatePickerState>>;
  selectDepartment: SelectState;
  setSelectDepartment: React.Dispatch<React.SetStateAction<SelectState>>;
  successCallback: () => void;
  scenarioUuid?: string;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  numberToCreate: Types.InputState;
  setNumberToCreate: React.Dispatch<React.SetStateAction<Types.InputState>>;
  awaitCalculations?: boolean;
}): Promise<void> => {
  setIsSubmitting(true);

  const positionToCreate = {
    hireDate: hireDate.value.startDate,
    title: title.value,
    employeeName: employeeName.value.length > 0 ? employeeName.value : null,
    departmentUuid: selectDepartment.selected?.value,
    payRate: [
      {
        value: Number(payRate.value) * CENTS_PER_DOLLAR,
        effectiveAt: "onHire",
      },
    ],
    awaitCalculations,
  };

  try {
    if (
      !!selectDepartment.selected?.value &&
      title.valid &&
      payRate.valid &&
      hireDate.valid
    ) {
      const createPositionResponse = (await request({
        method: "POST",
        url: `/organizations/${organizationUuid}/positions`,
        body: positionToCreate,
        params: {
          scenarioUuid: scenarioUuid ?? undefined,
          numberToCreate: Number(numberToCreate.value),
        },
      })) as IAPIResponse;

      if (createPositionResponse.status === 201) {
        toast.success(
          () => (
            <div data-testid="position-creation-successful">
              Position created
            </div>
          ),
          { position: "top-center" },
        );
        updateScenarioTray();
        successCallback();
      } else {
        throw new Error("Failed to create position");
      }
    } else {
      throw new Error("Invalid form data");
    }
  } catch (error) {
    toast.error("Failed to create position");
    setIsSubmitting(false);
    setSelectDepartment((prevState) => ({
      ...prevState,
      valid: !!prevState.selected?.value,
      pristine: false,
      touched: true,
    }));
    setTitle((prevState) => ({
      ...prevState,
      pristine: false,
      touched: true,
    }));
    setEmployeeName((prevState) => ({
      ...prevState,
      pristine: false,
      touched: true,
    }));
    setPayRate((prevState) => ({
      ...prevState,
      pristine: false,
      touched: true,
    }));
    setHireDate((prevState) => ({
      ...prevState,
      pristine: false,
      touched: true,
    }));
    setNumberToCreate((prevState) => ({
      ...prevState,
      valid: Number(prevState.value) > 0 && Number(prevState.value) < 101,
      pristine: false,
      touched: true,
    }));
  }
};

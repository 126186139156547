import React, { Fragment, useEffect, useState, useRef } from "react";
import Tray from "~/components/Tray";
import Typography from "~/components/Typography";
import LineGraph from "../LineGraph";
import { useDispatch, useSelector } from "react-redux";
import { State } from "~/store";
import {
  updateActiveScenarioData,
  updateActiveScenarioHasChanges,
  shouldCollapseTray,
} from "~/store/scenarioSlice";
import request from "~/utils/request";
import {
  IReportCollection,
  IConsolidatedReport,
  IStaticReport,
} from "~/pages/OldDashboard/entity/types";
import { useLocation } from "react-router-dom";
import {
  ZReportCollection,
  ZScenario,
} from "~/pages/OldDashboard/entity/schemas";
import { format, addMonths } from "date-fns";
import date from "~/utils/dates/date";
import { ILineProps } from "../LineGraph/entity/types";
import combineReportsIntoOne from "~/pages/OldDashboard/utils/combineReportsIntoOne";
import { v4 } from "uuid";
import getRunwayColor from "~/pages/OldDashboard/utils/getRunwayColor";
import Input, { useInput } from "../Input/InputWrapper";
import HoverPopover from "../HoverPopover";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import Button from "../Button";
import UserDateRange from "../UserDateRange";
import { Channel } from "pusher-js";
import updateScenarioTray from "./updateScenarioTray";

interface IFetchScenarioResponse {
  data: {
    data: unknown;
  };
  status: number;
}

interface IAPIResponse {
  data: {
    data: unknown[];
  };
  status: number;
}

const ScenarioTray = (): React.ReactElement => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    activeScenarioUuid,
    selectedScenarioUuids,
    activeScenarioData,
    isTrayCollapsed,
  } = useSelector((state: State) => state.scenario);
  const preferences = useSelector((state: State) => state.user.preferences);
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const pusher = useSelector((state: State) => state._sockets.pusher);
  const [cashBalanceReport, setCashBalanceReport] = useState<
    IConsolidatedReport | undefined
  >(undefined);
  const [arrReport, setArrReport] = useState<IConsolidatedReport | undefined>(
    undefined,
  );
  const [cashflowReport, setCashflowReport] = useState<
    IConsolidatedReport | undefined
  >(undefined);
  const [runway, setRunway] = useState<IStaticReport[]>([]);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const [scenarioTitle, setScenarioTitle] = useInput({
    value: activeScenarioData?.changeDescription ?? "",
  });
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<string | undefined>();

  useEffect(() => {
    if (activeScenarioData) {
      setScenarioTitle({
        ...scenarioTitle,
        value: activeScenarioData.changeDescription,
      });
    }
  }, [activeScenarioData]);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(`${contentRef.current.scrollHeight}px`);
    }
  }, [isTrayCollapsed, cashBalanceReport, arrReport, contentRef.current]);

  const lines: ILineProps[] = [
    { dataKey: "report0", stroke: "#64755C" },
    { dataKey: "report1", stroke: "#406F83", isDashed: true },
    { dataKey: "report2", stroke: "#EBA61E", isDashed: true },
    { dataKey: "report3", stroke: "#8A6190", isDashed: true },
  ].splice(
    0,
    cashBalanceReport?.data
      ? Object.keys(cashBalanceReport.data[0]).length - 1
      : 0,
  );
  const [userActiveIndex, setUserActiveIndex] = useState<number>(-1);

  useEffect(() => {
    const fetchScenarioData = async (): Promise<void> => {
      if (activeScenarioUuid) {
        if (!activeScenarioData) {
          const response = (await request({
            method: "GET",
            url: `/organizations/${organizationUuid}/scenarios/${activeScenarioUuid}`,
          })) as IFetchScenarioResponse;
          if (response.status === 200) {
            const parsedScenario = ZScenario.parse(response.data.data);
            dispatch(updateActiveScenarioData(parsedScenario));
          }
        }
        const reportsPromises = [];
        reportsPromises.push(
          request({
            url: `/reports`,
            method: "GET",
            params: {
              include: [
                "cashBalance",
                "cashflow",
                "expenses",
                "revenue",
                "runway",
              ],
              startDate: preferences.defaultGraphStartDate,
              endDate: preferences.defaultGraphEndDate,
              scenarioUuid: undefined,
              compareData: false,
            },
            headers: { "Organization-Uuid": organizationUuid },
          }),
        );
        reportsPromises.push(
          request({
            url: `/reports`,
            method: "GET",
            params: {
              include: [
                "cashBalance",
                "cashflow",
                "expenses",
                "revenue",
                "runway",
              ],
              startDate: preferences.defaultGraphStartDate,
              endDate: preferences.defaultGraphEndDate,
              scenarioUuid: activeScenarioUuid,
              compareData: false,
            },
            headers: { "Organization-Uuid": organizationUuid },
          }),
        );
        if (selectedScenarioUuids.length) {
          selectedScenarioUuids.forEach((scenarioUuid) => {
            reportsPromises.push(
              request({
                url: `/reports`,
                method: "GET",
                params: {
                  include: [
                    "cashBalance",
                    "cashflow",
                    "expenses",
                    "revenue",
                    "runway",
                  ],
                  startDate: preferences.defaultGraphStartDate,
                  endDate: preferences.defaultGraphEndDate,
                  scenarioUuid: scenarioUuid,
                  compareData: false,
                },
                headers: { "Organization-Uuid": organizationUuid },
              }),
            );
          });
        }
        const reportsResult = await Promise.all(reportsPromises);
        const parsedReports: IReportCollection[] = [];
        reportsResult.forEach((report: IAPIResponse) => {
          const parsedReport = ZReportCollection.parse(report.data.data);
          parsedReports.push(parsedReport);
        });

        const consolidatedReports = combineReportsIntoOne({
          reports: parsedReports,
        });

        setCashBalanceReport(consolidatedReports.cashBalance);
        setArrReport(consolidatedReports.revenue);
        setCashflowReport(consolidatedReports.cashflow);
        setRunway(consolidatedReports.runway ?? []);
      }
    };

    fetchScenarioData();

    window.addEventListener("updateScenarioTray", fetchScenarioData);

    return () => {
      window.removeEventListener("updateScenarioTray", fetchScenarioData);
    };
  }, [
    activeScenarioUuid,
    organizationUuid,
    selectedScenarioUuids,
    preferences.defaultGraphStartDate,
    preferences.defaultGraphEndDate,
  ]);

  useEffect(() => {
    let channel: Channel | null = null;
    if (activeScenarioUuid && pusher) {
      channel = pusher.subscribe(activeScenarioUuid);
      channel.bind("update-scenario-tray", () => {
        updateScenarioTray();
      });
    }
    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, [activeScenarioUuid]);

  useEffect(() => {
    if (
      arrReport?.data.every((data) => data.report0 === data.report1) &&
      cashBalanceReport?.data.every((data) => data.report0 === data.report1)
    ) {
      dispatch(updateActiveScenarioHasChanges(false));
    } else {
      dispatch(updateActiveScenarioHasChanges(true));
    }
  }, [arrReport, cashBalanceReport, dispatch]);

  const handleOnBlur = async (): Promise<void> => {
    try {
      if (
        activeScenarioData &&
        activeScenarioData.changeDescription !== scenarioTitle.value &&
        scenarioTitle.valid
      ) {
        const originalTitle = activeScenarioData.changeDescription;
        dispatch(
          updateActiveScenarioData({
            ...activeScenarioData,
            changeDescription: scenarioTitle.value,
          }),
        );
        const response = (await request({
          method: "PATCH",
          url: `/organizations/${organizationUuid}/scenarios/${activeScenarioUuid}`,
          body: {
            changeDescription: scenarioTitle.value,
          },
        })) as IAPIResponse;
        if (response.status >= 400) {
          dispatch(
            updateActiveScenarioData({
              ...activeScenarioData,
              changeDescription: originalTitle,
            }),
          );
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    setIsVisible(!!activeScenarioUuid && location.pathname !== "/dashboard");
  }, [activeScenarioUuid, location.pathname]);

  return (
    <>
      <div
        className={`${isVisible ? "" : "hidden"} ${isTrayCollapsed ? "h-[106px]" : "h-[450px]"} w-full z-50`}
      >
        <Tray isOpen={isVisible}>
          <div data-testid="scenario-tray">
            <div className="flex justify-between items-end">
              <div className="flex items-center gap-2">
                <div className="w-[200px]">
                  <Input
                    id={`scenario-tray-title`}
                    onBlur={handleOnBlur}
                    state={scenarioTitle}
                    setState={setScenarioTitle}
                    className="!border-transparent text-lg font-bold hover:!border hover:!border-neutral-100 !shadow-none"
                  />
                </div>
                <UserDateRange />
              </div>
              <div className="flex flex-row gap-4">
                <div
                  className="flex items-center"
                  data-testid="scenario-tray-runway-container"
                >
                  <Typography size="sm" color="disabled" className="mr-2">
                    🛫 Runway
                  </Typography>
                  {runway.map((item, index) => (
                    <Fragment key={v4()}>
                      <HoverPopover
                        buttonContent={
                          <Typography
                            size="lg"
                            weight="bold"
                            className="mr-1 leading-5"
                            color={getRunwayColor(index).text}
                          >
                            {item.data.value ?? "∞"}
                          </Typography>
                        }
                        panelContent={
                          <div
                            className={`${getRunwayColor(index).bg ? getRunwayColor(index).bg : "bg-black"} px-2 py-1 rounded`}
                          >
                            <Typography color="white">
                              {item.data.value !== null
                                ? format(
                                    addMonths(date(), item.data.value),
                                    "MMM yyyy",
                                  )
                                : "Infinite"}
                            </Typography>
                          </div>
                        }
                        anchor="top"
                      />
                      {index !== runway.length - 1 && (
                        <Typography size="xs" color="disabled" className="mr-1">
                          |
                        </Typography>
                      )}
                    </Fragment>
                  ))}
                  <Typography size="xs" color="disabled" className="mr-1">
                    MO.
                  </Typography>
                </div>
                <div>
                  <Button
                    fill="clear"
                    className="!w-fit !p-0"
                    onClick={() => {
                      dispatch(shouldCollapseTray(!isTrayCollapsed));
                    }}
                    id="scenario-tray-collapse-button"
                  >
                    {isTrayCollapsed ? (
                      <ChevronUpIcon className="size-5" />
                    ) : (
                      <ChevronDownIcon className="size-5" />
                    )}
                  </Button>
                </div>
              </div>
            </div>
            <div
              ref={contentRef}
              style={{ maxHeight: isTrayCollapsed ? "0" : contentHeight }}
              className={`transition-max-height duration-500 ease-in-out overflow-hidden`}
            >
              {cashBalanceReport?.data && arrReport?.data && (
                <div className="flex justify-between w-full gap-4 mt-2.5">
                  <div className="w-1/3 h-[300px]">
                    <LineGraph
                      id="cash-balance"
                      data={cashBalanceReport.data}
                      dataKeys={["date", "cwm", "scenario"]}
                      lines={lines}
                      card={{
                        title: "🏦 Cash Balance",
                      }}
                      isCashGraph
                      externalActiveIndex={userActiveIndex}
                      setExternalActiveIndex={setUserActiveIndex}
                    />
                  </div>
                  <div className="w-1/3 h-[300px]">
                    <LineGraph
                      id="cashflow"
                      data={cashflowReport?.data ?? []}
                      dataKeys={["date", "cwm", "scenario"]}
                      lines={lines}
                      card={{
                        title: "🌊 Cashflow",
                      }}
                      isCashGraph
                      externalActiveIndex={userActiveIndex}
                      setExternalActiveIndex={setUserActiveIndex}
                    />
                  </div>
                  <div className="w-1/3 h-[300px]">
                    <LineGraph
                      id="revenue"
                      data={arrReport.data}
                      dataKeys={["date", "cwm", "scenario"]}
                      lines={lines}
                      card={{
                        title: "💸 Revenue",
                      }}
                      externalActiveIndex={userActiveIndex}
                      setExternalActiveIndex={setUserActiveIndex}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Tray>
      </div>
    </>
  );
};

export default ScenarioTray;

import React from "react";
import { IStaticReportData } from "../../entity/types";
import Typography from "~/components/Typography";
import Divider from "~/components/Divider";

enum MetricColor {
  GREEN = "green",
  BLUE = "blue",
  ORANGE = "orange",
  PURPLE = "purple",
  TURQUOISE = "turquoise",
}

const MetricCard = ({
  metricTitle,
  metricValues,
}: {
  metricTitle: string;
  metricValues: Record<string, IStaticReportData>;
}): React.ReactNode => {
  const textColor: Record<string, MetricColor> = {
    workingModel: MetricColor.GREEN,
    activeScenario: MetricColor.BLUE,
    scenario1: MetricColor.ORANGE,
    scenario2: MetricColor.PURPLE,
    scenario3: MetricColor.TURQUOISE,
  };
  const renderedMetricValues = Object.entries(metricValues).map(
    ([key, value], index, array) => (
      <div className="flex no-wrap" key={`${key}-${value.value ?? "∞"}`}>
        <Typography weight="semibold" color={textColor[key]}>
          {value.value ?? "∞"}
        </Typography>
        {index < array.length - 1 && (
          <Divider orientation="vertical" className="text-neutral-75 mx-1.5" />
        )}
      </div>
    ),
  );
  return (
    <div className="flex flex-col border border-green-50 rounded-2xl py-4 px-5">
      <Typography color="secondary" size="xs">
        {metricTitle}
      </Typography>
      <div className="flex flex-wrap">{renderedMetricValues}</div>
    </div>
  );
};

export default MetricCard;

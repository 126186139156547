import React, { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { usePopper } from "react-popper";
import useHover from "~/utils/hooks/useHover";
import Typography from "../Typography";
import scenarioModeInfo from "~/assets/scenarioModeInfo.svg";

const ScenarioInfoPopover = ({ id }: { id: string }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);
  const [ref, hovering] = useHover();

  return (
    <div ref={ref} data-testid={id} className="size-5">
      <Popover className="relative size-5">
        <Popover.Button
          ref={setReferenceElement}
          className="pointer-events-auto"
        >
          <InformationCircleIcon className="size-5 text-white" />
        </Popover.Button>
        <Transition
          show={Boolean(hovering)}
          as={Fragment}
          enter="transition-opacity ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in duration-50"
          leaveTo="opacity-0"
        >
          <Popover.Panel
            ref={setPopperElement}
            style={{ ...styles.popper, zIndex: 20, position: "fixed" }}
            {...attributes.popper}
          >
            <div className="bg-white w-[469px] mt-4 flex flex-col gap-2 shadow-lg px-6 py-6 rounded-lg justify-center items-center text-center">
              <img src={scenarioModeInfo} alt="Scenario Mode Info" />
              <Typography weight="bold">Scenario Mode</Typography>
              <Typography color="secondary">
                {`Here you'll be able to adjust company levers to model the impact
                on your KPIs, empowering you to make informed decisions and
                meaningful bets.`}
              </Typography>
              <Typography color="secondary">
                {`Any changes made while in this mode are temporary and will not affect your base model.`}
              </Typography>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};

export default ScenarioInfoPopover;

import React from "react";
import Button from "~/components/Button";
import Input from "~/components/Input/InputWrapper";
import Modal from "~/components/Modal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  newDepartmentName: Types.InputState;
  setNewDepartmentName: React.Dispatch<React.SetStateAction<Types.InputState>>;
  attemptAddDepartment: () => void;
}

const CreateDepartmentModal = ({
  isOpen,
  onClose,
  newDepartmentName,
  setNewDepartmentName,
  attemptAddDepartment,
}: Props): React.ReactNode => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Add Department" size="sm">
      <div className="flex flex-col gap-4 w-full">
        <Input
          label="Department Name"
          className="w-full"
          state={newDepartmentName}
          setState={setNewDepartmentName}
          id="department-name"
          placeholder="Enter department name"
        />
        <div className="flex flex-row justify-between">
          <Button className="!w-auto !p-0" fill="clear" onClick={onClose}>
            Cancel
          </Button>
          <Button className="!w-auto" onClick={attemptAddDepartment}>
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateDepartmentModal;

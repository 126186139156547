import { isSameMonth } from "date-fns";
import { type IPositionDetails } from "~/pages/Headcount/entity/types";
import { IExpense } from "../components/Expenses/types";

export const getActivePositionsInMonthPerExpense = ({
  positions,
  month,
  isOnHire,
  expense,
}: {
  positions: IPositionDetails[];
  month: Date;
  isOnHire?: boolean;
  expense: IExpense;
}): IPositionDetails[] => {
  const validDepartments = expense.context.departments;
  return positions.filter((position) => {
    const positionDepartment = position.departmentUuid;
    let isValidDepartment = false;
    if (typeof positionDepartment === "string") {
      isValidDepartment =
        validDepartments.includes(positionDepartment) ||
        validDepartments.includes("ALL");
    }
    if (isOnHire) {
      return (
        isSameMonth(month, new Date(position.hireDate)) && isValidDepartment
      );
    }

    return (
      month >= new Date(position.hireDate) &&
      (!position.termDate || month <= new Date(position.termDate)) &&
      isValidDepartment
    );
  });
};

import React, { useMemo, useEffect, useState } from "react";
import { IStaticGraphData } from "../../entity/types";
import MetricCard from "./MetricCard";

const DashboardMetrics = ({
  staticMetrics,
}: {
  staticMetrics: IStaticGraphData;
}): React.ReactNode => {
  const [sectionSize, setSectionSize] = useState(3);

  useEffect(() => {
    const handleResize = (): void => {
      if (window.innerWidth < 600) {
        setSectionSize(1);
      } else if (window.innerWidth < 800) {
        setSectionSize(2);
      } else {
        setSectionSize(3);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const generatedMetrics = useMemo(() => {
    if (Object.keys(staticMetrics).length) {
      return Object.entries(staticMetrics).map(([key, value]) => {
        return (
          <MetricCard
            key={key}
            metricTitle={value.title}
            metricValues={value.data}
          />
        );
      });
    } else return null;
  }, [staticMetrics]);

  const sectionedMetrics = useMemo(() => {
    if (generatedMetrics) {
      const sections = [];
      for (let i = 0; i < generatedMetrics.length; i += sectionSize) {
        sections.push(generatedMetrics.slice(i, i + sectionSize));
      }
      return sections;
    }
    return [];
  }, [generatedMetrics, sectionSize]);

  return (
    <div className="w-full max-w-[1000px] flex flex-col gap-4">
      {sectionedMetrics.map((section, index) => (
        <div key={index} className="w-full grid gap-4 grid-flow-col">
          {section}
        </div>
      ))}
    </div>
  );
};

export default DashboardMetrics;

import { IAPIResponse } from "~/utils/types";
import { IPositionDetails } from "../entity/types";
import request from "~/utils/request";
import { ZPositionDetails } from "../entity/schemas";

export const fetchSinglePosition = async ({
  organizationUuid,
  positionUuid,
  scenarioUuid,
}: {
  organizationUuid: string;
  positionUuid: string;
  scenarioUuid?: string;
}): Promise<IPositionDetails> => {
  const response = (await request({
    url: `/organizations/${organizationUuid}/positions/${positionUuid}/details`,
    method: "GET",
    params: {
      scenarioUuid,
    },
  })) as IAPIResponse<IPositionDetails>;

  const parsedData = ZPositionDetails.parse(response.data.data);

  return parsedData;
};

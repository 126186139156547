import React, { createContext, useMemo, useState } from "react";
import { IExpense } from "~/pages/Expenses/components/Expenses/types";
import { IDepartmentDict } from "~/pages/Settings/Departments/entity/types";
import { IConsolidatedReportCollection } from "~/pages/OldDashboard/entity/types";
import { IPositionDetails } from "~/pages/Headcount/entity/types";
import { useSelector } from "react-redux";
import { State } from "~/store";
import { useExpensesData } from "../useExpensesData";
import { useSelect } from "~/components/Select";
import { useInput } from "~/components/Input";
import { SelectState } from "~/components/Select/Select.types";

interface IProps {
  children: React.ReactNode;
}

interface IExpensesPageContext {
  expenses: IExpense[];
  setExpenses: (expenses: IExpense[]) => void;
  departmentDict: IDepartmentDict;
  expenseModal: boolean;
  setExpenseModal: (value: boolean) => void;
  expenseUuid?: { uuid: string; expenseUuid: string; type: "edit" | "create" };
  setExpenseUuid: (
    uuid:
      | { uuid: string; expenseUuid: string; type: "edit" | "create" }
      | undefined,
  ) => void;
  discontinueExpense?: { uuid: string; expenseUuid: string; startDate: string };
  setDiscontinueExpense: (
    value: { uuid: string; expenseUuid: string; startDate: string } | undefined,
  ) => void;
  deleteExpense?: { uuid: string; expenseUuid: string };
  setDeleteExpense: (
    value: { uuid: string; expenseUuid: string } | undefined,
  ) => void;
  reload: () => void;
  reports: IConsolidatedReportCollection;
  lockedDate: Date | undefined;
  setLockedDate: (date: Date | undefined) => void;
  searchByName: string;
  setSearchByName: (name: string) => void;
  positions: IPositionDetails[];
  loading: boolean;
  revalidateFilteredExpensesReport: () => void;
  filteredExpensesReportLoading: boolean;
  showPastExpenses: boolean;
  setShowPastExpenses: (value: boolean) => void;
  categories: SelectState;
  setCategories: React.Dispatch<React.SetStateAction<SelectState>>;
  types: SelectState;
  setTypes: React.Dispatch<React.SetStateAction<SelectState>>;
  searchState: Types.InputState;
  setSearchState: React.Dispatch<React.SetStateAction<Types.InputState>>;
}

const initialContext: IExpensesPageContext = {
  expenses: [],
  setExpenses: () => {},
  departmentDict: {},
  expenseModal: false,
  setExpenseModal: () => {},
  expenseUuid: undefined,
  setExpenseUuid: () => {},
  discontinueExpense: undefined,
  setDiscontinueExpense: () => {},
  deleteExpense: undefined,
  setDeleteExpense: () => {},
  reload: () => {},
  reports: {} as IConsolidatedReportCollection,
  lockedDate: undefined,
  setLockedDate: () => {},
  searchByName: "",
  setSearchByName: () => {},
  positions: [],
  loading: false,
  revalidateFilteredExpensesReport: () => {},
  filteredExpensesReportLoading: false,
  showPastExpenses: false,
  setShowPastExpenses: () => {},
  categories: {} as SelectState,
  setCategories: () => {},
  types: {} as SelectState,
  setTypes: () => {},
  searchState: {} as Types.InputState,
  setSearchState: () => {},
};

export const ExpensesPageContext =
  createContext<IExpensesPageContext>(initialContext);

export const ExpensesPageContextProvider = ({
  children,
}: IProps): React.ReactElement => {
  const {
    loading,
    initialExpenses,
    initialReports,
    initialPositions,
    revalidate,
    revalidateFilteredExpensesReport,
    filteredExpensesReportLoading,
  } = useExpensesData();
  const departments = useSelector(
    (state: State) => state.organization.departments,
  );
  const [expenses, setExpenses] = useState<IExpense[]>([]);
  useMemo(() => {
    setExpenses(initialExpenses);
  }, [initialExpenses]);

  const reports = useMemo(() => {
    return initialReports;
  }, [initialReports]);

  const positions = useMemo(() => {
    return initialPositions;
  }, [initialPositions]);

  const [expenseModal, setExpenseModal] = React.useState<boolean>(false);
  const [expenseUuid, setExpenseUuid] = React.useState<
    { uuid: string; expenseUuid: string; type: "edit" | "create" } | undefined
  >(undefined);
  const [discontinueExpense, setDiscontinueExpense] = React.useState<
    { uuid: string; expenseUuid: string; startDate: string } | undefined
  >(undefined);
  const [deleteExpense, setDeleteExpense] = React.useState<
    | {
        uuid: string;
        expenseUuid: string;
      }
    | undefined
  >(undefined);
  const [lockedDate, setLockedDate] = useState<Date | undefined>(undefined);
  const [searchByName, setSearchByName] = useState<string>("");
  const [showPastExpenses, setShowPastExpenses] = useState<boolean>(false);

  const [categories, setCategories] = useSelect({
    options: [
      { value: "all", label: "All Categories" },
      { value: "Headcount Related", label: "Headcount Related" },
      { value: "Software", label: "Software" },
      { value: "Other", label: "Other" },
    ],
  });
  const [types, setTypes] = useSelect({
    options: [
      { value: "all", label: "All Types" },
      { value: "oneTime", label: "One Time" },
      { value: "monthly", label: "Monthly" },
      { value: "quarterly", label: "Quarterly" },
      { value: "annually", label: "Annually" },
      { value: "onHire", label: "On Hire" },
    ],
  });
  const [searchState, setSearchState] = useInput({ validation: /.*/ });

  const departmentDict = departments.reduce((acc, department) => {
    acc[department.uuid] = department;
    return acc;
  }, {} as IDepartmentDict);

  return (
    <ExpensesPageContext.Provider
      value={{
        expenses,
        setExpenses,
        departmentDict,
        expenseUuid,
        setExpenseUuid,
        expenseModal,
        setExpenseModal,
        discontinueExpense,
        setDiscontinueExpense,
        deleteExpense,
        setDeleteExpense,
        reload: revalidate,
        reports,
        lockedDate,
        setLockedDate,
        searchByName,
        setSearchByName,
        positions,
        loading,
        revalidateFilteredExpensesReport,
        filteredExpensesReportLoading,
        showPastExpenses,
        setShowPastExpenses,
        categories,
        setCategories,
        types,
        setTypes,
        searchState,
        setSearchState,
      }}
    >
      {children}
    </ExpensesPageContext.Provider>
  );
};

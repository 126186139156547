import React, { ReactElement, useState } from "react";
import EllipsisDropdown from "~/components/EllipsisDropdown";
import RenameDepartmentModal from "./RenameDepartmentModal";
import { IDepartment } from "./entity/types";
import request from "~/utils/request";
import { useSelector } from "react-redux";
import { State } from "~/store";
import toast from "react-hot-toast";

const DepartmentOptions = ({
  newDepartmentName,
  setNewDepartmentName,
  department,
  refreshDepartments,
}: {
  newDepartmentName: Types.InputState;
  setNewDepartmentName: React.Dispatch<React.SetStateAction<Types.InputState>>;
  department: IDepartment;
  refreshDepartments: () => void;
}): ReactElement => {
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);

  const handleClickRename = (): void => {
    setNewDepartmentName({
      ...newDepartmentName,
      value: department.name,
    });
    setIsRenameModalOpen(true);
  };

  const submitDepartmentRename = async (): Promise<void> => {
    if (
      newDepartmentName.valid &&
      newDepartmentName.value !== department.name
    ) {
      // API request to rename department
      const response = await request({
        url: `/organizations/${organizationUuid}/groups/${department.uuid}`,
        method: "PATCH",
        body: {
          name: newDepartmentName.value,
        },
        params: {
          scenarioUuid: activeScenarioUuid ?? undefined,
        },
      });

      if (response.status === 200) {
        setIsRenameModalOpen(false);
        setNewDepartmentName((prev) => ({
          ...prev,
          value: "",
        }));
        refreshDepartments();
        toast.success("Department renamed successfully");
      } else {
        toast.error("Failed to rename department");
      }
    }
  };

  const handleDeleteDepartment = async (): Promise<void> => {
    try {
      const response = await request({
        url: `/organizations/${organizationUuid}/groups/${department.uuid}`,
        method: "DELETE",
        params: {
          scenarioUuid: activeScenarioUuid ?? undefined,
        },
      });
      if (response.status === 204) {
        refreshDepartments();
        toast.success("Department deleted successfully");
      } else if (response.status === 409) {
        toast.error("Department cannot be deleted");
      } else {
        toast.error("Failed to delete department");
      }
    } catch (error) {
      toast.error("Failed to delete department");
    }
  };

  return (
    <div>
      <RenameDepartmentModal
        id="rename-department-modal"
        isOpen={isRenameModalOpen}
        onClose={() => setIsRenameModalOpen(false)}
        attemptRenameDepartment={submitDepartmentRename}
        newDepartmentName={newDepartmentName}
        setNewDepartmentName={setNewDepartmentName}
      />
      <EllipsisDropdown
        options={[
          {
            label: "Rename",
            onClick: handleClickRename,
          },
          {
            label: "Delete",
            onClick: handleDeleteDepartment,
            className: "text-red-400",
          },
        ]}
        id={`department-options-${department.uuid}`}
      />
    </div>
  );
};

export default DepartmentOptions;

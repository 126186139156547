import { useCallback, useEffect, useState } from "react";
import pageData from "./pageData";
import { useSelector } from "react-redux";
import { State } from "~/store";

const useDashboardData = (): {
  loading: boolean;
  data: unknown | null;
  revalidate: () => Promise<void>;
} => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<unknown | null>(null);
  const { defaultGraphStartDate, defaultGraphEndDate } = useSelector(
    (state: State) => state.user.preferences,
  );
  const { activeScenarioUuid, selectedScenarioUuids } = useSelector(
    (state: State) => state.scenario,
  );

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await pageData();
      setData(result);
    } catch (error) {
      throw new Error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [
    fetchData,
    defaultGraphStartDate,
    defaultGraphEndDate,
    activeScenarioUuid,
    selectedScenarioUuids,
  ]);

  return { loading, data, revalidate: fetchData };
};

export default useDashboardData;

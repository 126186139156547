import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { State } from "~/store";
import { SelectMultipleState } from "../SelectMultiple/SelectMultiple.types";
import SelectMultiple from "../SelectMultiple";
import { useFeatureFlag } from "~/utils/hooks/useFeatureFlag";

interface Props {
  departmentOptions: SelectMultipleState;
  setDepartmentOptions: React.Dispatch<
    React.SetStateAction<SelectMultipleState>
  >;
  className?: string;
  listBoxClassName?: string;
  label?: string;
  placeholder?: string;
  allowSelectAll?: boolean;
}

const SelectMultipleDepartments = ({
  departmentOptions,
  setDepartmentOptions,
  className = "",
  listBoxClassName = "",
  label,
  placeholder,
  allowSelectAll = false,
}: Props): React.ReactNode => {
  const scenarioDiffing = useFeatureFlag("scenarioDiffing");
  const departments = useSelector(
    (state: State) => state.organization.departments,
  );

  useEffect(() => {
    const getDepartments = async (): Promise<void> => {
      const allDepartmentsOption = { label: "All Departments", value: "ALL" };
      const departmentOptions = departments.map((department) => ({
        label: department.name,
        value: scenarioDiffing ? department.departmentUuid : department.uuid,
      }));
      const options = allowSelectAll
        ? [allDepartmentsOption, ...departmentOptions]
        : departmentOptions;

      setDepartmentOptions((prevState) => ({
        ...prevState,
        selected: prevState.selected,
        options: options.map((option) => {
          return {
            ...option,
            selected:
              prevState.selected?.length === 1 &&
              prevState.selected[0].value === "ALL",
            disabled:
              prevState.selected?.length === 1 &&
              prevState.selected[0].value === "ALL" &&
              option.value !== "ALL",
          };
        }),
      }));
    };
    getDepartments();
  }, [departments]);

  return (
    <SelectMultiple
      id="department-select"
      label={label}
      state={departmentOptions}
      setState={setDepartmentOptions}
      className={className}
      placeholder={placeholder}
      listBoxClassName={listBoxClassName}
    />
  );
};

export default SelectMultipleDepartments;

import z from "zod";
import { ZConsolidatedReportCollection } from "~/pages/OldDashboard/entity/schemas";
import {
  DEFAULT_TAGS,
  ExpenseDriverEnum,
  ExpenseFrequencyEnum,
} from "~/pages/Expenses/components/Expenses/types";
import { ZPositionDetails } from "~/pages/Headcount/entity/schemas";
import iso8601Validator from "~/utils/zodValidationHelpers/iso8601Validator";

export const ZExpenseContext = z.object({
  startDate: z.string().date(),
  endDate: z.string().date().nullable(),
  tag: z.enum(DEFAULT_TAGS),
  amount: z.number(),
  frequency: z.nativeEnum(ExpenseFrequencyEnum),
  driver: z.nativeEnum(ExpenseDriverEnum),
  departments: z.array(z.string()),
});

export const ZExpense = z.object({
  uuid: z.string(),
  expenseUuid: z.string(),
  organizationUuid: z.string(),
  scenarioUuid: z.string().nullable(),
  createdBy: z.string().nullable(),
  deletedBy: z.string().nullable(),
  createdAt: iso8601Validator,
  deletedAt: iso8601Validator.nullable(),
  name: z.string(),
  context: ZExpenseContext,
  isGrayedOut: z.boolean().optional(),
});

export const ZExpensesPageRequest = z.object({
  expenses: z.array(ZExpense),
  reports: ZConsolidatedReportCollection,
  positions: z.array(ZPositionDetails),
});

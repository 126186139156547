import React, { Fragment, useContext, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import Typography from "~/components/Typography";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { IScenario } from "~/pages/OldDashboard/entity/types";
import Divider from "~/components/Divider";
import request from "~/utils/request";
import toast from "react-hot-toast";
import Button from "~/components/Button";
import { useSelector, useDispatch } from "react-redux";
import { State } from "~/store";
import {
  addScenarioUuid,
  removeScenarioUuid,
  update,
} from "~/store/scenarioSlice";
import { DashboardPageContext } from "../../../context/DashboardContext";
import ScenarioItem from "./ScenarioItem";
import DeleteScenarioModal from "./DeleteScenarioModal";

interface IGetScenarioResponse {
  status: number;
  data: {
    data: IScenario;
  };
}

const ScenarioMenuDropdown = ({
  scenarios,
  createQuickScenario,
}: {
  scenarios: IScenario[];
  createQuickScenario?: () => Promise<void>;
}): React.ReactElement => {
  const { reload } = useContext(DashboardPageContext);
  const dispatch = useDispatch();
  const { activeScenarioUuid, selectedScenarioUuids } = useSelector(
    (state: State) => state.scenario,
  );
  const [scenarioToDelete, setScenarioToDelete] = useState<IScenario | null>(
    null,
  );

  const handleSelectScenario = (
    scenarioUuid: string,
    close?: () => void,
  ): void => {
    const isSelected = selectedScenarioUuids.includes(scenarioUuid);

    if (isSelected) {
      dispatch(removeScenarioUuid(scenarioUuid));
    } else {
      dispatch(addScenarioUuid(scenarioUuid));
    }

    if (close) close();
  };

  const handleDeleteScenario = async (scenario: IScenario): Promise<void> => {
    const response = await request({
      method: "DELETE",
      url: `/organizations/${scenario.organizationUuid}/scenarios/${scenario.uuid}`,
    });

    if (response.status === 204) {
      if (selectedScenarioUuids.includes(scenario.uuid)) {
        handleSelectScenario(scenario.uuid);
      }
      reload();
      toast.success("Scenario Deleted");
    }
    setScenarioToDelete(null);
  };

  const handleEditScenario = async (scenario: IScenario): Promise<void> => {
    const response: IGetScenarioResponse = await request({
      method: "GET",
      url: `/organizations/${scenario.organizationUuid}/scenarios/${scenario.uuid}`,
    });
    if (response.status !== 200) return;
    const filteredSelectedScenarioUuids = selectedScenarioUuids.filter(
      (uuid) => uuid !== scenario.uuid,
    );
    dispatch(
      update({
        activeScenarioUuid: response.data.data.uuid,
        activeScenarioData: response.data.data,
        activeScenarioHasChanges: false,
        leverChanges: [],
        cashBalanceLockedIndexes: [],
        cashBalanceSelectedPoint: null,
        selectedScenarioUuids: filteredSelectedScenarioUuids,
      }),
    );
    reload();
  };

  const propagateClick = (): void => {
    document.dispatchEvent(new MouseEvent("mousedown", { bubbles: true }));
  };

  const scenarioOptions = scenarios.map((scenario, index) => (
    <ScenarioItem
      scenario={scenario}
      key={scenario.uuid}
      onSelect={handleSelectScenario}
      onEdit={handleEditScenario}
      onDelete={setScenarioToDelete}
      anchorDirection={index === scenarios.length - 1 ? "bottom" : "top"}
    />
  ));

  return (
    <>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              id="scenario-dropdown-button"
              data-testid="scenario-dropdown-button"
              onClick={propagateClick}
              className="focus:outline-none focus:ring-0 focus:border-transparent"
            >
              <div className="flex text-green-400 hover:text-green-500 !w-fit !py-[5px] !pl-0 !pr-1 gap-1 items-center justify-center ">
                Scenarios
                {open ? (
                  <ChevronUpIcon width={16} height={16} strokeWidth={2.125} />
                ) : (
                  <ChevronDownIcon width={16} height={16} strokeWidth={2.125} />
                )}
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={`absolute z-10 transform left-0 mt-1 above shadow-lg`}
              >
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 w-[370px] max-sm:max-w-[200px]">
                  <div className="bg-white py-2">
                    <div className="flex items-center justify-between pt-2 px-4 w-full">
                      <Typography size="sm" weight="bold">
                        Scenarios
                      </Typography>
                      <Button
                        className="flex items-center justify-end gap-1 cursor-pointer !w-fit !p-0 max-sm:hidden"
                        onClick={async () => {
                          close();
                          if (createQuickScenario) {
                            await createQuickScenario();
                          }
                        }}
                        fill="clearBlue"
                        disabled={!!activeScenarioUuid}
                        id="new-scenario-button"
                      >
                        <PlusIcon width={16} height={16} />
                        New
                      </Button>
                    </div>
                    <Divider orientation="horizontal" className="mt-3 px-4" />
                    <div
                      className={`max-h-[250px] ${scenarios.length > 4 && "overflow-y-scroll"}`}
                    >
                      {scenarioOptions}
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <DeleteScenarioModal
        isOpen={Boolean(scenarioToDelete)}
        onClose={() => setScenarioToDelete(null)}
        onDelete={() => {
          if (scenarioToDelete) handleDeleteScenario(scenarioToDelete);
        }}
      />
    </>
  );
};

export default ScenarioMenuDropdown;

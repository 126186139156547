import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Typography from "../Typography";
import { ChatBubbleOvalLeftIcon } from "@heroicons/react/24/solid";
import MessageBox from "./components/MessageBox";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, State } from "~/store";
import {
  fetchConversations,
  fetchUnreadCount,
  openConversationBox,
  closeConversationBox,
  selectConversation,
} from "~/store/chatSlice";
import useChatBoxState from "./components/useChatBoxState";
import request from "~/utils/request";
import { ZConversationResponse } from "~/pages/OldDashboard/entity/schemas";
import generateMessageList from "./utils/generateMessageList";
import WhiteLogo from "~/assets/parallelLogoSVGWhite.svg";
import { compareDesc } from "date-fns";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Channel } from "pusher-js";
import { IMessageRoleEnum } from "~/pages/OldDashboard/entity/types";

const ConversationBox = (): React.ReactNode => {
  const dispatch = useDispatch<AppDispatch>();
  const { conversationUuid, isOpen, data } = useSelector(
    (state: State) => state.chat,
  );
  const userUuid = useSelector((state: State) => state.user.uuid);
  const pusher = useSelector((state: State) => state._sockets.pusher);
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const storedConversationUuid = localStorage.getItem("conversationUuid");
  if (storedConversationUuid && !conversationUuid) {
    dispatch(selectConversation(storedConversationUuid));
  }
  const { message, setMessage, resetFormState } = useChatBoxState();
  const [conversation, setConversation] = useState<ReactElement[]>([]);
  const activeScenarioUuid = useSelector(
    (state: State) => state.scenario.activeScenarioUuid,
  );
  const [messageMode, setMessageMode] = useState<{
    mode: "chat" | "history";
    conversationUuid?: string | null;
  }>(
    conversationUuid
      ? { mode: "chat", conversationUuid }
      : {
          mode: "history",
          conversationUuid: null,
        },
  );
  const [isLoadingConversation, setIsLoadingConversation] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const chatBoxRef = useRef<HTMLTextAreaElement>(null);
  const [isChatBoxFocused, setIsChatBoxFocused] = useState<boolean>(false);

  useEffect(() => {
    const currentChatBoxRef = chatBoxRef.current;

    if (currentChatBoxRef) {
      currentChatBoxRef.focus();
      setIsChatBoxFocused(true);
    }

    const handleFocus = (): void => {
      setIsChatBoxFocused(true);
    };

    const handleBlur = (): void => {
      setIsChatBoxFocused(false);
    };

    if (currentChatBoxRef) {
      currentChatBoxRef.addEventListener("focus", handleFocus);
      currentChatBoxRef.addEventListener("blur", handleBlur);
    }

    return () => {
      if (currentChatBoxRef) {
        currentChatBoxRef.removeEventListener("focus", handleFocus);
        currentChatBoxRef.removeEventListener("blur", handleBlur);
      }
    };
  }, [isOpen, isLoadingConversation, messageMode]);

  useEffect(() => {
    let channel: Channel | null = null;
    if (pusher) {
      channel = pusher.subscribe(userUuid);
      channel.bind("new-chat-reply", () => {
        dispatch(fetchConversations());
        dispatch(fetchUnreadCount());
      });
    }
    return () => {
      if (channel) channel.disconnect();
    };
  }, [pusher, userUuid]);

  useEffect(() => {
    dispatch(fetchConversations());
    dispatch(fetchUnreadCount());
  }, []);

  useEffect(() => {
    if (conversationUuid) {
      enterChatMode({ conversationUuid });
    }
  }, [conversationUuid]);

  const sortedConversations = useMemo(
    () =>
      data.conversations.length
        ? [...data.conversations].sort((a, b) =>
            compareDesc(new Date(a.createdAt), new Date(b.createdAt)),
          )
        : [],
    [data],
  );

  const enterChatMode = async ({
    conversationUuid,
  }: {
    conversationUuid?: string;
  }): Promise<void> => {
    if (messageMode.mode === "history" && conversationUuid)
      setIsLoadingConversation(true);
    try {
      if (conversationUuid) {
        const conversationResponse = (await request({
          url: `/chats/conversations/${conversationUuid}`,
          method: "GET",
        })) as { data: { data: unknown[] }; status: number };

        const parsedConversationResponse =
          ZConversationResponse.parse(conversationResponse);

        setConversation(
          generateMessageList({
            messages: parsedConversationResponse.data.data.messages,
            id: parsedConversationResponse.data.data.uuid,
          }),
        );
      }

      if (messageMode.mode === "history" && conversationUuid)
        setIsLoadingConversation(false);
      setMessageMode({ mode: "chat", conversationUuid });
      localStorage.setItem("conversationUuid", conversationUuid ?? "");
      dispatch(selectConversation(conversationUuid ?? null));
    } catch (error) {
      if (messageMode.mode === "history" && conversationUuid)
        setIsLoadingConversation(false);
    } finally {
      setIsSendingMessage(false);
    }
  };

  const showHistory = (): void => {
    setMessageMode({ mode: "history", conversationUuid: null });
    dispatch(selectConversation(null));
    setConversation([]);
    resetFormState();
  };

  const submitMessage = async (): Promise<void> => {
    if (!message.value.trim().length) return;
    setIsSendingMessage(true);

    setConversation((prev) => {
      const filteredMessages = prev.filter(
        (message) => message.key !== "error" && message.key !== "new",
      );
      return [
        ...filteredMessages,
        generateMessageList({
          id: "new",
          messages: [{ content: message.value, role: IMessageRoleEnum.User }],
        }),
      ];
    });

    resetFormState();

    try {
      const conversationResponse = await request({
        url: `/chats/conversations${messageMode.conversationUuid ? `/${messageMode.conversationUuid}` : ""}`,
        method: "POST",
        headers: { "Organization-Uuid": organizationUuid },
        body: { message: message.value },
      });

      const parsedConversationResponse =
        ZConversationResponse.parse(conversationResponse);

      if (parsedConversationResponse.status >= 400)
        throw new Error("Failed to send message");

      dispatch(fetchConversations());
      enterChatMode({
        conversationUuid: parsedConversationResponse.data.data.uuid,
      });
    } catch (error) {
      setIsSendingMessage(false);
      setConversation((prev) => [
        ...prev,
        <div key="error" className="flex flex-row gap-2">
          <div className="flex flex-col gap-1">
            <div className="m-[3px] w-[26px] h-[26px] bg-green-400 rounded-full flex items-center justify-center self-start">
              <img src={WhiteLogo} alt="logo" className="w-4 h-auto" />
            </div>
          </div>
          <div className="bg-red-50 py-2 px-5 rounded border border-red-200 flex items-center">
            <Typography color="warning">
              An error occurred, please resend message.
            </Typography>
          </div>
        </div>,
      ]);
    }
  };

  return (
    <div
      id="conversation-box"
      className={`${activeScenarioUuid && "hidden"} fixed z-40 right-12 bottom-4 flex flex-col items-end max-sm:right-0 max-sm:items-center max-sm:w-screen
      ${isChatBoxFocused && "max-sm:bottom-0 max-sm:flex-grow"}`}
    >
      {isOpen && (
        <MessageBox
          isLoading={data.isLoading}
          conversations={sortedConversations}
          messageMode={messageMode}
          conversation={conversation}
          enterChatMode={enterChatMode}
          showHistory={showHistory}
          message={message}
          setMessage={setMessage}
          submitMessage={submitMessage}
          isSendingMessage={isSendingMessage}
          isLoadingConversation={isLoadingConversation}
          chatBoxRef={chatBoxRef}
          isChatBoxFocused={isChatBoxFocused}
        />
      )}
      {isOpen ? (
        <div
          className={`max-sm:w-[90vw] max-sm:flex max-sm:justify-end ${isChatBoxFocused && "max-sm:hidden"}`}
        >
          <div
            data-testid="close-conversation-box"
            onClick={() => dispatch(closeConversationBox())}
            className={`w-fit cursor-pointer p-3 bg-green-400 hover:bg-green-500 rounded-full`}
          >
            <ChevronDownIcon className="size-6 text-white" />
          </div>
        </div>
      ) : (
        <div
          data-testid="open-conversation-box"
          onClick={() => dispatch(openConversationBox())}
          className="w-fit cursor-pointer hover:bg-green-500 flex flex-row gap-2 bg-green-400 py-3 px-4 rounded-full max-sm:w-[80vw] max-sm:justify-center"
        >
          <Typography className="text-white">Ask Parallel</Typography>
          <ChatBubbleOvalLeftIcon className="size-6 text-white" />
          {data.unreadCount > 0 && (
            <Typography
              size="xs"
              className="text-white absolute bg-red-400 rounded-full px-1.5 right-0 bottom-9 max-sm:bottom-9 max-sm:right-11 max-sm:px-2"
              id="unread-count"
            >
              {data.unreadCount > 99 ? "99+" : data.unreadCount}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default ConversationBox;

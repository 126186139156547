import { datadogRum } from "@datadog/browser-rum";

interface IParams {
  user: {
    uuid: string;
    name: string;
    email: string;
  };
  isLoggedIn: boolean;
}

export default ({ user, isLoggedIn }: IParams): void => {
  const {
    VITE_DATADOG_APPLICATION_ID,
    VITE_DATADOG_CLIENT_TOKEN,
    VITE_DATADOG_SITE,
  } = import.meta.env;
  if (
    VITE_DATADOG_APPLICATION_ID &&
    VITE_DATADOG_CLIENT_TOKEN &&
    VITE_DATADOG_SITE &&
    isLoggedIn &&
    user.uuid &&
    import.meta.env.PROD
  ) {
    datadogRum.init({
      applicationId: VITE_DATADOG_APPLICATION_ID,
      clientToken: VITE_DATADOG_CLIENT_TOKEN,
      site: VITE_DATADOG_SITE,
      service: "parallel",
      env: import.meta.env.PROD ? "production" : "development",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: "allow",
    });
    datadogRum.startSessionReplayRecording();

    datadogRum.setUser({
      id: user.uuid,
      name: user.name,
      email: user.email,
    });
  }
};

import React from "react";
import Button from "~/components/Button";
import Modal from "~/components/Modal";
import Typography from "~/components/Typography";

const CancelScenarioModal = ({
  isOpen,
  cancel,
  confirm,
}: {
  isOpen: boolean;
  cancel: () => void;
  confirm: () => void;
}): React.ReactNode => {
  return (
    <Modal
      isOpen={isOpen}
      title="Cancel & Discard Scenario"
      size="xs"
      id="scenario-tray-delete-modal"
    >
      <div>
        <Typography size="sm" color="secondary">
          This will revert all changes made and this scenario will be discarded.
          Are you sure you want to cancel?
        </Typography>
        <div className="w-full flex items-center justify-between mt-5">
          <Button fill="clear" onClick={cancel} className="!w-fit !px-0">
            Continue Editing
          </Button>
          <Button fill="destructive" onClick={confirm} className="!w-auto">
            Confirm, Discard
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelScenarioModal;

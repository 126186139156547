import React from "react";
import TooltipImg from "./TooltipImg";
import Typography from "~/components/Typography";

const FinancialModelTooltip = (): React.ReactElement => {
  return (
    <div className="w-[469px] h-[440px] rounded-xl shadow-md flex flex-col gap-4 items-center bg-white text-center p-10">
      <TooltipImg />
      <Typography size="md" weight="bold">
        Financial Model
      </Typography>
      <Typography size="sm" color="secondary">
        Customize and update key attributes of your financial models here. Use
        these attributes to build and refine your revenue model, override
        formulas with alternate projections, and incorporate actuals to assess
        forecast accuracy.
      </Typography>
      <div className="flex gap-5 items-center">
        <div className="flex items-center gap-2">
          <svg
            width="8"
            height="9"
            viewBox="0 0 8 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="4"
              cy="4.65685"
              r="4"
              transform="rotate(45 4 4.65685)"
              fill="#DC960A"
            />
          </svg>
          <Typography size="sm" color="secondary">
            Actuals
          </Typography>
        </div>
        <div className="flex items-center gap-2">
          <svg
            width="8"
            height="9"
            viewBox="0 0 8 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="4"
              cy="4.65685"
              r="4"
              transform="rotate(45 4 4.65685)"
              fill="#5A8496"
            />
          </svg>
          <Typography size="sm" color="secondary">
            Overrides
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default FinancialModelTooltip;

import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";

export default (dateString?: string): Date => {
  const mockDate = import.meta.env.VITE_MOCK_DATE;
  if (!dateString && mockDate) {
    dateString = mockDate;
  }

  const zonedNow = toZonedTime(new Date(), "UTC");

  const currentDay = Number(format(zonedNow, "dd"));
  const currentMonth = Number(format(zonedNow, "MM"));
  const currentYear = Number(format(zonedNow, "yyyy"));

  const [year, month, day] = dateString
    ? dateString.split("T")[0].split("-").map(Number)
    : [currentYear, currentMonth, currentDay];

  if (!day || !month || !year) throw new Error("Invalid date string");

  return new Date(Date.UTC(year, month - 1, day));
};

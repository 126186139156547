import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface SettingsState {
  showSidebar: boolean;
  isMobile: boolean;
  financialModelExpand: { name: string; expanded: boolean }[];
}

const initialState = {
  showSidebar: false,
  isMobile: false,
  financialModelExpand: [],
} as SettingsState;

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Partial<SettingsState>>) => ({
      ...state,
      ...action.payload,
    }),
    reset: () => initialState,
  },
});

export const { update, reset } = settingsSlice.actions;
export const settings = settingsSlice.actions;
export default settingsSlice.reducer;

import React from "react";
import preciseDifferenceInMonths from "./utils/preciseDifferenceInMonths";
import Typography from "../Typography";
import { SPACE_PER_MONTH } from "./utils/constants";
import date from "~/utils/dates/date";
import { isAfter } from "date-fns";

const TodayIndicator = ({
  startDate,
}: {
  startDate: Date;
}): React.ReactElement => {
  const monthsDifference = preciseDifferenceInMonths({
    startDate,
    endDate: date(),
  });

  /**
   * If today isn't represented on the timeline, don't show the today indicator
   */
  if (isAfter(startDate, date())) {
    return <></>;
  }

  return (
    <div
      className={`absolute z-0 h-full w-[1px] top-0 bg-neutral-100`}
      style={{ left: `${monthsDifference * SPACE_PER_MONTH}rem` }}
    >
      <div className="absolute top-1 -left-6 bg-neutral-15">
        <Typography size="2xs" color="disabled" weight="medium">
          TODAY
        </Typography>
      </div>
    </div>
  );
};

export default TodayIndicator;

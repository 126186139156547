import React, { Fragment, useContext, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import Typography from "~/components/Typography";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PlusIcon,
  TrashIcon,
  PencilSquareIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import { IScenario } from "~/pages/OldDashboard/entity/types";
import Divider from "~/components/Divider";
import request from "~/utils/request";
import toast from "react-hot-toast";
import Modal from "~/components/Modal";
import Button from "~/components/Button";
import { useSelector, useDispatch } from "react-redux";
import { State } from "~/store";
import {
  addScenarioUuid,
  removeScenarioUuid,
  update,
} from "~/store/scenarioSlice";
import { DashboardPageContext } from "../../context/DashboardContext";
import HoverPopover from "~/components/HoverPopover";
import { format } from "date-fns";

interface IGetScenarioResponse {
  status: number;
  data: {
    data: IScenario;
  };
}

const ScenarioMenuDropdown = ({
  scenarios,
  createQuickScenario,
}: {
  scenarios: IScenario[];
  createQuickScenario?: () => Promise<void>;
}): React.ReactElement => {
  const { reload } = useContext(DashboardPageContext);
  const dispatch = useDispatch();
  const { activeScenarioUuid, selectedScenarioUuids } = useSelector(
    (state: State) => state.scenario,
  );
  const [scenarioToDelete, setScenarioToDelete] = useState<IScenario | null>(
    null,
  );

  const handleSelectScenario = (
    scenarioUuid: string,
    close?: () => void,
  ): void => {
    const isSelected = selectedScenarioUuids.includes(scenarioUuid);

    if (isSelected) {
      dispatch(removeScenarioUuid(scenarioUuid));
    } else {
      dispatch(addScenarioUuid(scenarioUuid));
    }

    if (close) close();
  };

  const handleDeleteScenario = async (scenario: IScenario): Promise<void> => {
    const response = await request({
      method: "DELETE",
      url: `/organizations/${scenario.organizationUuid}/scenarios/${scenario.uuid}`,
    });

    if (response.status === 204) {
      if (selectedScenarioUuids.includes(scenario.uuid)) {
        handleSelectScenario(scenario.uuid);
      }
      reload();
      toast.success("Scenario Deleted");
    }
    setScenarioToDelete(null);
  };

  const handleEditScenario = async (scenario: IScenario): Promise<void> => {
    const response: IGetScenarioResponse = await request({
      method: "GET",
      url: `/organizations/${scenario.organizationUuid}/scenarios/${scenario.uuid}`,
    });
    if (response.status !== 200) return;
    const filteredSelectedScenarioUuids = selectedScenarioUuids.filter(
      (uuid) => uuid !== scenario.uuid,
    );
    dispatch(
      update({
        activeScenarioUuid: response.data.data.uuid,
        activeScenarioData: response.data.data,
        activeScenarioHasChanges: false,
        leverChanges: [],
        cashBalanceLockedIndexes: [],
        cashBalanceSelectedPoint: null,
        selectedScenarioUuids: filteredSelectedScenarioUuids,
      }),
    );
    reload();
  };

  const propagateClick = (): void => {
    document.dispatchEvent(new MouseEvent("mousedown", { bubbles: true }));
  };

  return (
    <>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              id="scenario-dropdown-button"
              data-testid="scenario-dropdown-button"
              onClick={propagateClick}
              className="focus:outline-none focus:ring-0 focus:border-transparent"
            >
              <div className="flex text-blue-400 hover:text-green-500 !w-fit !py-[5px] !pl-0 !pr-1 gap-1 items-center justify-center ">
                Scenarios
                {open ? (
                  <ChevronUpIcon width={16} height={16} strokeWidth={2.125} />
                ) : (
                  <ChevronDownIcon width={16} height={16} strokeWidth={2.125} />
                )}
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={`absolute z-10 transform left-0 mt-1 above shadow-lg`}
              >
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 w-[370px] max-sm:max-w-[200px]">
                  <div className="bg-white py-2">
                    <div className="flex items-center justify-between pt-2 px-4 w-full">
                      <Typography size="sm" weight="bold">
                        Scenarios
                      </Typography>
                      <Button
                        className="flex items-center justify-end gap-1 cursor-pointer !w-fit !p-0 max-sm:hidden"
                        onClick={async () => {
                          close();
                          if (createQuickScenario) {
                            await createQuickScenario();
                          }
                        }}
                        fill="clearBlue"
                        disabled={!!activeScenarioUuid}
                        id="new-scenario-button"
                      >
                        <PlusIcon width={16} height={16} />
                        New
                      </Button>
                    </div>
                    <Divider orientation="horizontal" className="mt-3 px-4" />
                    <div
                      className={`max-h-[250px] ${scenarios.length > 4 && "overflow-y-scroll"}`}
                    >
                      {scenarios.map((scenario, index) => (
                        <div
                          className={`w-full flex px-4 justify-between items-center py-2 group ${activeScenarioUuid === scenario.uuid ? "" : "hover:bg-neutral-25"} ${selectedScenarioUuids.includes(scenario.uuid) && "bg-neutral-25"}`}
                          key={scenario.uuid}
                        >
                          <div
                            className={`flex justify-start align-middle items-center gap-[8px] ${activeScenarioUuid === scenario.uuid ? "cursor-default" : " cursor-pointer "} flex-grow`}
                            onClick={() => {
                              if (activeScenarioUuid !== scenario.uuid) {
                                handleSelectScenario(scenario.uuid);
                              }
                            }}
                          >
                            <input
                              data-testid={`scenario-input-${scenario.uuid}`}
                              value={`scenario-input-${scenario.uuid}`}
                              id={`scenario-input-${scenario.uuid}`}
                              type="checkbox"
                              name="role"
                              checked={
                                selectedScenarioUuids.includes(scenario.uuid) ||
                                activeScenarioUuid === scenario.uuid
                              }
                              className="hover:bg-neutral-15 hover:border-green-500 
                              checked:bg-green-400 checked:hover:bg-green-500 checked:focus:bg-green-400 checked:focus:hover:bg-green-500
                              focus:ring-green-400 focus:hover:ring-green-500 cursor-pointer rounded disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:border-neutral-100 disabled:checked:bg-neutral-75"
                              disabled={activeScenarioUuid === scenario.uuid}
                              readOnly
                            />
                            <Typography
                              color={
                                activeScenarioUuid === scenario.uuid
                                  ? "empty"
                                  : "primary"
                              }
                              size="sm"
                              className="sm:group-hover:truncate sm:group-hover:max-w-[225px] sm:truncate"
                            >
                              {scenario.changeDescription}
                            </Typography>
                            {scenario.markedAsStaleAt && (
                              <HoverPopover
                                buttonContent={
                                  <ClockIcon
                                    data-testid={`${scenario.uuid}-scenario-info`}
                                    className="text-neutral-200 cursor-pointer size-5"
                                  />
                                }
                                panelClassName="shadow-xl rounded-xl"
                                panelContent={
                                  <div className="p-4 rounded bg-white max-w-56">
                                    <Typography weight="bold" size="sm">
                                      Scenario Outdated
                                    </Typography>
                                    <br />
                                    <Typography size="xs" color="lightGray">
                                      {`Outdated as of ${format(
                                        new Date(scenario.markedAsStaleAt),
                                        "MM/dd/yyyy",
                                      )}`}
                                    </Typography>
                                    <br />
                                    <Typography size="xs">
                                      Modifications have been made to the base
                                      model since this scenario was created
                                    </Typography>
                                  </div>
                                }
                                anchor={
                                  index === scenarios.length - 1
                                    ? "bottom"
                                    : "top"
                                }
                              />
                            )}
                          </div>
                          {activeScenarioUuid === scenario.uuid ? (
                            <div>
                              <Typography color="blue">
                                {`(In Progress)`}
                              </Typography>
                            </div>
                          ) : (
                            <div className="flex justify-end items-center gap-1 opacity-0 group-hover:opacity-100 max-sm:hidden">
                              {!scenario.markedAsStaleAt && (
                                <HoverPopover
                                  buttonContent={
                                    <PencilSquareIcon
                                      data-testid={`${scenario.uuid}-scenario-edit`}
                                      className="text-neutral-200 cursor-pointer size-5"
                                      onClick={() =>
                                        handleEditScenario(scenario)
                                      }
                                    />
                                  }
                                  panelContent={
                                    <div className="bg-black px-2 py-1 rounded text-white">
                                      Edit
                                    </div>
                                  }
                                  anchor={
                                    index === scenarios.length - 1
                                      ? "bottom"
                                      : "top"
                                  }
                                />
                              )}
                              <HoverPopover
                                buttonContent={
                                  <TrashIcon
                                    data-testid={`${scenario.uuid}-scenario-delete`}
                                    className="text-neutral-200 cursor-pointer size-5"
                                    onClick={() =>
                                      setScenarioToDelete(scenario)
                                    }
                                  />
                                }
                                panelContent={
                                  <div className="bg-black px-2 py-1 rounded text-white">
                                    Delete
                                  </div>
                                }
                                anchor={
                                  index === scenarios.length - 1
                                    ? "bottom"
                                    : "top"
                                }
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <Modal
        isOpen={Boolean(scenarioToDelete)}
        onClose={() => setScenarioToDelete(null)}
        title="Delete Scenario"
        size="xs"
        id="delete-scenario-modal"
      >
        <div>
          <Typography size="sm" color="secondary">
            Deleting a scenario cannot be undone. Are you sure you want to do
            this action?
          </Typography>
          <div className="flex justify-between mt-5">
            <Button
              fill="clear"
              onClick={() => setScenarioToDelete(null)}
              className="!w-auto"
            >
              Close
            </Button>
            <Button
              fill="destructive"
              onClick={() => {
                if (scenarioToDelete) handleDeleteScenario(scenarioToDelete);
              }}
              className="!w-auto"
              id="confirm-delete-scenario"
            >
              Confirm Delete
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ScenarioMenuDropdown;

import React, { useContext, useEffect, useRef } from "react";
import Header from "~/components/Header";
import ExpensesTable from "./components/Expenses/table/ExpensesTable";
import {
  ExpensesPageContext,
  ExpensesPageContextProvider,
} from "~/pages/Expenses/context/ExpensesContext";
import Button from "~/components/Button";
import CreateExpenseModal from "~/pages/Expenses/components/Expenses/createExpense/CreateExpenseModal";
import DiscontinueExpenseModal from "./components/Expenses/DiscontinueExpenseModal";
import DeleteExpenseModal from "./components/DeleteExpenseModal";
import { useExitScenarioListener } from "~/utils/hooks/useExitScenarioListener";
import ExpensesGraph from "./components/Expenses/graph/ExpensesGraph";
import UserDateRange from "~/components/UserDateRange";
import { useSelector } from "react-redux";
import { State } from "~/store";
import Skeleton from "react-loading-skeleton";
import Select from "~/components/Select";
import InputWrapper from "~/components/Input/InputWrapper";
import { isBefore } from "date-fns";
import date from "~/utils/dates/date";
import Checkbox from "~/components/Checkbox";

const ExpensesPage = (): React.ReactNode => {
  const preferences = useSelector((state: State) => state.user.preferences);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);

  const isNotInitialRender = useRef(false);
  const {
    reload,
    setExpenseModal,
    loading,
    showPastExpenses,
    setShowPastExpenses,
    expenses,
    categories,
    types,
    setCategories,
    setTypes,
    searchState,
    setSearchState,
  } = useContext(ExpensesPageContext);
  useExitScenarioListener(reload);

  useEffect(() => {
    if (isNotInitialRender.current) {
      reload();
    } else {
      isNotInitialRender.current = true;
    }
  }, [
    preferences.defaultGraphStartDate,
    preferences.defaultGraphEndDate,
    activeScenarioUuid,
  ]);

  if (loading) {
    return (
      <div className="w-full max-sm:min-h-screen max-sm:pb-32 pb-16">
        <Header title="Expenses" />
        <div className="px-10 mt-10 mb-4">
          <Skeleton className="w-full h-[250px]" baseColor="#F8F9F6" />
        </div>
        <div className="px-10 max-w-full w-full">
          <Skeleton
            height={40}
            count={20}
            className="mb-4"
            baseColor="#F8F9F6"
          />
        </div>
      </div>
    );
  }

  const hasPastExpenses = expenses.some(
    (expense) =>
      expense.context.endDate && isBefore(expense.context.endDate, date()),
  );

  return (
    <div className="w-full max-sm:min-h-screen max-sm:pb-32 pb-16">
      <Header
        title="Expenses"
        endChildren={
          <div className="flex w-full justify-end items-center gap-2">
            <UserDateRange />
            <Button
              fill="outline"
              onClick={() => setExpenseModal(true)}
              id="new-expense-button"
              className="max-sm:hidden"
            >
              Add Expense
            </Button>
          </div>
        }
      />
      <div>
        <div className="w-full flex items-center justify-between px-10 mt-10 mb-4">
          <div className="flex items-center gap-2">
            <div className="w-[200px]">
              <Select
                id="select-category"
                state={categories}
                setState={setCategories}
                placeholder="All Categories"
              />
            </div>
            <div className="w-[150px]">
              <Select
                id="select-type"
                state={types}
                setState={setTypes}
                placeholder="All Types"
              />
            </div>
            {hasPastExpenses && (
              <div className="flex text-nowrap items-center gap-2 mb-1">
                <Checkbox
                  checked={showPastExpenses}
                  toggleValue={() => setShowPastExpenses(!showPastExpenses)}
                  label="Show Past Expenses"
                  id="show-past-employees"
                />
              </div>
            )}
          </div>
          <div className="w-[200px] mb-1">
            <InputWrapper
              id="search-expense-input"
              type="search"
              placeholder="Search Name"
              state={searchState}
              setState={setSearchState}
            />
          </div>
        </div>
        <ExpensesGraph />
        <ExpensesTable />
        <DiscontinueExpenseModal />
        <DeleteExpenseModal />
        <CreateExpenseModal />
      </div>
    </div>
  );
};

const Page = (): React.ReactNode => {
  return (
    <ExpensesPageContextProvider>
      <ExpensesPage />
    </ExpensesPageContextProvider>
  );
};

export default Page;

import React from "react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

interface Props {
  id?: string;
  options: Option[];
  placeTop?: boolean;
  className?: string;
  color?: string;
}

interface Option {
  label: string;
  onClick: () => void;
  className?: string;
  id?: string;
}

const EllipsisDropdown = ({
  id,
  options,
  placeTop,
  className,
  color,
}: Props): React.ReactElement => {
  return (
    <Popover className={className}>
      {({ open, close }) => (
        <>
          <PopoverButton
            as="div"
            className={`cursor-pointer !w-fit bg-white ${color ?? "text-neutral-400"}`}
            data-testid={id ?? ""}
          >
            <EllipsisVerticalIcon className="size-6" />
          </PopoverButton>
          <PopoverPanel
            anchor={placeTop ? "top end" : "bottom end"}
            as="div"
            className={`${
              open ? "block" : "hidden"
            } absolute bg-white shadow-md rounded-md z-40 border-none outline-none`}
          >
            {options.map((option) => (
              <button
                key={option.label}
                className={`
                  block px-4 py-2 w-full text-left outline-none border-none hover:bg-neutral-15
                  ${option.className} 
                `}
                onClick={() => {
                  close();
                  option.onClick();
                }}
                data-testid={id ? id + "-" + option.label : ""}
              >
                {option.label}
              </button>
            ))}
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};

export default EllipsisDropdown;

import React, { useState } from "react";

const useInput = ({
  value,
  valid,
  touched,
  pristine,
  validation,
  errorMessage,
  disabled,
}: Partial<Types.InputState>): [
  value: Types.InputState,
  setState: React.Dispatch<React.SetStateAction<Types.InputState>>,
  resetState: () => void,
] => {
  const initialState: Types.InputState = {
    value: value ?? "",
    valid: valid ?? false,
    touched: touched ?? false,
    pristine: pristine ?? true,
    validation: validation ?? /^.+$/,
    errorMessage: errorMessage ?? "Please enter a valid value",
    disabled: disabled ?? false,
  };
  const [input, setInput] = useState(initialState);
  const resetInput = (): void => setInput(initialState);
  return [input, setInput, resetInput];
};

export default useInput;

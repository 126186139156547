import { z } from "zod";
import {
  ZDashboardLoader,
  ZConversation,
  ZStaticReport,
  ZTimeseriesReport,
  ZScenario,
  ZReportCollection,
  ZTimeseriesReportData,
  ZConsolidatedReportData,
  ZConsolidatedReport,
  ZConsolidatedReportCollection,
  ZGroupedExpensesReport,
  ZItemizedExpenseReportData,
  ZItemizedExpenseByMonthData,
  ZStaticReportData,
  ZMessage,
  ZFormulaExpensesByMonth,
  ZConsolidatedData,
  ZConsolidatedGraphBody,
  ZStaticGraphBody,
  ZStaticGraphData,
  ZConsolidatedGraphData,
  ZGraphData,
} from "./schemas";

export type IDashboardLoader = z.infer<typeof ZDashboardLoader>;
export enum IMessageRoleEnum {
  User = "user",
  Assistant = "assistant",
  System = "system",
  Tool = "tool",
}
export type IMessage = z.infer<typeof ZMessage>;
export type IConversation = z.infer<typeof ZConversation>;
export type IStaticReport = z.infer<typeof ZStaticReport>;
export type IStaticReportData = z.infer<typeof ZStaticReportData>;
export type ITimeseriesReport = z.infer<typeof ZTimeseriesReport>;
export type IScenario = z.infer<typeof ZScenario>;
export type IReportCollection = z.infer<typeof ZReportCollection>;
export type ITimeseriesReportData = z.infer<typeof ZTimeseriesReportData>;
export type IConsolidatedReportsData = z.infer<typeof ZConsolidatedReportData>;
export type IConsolidatedReport = z.infer<typeof ZConsolidatedReport>;
export type IConsolidatedReportCollection = z.infer<
  typeof ZConsolidatedReportCollection
>;
export type IGroupedExpensesReport = z.infer<typeof ZGroupedExpensesReport>;
export type IItemizedExpensesReportData = z.infer<
  typeof ZItemizedExpenseReportData
>;
export type IItemizedExpensesByMonthData = z.infer<
  typeof ZItemizedExpenseByMonthData
>;
export type IFormulaExpensesByMonth = z.infer<typeof ZFormulaExpensesByMonth>;

export type IConsolidatedData = z.infer<typeof ZConsolidatedData>;
export type IConsolidatedGraphBody = z.infer<typeof ZConsolidatedGraphBody>;
export type IStaticGraphBody = z.infer<typeof ZStaticGraphBody>;
export type IStaticGraphData = z.infer<typeof ZStaticGraphData>;
export type IConsolidatedGraphData = z.infer<typeof ZConsolidatedGraphData>;
export type IGraphData = z.infer<typeof ZGraphData>;

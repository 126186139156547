import React, { ReactElement, useContext } from "react";
import { FinancialModelContext } from "../../context/FinancialModelContext";
import quickbooksLogo from "../../../Settings/Integrations/logos/quickbooks.svg";
import xeroLogo from "../../../Settings/Integrations/logos/xero.svg";

const FormulaCell = ({
  onClick,
  valueToDisplay,
  integration,
}: {
  onClick: () => void;
  valueToDisplay: ReactElement[] | string;
  integration?: "quickbooks-online" | "xero";
}): React.ReactNode => {
  const { overridesHaveChanges, actualsHaveChanges } = useContext(
    FinancialModelContext,
  );
  const isTitle = typeof valueToDisplay === "string";

  const accountingLogos: Record<string, ReactElement> = {
    "quickbooks-online": (
      <img src={quickbooksLogo} alt="Quickbooks logo" className="size-5" />
    ),
    xero: <img src={xeroLogo} alt="Xero logo" className="size-[22px]" />,
  };

  return (
    <div
      className={`w-full h-full pl-10 pr-4 flex items-center text-left text-nowrap ${(overridesHaveChanges || actualsHaveChanges) && "!bg-neutral-15 text-neutral-100"}${isTitle && " justify-between"}`}
      onClick={() => {
        if (!overridesHaveChanges && !actualsHaveChanges) {
          onClick();
        }
      }}
      data-testid={
        typeof valueToDisplay === "string" &&
        `formula-cell-title-${valueToDisplay}`
      }
    >
      {valueToDisplay}
      {isTitle && integration && accountingLogos[integration]}
    </div>
  );
};

export default FormulaCell;

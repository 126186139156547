import React from "react";
import toast from "react-hot-toast";
import request from "~/utils/request";
import { SelectState } from "~/components/Select/Select.types";

interface IAPIResponse {
  data: {
    data: unknown[];
  };
  status: number;
}

export const editPosition = async ({
  organizationUuid,
  title,
  setTitle,
  employeeName,
  setEmployeeName,
  selectDepartment,
  setSelectDepartment,
  successCallback,
  scenarioUuid,
  setIsSubmitting,
  awaitCalculations,
  editPositionUuid,
}: {
  editPositionUuid: string;
  organizationUuid: string;
  title: Types.InputState;
  setTitle: React.Dispatch<React.SetStateAction<Types.InputState>>;
  employeeName: Types.InputState;
  setEmployeeName: React.Dispatch<React.SetStateAction<Types.InputState>>;
  selectDepartment: SelectState;
  setSelectDepartment: React.Dispatch<React.SetStateAction<SelectState>>;
  successCallback: () => void;
  scenarioUuid?: string;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  awaitCalculations?: boolean;
}): Promise<void> => {
  setIsSubmitting(true);

  try {
    if (!!selectDepartment.selected?.value && title.valid) {
      const updatedValues: {
        title?: string;
        employeeName?: string;
        departmentUuid?: string;
      } = {};
      if (title.value) {
        updatedValues.title = title.value;
      }
      if (employeeName.value) {
        updatedValues.employeeName = employeeName.value;
      }
      if (selectDepartment.selected?.value) {
        updatedValues.departmentUuid = selectDepartment.selected?.value;
      }
      const editPositionResponse = (await request({
        method: "PATCH",
        url: `/organizations/${organizationUuid}/positions/${editPositionUuid}`,
        body: updatedValues,
        params: {
          scenarioUuid: scenarioUuid ?? undefined,
          awaitCalculations: awaitCalculations ?? undefined,
        },
      })) as IAPIResponse;

      if (editPositionResponse.status === 201) {
        toast.success(
          () => (
            <div data-testid="position-update-successful">Position updated</div>
          ),
          { position: "top-center" },
        );
        successCallback();
      } else {
        throw new Error("Failed to create position");
      }
    } else {
      throw new Error("Invalid form data");
    }
  } catch (error) {
    toast.error("Failed to create position");
    setIsSubmitting(false);
    setSelectDepartment((prevState) => ({
      ...prevState,
      valid: !!prevState.selected?.value,
      pristine: false,
      touched: true,
    }));
    setTitle((prevState) => ({
      ...prevState,
      pristine: false,
      touched: true,
    }));
    setEmployeeName((prevState) => ({
      ...prevState,
      pristine: false,
      touched: true,
    }));
  }
};

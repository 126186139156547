import { useState } from "react";
import { useSelector } from "react-redux";
import { State } from "~/store";
import useInput from "~/components/Input/useInput";

const useChatBoxState = (): {
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  organizationUuid: string;
  message: Types.InputState;
  setMessage: React.Dispatch<React.SetStateAction<Types.InputState>>;
  resetFormState: () => void;
} => {
  const [errorMessage, setErrorMessage] = useState("");
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const [message, setMessage] = useInput({
    validation: /^.*$/s,
  });
  const CHARACTER_LIMIT = 10000;

  const updateMessage: React.Dispatch<
    React.SetStateAction<Types.InputState>
  > = (value) => {
    setMessage((prev) => {
      const newValue = typeof value === "function" ? value(prev) : value;

      if (newValue.value.length <= CHARACTER_LIMIT) {
        return newValue;
      } else {
        setErrorMessage(`Message cannot exceed ${CHARACTER_LIMIT} characters.`);
        return { ...prev, value: prev.value };
      }
    });
  };

  const resetFormState = (): void => {
    setMessage({
      ...message,
      value: "",
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
  };

  return {
    errorMessage,
    setErrorMessage,
    organizationUuid,
    message,
    setMessage: updateMessage,
    resetFormState,
  };
};

export default useChatBoxState;

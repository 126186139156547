import React, { ReactElement, useContext, useMemo } from "react";
import BaseTable from "~/components/Table/Base/BaseTable";
import {
  Row,
  SortingFn,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  sortingFns,
  useReactTable,
} from "@tanstack/react-table";
import { HeadcountContext } from "../../context/HeadcountContext";
import Cell from "./Cell";
import { IInitialValue } from "./Cell/entities/types";
import Typography from "~/components/Typography";
import { useSelector } from "react-redux";
import { State } from "~/store";
import TotalCostColumnHeader from "./TotalCostColumnHeader";
import { isAfter, isBefore } from "date-fns";
import { z } from "zod";
import HeadcountGraphs from "../HeadcountGraphs";
import useQueryParams from "~/utils/hooks/useQueryParams";
import Skeleton from "react-loading-skeleton";

const HeadcountTable = (): ReactElement => {
  const { renderedPositions, pageLoading, showTermedPositions } =
    useContext(HeadcountContext);
  const activeScenarioUuid = useSelector(
    (state: State) => state.scenario.activeScenarioUuid,
  );
  const columnHelper = createColumnHelper<Record<string, IInitialValue>>();
  const [queryParams] = useQueryParams();
  const selectedDepartment = queryParams.get("departments");

  const alphabeticalSortingFn: SortingFn<IInitialValue> = (
    rowA: Row<IInitialValue>,
    rowB: Row<IInitialValue>,
    columnId: string,
  ) => {
    const ZColumnId = z.union([
      z.literal("title"),
      z.literal("employeeName"),
      z.literal("departmentUuid"),
    ]);

    interface IRowSort {
      original: Record<
        "title" | "employeeName" | "departmentUuid",
        {
          value: string | null;
        }
      >;
    }

    const parsedColumnId = ZColumnId.parse(columnId);

    const parsedRowA = rowA as unknown as IRowSort;
    const parsedRowB = rowB as unknown as IRowSort;

    const valueA = parsedRowA.original[parsedColumnId].value ?? "";

    const valueB = parsedRowB.original[parsedColumnId].value ?? "";

    return valueA.localeCompare(valueB);
  };

  const dateSortingFn: SortingFn<IInitialValue> = (
    rowA: Row<IInitialValue>,
    rowB: Row<IInitialValue>,
  ) => {
    if ("hireDate" in rowA.original && "hireDate" in rowB.original) {
      const ZSortableRow = z.object({
        value: z.string(),
      });

      const parsedRowA = ZSortableRow.parse(rowA.original.hireDate);
      const valueA = new Date(parsedRowA.value);
      const parsedRowB = ZSortableRow.parse(rowB.original.hireDate);
      const valueB = new Date(parsedRowB.value);

      if (valueA === valueB) {
        return 0;
      }
      return isBefore(valueA, valueB) ? 1 : -1;
    }
    return 0;
  };

  const desiredColumns = [
    {
      id: "employeeName",
      label: "Employee",
      className: "w-full text-left ml-2.5 text-nowrap",
      sortingFn: alphabeticalSortingFn,
      enableSorting: true,
    },
    {
      id: "title",
      label: "Title",
      className: "w-full text-left ml-2.5 text-nowrap",
      sortingFn: alphabeticalSortingFn,
      enableSorting: true,
    },
    {
      id: "departmentUuid",
      label: "Department",
      className: "w-full text-left text-nowrap",
      sortingFn: alphabeticalSortingFn,
      enableSorting: true,
    },
    {
      id: "payRate",
      label: "Pay Rate",
      className: "w-full text-right text-nowrap",
    },
    {
      id: "fullyBurdenedCost",
      label: "Total Cost",
      className: "flex w-full justify-end items-end text-nowrap",
    },
    {
      id: "hireDate",
      label: "Hire Date",
      className: "w-full text-left text-nowrap",
      sortingFn: dateSortingFn,
      enableSorting: true,
    },
    {
      id: "termDate",
      label: "Term Date",
      className: "w-full text-left text-nowrap",
    },
    { id: "options", label: "", className: "w-full text-left text-nowrap" },
  ];

  if (activeScenarioUuid) {
    desiredColumns.unshift({
      id: "isActive",
      label: "",
      className: "w-full text-left text-nowrap",
    });
  }

  const { tableData = [], tableColumns } = useMemo(() => {
    const tableColumns = desiredColumns.map((col) =>
      columnHelper.accessor(col.id, {
        enableResizing: false,
        enablePinning: false,
        enableSorting: col.enableSorting ?? false,
        header: () => (
          <div className={col.className}>
            {col.id === "fullyBurdenedCost" ? (
              <TotalCostColumnHeader />
            ) : (
              col.label.toUpperCase()
            )}
          </div>
        ),
        cell: (cellContext) => <Cell cellContext={cellContext} />,
        sortingFn: col.sortingFn ?? sortingFns.alphanumeric,
      }),
    );

    let tableData: Record<string, IInitialValue>[] = [];
    if (renderedPositions.length) {
      let filteredPositions = renderedPositions;
      if (selectedDepartment && selectedDepartment !== "all") {
        filteredPositions = filteredPositions.filter(
          (position) => position.departmentUuid === selectedDepartment,
        );
      }
      tableData = filteredPositions
        .sort(
          (a, b) =>
            new Date(b.hireDate).getTime() - new Date(a.hireDate).getTime(),
        )
        .map((position) => {
          const attributeDict = {} as Record<string, IInitialValue>;

          attributeDict.payRate = {
            value: position.currentPayRate.value.toString(),
            positionUuid: position.positionUuid,
            changeHistory: position.payRates,
            effectiveAt: position.currentPayRate.effectiveAt,
            upcomingChange: position.payRates.some(
              (payRate) =>
                payRate.effectiveAt !== "onHire" &&
                isAfter(
                  payRate.effectiveAt,
                  position.currentPayRate.effectiveAt === "onHire"
                    ? position.hireDate
                    : position.currentPayRate.effectiveAt,
                ),
            ),
          };

          attributeDict.employeeName = {
            value: position.employeeName,
            positionUuid: position.positionUuid,
          };

          attributeDict.departmentUuid = {
            value: position.currentDepartment.name,
            positionUuid: position.positionUuid,
          };

          attributeDict.title = {
            value: position.title,
            positionUuid: position.positionUuid,
          };

          attributeDict.hireDate = {
            value: position.hireDate,
            positionUuid: position.positionUuid,
            maxDate: position.termDate ? position.termDate : undefined,
          };

          attributeDict.termDate = {
            value: position.termDate,
            positionUuid: position.positionUuid,
            minDate: position.hireDate,
          };

          attributeDict.isActive = {
            value: position.isActive.toString(),
            positionUuid: position.positionUuid,
            positionIsFilled: position.employeeName !== null,
          };

          attributeDict.fullyBurdenedCost = {
            value: position.fullyBurdenedCost?.toString() ?? null,
            positionUuid: position.positionUuid,
          };

          attributeDict.options = {
            value: "",
            positionUuid: position.positionUuid,
          };

          return attributeDict;
        });
    }

    return { tableData, tableColumns };
  }, [
    renderedPositions,
    activeScenarioUuid,
    selectedDepartment,
    showTermedPositions,
  ]);

  const table = useReactTable({
    columns: tableColumns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [{ id: "hireDate", desc: true }],
    },
  });

  const emptyState = (
    <div className="my-6">
      <Typography color="empty">No positions found</Typography>
    </div>
  );

  if (pageLoading) {
    return (
      <div
        className="max-w-full overflow-scroll hide-scrollbar h-full px-10 pb-96"
        data-testid="headcount-page-loading"
      >
        <div className="flex items-center justify-between gap-4 mb-8 h-[243px] min-w-[1200px]">
          <Skeleton height={243} width={1000} count={1} baseColor="#F8F9F6" />
          <Skeleton height={243} width={500} count={1} baseColor="#F8F9F6" />
        </div>
        <Skeleton height={40} count={20} className="mb-4" baseColor="#F8F9F6" />
      </div>
    );
  }

  return (
    <div className="max-w-full overflow-scroll hide-scrollbar h-full px-10 pb-96">
      <HeadcountGraphs />
      <BaseTable
        styles={{
          table: "w-full",
          th: "px-4 py-2 text-xs font-normal text-neutral-200",
          td: "h-14 px-4 border-t border-b border-gray-200",
        }}
        emptyState={emptyState}
        table={table}
        id="headcount-table"
      />
    </div>
  );
};

export default HeadcountTable;

import { IFormula, IFormulaSegment, IVariables } from "../entity/types";
import isEqual from "lodash.isequal";

const validateFormula = ({
  formulaToValidate,
  formulaList,
  expression,
  recipeVariables,
  formulaUuid,
}: {
  formulaToValidate: IFormulaSegment[];
  formulaList: IFormula[];
  expression: string;
  recipeVariables: IVariables;
  formulaUuid?: string;
}): { validated: boolean; errorMessage: string } => {
  const disallowedCombinations = [
    "++",
    "+/",
    "+)",
    "+*",
    "*/",
    "**",
    "*)",
    "*+",
    "/+",
    "/*",
    "/)",
    "//",
    "(+",
    "(*",
    "(/",
    "()",
    "-+",
    "-*",
    "-/",
    "-)",
  ];

  for (const combination of disallowedCombinations) {
    if (expression.includes(combination)) {
      return { validated: false, errorMessage: "This is not a valid formula" };
    }
  }

  if (!/^[-($]/.test(expression)) {
    return { validated: false, errorMessage: "This is not a valid formula" };
  }

  if (/[-+*/]{3,}/.test(expression)) {
    return { validated: false, errorMessage: "This is not a valid formula" };
  }

  if (!expression.length || !Object.keys(recipeVariables).length) {
    return { validated: false, errorMessage: "This is not a valid formula" };
  }

  if (/[+\-*/(]$/.test(expression)) {
    return { validated: false, errorMessage: "This is not a valid formula" };
  }

  const hasCircularDependency = (
    currentFormulaUuid: string | null,
    targetUuid: string,
    visited: Set<string> = new Set(),
  ): boolean => {
    if (!currentFormulaUuid || visited.has(currentFormulaUuid)) {
      return false;
    }
    visited.add(currentFormulaUuid);

    const formula = formulaList.find((f) => f.uuid === currentFormulaUuid);
    if (!formula) return false;

    const formulaVariables = formula.recipe.variables;
    const variableValues = Object.values(formulaVariables);

    for (const variable of variableValues) {
      if (
        variable.formulaUuid === targetUuid &&
        isEqual(variable.timeModifier, {})
      ) {
        return true;
      }
      if (
        variable.formulaUuid &&
        isEqual(variable.timeModifier, {}) &&
        hasCircularDependency(variable.formulaUuid, targetUuid, visited)
      ) {
        return true;
      }
    }

    return false;
  };

  for (const segment of formulaToValidate) {
    if (segment.type === "formula" && formulaUuid) {
      const segmentFormula = recipeVariables[segment.textValue];
      if (
        segmentFormula.type === "self" &&
        isEqual(segmentFormula.timeModifier, {})
      ) {
        return {
          validated: false,
          errorMessage: "This creates a circular dependency",
        };
      }
      if (
        isEqual(segmentFormula.timeModifier, {}) &&
        hasCircularDependency(segmentFormula.formulaUuid, formulaUuid)
      ) {
        return {
          validated: false,
          errorMessage: "This creates a circular dependency",
        };
      }
    } else if (segment.type === "invalid") {
      return { validated: false, errorMessage: "This is not a valid formula" };
    }
  }

  let previousType = null;
  for (const segment of formulaToValidate) {
    if (["constant", "calculated", "formula"].includes(segment.type)) {
      if (
        previousType &&
        ["constant", "calculated", "formula"].includes(previousType)
      ) {
        return {
          validated: false,
          errorMessage:
            "Must have an operator between two constants, formulas, or calculated variables",
        };
      }
      previousType = segment.type;
    } else {
      previousType = null;
    }
  }

  return { validated: true, errorMessage: "" };
};

export default validateFormula;

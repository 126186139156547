import React from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

const useQueryParams = (): [
  URLSearchParams,
  (newParams: Record<string, string>) => void,
] => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const queryParams = React.useMemo(
    () => new URLSearchParams(search),
    [search],
  );

  const setQueryParams = (newParams: Record<string, string>): void => {
    navigate(
      {
        pathname: window.location.pathname,
        search: createSearchParams(newParams).toString(),
      },
      { replace: true },
    );
  };

  return [queryParams, setQueryParams];
};

export default useQueryParams;

export const moveArrayElement = ({
  groupMode,
  groups,
  fromIndex,
  toIndex,
}: {
  groupMode: boolean;
  groups: { name: string; sortOrder: string[] }[];
  fromIndex: number;
  toIndex: number;
}): string[] => {
  const flattenedGroups = groups.reduce((output, group): string[] => {
    output.push(group.name);
    output.push(...group.sortOrder);
    return output;
  }, [] as string[]);

  if (fromIndex === toIndex) return flattenedGroups;

  if (
    fromIndex < 0 ||
    fromIndex >= flattenedGroups.length ||
    toIndex < 0 ||
    toIndex >= flattenedGroups.length
  ) {
    throw new Error("Index out of bounds");
  }

  if (groupMode) {
    const fromElement = groups[fromIndex];
    const groupToMove = groups.find((group) => group.name === fromElement.name);
    if (!groupToMove) throw new Error("Group not found");

    const elementsToMove = [groupToMove.name, ...groupToMove.sortOrder];

    // Remove elements to move
    const newArray = flattenedGroups.filter(
      (element) => !elementsToMove.includes(element),
    );

    const groupIndexWithinFlattenedArray = groups
      .filter(({ name }) => groupToMove.name != name)
      .reduce((output, group, index) => {
        if (index < toIndex) {
          // Length of sort order for the group itself, not minus 1 because we are moving the group itself
          output += group.sortOrder.length + 1;
        }
        return output;
      }, 0);

    // Insert elements at new position
    newArray.splice(groupIndexWithinFlattenedArray, 0, ...elementsToMove);
    return newArray;
  } else {
    const element = flattenedGroups[fromIndex];
    const newArray = flattenedGroups.slice();
    newArray.splice(fromIndex, 1);
    newArray.splice(toIndex, 0, element);

    return newArray;
  }
};

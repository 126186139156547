import React, { ReactElement, useContext, useState } from "react";
import { IInitialValue } from "../entities/types";
import { HeadcountContext } from "~/pages/Headcount/context/HeadcountContext";
import DeletePositionModal from "../../../DeletePositionModal";
import { CellContext } from "@tanstack/react-table";
import EllipsisDropdown from "~/components/EllipsisDropdown";
import { useSelector } from "react-redux";
import { State } from "~/store";

const CellOptions = ({
  cellContext,
  initialValue,
}: {
  cellContext: CellContext<Record<string, IInitialValue>, IInitialValue>;
  initialValue: IInitialValue;
}): ReactElement => {
  const { deletePosition, setOpenTermDateRowIndex, deleteIsLoading } =
    useContext(HeadcountContext);
  const activeScenarioUuid = useSelector(
    (state: State) => state.scenario.activeScenarioUuid,
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleAddTerm = (): void => {
    const termDateElementParent: HTMLElement | null = document.querySelector(
      `[data-testid="termDate-${cellContext.row.index}-container"]`,
    );
    if (termDateElementParent) {
      termDateElementParent.click();
    }
    setOpenTermDateRowIndex(cellContext.row.index);
  };

  const handlePayChange = (): void => {
    const payRateElement: HTMLElement | null = document.querySelector(
      `[data-testid="payRate-${cellContext.row.index}"]`,
    );

    if (payRateElement) {
      payRateElement.click();
    }
  };

  const handleClickDelete = (): void => {
    if (activeScenarioUuid) {
      deletePosition({
        positionUuid: initialValue.positionUuid,
      });
    } else {
      setIsDeleteModalOpen(true);
    }
  };

  return (
    <div>
      <DeletePositionModal
        id="delete-position-modal"
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={() =>
          deletePosition({
            positionUuid: initialValue.positionUuid,
          })
        }
        deleteIsLoading={deleteIsLoading}
      />
      <EllipsisDropdown
        options={[
          {
            label: "Pay Change",
            onClick: handlePayChange,
          },
          {
            label: "Term",
            onClick: handleAddTerm,
          },
          {
            label: "Delete",
            onClick: () => handleClickDelete(),
            className: "text-red-500",
          },
        ]}
        id={`${cellContext.column.id}-${cellContext.row.index}`}
      />
    </div>
  );
};

export default CellOptions;

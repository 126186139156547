import store from "~/store";
import request from "~/utils/request";
import { IAPIResponse } from "~/utils/types";
import { z } from "zod";
import { setDepartments } from "~/store/organizationSlice";

const ZDepartmentsResponseData = z.object({
  data: z.array(
    z.object({
      uuid: z.string().uuid(),
      name: z.string(),
      organizationUuid: z.string(),
      createdAt: z.string(),
      updatedAt: z.string(),
    }),
  ),
});

export const refreshDepartments = async (): Promise<void> => {
  const state = store.getState();
  const dispatch = store.dispatch;
  const organizationUuid = state.organization.uuid;
  const activeScenarioUuid = state.scenario.activeScenarioUuid;

  const response = (await request({
    url: `/organizations/${organizationUuid}/groups`,
    method: "GET",
    params: {
      scenarioUuid: activeScenarioUuid,
    },
  })) as IAPIResponse;

  if (response.status >= 400) throw new Error("Failed to fetch departments");

  const parsedDepartments = ZDepartmentsResponseData.parse(response.data);
  dispatch(setDepartments(parsedDepartments.data));
};

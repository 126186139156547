import React, { useEffect, useMemo, useRef } from "react";
import { IConsolidatedGraphData } from "../../entity/types";
import LineGraph from "~/components/LineGraph";
import { ILineProps } from "~/components/LineGraph/entity/types";
import date from "~/utils/dates/date";
import { format, isSameMonth } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { State } from "~/store";
import { reassignSelectedIndexesForNewDate } from "~/store/scenarioSlice";

const DashboardGraphs = ({
  consolidatedReports,
}: {
  consolidatedReports: IConsolidatedGraphData;
}): React.ReactNode => {
  const dispatch = useDispatch();

  const [userActiveIndex, setUserActiveIndex] = React.useState<number>(-1);
  const cashBalanceReport = useMemo(() => {
    if (Object.keys(consolidatedReports).length) {
      return consolidatedReports["Cash Balance"];
    } else return null;
  }, [consolidatedReports]);

  //   const { pageLoading } = useContext(DashboardPageContext);

  const { preferences } = useSelector((state: State) => state.user);
  const isNotInitialRender = useRef(false);

  const currentMonthIndex = useMemo(() => {
    if (cashBalanceReport?.data.length) {
      const currentDate = date();
      return cashBalanceReport.data.findIndex((item) =>
        isSameMonth(new Date(item.date), currentDate),
      );
    }
    return -1;
  }, [cashBalanceReport]);

  const dataKeys = useMemo(() => {
    if (cashBalanceReport?.data.length) {
      const applicableKeys = [
        "date",
        "workingModel",
        "activeScenario",
        "scenario1",
        "scenario2",
        "scenario3",
      ];
      return Object.keys(cashBalanceReport.data[0]).filter((key) =>
        applicableKeys.includes(key),
      );
    } else return [];
  }, [cashBalanceReport]);
  const lines: ILineProps[] = [
    { dataKey: "workingModel", stroke: "#64755C" },
    { dataKey: "activeScenario", stroke: "#406F83", isDashed: true },
    { dataKey: "scenario1", stroke: "#EBA61E", isDashed: true },
    { dataKey: "scenario2", stroke: "#8A6190", isDashed: true },
    { dataKey: "scenario3", stroke: "#45A59F", isDashed: true },
  ];
  const filteredLines = useMemo(() => {
    return lines.filter((line) => dataKeys.includes(line.dataKey));
  }, [dataKeys, lines]);

  useEffect(() => {
    if (isNotInitialRender.current) {
      if (preferences.defaultGraphStartDate)
        dispatch(
          reassignSelectedIndexesForNewDate(
            new Date(preferences.defaultGraphStartDate),
          ),
        );
    } else {
      isNotInitialRender.current = true;
    }
  }, [preferences.defaultGraphStartDate, preferences.defaultGraphEndDate]);

  const generatedGraphs = useMemo(() => {
    if (Object.keys(consolidatedReports).length) {
      return Object.entries(consolidatedReports).map(([key, value]) => {
        return (
          <div key={key} className="max-h-[300px] min-h-[230px] h-[18dvw] ">
            <LineGraph
              id={key}
              key={`${key}-${Math.random()}`}
              data={value.data}
              dataKeys={dataKeys}
              lines={filteredLines}
              card={{
                title: value.title,
                figure:
                  userActiveIndex !== -1 || currentMonthIndex !== -1
                    ? value.data[
                        userActiveIndex !== -1
                          ? userActiveIndex
                          : currentMonthIndex
                      ]?.workingModel?.toString() ?? "0"
                    : undefined,
                month:
                  userActiveIndex !== -1 || currentMonthIndex !== -1
                    ? format(
                        new Date(
                          value.data[
                            userActiveIndex !== -1
                              ? userActiveIndex
                              : currentMonthIndex
                          ].date,
                        ),
                        "MMM",
                      ).toUpperCase()
                    : undefined,
              }}
              isCashGraph
              externalActiveIndex={userActiveIndex}
              setExternalActiveIndex={setUserActiveIndex}
            />
          </div>
        );
      });
    } else return null;
  }, [consolidatedReports, dataKeys]);

  return (
    <div className="flex flex-col gap-4 h-full w-full">{generatedGraphs}</div>
  );
};

export default DashboardGraphs;

import { format } from "date-fns";
import { z } from "zod";
import request from "~/utils/request";
import { IExpense } from "../types";
import { ZExpense } from "../entity/schemas";

interface IAPIResponse {
  data: {
    data: unknown[];
  };
  status: number;
}

const createExpense = async ({
  scenarioDiffing,
  organizationUuid,
  scenarioUuid,
  expenseUuid,
  type,
  name,
  category,
  frequency,
  amount,
  departments,
  startDate,
  endDate,
  successCallback,
  failureCallback,
  validateFormState,
}: {
  scenarioDiffing: boolean;
  organizationUuid: string;
  scenarioUuid: string | null;
  expenseUuid:
    | { uuid: string; expenseUuid: string; type: "edit" | "create" }
    | undefined;
  type: string | null;
  name: string;
  category: string | null;
  frequency: string | null;
  amount: string;
  departments: (string | null | undefined)[] | null;
  startDate: Date | null;
  endDate: Date | null;
  successCallback: ({ createdExpense }: { createdExpense: IExpense }) => void;
  failureCallback: () => void;
  validateFormState: () => void;
}): Promise<void> => {
  validateFormState();

  const ZValidateExpenseRequestData = z
    .object({
      organizationUuid: z.string(),
      scenarioUuid: z.string().nullable(),
      type: z.string(),
      name: z.string().min(1),
      category: z.string(),
      frequency: z.string(),
      amount: z
        .string()
        .min(1)
        .pipe(z.coerce.number())
        .transform((val) => val * 100),
      departments: z.array(z.string()).nonempty(),
      startDate: z
        .date()
        .transform((val) => format(val, "yyyy-MM-dd"))
        .nullable(),
      endDate: z
        .date()
        .nullable()
        .transform((val) => {
          if (val === null) {
            return null;
          }
          return format(val, "yyyy-MM-dd");
        })
        .nullable(),
    })
    .refine(
      (val) => {
        if (val.type === "headcountPercentCompensation" && val.amount > 10000) {
          return false;
        }
        return true;
      },
      {
        message: "Amount must not exceed 10,000 for the specified type.",
        path: ["amount"],
      },
    );

  const { data: parsedData, success } = ZValidateExpenseRequestData.safeParse({
    organizationUuid,
    scenarioUuid,
    type,
    name,
    category,
    frequency,
    amount: amount,
    departments,
    startDate,
    endDate,
  });

  if (!success) {
    console.error("Failed to validate expense data.");
    failureCallback();
    return;
  }

  const updateBody = {
    name: parsedData.name,
    context: {
      driver: parsedData.type,
      tag: parsedData.category,
      frequency: parsedData.frequency,
      amount: parsedData.amount,
      departments: parsedData.departments,
      startDate: parsedData.startDate,
      endDate: parsedData.endDate,
    },
  };

  let response;
  if (expenseUuid && expenseUuid.type === "edit") {
    response = (await request({
      method: "PATCH",
      url: `/expenses/${scenarioDiffing ? expenseUuid.expenseUuid : expenseUuid.uuid}`,
      headers: { "Organization-Uuid": organizationUuid },
      body: updateBody,
      params: { scenarioUuid: scenarioUuid },
    })) as IAPIResponse;
  } else {
    response = (await request({
      method: "POST",
      url: `/expenses/`,
      headers: { "Organization-Uuid": organizationUuid },
      body: updateBody,
      params: { scenarioUuid: scenarioUuid },
    })) as IAPIResponse;
  }
  const parsedResponseData = ZExpense.parse(response.data.data);
  if ([200, 201].includes(response.status)) {
    successCallback({ createdExpense: parsedResponseData });
  } else {
    failureCallback();
  }
};

export default createExpense;

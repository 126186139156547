import React, { ReactElement, useContext, useEffect, useState } from "react";
import { IInitialValue } from "../entities/types";
import { CellContext } from "@tanstack/react-table";
import Switch from "~/components/Switch";
import request from "~/utils/request";
import { IAPIResponse } from "~/utils/types";
import { State } from "~/store";
import { useSelector } from "react-redux";
import updateScenarioTray from "~/components/ScenarioTray/updateScenarioTray";
import { StatusCodes } from "http-status-codes";
import toast from "react-hot-toast";
import { HeadcountContext } from "~/pages/Headcount/context/HeadcountContext";
import type { IPositionDetails } from "~/pages/Headcount/entity/types";

const TogglePositionCell = ({
  initialValue,
  cellContext,
}: {
  cellContext: CellContext<Record<string, IInitialValue>, IInitialValue>;
  initialValue: IInitialValue;
}): ReactElement => {
  const { renderedPositions, setRenderedPositions, positions, setPositions } =
    useContext(HeadcountContext);
  const [positionEnabled, setPositionEnabled] = useState(
    cellContext.row.original.isActive.value === "true",
  );
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);

  useEffect(() => {
    const updatedValue = cellContext.row.original.isActive.value === "true";
    if (updatedValue !== positionEnabled) {
      setPositionEnabled(updatedValue);
    }
  }, [cellContext.row.original.isActive.value]);

  const togglePositionActiveInScenario = async (
    checked: boolean,
  ): Promise<void> => {
    setPositionEnabled(checked);
    const response = (await request({
      method: "PATCH",
      url: `/organizations/${organizationUuid}/positions/${initialValue.positionUuid}`,
      params: {
        scenarioUuid: activeScenarioUuid ?? undefined,
      },
      body: {
        isActive: checked,
      },
    })) as IAPIResponse;

    if (response.status !== StatusCodes.CREATED) {
      setPositionEnabled(!checked);
      toast.error("Failed to make changes");
    } else {
      if (positions) {
        setPositions(
          (positions as IPositionDetails[]).map((position) => {
            if (position.positionUuid === initialValue.positionUuid) {
              return { ...position, isActive: checked };
            }
            return position;
          }) as typeof positions,
        );
      }

      if (renderedPositions.length) {
        setRenderedPositions(
          (renderedPositions as IPositionDetails[]).map((position) => {
            if (position.positionUuid === initialValue.positionUuid) {
              return { ...position, isActive: checked };
            }
            return position;
          }) as typeof renderedPositions,
        );
      }
      updateScenarioTray();
    }
  };

  return (
    <div data-testid={`${cellContext.column.id}-${cellContext.row.index}`}>
      {!initialValue.positionIsFilled && (
        <Switch
          id={`is-active-switch-${initialValue.positionUuid}`}
          checked={positionEnabled}
          onChange={togglePositionActiveInScenario}
        />
      )}
    </div>
  );
};

export default TogglePositionCell;

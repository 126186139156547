import React, { useEffect, useCallback, useState } from "react";

const useHover = (): [
  React.RefObject<HTMLElement>,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
] => {
  const [hovering, setHovering] = useState(false);
  const ref = React.useRef<HTMLElement>(null);

  const handleMouseEnter = useCallback((): void => {
    setHovering(true);
  }, []);

  const handleMouseLeave = useCallback((): void => {
    setHovering(false);
  }, []);

  useEffect(() => {
    const node = ref.current;

    if (node) {
      node.addEventListener("mouseenter", handleMouseEnter);
      node.addEventListener("mouseleave", handleMouseLeave);

      return (): void => {
        node.removeEventListener("mouseenter", handleMouseEnter);
        node.removeEventListener("mouseleave", handleMouseLeave);
      };
    }

    return undefined;
  }, [handleMouseEnter, handleMouseLeave]);

  return [ref, hovering, setHovering];
};

export default useHover;

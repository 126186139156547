import { formatDate } from "date-fns";
import React from "react";
import {
  IDataArrayDictionary,
  ILineProps,
} from "~/components/LineGraph/entity/types";
import Typography from "~/components/Typography";
import formatToTextAbbreviatedCurrency from "~/utils/formatToTextAbbreviatedCurrency";
import { camelCaseToNormal } from "~/utils/text/camelCaseToNormal";

interface IProps {
  active?: boolean;
  payload?: { payload: IDataArrayDictionary }[];
  label?: number;
  lines: ILineProps[];
}

const ExpensesGraphTooltip = ({
  active,
  payload,
  label,
  lines,
}: IProps): React.ReactElement | null => {
  const monthFormatter = (value: number | null): string => {
    if (!value) return "";
    return formatDate(value, "MMM yyyy");
  };

  if (active && payload?.length) {
    return (
      <div className="bg-white ml-2 rounded-xl py-1 px-4">
        <Typography color="empty" size="xs">
          {monthFormatter(label ?? null)}
        </Typography>
        <div className="flex flex-col items-center justify-between gap-2">
          {lines.map((line: ILineProps, index: number) => (
            <div
              key={line.dataKey}
              className="flex items-center justify-between gap-2 w-full"
            >
              <div className="flex items-center gap-2">
                <div
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: line.stroke }}
                />
                <Typography color="secondary" size="sm">
                  {camelCaseToNormal(line.dataKey)}
                </Typography>
              </div>
              <Typography size="sm" weight="bold" className="mr-1">
                {payload[index]
                  ? formatToTextAbbreviatedCurrency({
                      value: payload[index].payload[line.dataKey] ?? "0",
                      decimal: 1,
                    })
                  : "$0"}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

export default ExpensesGraphTooltip;

import React from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import HoverPopover from "~/components/HoverPopover";
import Typography from "~/components/Typography";

const TotalCostColumnHeader = (): React.ReactNode => {
  const buttonContent = (
    <div className="flex items-center justify-start gap-2">
      <div>TOTAL COST</div>
      <InformationCircleIcon className="size-4" />
    </div>
  );

  const panelContent = (
    <div className="px-4 py-4 bg-white w-[300px] flex flex-col justify-start items-start gap-4">
      <div>
        <Typography weight="bold" size="sm">
          Total Cost / Fully Burdened Cost
        </Typography>
      </div>
      <div>
        <Typography size="sm">
          The total cost of an employee includes their pay rate, benefits, and
          any other costs associated with their employment
        </Typography>
      </div>
    </div>
  );

  return (
    <HoverPopover
      buttonContent={buttonContent}
      panelContent={panelContent}
      panelClassName="shadow-lg rounded-xl"
    />
  );
};

export default TotalCostColumnHeader;

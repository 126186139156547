import React, { ReactElement, useContext, useEffect, useRef } from "react";
import { IInitialValue } from "../entities/types";
import { CellContext } from "@tanstack/react-table";
import Input, { useInput } from "~/components/Input/InputWrapper";
import { State } from "~/store";
import request from "~/utils/request";
import { z } from "zod";
import { useSelector } from "react-redux";
import { HeadcountContext } from "~/pages/Headcount/context/HeadcountContext";
import updateScenarioTray from "~/components/ScenarioTray/updateScenarioTray";
import toast from "react-hot-toast";
import { StatusCodes } from "http-status-codes";
import {
  ZPositionDetails,
  ZPositionDetailsWithOrderedDates,
} from "~/pages/Headcount/entity/schemas";

const inputValidation: Record<string, RegExp> = {
  employeeName: /^.*$/,
  title: /.+/,
};

const ZUpdateAttributeValueResponse = z.object({
  data: z.object({
    data: ZPositionDetails,
  }),
  status: z.number(),
});

const CellInput = ({
  initialValue,
  cellContext,
}: {
  initialValue: IInitialValue;
  cellContext: CellContext<Record<string, IInitialValue>, IInitialValue>;
}): ReactElement => {
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const { setRenderedPositions, setPositions } = useContext(HeadcountContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useInput({
    value: initialValue.value?.toString() ?? "",
    validation: inputValidation[cellContext.column.id],
    disabled: cellContext.row.original.isActive.value === "false",
  });
  const onBlur = async (): Promise<void> => {
    if (value.value !== initialValue.value && value.valid) {
      try {
        const updatedValueResponse = await request({
          method: "PATCH",
          url: `/organizations/${organizationUuid}/positions/${initialValue.positionUuid}`,
          params: {
            scenarioUuid: activeScenarioUuid ?? undefined,
          },
          body: {
            [cellContext.column.id]: value.value,
          },
        });

        const parsedResponse =
          ZUpdateAttributeValueResponse.parse(updatedValueResponse);

        if (parsedResponse.status === StatusCodes.CREATED) {
          setRenderedPositions((prev) => {
            const newPositions = prev.map((p) =>
              p.positionUuid === initialValue.positionUuid
                ? { ...parsedResponse.data.data, orderedDate: p.orderedDate }
                : p,
            );
            return ZPositionDetailsWithOrderedDates.array().parse(newPositions);
          });
          setPositions((prev) => {
            const newPositions = prev.map((p) =>
              p.positionUuid === initialValue.positionUuid
                ? { ...parsedResponse.data.data, orderedDate: p.orderedDate }
                : p,
            );
            return ZPositionDetailsWithOrderedDates.array().parse(newPositions);
          });
          updateScenarioTray();
        } else {
          toast.error("Failed to update value");
          setValue((prev) => ({
            ...prev,
            value: initialValue.value?.toString() ?? "",
          }));
        }
      } catch (error) {
        setValue((prev) => ({
          ...prev,
          value: initialValue.value?.toString() ?? "",
        }));
      }
    }
  };

  useEffect(() => {
    setValue((prev) => ({
      ...prev,
      disabled: cellContext.row.original.isActive.value === "false",
    }));
  }, [cellContext.row.original.isActive]);

  return (
    <div className="min-w-32">
      <Input
        id={`${cellContext.column.id}-${cellContext.row.index}`}
        type="text"
        onBlur={onBlur}
        state={value}
        setState={setValue}
        textRef={inputRef}
        className="!border-transparent hover:!border hover:!border-neutral-100 !shadow-none"
      />
    </div>
  );
};

export default CellInput;

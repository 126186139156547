import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTimelineContext } from "./TimelineContext";
import preciseDifferenceInMonths from "./utils/preciseDifferenceInMonths";
import { SPACE_PER_MONTH } from "./utils/constants";
import { addDays, addMonths, format, getDaysInMonth } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import Typography from "../Typography";

interface IProps {
  id: string;
  startDate: Date;
  endDate?: Date;
  children: React.ReactNode | React.ReactNode[];
}

const TimelineNode = ({
  id,
  startDate,
  endDate,
  children,
}: IProps): React.ReactElement => {
  const context = useTimelineContext();
  const [scrollPositionOnStartDrag, setScrollPositionOnStartDrag] = useState(
    context.scrollPosition,
  );
  const [dragStartLocation, setDragStartLocation] = useState(0);
  const lastDragCalculation = useRef(0);
  const [draggingDates, setDraggingDates] = useState<[Date, Date | undefined]>([
    startDate,
    endDate,
  ]);

  useEffect(() => {
    setDraggingDates([startDate, endDate]);
  }, [startDate]);

  /**
   * Cross browser drag image
   */
  const img = document.createElement("img");
  img.src =
    "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

  const handleDragStart = (e: React.DragEvent<HTMLButtonElement>): void => {
    e.dataTransfer.setDragImage(img, 0, 0);
    lastDragCalculation.current = 0;
    setDragStartLocation(e.clientX);
  };

  const handleDrag = (e: React.DragEvent<HTMLButtonElement>): void => {
    // Skip calculations if the mouse hasn't moved
    if (e.clientX === lastDragCalculation.current) return;
    // The last drag event returns a clientX of 0, so we need to skip that
    if (e.clientX === 0) return;

    lastDragCalculation.current = e.clientX;

    const REM_BASE = 16;
    const amountScrolled = context.scrollPosition - scrollPositionOnStartDrag;
    const monthsDelta =
      (e.clientX - dragStartLocation + amountScrolled) /
      (SPACE_PER_MONTH * REM_BASE); // convert pixels to months

    let updatedStartDate = startDate;
    const updatedStartDateMonth = addMonths(startDate, Math.floor(monthsDelta));
    const portionOfStartDateMonth = monthsDelta - Math.floor(monthsDelta);
    const daysInStartDateMonth = getDaysInMonth(updatedStartDateMonth);

    if (portionOfStartDateMonth > 0) {
      updatedStartDate = addDays(
        updatedStartDateMonth,
        daysInStartDateMonth * portionOfStartDateMonth,
      );
    }

    /**
     * End date offset should be calculated based on the start date offset
     * so that they move in unison.
     */
    let updatedEndDate = endDate;
    if (updatedEndDate) {
      const updatedEndDateMonth = addMonths(
        updatedEndDate,
        Math.floor(monthsDelta),
      );
      if (portionOfStartDateMonth > 0) {
        updatedEndDate = addDays(
          updatedEndDateMonth,
          daysInStartDateMonth * portionOfStartDateMonth,
        );
      }
    }

    setDraggingDates([updatedStartDate, updatedEndDate]);
  };

  const handleDragEnd = (): void => {
    setScrollPositionOnStartDrag(context.scrollPosition);
    context.onChange({
      elementId: id,
      startDate: draggingDates[0],
      endDate: draggingDates[1],
      refreshData: false,
    });
  };

  const leftOffset = useMemo(
    (): number =>
      preciseDifferenceInMonths({
        startDate: context.startDate,
        endDate: draggingDates[0],
      }),
    [context.startDate, draggingDates],
  );

  const lengthOfNode = useMemo(
    (): number =>
      preciseDifferenceInMonths({
        // overriding the start date so that the node can be dragged and be updated on drag complete
        startDate: draggingDates[0],
        // Add a day to the end date so that it can look right by butting up against the correct date
        endDate: draggingDates[1]
          ? addDays(draggingDates[1], 1)
          : addDays(context.endDate, 1),
      }),
    [context.endDate, draggingDates],
  );

  return (
    <div
      className={`relative bg-white rounded-lg ml-3 p-2 z-10 border flex group items-center${!draggingDates[1] ? " rounded-r-none border-r-0" : ""}`}
      style={{
        marginLeft: `${leftOffset * SPACE_PER_MONTH}rem`,
        width: `${lengthOfNode * SPACE_PER_MONTH}rem`,
      }}
    >
      <div className="absolute -left-14">
        <Typography size="xs" color="disabled">
          {format(toZonedTime(draggingDates[0], "UTC"), "MMM d")}
        </Typography>
      </div>
      <button
        className="w-full overflow-clip cursor-ew-resize"
        draggable
        onDragStart={handleDragStart}
        onDrag={(e) => handleDrag(e)}
        onDragEnd={() => handleDragEnd()}
      >
        {children}
      </button>
      {draggingDates[1] && (
        <div className="absolute -right-14">
          <Typography size="xs" color="disabled">
            {format(toZonedTime(draggingDates[1], "UTC"), "MMM d")}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default TimelineNode;

import Header from "~/components/Header";
import Typography from "~/components/Typography";
import Divider from "~/components/Divider";
import React from "react";
import Departments from "./Departments";
import Users from "./Users";
import Integrations from "./Integrations";
import { useFeatureFlag } from "~/utils/hooks/useFeatureFlag";
import Personalization from "./Personalization";

const SettingsContainer = (): React.ReactNode => {
  const integrationsEnabled = useFeatureFlag("integrations");
  const personalizationEnabled = useFeatureFlag("personalSettingsEnabled");

  return (
    <div className="w-full h-full flex flex-col">
      <Header title="Settings" />
      <div className="flex w-full h-full justify-center px-10 mb-10">
        <div className="max-w-[880px] w-full flex flex-col !items-start">
          <Typography tag="h1" size="xl" weight="bold" className="mb-5 mt-10">
            Manage Departments
          </Typography>
          <div className="w-full mb-10">
            <Departments />
            {integrationsEnabled && <Integrations />}
            <Divider className="mt-10" />
            <Users />
            {personalizationEnabled && (
              <div>
                <Divider className="mt-10" />
                <Personalization />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsContainer;

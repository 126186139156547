import { combineReducers, configureStore } from "@reduxjs/toolkit";

import authReducer, { AuthState } from "./authSlice";
import organizationReducer, { IOrganizationState } from "./organizationSlice";
import userReducer, { UserState } from "./userSlice";
import scenarioReducer, { ScenarioState } from "./scenarioSlice";
import settingsReducer, { SettingsState } from "./settingsSlice";
import hydrationReducer, { HydrationState } from "./hydrationSlice";
import socketReducer, { SocketState } from "./socketSlice";
import chatReducer, { ChatState } from "./chatSlice";

export interface State {
  _sockets: SocketState;
  _hydration: HydrationState;
  auth: AuthState;
  user: UserState;
  organization: IOrganizationState;
  scenario: ScenarioState;
  settings: SettingsState;
  chat: ChatState;
}

const reducer = combineReducers({
  _sockets: socketReducer,
  _hydration: hydrationReducer,
  auth: authReducer,
  user: userReducer,
  organization: organizationReducer,
  scenario: scenarioReducer,
  settings: settingsReducer,
  chat: chatReducer,
});

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

let previousHydratedState = store.getState()._hydration.hydrated;

store.subscribe(() => {
  const currentHydratedState = store.getState()._hydration.hydrated;
  if (!previousHydratedState && currentHydratedState) {
    window.dispatchEvent(new Event("reduxHydrated"));
  }
  previousHydratedState = currentHydratedState;
});

export type AppDispatch = typeof store.dispatch;
export default store;

import { useEffect } from "react";

export const useExitScenarioListener = (reload: () => void): void => {
  useEffect(() => {
    const handleExitScenarioEvent = (): void => {
      reload();
    };
    window.addEventListener("exitScenarioModeEvent", handleExitScenarioEvent);

    return () => {
      window.removeEventListener(
        "exitScenarioModeEvent",
        handleExitScenarioEvent,
      );
    };
  }, [reload]);
};

import React, { useRef, useEffect } from "react";

interface Props {
  checked: boolean;
  isIndeterminate?: boolean;
  toggleValue: () => void;
  className?: string;
  id?: string;
  label?: string;
}

const Checkbox = ({
  checked,
  isIndeterminate,
  toggleValue,
  className,
  id,
  label,
}: Props): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = !!isIndeterminate;
    }
  }, [isIndeterminate]);
  return (
    <label
      htmlFor={id}
      className={`flex items-center ml-2 cursor-pointer ${className}`}
    >
      <input
        id={id}
        data-testid={id}
        checked={checked}
        onChange={toggleValue}
        className={`cursor-pointer border border-green-400 rounded hover:bg-neutral-15 hover:border-green-500 indeterminate:bg-green-400 indeterminate:hover:bg-green-500 indeterminate:focus:bg-green-500 checked:bg-green-400 checked:hover:bg-green-500 checked:focus:bg-green-500 focus:ring-green-500 size-4`}
        type="checkbox"
        ref={inputRef}
      />
      {label && <span className="ml-2">{label}</span>}
    </label>
  );
};

export default Checkbox;

import React, { ReactElement, useContext, useEffect } from "react";
import { IInitialValue } from "../entities/types";
import { CellContext } from "@tanstack/react-table";
import { useSelect } from "~/components/Select";
import SelectDepartment from "~/components/SelectDepartment";
import request from "~/utils/request";
import { useSelector } from "react-redux";
import { z } from "zod";
import { State } from "~/store";
import updateScenarioTray from "~/components/ScenarioTray/updateScenarioTray";
import toast from "react-hot-toast";
import { StatusCodes } from "http-status-codes";
import { HeadcountContext } from "~/pages/Headcount/context/HeadcountContext";
import { fetchSinglePosition } from "~/pages/Headcount/utils/fetchSinglePosition";
import { ZPositionDetails } from "~/pages/Headcount/entity/schemas";

const ZUpdateAttributeValueResponse = z.object({
  data: z.object({
    data: ZPositionDetails,
  }),
  status: z.number(),
});

const CellSelect = ({
  initialValue,
  cellContext,
}: {
  initialValue: IInitialValue;
  cellContext: CellContext<Record<string, IInitialValue>, IInitialValue>;
}): ReactElement => {
  const { renderedPositions, setRenderedPositions, positions, setPositions } =
    useContext(HeadcountContext);

  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const [selectDepartment, setSelectDepartment] = useSelect({
    selected: {
      value: initialValue.value?.toString() ?? "",
      label: initialValue.value?.toString() ?? "",
    },
    options: [],
  });

  const onBlur = async (): Promise<void> => {
    if (
      selectDepartment.selected?.value &&
      selectDepartment.selected.label !== initialValue.value?.toString()
    ) {
      try {
        const updatedValueResponse = await request({
          method: "PATCH",
          url: `/organizations/${organizationUuid}/positions/${initialValue.positionUuid}`,
          params: {
            scenarioUuid: activeScenarioUuid ?? undefined,
          },
          body: {
            [cellContext.column.id]: selectDepartment.selected.value,
          },
        });

        const parsedResponse =
          ZUpdateAttributeValueResponse.parse(updatedValueResponse);

        if (
          parsedResponse.status === StatusCodes.CREATED &&
          renderedPositions.length
        ) {
          const updatedPosition = await fetchSinglePosition({
            positionUuid: initialValue.positionUuid,
            organizationUuid,
            scenarioUuid: activeScenarioUuid ?? undefined,
          });
          const updateIndex = renderedPositions.findIndex(
            (position) => position.positionUuid === initialValue.positionUuid,
          );

          const updatedPositionIndex = positions?.findIndex(
            (position) => position.positionUuid === initialValue.positionUuid,
          );

          if (
            updatedPositionIndex !== undefined &&
            updatedPositionIndex !== -1 &&
            positions
          ) {
            const updatedPositions = [...positions];
            updatedPositions[updatedPositionIndex] = {
              ...updatedPosition,
              orderedDate: updatedPosition.hireDate,
            };
            setPositions(updatedPositions);
          }
          if (updateIndex !== -1) {
            const updatedPositions = [...renderedPositions];
            updatedPositions[updateIndex] = {
              ...updatedPosition,
              orderedDate: updatedPosition.hireDate,
            };
            setRenderedPositions(updatedPositions);
          }
          updateScenarioTray();
        } else {
          toast.error("Failed to update department");
          setSelectDepartment((prev) => ({
            ...prev,
            selected: {
              label: prev.selected?.label ?? "",
              value: initialValue.value?.toString() ?? "",
            },
          }));
        }
      } catch (error) {
        toast.error("Failed to update department");
        setSelectDepartment((prev) => ({
          ...prev,
          selected: {
            label: prev.selected?.label ?? "",
            value: initialValue.value?.toString() ?? "",
          },
        }));
      }
    }
  };

  useEffect(() => {
    if (selectDepartment.selected?.value !== initialValue.value?.toString()) {
      onBlur();
    }
  }, [selectDepartment.selected]);

  const isActive = cellContext.row.original.isActive.value === "true";

  const disabledStyles = isActive ? "" : " text-neutral-100 cursor-default";

  return (
    <div
      className="w-44 group"
      data-testid={`${cellContext.column.id}-${cellContext.row.index}`}
    >
      <div
        className={`text-nowrap overflow-hidden text-ellipsis ${disabledStyles} group-hover:hidden`}
      >
        {selectDepartment.selected?.label ?? "-"}
      </div>
      <div
        className={`hidden group-hover:block`}
        data-testid={`${cellContext.column.id}-${cellContext.row.index}-selector`}
      >
        <SelectDepartment
          departmentOptions={selectDepartment}
          setDepartmentOptions={setSelectDepartment}
          id={`department-${cellContext.row.index}`}
        />
      </div>
    </div>
  );
};

export default CellSelect;

import React from "react";
import Skeleton from "react-loading-skeleton";
import Card from "~/components/Card";
import Typography from "~/components/Typography";

const EmptyGraph = ({ loading }: { loading: boolean }): React.ReactElement => {
  if (loading) {
    return <Skeleton className="h-full w-full" baseColor="#F8F9F6" />;
  }

  return (
    <Card className="!px-5 !md:px-5 !py-4 !md:py-4 h-full  justify-center items-center">
      <Typography size="sm" color="disabled">
        No data available
      </Typography>
    </Card>
  );
};

export default EmptyGraph;

import React from "react";
import Input from "~/components/Input/InputWrapper";
import Typography from "~/components/Typography";

interface CurrencyInputProps {
  id?: string;
  state: Types.InputState;
  setState: React.Dispatch<React.SetStateAction<Types.InputState>>;
  label?: string;
  required?: boolean;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  id,
  state,
  setState,
  label,
  required = false,
}) => {
  const showingError = state.touched && !state.pristine && !state.valid;
  return (
    <label className="flex flex-col justify-start w-full">
      {label && (
        <div className="flex flex-row">
          <Typography
            size="xs"
            color={state.disabled ? "disabled" : "primary"}
            className="mb-1"
            id={id ? `${id}-label` : undefined}
          >
            {label}
          </Typography>
          {required && (
            <Typography
              tag="span"
              size="2xs"
              color={state.disabled ? "disabled" : "primary"}
            >
              *
            </Typography>
          )}
        </div>
      )}
      <div className="relative">
        <div
          className={`h-[42px] z-10 absolute flex justify-center p-2 bg-neutral-25 border border-solid border-gray-300 rounded-l shadow-sm text-neutral-200${
            state.disabled ? " bg-neutral-25 text-neutral-100" : " bg-gray-15"
          }${showingError ? " border-red-300" : ""}`}
        >
          {"$"}
        </div>
        <Input
          id={id ? `${id}-currency` : "currency"}
          type="currency"
          className="w-full pl-10"
          state={state}
          setState={setState}
        />
      </div>
    </label>
  );
};

export default CurrencyInput;

import React from "react";
import { ClockIcon } from "@heroicons/react/24/outline";
import HoverPopover from "~/components/HoverPopover";

const UpcomingChangePopover = ({ id }: { id: string }): React.ReactNode => {
  return (
    <div data-testid={id}>
      <HoverPopover
        buttonContent={
          <div className="w-fit bg-green-400 rounded-full p-1 cursor-pointer">
            <ClockIcon className="size-4 text-white" />
          </div>
        }
        panelContent={<div>Upcoming change</div>}
        panelClassName="bg-white w-fit text-nowrap !no-underline !hover:no-underline text-black text-xs border-0 shadow-lg px-6 py-4 rounded-lg"
      />
    </div>
  );
};

export default UpcomingChangePopover;

import React from "react";
import toast from "react-hot-toast";
import request from "~/utils/request";
import { IFormula } from "~/pages/FinancialModel/entity/types";
import { RadioInputState } from "~/components/RadioInput/RadioInput.types";
import { IAPIResponse } from "~/utils/types";
import { IRatio } from "../entity/types";

interface IProps {
  startDate: string;
  endDate: string;
  organizationUuid: string;
  activeScenarioUuid: string | null;
  positionNeeded: Types.InputState;
  amount: Types.InputState;
  drivenByValue: Types.InputState;
  driverType: RadioInputState;
  formulasList: IFormula[];
  setPositionNeeded: React.Dispatch<React.SetStateAction<Types.InputState>>;
  setAmount: React.Dispatch<React.SetStateAction<Types.InputState>>;
  setDrivenByValue: React.Dispatch<React.SetStateAction<Types.InputState>>;
  setDriverType: React.Dispatch<React.SetStateAction<RadioInputState>>;
  successCallback: ({
    createdRatio,
    editedRatioUuid,
  }: {
    createdRatio: IRatio;
    editedRatioUuid: string | null;
  }) => void;
  editRatioUuid: string | null;
  scenarioDiffing?: boolean;
}

const generateTarget = ({
  driverType,
  drivenByValue,
  formulasList,
  scenarioDiffing,
}: {
  driverType?: string;
  drivenByValue: string;
  formulasList: IFormula[];
  scenarioDiffing?: boolean;
}): string => {
  if (driverType === "position") {
    return drivenByValue;
  } else if (driverType === "formula") {
    const formulaUuid = scenarioDiffing
      ? formulasList.find((formula) => formula.recipe.name === drivenByValue)
          ?.formulaUuid
      : formulasList.find((formula) => formula.recipe.name === drivenByValue)
          ?.uuid;
    if (formulaUuid) {
      return formulaUuid;
    }
  }
  return "";
};

export default async ({
  startDate,
  endDate,
  organizationUuid,
  activeScenarioUuid,
  positionNeeded,
  amount,
  drivenByValue,
  driverType,
  formulasList,
  setPositionNeeded,
  setAmount,
  setDrivenByValue,
  setDriverType,
  successCallback,
  editRatioUuid,
  scenarioDiffing,
}: IProps): Promise<void> => {
  const isDrivenByValid =
    driverType.selected?.value &&
    drivenByValue.value &&
    ((driverType.selected.value === "modelAttribute" &&
      drivenByValue.valid &&
      !!formulasList.find(
        (formula) => formula.recipe.name === drivenByValue.value,
      )) ||
      (driverType.selected.value === "position" && drivenByValue.valid));
  try {
    if (
      !!driverType.selected?.value &&
      amount.valid &&
      positionNeeded.valid &&
      isDrivenByValid
    ) {
      const ratioToCreate = {
        startDate,
        endDate,
        drivenElement: {
          target: positionNeeded.value.trim(),
          type: "position",
          value: 1,
        },
        drivingElements: [
          {
            target: generateTarget({
              driverType:
                driverType.selected.value === "modelAttribute"
                  ? "formula"
                  : driverType.selected.value,
              drivenByValue: drivenByValue.value,
              formulasList,
            }),
            type:
              driverType.selected.value === "modelAttribute"
                ? "formula"
                : driverType.selected.value,
            value: Number(amount.value),
            scenarioDiffing,
          },
        ],
      };

      let response;
      if (editRatioUuid) {
        response = (await request({
          method: "PATCH",
          url: `/ratios/${editRatioUuid}`,
          body: ratioToCreate,
          params: {
            scenarioUuid: activeScenarioUuid ?? undefined,
          },
          headers: {
            "Organization-Uuid": organizationUuid,
          },
        })) as IAPIResponse<IRatio>;
      } else {
        response = (await request({
          method: "POST",
          url: "/ratios",
          body: ratioToCreate,
          params: {
            scenarioUuid: activeScenarioUuid ?? undefined,
          },
          headers: {
            "Organization-Uuid": organizationUuid,
          },
        })) as IAPIResponse<IRatio>;
      }

      if (response.status === 201) {
        successCallback({
          createdRatio: response.data.data,
          editedRatioUuid: editRatioUuid,
        });
        toast.success("Ratio created");
      } else if (response.status === 200) {
        successCallback({
          createdRatio: response.data.data,
          editedRatioUuid: editRatioUuid,
        });
        toast.success("Ratio updated");
      } else {
        throw new Error("Failed to create ratio");
      }
    } else {
      throw new Error("Invalid form data");
    }
  } catch (error) {
    toast.error("Failed to create ratio");
    setPositionNeeded((prevState) => ({
      ...prevState,
      pristine: false,
      touched: true,
    }));
    setAmount((prevState) => ({
      ...prevState,
      pristine: false,
      touched: true,
    }));
    setDrivenByValue((prevState) => ({
      ...prevState,
      pristine: false,
      touched: true,
    }));
    setDriverType((prevState) => ({
      ...prevState,
      pristine: false,
      touched: true,
    }));
  }
};

import React from "react";
import Card from "~/components/Card";
import Typography from "~/components/Typography";
import { IFormulaExpensesByMonth } from "../../entity/types";
import formatCurrency from "~/utils/formatCurrency";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

interface IProps {
  selectedMonthsGroupedExpenses: IFormulaExpensesByMonth[];
}

const ExpenseDrilldownContainer = ({
  selectedMonthsGroupedExpenses,
}: IProps): React.ReactNode => {
  const totalExpenseImpact = selectedMonthsGroupedExpenses.find(
    (formula) => formula.name === "Expenses",
  )?.impact;
  const expensesSortedByImpact = selectedMonthsGroupedExpenses
    .slice()
    .sort((a, b) => {
      if (b.impact !== a.impact) {
        return b.impact - a.impact; // Sort in descending order
      } else {
        return a.name.localeCompare(b.name);
      }
    });

  const totalCalculatedImpact = selectedMonthsGroupedExpenses.reduce(
    (acc, expense) => {
      if (expense.name !== "Expenses") {
        acc += expense.impact;
      }

      return acc;
    },
    0,
  );

  const sumsMatch = totalExpenseImpact === totalCalculatedImpact;

  return (
    <div
      className={`flex items-start justify-between gap-4 w-full mt-6 ${sumsMatch ? " mb-16" : " mb-8"}  max-sm:flex-col`}
      data-testid="expense-drilldown-container"
    >
      <div className="w-1/2 max-sm:w-full">
        <div className="flex items-center mb-2">
          <Typography size="sm" weight="bold" className="mr-1">
            Expense Categories
          </Typography>
          <Typography
            size="sm"
            color="empty"
            id="expense-drilldown-category-month"
          >
            {format(
              toZonedTime(expensesSortedByImpact[0].date, "UTC"),
              "MMM yyyy",
            )}
          </Typography>
        </div>
        <Card>
          <div className="flex justify-between w-full">
            <div className="w-full flex flex-col">
              {expensesSortedByImpact.map((expense) => {
                if (expense.name === "Expenses") return null;

                return (
                  <div
                    className="flex justify-between mb-2 gap-2"
                    key={expense.name}
                    data-testid={`expense-drilldown-category-${expense.name}`}
                  >
                    <Typography
                      size="sm"
                      className="text-nowrap overflow-hidden text-ellipsis"
                    >
                      {expense.name}
                    </Typography>
                    <div className="flex justify-between text-right gap-2">
                      <div className="flex justify-end w-fit">
                        <Typography
                          size="sm"
                          weight="thin"
                          className="text-nowrap overflow-hidden text-ellipsis"
                        >
                          {formatCurrency(expense.impact, false)}
                        </Typography>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Card>
        {!sumsMatch && (
          <div className="flex flex-row gap-1.5 mt-2 items-center">
            <ExclamationTriangleIcon className="size-5 text-red-500" />
            <Typography color="warning">
              {`Category actuals don't sum to the total`}{" "}
              <a
                href="https://www.loom.com/share/b8620f4cc8ce4a4ab56efeaa2bec3d2c?sid=c1835b91-00aa-4831-9683-b0d770b02b24"
                target="_blank"
                rel="noopener noreferrer"
                className="underline underline-offset-4 cursor-pointer hover:text-red-600"
              >
                Learn More
              </a>
            </Typography>
          </div>
        )}
      </div>
      <div className="w-1/2 max-sm:w-full">
        <div className="flex items-center mb-2">
          <Typography size="sm" weight="bold" className="mr-1">
            Expense MoM Change
          </Typography>
          <Typography size="sm" color="empty" id="expense-drilldown-mom-month">
            {format(
              toZonedTime(expensesSortedByImpact[0].date, "UTC"),
              "MMM yyyy",
            )}
          </Typography>
        </div>
        <Card>
          <div className="w-full">
            {expensesSortedByImpact.map((expense) => {
              if (expense.name === "Expenses") return null;
              return (
                <div
                  className="w-full flex items-center justify-between mb-2"
                  key={expense.name}
                  data-testid={`expense-drilldown-mom-${expense.name}`}
                >
                  <Typography
                    size="sm"
                    className="text-nowrap overflow-hidden text-ellipsis"
                  >
                    {expense.name}
                  </Typography>
                  <Typography>
                    {formatCurrency(expense.monthOverMonthDollar, false)}
                  </Typography>
                </div>
              );
            })}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ExpenseDrilldownContainer;

import React, { createContext, useMemo, ReactElement, useState } from "react";
import useQueryParams from "~/utils/hooks/useQueryParams";
import { ZDashboardLoader } from "../entity/schemas";
import { IScenario } from "~/pages/OldDashboard/entity/types";
import useDashboardData from "../useDashboardData";
import { IGraphData } from "../entity/types";

interface IProps {
  children: React.ReactNode;
}

interface IDashboardPageContext {
  reports: IGraphData;
  scenarios: IScenario[];
  queryParams: URLSearchParams | null;
  setQueryParams: (newParams: Record<string, string>) => void;
  reload: () => Promise<void>;
  pageLoading: boolean;
  scenarioCreationLoading: boolean;
  setScenarioCreationLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialContext: IDashboardPageContext = {
  reports: {} as IGraphData,
  scenarios: [],
  queryParams: null,
  setQueryParams: () => {},
  reload: async () => {},
  pageLoading: true,
  scenarioCreationLoading: false,
  setScenarioCreationLoading: () => {},
};

export const DashboardPageContext =
  createContext<IDashboardPageContext>(initialContext);

export const DashboardPageContextProvider = ({
  children,
}: IProps): ReactElement => {
  const [queryParams, setQueryParams] = useQueryParams();
  const [scenarioCreationLoading, setScenarioCreationLoading] =
    useState<boolean>(false);

  const { data, revalidate, loading } = useDashboardData();
  const { reportData, scenarios } = useMemo(() => {
    if (!data)
      return {
        ...initialContext,
        reportData: {} as IGraphData,
      };
    return ZDashboardLoader.parse(data);
  }, [data]);

  return (
    <DashboardPageContext.Provider
      value={{
        reports: reportData,
        scenarios,
        queryParams,
        setQueryParams,
        reload: revalidate,
        pageLoading: loading,
        scenarioCreationLoading,
        setScenarioCreationLoading,
      }}
    >
      {children}
    </DashboardPageContext.Provider>
  );
};

import React, { useEffect, useState } from "react";
import { useLoaderData, useRevalidator } from "react-router-dom";
import Typography from "~/components/Typography";
import quickbooksLogo from "../logos/quickbooks.svg";
import request from "~/utils/request";
import { useSelector } from "react-redux";
import { State } from "~/store";
import MergeLink from "./MergeLink";
import StatusChip, { IChipStatus } from "./StatusChip";
import { Channel } from "pusher-js";
import xeroLogo from "../logos/xero.svg";
import { ReactElement } from "react-markdown/lib/react-markdown";
import { format } from "date-fns";

const IntegrationsList: React.FC = () => {
  const revalidator = useRevalidator();
  const { organization, _sockets } = useSelector((state: State) => state);
  const [mergeLinkToken, setMergeLinkToken] = useState<string | null>(null);
  const {
    integrations: { sources, connected },
  } = useLoaderData() as {
    integrations: {
      sources: {
        uuid: string;
        name: string;
        service: string;
      }[];
      connected: {
        sourceUuid: string;
        lastSyncAttempt: Date | null;
        lastSyncSuccess: Date | null;
        isCredentialsValid: boolean;
      }[];
    };
  };

  useEffect(() => {
    let channel: Channel | null = null;
    if (_sockets.pusher) {
      channel = _sockets.pusher.subscribe(organization.uuid);
      channel.bind("integration-update", () => {
        revalidator.revalidate();
      });
    }
    return () => {
      if (channel) channel.disconnect();
    };
  }, [_sockets.pusher, organization.uuid]);

  useEffect(() => {
    const populateIntegrations = async (): Promise<void> => {
      const integrationsList = sources.map((source) => {
        return {
          ...source,
        };
      });

      const linkUrlResponse = (await request({
        url: `/integrations/link`,
        method: "POST",
        headers: {
          "Organization-Uuid": organization.uuid,
        },
        body: {
          integrationSourceUuid: integrationsList[0].uuid,
        },
      })) as {
        data: {
          data: {
            token: string;
          };
        };
      };

      if (linkUrlResponse.data.data.token) {
        setMergeLinkToken(linkUrlResponse.data.data.token);
      }
    };
    populateIntegrations();
  }, [sources]);

  const refreshLinkToken = async ({
    uuid,
  }: {
    uuid: string;
  }): Promise<void> => {
    const linkUrlResponse = (await request({
      url: `/integrations/link`,
      method: "POST",
      headers: {
        "Organization-Uuid": organization.uuid,
      },
      body: {
        integrationSourceUuid: uuid,
      },
    })) as {
      data: {
        data: {
          token: string;
        };
      };
    };

    if (linkUrlResponse.data.data.token) {
      setMergeLinkToken(linkUrlResponse.data.data.token);
    }
  };

  const mergeIntegrationConnector = (): React.ReactNode => {
    const accountingLogos: Record<string, ReactElement> = {
      Quickbooks: <img src={quickbooksLogo} alt="Quickbooks logo" />,
      Xero: <img src={xeroLogo} alt="Xero logo" className="size-[34px]" />,
    };

    if (connected.length) {
      const connectionSource = sources.find(
        (source) => source.uuid === connected[0].sourceUuid,
      );

      const { lastSyncAttempt, lastSyncSuccess, isCredentialsValid } =
        connected[0];

      let dateToDisplay;
      if (lastSyncSuccess) {
        dateToDisplay = format(lastSyncSuccess, "MM/dd/yyyy");
      } else if (lastSyncAttempt) {
        dateToDisplay = format(lastSyncAttempt, "MM/dd/yyyy");
      }

      let chipStatus;
      if (!isCredentialsValid) {
        chipStatus = IChipStatus.RELINK_NEEDED;
      } else if (!lastSyncAttempt) {
        chipStatus = IChipStatus.SYNCING;
      } else if (lastSyncSuccess) {
        chipStatus = IChipStatus.LINKED;
      }

      if (connectionSource) {
        return (
          <div className="flex items-center justify-between">
            <div className="flex gap-4 items-center">
              {accountingLogos[connectionSource.name]}
              <div className="flex flex-col">
                <Typography weight="medium">{connectionSource.name}</Typography>
                <Typography color="empty">{dateToDisplay}</Typography>
              </div>
            </div>
            {chipStatus && <StatusChip status={chipStatus} />}
          </div>
        );
      }
    }

    return (
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Typography weight="semibold" className="pl-42">
            Accounting
          </Typography>
          <img src={quickbooksLogo} alt="Quickbooks logo" />
          <img src={xeroLogo} alt="Xero logo" className="size-[34px]" />
        </div>
        <MergeLink
          linkToken={mergeLinkToken ?? ""}
          refreshLinkToken={refreshLinkToken}
          integrationSourceUuid={sources[0].uuid}
        />
      </div>
    );
  };

  return <ul>{mergeIntegrationConnector()}</ul>;
};

export default IntegrationsList;

import React from "react";
import Input from "~/components/Input/InputWrapper";
import DatePicker from "~/components/DatePicker";
import Button from "~/components/Button";
import CurrencyInput from "../../../components/Input/currencyInput/CurrencyInput";
import InputState = Types.InputState;
import { IDatePickerState } from "~/components/DatePicker/useDatePicker";
import { SelectState } from "~/components/Select/Select.types";
import SelectDepartment from "~/components/SelectDepartment";
import Checkbox from "~/components/Checkbox";
import Typography from "~/components/Typography";

interface Props {
  onClose: () => void;
  hireDate: IDatePickerState;
  setHireDate: React.Dispatch<React.SetStateAction<IDatePickerState>>;
  employeeName: InputState;
  setEmployeeName: React.Dispatch<React.SetStateAction<InputState>>;
  title: InputState;
  setTitle: React.Dispatch<React.SetStateAction<InputState>>;
  payRate: InputState;
  setPayRate: React.Dispatch<React.SetStateAction<InputState>>;
  selectDepartment: SelectState;
  setSelectDepartment: React.Dispatch<React.SetStateAction<SelectState>>;
  errorMessage: string;
  createPosition: () => void;
  isSubmitting: boolean;
  numberToCreate: InputState;
  setNumberToCreate: React.Dispatch<React.SetStateAction<InputState>>;
  isChecked: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

const Index = ({
  onClose,
  hireDate,
  setHireDate,
  employeeName,
  setEmployeeName,
  title,
  setTitle,
  payRate,
  setPayRate,
  selectDepartment,
  setSelectDepartment,
  errorMessage,
  createPosition,
  numberToCreate,
  setNumberToCreate,
  isSubmitting,
  isChecked,
  setIsChecked,
}: Props): React.ReactNode => {
  return (
    <div className="container px-0">
      <div className="w-full flex flex-col">
        <div className="w-full flex flex-col gap-4 mb-2">
          <Input
            id="employeeName"
            label="Name"
            state={employeeName}
            setState={setEmployeeName}
            placeholder="Employee Name"
            optional
          />
          <Input
            id="title"
            label="Title"
            state={title}
            setState={setTitle}
            placeholder="Title"
            required
          />
          <SelectDepartment
            label="Department"
            departmentOptions={selectDepartment}
            setDepartmentOptions={setSelectDepartment}
            required
          />
          <CurrencyInput
            id="payRate"
            label="Annual Pay"
            state={payRate}
            setState={setPayRate}
            required
          />
          <DatePicker
            id="hireDate"
            label="Hire Date"
            state={hireDate}
            setState={setHireDate}
            required
          />
          <label className="flex flex-row gap-2 items-center mt-2">
            <Checkbox
              id="create-multiple-positions"
              checked={isChecked}
              toggleValue={() => {
                setIsChecked((prev) => !prev);
              }}
            />
            <Typography color="secondary" className="text-sm">
              Create Multiple Positions
            </Typography>
          </label>
          {isChecked && (
            <Input
              id="numberToCreate"
              label="Count"
              state={numberToCreate}
              setState={setNumberToCreate}
              required
            />
          )}
        </div>
      </div>
      <div className="flex flex-col mt-6 gap-5">
        {errorMessage && (
          <div className="text-red-500 text-sm">{errorMessage}</div>
        )}
        <div className="flex justify-between gap-5">
          <Button
            className="!w-auto px-6"
            id="cancel-create-position"
            fill="clear"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            id="continue-create-position"
            onClick={createPosition}
            className="!w-auto"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Index;

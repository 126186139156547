import React, { ReactElement, useContext } from "react";
import EllipsisDropdown from "~/components/EllipsisDropdown";
import { FinancialModelContext } from "../context/FinancialModelContext";

const FinancialModelOptions = (): ReactElement => {
  const { downloadableModel } = useContext(FinancialModelContext);

  const handleExport = (): void => {
    if (downloadableModel) {
      const blob = new Blob([downloadableModel], {
        type: "text/csv;charset=utf-8;",
      });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "financial_model_export.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  return (
    <div className="relative z-40">
      <EllipsisDropdown
        id="financial-model-options"
        options={[
          {
            label: "Export Values",
            onClick: handleExport,
          },
        ]}
      />
    </div>
  );
};

export default FinancialModelOptions;

import React, { useContext, useEffect, useMemo } from "react";
import Header from "~/components/Header";
import UserDateRange from "~/components/UserDateRange";
import RatiosProvider, { RatiosContext } from "./context/RatiosContext";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import HoverPopover from "~/components/HoverPopover";
import RatiosPageTooltip from "./components/RatiosPageTooltip";
import CreateRatioModal from "./components/CreateRatioModal";
import RatiosTable from "./components/RatiosTable/RatiosTable";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { State } from "~/store";
import Button from "~/components/Button";
import Typography from "~/components/Typography";
import { useExitScenarioListener } from "~/utils/hooks/useExitScenarioListener";
import request from "~/utils/request";

const RatiosContainer = (): React.ReactNode => {
  const { pageLoading, ratiosList, setIsCreateRatioModalOpen, reloadRatios } =
    useContext(RatiosContext);
  const { activeScenarioUuid, isTrayCollapsed } = useSelector(
    (state: State) => state.scenario,
  );
  const { uuid: userUuid } = useSelector((state: State) => state.user);
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );

  const parentClass = useMemo(() => {
    if (activeScenarioUuid) {
      return isTrayCollapsed
        ? "max-h-[calc(100vh-106px)]"
        : "max-h-[calc(100vh-450px)]";
    } else {
      return "max-h-[100vh]";
    }
  }, [activeScenarioUuid, isTrayCollapsed]);

  const childClass = useMemo(() => {
    if (activeScenarioUuid) {
      return isTrayCollapsed
        ? "max-h-[calc(92vh-10px)]"
        : "max-h-[calc(92vh-310px)]";
    } else {
      return "max-h-[92vh]";
    }
  }, [activeScenarioUuid, isTrayCollapsed]);

  const ratiosTableDisplay = useMemo(() => {
    if (pageLoading) {
      return (
        <div
          className="max-w-full overflow-scroll hide-scrollbar h-full px-10 pb-96"
          data-testid="ratios-page-loading"
        >
          <div className="flex items-center justify-between gap-4 mb-8 h-[243px] min-w-[1200px]">
            <Skeleton height={243} width={1000} count={1} baseColor="#F8F9F6" />
            <Skeleton height={243} width={500} count={1} baseColor="#F8F9F6" />
          </div>
          <Skeleton
            height={40}
            count={20}
            className="mb-4"
            baseColor="#F8F9F6"
          />
        </div>
      );
    } else {
      return <RatiosTable />;
    }
  }, [pageLoading]);

  const hasRatios = useMemo(() => {
    return ratiosList.length > 0;
  }, [ratiosList]);

  useExitScenarioListener(reloadRatios);

  useEffect(() => {
    const markRatioNotifcationsAsRead = async (): Promise<void> => {
      await request({
        url: `/users/notifications/mark-read`,
        method: "PATCH",
        body: {
          userUuid: userUuid,
          organizationUuid: organizationUuid,
          type: "ratio",
        },
      });
    };

    markRatioNotifcationsAsRead();
  }, [activeScenarioUuid, organizationUuid, userUuid, ratiosList]);

  return (
    <div className={`max-w-full w-full max-sm:pb-32 ${parentClass}`}>
      <Header
        title="Ratios"
        startChildren={
          hasRatios ? (
            <HoverPopover
              buttonContent={
                <InformationCircleIcon className="size-4 text-neutral-500" />
              }
              panelContent={<RatiosPageTooltip />}
              anchor="bottom"
              panelClassName="shadow-md z-50"
            />
          ) : null
        }
        endChildren={
          <div className="flex w-full justify-end items-center gap-2">
            <UserDateRange pickerAlignment="right" />
          </div>
        }
      />
      <div
        className={`pt-10 pl-12 max-w-full w-full ${childClass} flex flex-col gap-4`}
      >
        {ratiosTableDisplay}
        {hasRatios && (
          <div className="w-full flex flex-row justify-between items-center pr-8 pb-20">
            <Button
              onClick={() => setIsCreateRatioModalOpen(true)}
              className="bg-primary-500 !w-fit !px-0"
              fill="clear"
            >
              + New Ratio
            </Button>
            <div className="flex flex-row gap-4">
              <div className="flex items-center gap-1">
                <div className="size-2 rounded-full bg-purple" />
                <Typography color="secondary">Over-staffed</Typography>
              </div>
              <div className="flex items-center gap-1">
                <div className="size-2 rounded-full bg-red-500" />
                <Typography color="secondary">Under-staffed</Typography>
              </div>
            </div>
          </div>
        )}
      </div>
      <CreateRatioModal />
    </div>
  );
};

const RatiosPage = (): React.ReactNode => {
  return (
    <RatiosProvider>
      <RatiosContainer />
    </RatiosProvider>
  );
};

export default RatiosPage;

import { z } from "zod";
import { IRatioTypeEnum } from "./types";
import { ZPositionDetails } from "~/pages/Headcount/entity/schemas";
import { ZFormula } from "~/pages/FinancialModel/entity/schemas";

export const ZRatioElement = z.object({
  target: z.string(),
  type: z.nativeEnum(IRatioTypeEnum),
  value: z.number(),
});

export const ZRatioByMonth = z.object({
  month: z.string(),
  planned: z.number(),
  driving: z.number().nullable().array(),
  needed: z.number(),
});

export const ZRatio = z.object({
  uuid: z.string(),
  organizationUuid: z.string(),
  drivenElement: ZRatioElement.extend({
    title: z.string(),
  }),
  drivingElements: ZRatioElement.extend({
    title: z.string(),
  }).array(),
  createdAt: z.string(),
  updatedAt: z.string().nullable(),
  deletedAt: z.string().nullable(),
  createdBy: z.string().nullable(),
  deletedBy: z.string().nullable(),
  scenarioUuid: z.string().nullable(),
  byMonth: ZRatioByMonth.array(),
});

export const ZRatiosDataSchema = z.object({
  positions: z.array(ZPositionDetails).optional(),
  formulas: z.array(ZFormula).optional(),
  ratios: z.array(ZRatio).optional(),
  monthsBetweenDates: z.array(z.date()),
});

export const ZUserNotification = z.object({
  uuid: z.string(),
  userUuid: z.string(),
  organizationUuid: z.string(),
  isRead: z.boolean(),
  type: z.string(),
  context: z.object({
    title: z.string(),
  }),
  createdAt: z.string(),
  updatedAt: z.string().nullable(),
});

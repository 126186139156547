import React from "react";
import Button from "~/components/Button";
import InputWrapper from "~/components/Input/InputWrapper";
import Modal from "~/components/Modal";

const NameScenarioModal = ({
  isOpen,
  cancel,
  confirm,
  scenarioTitle,
  setScenarioTitle,
}: {
  isOpen: boolean;
  cancel: () => void;
  confirm: () => void;
  scenarioTitle: Types.InputState;
  setScenarioTitle: React.Dispatch<React.SetStateAction<Types.InputState>>;
}): React.ReactNode => {
  return (
    <Modal
      isOpen={isOpen}
      title="Scenario Name"
      size="xs"
      id="scenario-tray-naming-modal"
    >
      <div className="w-full">
        <InputWrapper
          id="new-scenario-title"
          state={scenarioTitle}
          setState={setScenarioTitle}
          placeholder="Additional Bridge, Aggressive Plan, etc."
          className="mt-5"
        />
        <div className="w-full flex items-center justify-between mt-5">
          <Button
            fill="clear"
            onClick={cancel}
            id="cancel-saving-scenario"
            className="!w-fit !px-0"
          >
            Cancel
          </Button>
          <Button
            onClick={confirm}
            id="rename-and-save-scenario"
            className="!w-auto"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NameScenarioModal;

import { IDataArrayDictionary } from "~/components/LineGraph/entity/types";
import { ExpenseFrequencyEnum, IExpense } from "../types";
import { createMonthArrayBetweenDates } from "~/utils/dates/createMonthArrayBetweenDates";
import { isExpenseAppliedInMonth } from "~/pages/Expenses/utils/isExpenseAppliedInMonth";
import { IPositionDetails } from "~/pages/Headcount/entity/types";
import { calculateExpenseAmount } from "~/pages/Expenses/utils/calculateExpenseAmount";
import { toZonedTime } from "date-fns-tz";
import { formatDateToISO } from "~/utils/dates/formatDateToISO";

export const graphDataByType = ({
  expenses,
  startDate,
  endDate,
  positions,
}: {
  expenses: IExpense[];
  startDate: Date;
  endDate: Date;
  positions: IPositionDetails[];
}): {
  typeData: IDataArrayDictionary[];
  categoryData: IDataArrayDictionary[];
} => {
  const typeData: IDataArrayDictionary[] = [];
  const categoryData: IDataArrayDictionary[] = [];
  const monthArray = createMonthArrayBetweenDates(
    toZonedTime(startDate, "UTC"),
    toZonedTime(endDate, "UTC"),
    {
      coerceToMonthEnd: true,
    },
  );
  monthArray.forEach((month) => {
    month = new Date(formatDateToISO({ date: month }));
    const time = month.getTime();
    const monthTypeData: Record<string, number> = {
      date: time,
      oneTime: 0,
      monthly: 0,
      quarterly: 0,
      annual: 0,
      onHire: 0,
    };
    const monthCategoryData: Record<string, number> = {
      date: time,
      headcountRelated: 0,
      software: 0,
      other: 0,
    };

    expenses.forEach((expense) => {
      const expenseIsApplied = isExpenseAppliedInMonth({
        expense,
        month,
        positions,
      });

      if (expenseIsApplied) {
        switch (expense.context.frequency) {
          case ExpenseFrequencyEnum.OneTime:
            monthTypeData.oneTime += calculateExpenseAmount({
              expense,
              month,
              positions: positions,
            });
            break;
          case ExpenseFrequencyEnum.Monthly:
            monthTypeData.monthly += calculateExpenseAmount({
              expense,
              month,
              positions: positions,
            });
            break;
          case ExpenseFrequencyEnum.Quarterly:
            monthTypeData.quarterly += calculateExpenseAmount({
              expense,
              month,
              positions: positions,
            });
            break;
          case ExpenseFrequencyEnum.Annually:
            monthTypeData.annual += calculateExpenseAmount({
              expense,
              month,
              positions: positions,
            });
            break;
          case ExpenseFrequencyEnum.OnHire:
            monthTypeData.onHire += calculateExpenseAmount({
              expense,
              month,
              positions: positions,
            });
            break;
          default:
            break;
        }

        switch (expense.context.tag) {
          case "Headcount Related":
            monthCategoryData.headcountRelated += calculateExpenseAmount({
              expense,
              month,
              positions: positions,
            });
            break;
          case "Software":
            monthCategoryData.software += calculateExpenseAmount({
              expense,
              month,
              positions: positions,
            });
            break;
          case "Other":
            monthCategoryData.other += calculateExpenseAmount({
              expense,
              month,
              positions: positions,
            });
            break;
          default:
            break;
        }
      }
    });

    typeData.push(monthTypeData);
    categoryData.push(monthCategoryData);
  });

  return { typeData, categoryData };
};

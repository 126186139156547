import React, { Fragment, ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Input from "../Input/InputWrapper";

interface Props {
  id?: string;
  isOpen: boolean;
  onClose?: () => void;
  children: ReactNode;
  title?: string | ReactNode;
  position?: "top" | "center";
  size?: "xxs" | "xs" | "sm" | "md" | "xl" | "full" | "lg" | "xxl";
  showClose?: boolean;
  textInput?: {
    id?: string;
    placeholder: string;
    state: Types.InputState;
    setState: React.Dispatch<React.SetStateAction<Types.InputState>>;
    resetState?: React.Dispatch<React.SetStateAction<Types.InputState>>;
    className?: string;
    type?:
      | "search"
      | "textarea"
      | "text"
      | "password"
      | "currency"
      | "percentage";
  };
}
const Modal = ({
  id,
  isOpen,
  onClose,
  title,
  children,
  position,
  size = "xl",
  showClose,
  textInput,
}: Props): React.ReactElement => {
  let sizeStyle = "";
  if (size === "xxs") {
    sizeStyle = "sm:max-w-sm";
  } else if (size === "xs") {
    sizeStyle = "sm:max-w-md";
  } else if (size === "sm") {
    sizeStyle = "sm:max-w-lg";
  } else if (size === "md") {
    sizeStyle = "sm:max-w-xl";
  } else if (size === "lg") {
    sizeStyle = "sm:max-w-2xl";
  } else if (size === "xl") {
    sizeStyle = "sm:max-w-4xl";
  } else if (size === "xxl") {
    sizeStyle = "sm:max-w-6xl";
  }
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={onClose ?? ((): void => {})}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className={`flex min-h-full items-end justify-center p-4 text-center sm:p-0 ${
              position === "top" ? "sm:items-start" : "sm:items-center"
            }`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full rounded-2xl ${sizeStyle}`}
              >
                <div
                  className="bg-white px-4 pb-4 pt-5 sm:py-6 sm:px-8 rounded-2xl"
                  data-testid={id}
                >
                  <div className="flex items-start justify-between w-full">
                    <Dialog.Title
                      as="h3"
                      className="font-semibold leading-6 text-gray-900 text-xl mb-3"
                    >
                      {title}
                    </Dialog.Title>
                    {textInput && (
                      <div>
                        <Input
                          id={textInput.id ?? ""}
                          placeholder={textInput.placeholder}
                          state={textInput.state}
                          setState={textInput.setState}
                          className={textInput.className}
                          type={textInput.type}
                        />
                      </div>
                    )}
                    {showClose && (
                      <button
                        onClick={onClose}
                        type="button"
                        data-testid={`${id}-close`}
                      >
                        <XMarkIcon className="size-6 text-neutral-100" />
                      </button>
                    )}
                  </div>
                  <div className="flex w-full">{children}</div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default Modal;

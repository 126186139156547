import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IInitialValue } from "../entities/types";
import { CellContext } from "@tanstack/react-table";
import useClickOutside from "~/utils/hooks/useClickOutside";
import useHover from "~/utils/hooks/useHover";
import DatePicker, { useDatePicker } from "~/components/DatePicker";
import { addDays, format, subDays } from "date-fns";
import request from "~/utils/request";
import { useSelector } from "react-redux";
import { State } from "~/store";
import { z } from "zod";
import { toZonedTime } from "date-fns-tz";
import { HeadcountContext } from "~/pages/Headcount/context/HeadcountContext";
import updateScenarioTray from "~/components/ScenarioTray/updateScenarioTray";
import debounce from "lodash/debounce";
import toast from "react-hot-toast";
import { StatusCodes } from "http-status-codes";
import { fetchSinglePosition } from "~/pages/Headcount/utils/fetchSinglePosition";
import { ZPositionDetails } from "~/pages/Headcount/entity/schemas";
import "./styles.css";

const ZUpdatePositionResponse = z.object({
  data: z.object({
    data: ZPositionDetails,
  }),
  status: z.number(),
});

const CellDateInput = ({
  initialValue,
  cellContext,
}: {
  initialValue: IInitialValue;
  cellContext: CellContext<Record<string, IInitialValue>, IInitialValue>;
}): ReactElement => {
  const parsedInitialValue = z
    .object({
      value: z.string().nullable(),
      positionUuid: z.string(),
      minDate: z.string().optional(),
      maxDate: z.string().optional(),
    })
    .parse(initialValue);

  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const {
    openTermDateRowIndex,
    renderedPositions,
    setRenderedPositions,
    positions,
    setPositions,
  } = useContext(HeadcountContext);

  const [date, setDate] = useDatePicker({
    value: parsedInitialValue.value
      ? {
          startDate: toZonedTime(parsedInitialValue.value, "UTC").toString(),
          endDate: toZonedTime(parsedInitialValue.value, "UTC").toString(),
        }
      : {
          startDate: null,
          endDate: null,
        },
    minDate: parsedInitialValue.minDate
      ? addDays(parsedInitialValue.minDate, 1).toString()
      : undefined,
    maxDate: parsedInitialValue.maxDate
      ? subDays(toZonedTime(parsedInitialValue.maxDate, "UTC"), 1).toString()
      : undefined,
  });

  const wrapperRef = useRef<HTMLElement>(null);
  const [ref, hovering] = useHover();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [inEditMode, setInEditMode] = useState(false);

  const updatePositionValue = debounce(async () => {
    const isValidUpdateToHireDate = Boolean(
      cellContext.column.id === "hireDate" &&
        date.value.startDate &&
        format(
          toZonedTime(new Date(date.value.startDate), "UTC"),
          "yyyy-MM-dd",
        ) !==
          (parsedInitialValue.value
            ? format(toZonedTime(parsedInitialValue.value, "UTC"), "yyyy-MM-dd")
            : null),
    );
    const isValidUpdateToTermDate = Boolean(
      cellContext.column.id === "termDate" &&
        date.value.startDate !==
          (parsedInitialValue.value
            ? toZonedTime(parsedInitialValue.value, "UTC").toString()
            : null),
    );

    if (isValidUpdateToHireDate || isValidUpdateToTermDate) {
      try {
        const updatedValueResponse = await request({
          method: "PATCH",
          url: `/organizations/${organizationUuid}/positions/${parsedInitialValue.positionUuid}`,
          params: {
            scenarioUuid: activeScenarioUuid ?? undefined,
          },
          body: {
            hireDate:
              cellContext.column.id === "hireDate"
                ? date.value.startDate
                : undefined,
            termDate:
              cellContext.column.id === "termDate"
                ? date.value.startDate
                : undefined,
          },
        });

        const parsedResponse =
          ZUpdatePositionResponse.parse(updatedValueResponse);

        if (
          parsedResponse.status === StatusCodes.CREATED &&
          renderedPositions.length
        ) {
          updateScenarioTray();
          const updatedPosition = await fetchSinglePosition({
            organizationUuid,
            positionUuid: parsedInitialValue.positionUuid,
            scenarioUuid: activeScenarioUuid ?? undefined,
          });
          const positionIndex = renderedPositions.findIndex(
            (position) =>
              position.positionUuid === parsedInitialValue.positionUuid,
          );
          const updatedPositionIndex = positions?.findIndex(
            (position) =>
              position.positionUuid === parsedInitialValue.positionUuid,
          );

          if (
            updatedPositionIndex !== undefined &&
            updatedPositionIndex !== -1 &&
            positions
          ) {
            const updatedPositions = [...positions];
            updatedPositions[updatedPositionIndex] = {
              ...updatedPosition,
              orderedDate: updatedPosition.hireDate,
            };
            setPositions(updatedPositions);
          }
          if (positionIndex !== -1 && date.value.startDate) {
            const updatedPositions = [...renderedPositions];
            updatedPositions[positionIndex] = {
              ...updatedPosition,
              orderedDate: updatedPosition.hireDate,
            };
            setRenderedPositions(updatedPositions);
          }
        } else {
          toast.error("Failed to update date");
          resetDate();
        }

        setInEditMode(false);
        setShowDatePicker(false);
      } catch (error) {
        toast.error("Failed to update date");
        resetDate();
      }
    } else {
      resetDate();
    }
  }, 300);

  const resetDate = (): void => {
    setDate((prev) => ({
      ...prev,
      value: {
        startDate: parsedInitialValue.value
          ? toZonedTime(parsedInitialValue.value, "UTC").toString()
          : null,
        endDate: parsedInitialValue.value
          ? toZonedTime(parsedInitialValue.value, "UTC").toString()
          : null,
      },
      minDate: parsedInitialValue.minDate
        ? addDays(toZonedTime(parsedInitialValue.minDate, "UTC"), 1).toString()
        : undefined,
      maxDate: parsedInitialValue.maxDate
        ? addDays(toZonedTime(parsedInitialValue.maxDate, "UTC"), -1).toString()
        : undefined,
      valid: true,
      pristine: true,
      touched: false,
      disabled: false,
    }));
    setInEditMode(false);
    setShowDatePicker(false);
  };

  const onBlur = async (): Promise<void> => {
    await updatePositionValue();
  };

  const isActive = cellContext.row.original.isActive.value === "true";

  useEffect(() => {
    if (hovering && isActive) {
      if (!showDatePicker) {
        setShowDatePicker(true);
      }
    } else if (!hovering && isActive && !inEditMode) {
      setShowDatePicker(false);
    }
  }, [hovering, isActive]);

  useEffect(() => {
    if (
      openTermDateRowIndex !== null &&
      openTermDateRowIndex === cellContext.row.index &&
      cellContext.column.id === "termDate"
    ) {
      if (inEditMode) {
        const datePickerElement: HTMLElement | null = document.querySelector(
          `[data-testid="termDate-${cellContext.row.index}-date-picker"]`,
        );

        if (datePickerElement) {
          datePickerElement.click();
        }
      }
    } else if (
      cellContext.column.id === "termDate" &&
      inEditMode &&
      !showDatePicker
    ) {
      setInEditMode(false);
    }
  }, [openTermDateRowIndex, inEditMode, cellContext.column.id]);

  useEffect(() => {
    onBlur();
  }, [date.value.startDate]);

  useClickOutside(wrapperRef, resetDate);

  const dateToRender = useMemo(() => {
    if (date.value.startDate) {
      return format(
        toZonedTime(new Date(date.value.startDate), "UTC"),
        "MM/dd/yyyy",
      );
    } else if (
      cellContext.column.id === "termDate" &&
      parsedInitialValue.value &&
      !date.value.startDate
    ) {
      return "-";
    } else if (parsedInitialValue.value) {
      return format(
        toZonedTime(parsedInitialValue.value, "UTC").toString(),
        "MM/dd/yyyy",
      );
    } else {
      return "-";
    }
  }, [date.value.startDate, parsedInitialValue.value]);

  const datePicker = useMemo(() => {
    return (
      <DatePicker
        id={`${cellContext.column.id}-${cellContext.row.index}-date-picker`}
        className={
          cellContext.column.id === "hireDate" ? "clearDateHidden" : ""
        }
        state={date}
        setState={setDate}
        required={cellContext.column.id === "hireDate"}
      />
    );
  }, [showDatePicker, date]);

  return (
    <div className="w-[150px]" ref={ref}>
      {inEditMode || showDatePicker ? (
        <div
          ref={wrapperRef}
          data-testid={`${cellContext.column.id}-${cellContext.row.index}-container`}
          onClick={(e) => {
            e.stopPropagation();
            isActive && setInEditMode(true);
          }}
        >
          {datePicker}
        </div>
      ) : (
        <div
          className={`flex text-nowrap overflow-hidden${
            isActive ? "" : " text-neutral-100 cursor-default"
          }`}
          data-testid={`${cellContext.column.id}-${cellContext.row.index}-container`}
          onClick={(e) => {
            e.stopPropagation();
            isActive && setInEditMode(true);
          }}
        >
          {dateToRender}
        </div>
      )}
    </div>
  );
};

export default CellDateInput;

import React, { ReactElement, useCallback } from "react";
import Button from "~/components/Button";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { useRevalidator } from "react-router-dom";
import request from "~/utils/request";
import { State } from "~/store";
import { useSelector } from "react-redux";

const MergeLink = ({
  linkToken,
  refreshLinkToken,
  integrationSourceUuid,
}: {
  linkToken: string;
  refreshLinkToken: ({ uuid }: { uuid: string }) => void;
  integrationSourceUuid: string;
}): ReactElement => {
  const revalidator = useRevalidator();
  const { organization } = useSelector((state: State) => state);
  const onMergeLinkSuccess = useCallback(async (publicToken: string) => {
    await request({
      method: "POST",
      url: "/integrations",
      body: {
        publicToken,
      },
      headers: {
        "Organization-Uuid": organization.uuid,
      },
    });

    revalidator.revalidate();
  }, []);

  const { open, isReady } = useMergeLink({
    linkToken,
    onSuccess: onMergeLinkSuccess,
    onExit: () => refreshLinkToken({ uuid: integrationSourceUuid }),
    shouldSendTokenOnSuccessfulLink: true,
  });

  return (
    <Button
      disabled={!isReady}
      className="!w-auto !px-5"
      fill="outline"
      onClick={open}
    >
      Connect
    </Button>
  );
};

export default MergeLink;

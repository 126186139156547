import { IAPIResponse } from "~/utils/types";
import serverRequest from "~/utils/request";
import { ZFormula } from "~/pages/FinancialModel/entity/schemas";
import { z } from "zod";
import { IFormula } from "~/pages/FinancialModel/entity/types";
import { subMonths } from "date-fns";

export const fetchGroupedExpenses = async ({
  organizationUuid,
  startDate,
  endDate,
  activeScenarioUuid,
}: {
  organizationUuid: string;
  startDate: string;
  endDate: string;
  activeScenarioUuid?: string | null;
}): Promise<IFormula[]> => {
  const response = (await serverRequest({
    url: `/formulas`,
    method: "GET",
    params: {
      startDate: subMonths(startDate, 1),
      endDate,
      includes: ["calculations"],
      scenarioUuid: activeScenarioUuid ?? undefined,
      filter: ["Expenses"],
      includeChildren: true,
    },
    headers: { "Organization-Uuid": organizationUuid },
  })) as IAPIResponse;

  const parsedResponse = z.array(ZFormula).parse(response.data.data);

  return parsedResponse;
};

import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import utc from "dayjs/plugin/utc";
import {
  DashboardPageContext,
  DashboardPageContextProvider,
} from "./context/DashboardContext";
import { useDispatch, useSelector } from "react-redux";
import { State } from "~/store";
import request from "~/utils/request";
import { IScenario } from "~/pages/OldDashboard/entity/types";
import { update } from "~/store/scenarioSlice";
import { closeConversationBox } from "~/store/chatSlice";
import { StatusCodes } from "http-status-codes";
import { useFeatureFlag } from "~/utils/hooks/useFeatureFlag";
import { useNavigate } from "react-router-dom";
import Header from "~/components/Header";
import ScenarioMenuDropdown from "./components/ScenariosContainer/ScenarioMenuDropdown/ScenarioMenuDropdown";
import UserDateRange from "~/components/UserDateRange";
import Typography from "~/components/Typography";
import Spinner from "~/components/Spinner";
import SelectedScenarioTextEdit from "./components/ScenariosContainer/SelectedScenarioTextEdit";
import SelectedScenarioContainer from "./components/ScenariosContainer/SelectedScenarioContainer";
import DashboardGraphs from "./components/DashboardGraphs/DashboardGraphs";
import DashboardMetrics from "./components/DashboardMetrics/DashboardMetrics";
import HeadcountProvider, {
  HeadcountContext,
} from "~/pages/Headcount/context/HeadcountContext";
import * as HeadcountTimeline from "~/pages/Headcount/components/HeadcountTimeline";
import CreatePosition from "../Headcount/components/CreatePosition";
import { UserPlusIcon } from "@heroicons/react/24/outline";
// import DraggableGraph from "~/components/DraggableGraph";

dayjs.extend(quarterOfYear);
dayjs.extend(utc);

interface ICreateScenarioResponse {
  status: number;
  data: {
    data: IScenario;
  };
}

const DashboardContainer = (): React.ReactNode => {
  const {
    reports,
    scenarios,
    reload,
    setScenarioCreationLoading,
    scenarioCreationLoading,
  } = useContext(DashboardPageContext);
  const {
    reload: reloadHeadcount,
    positionFormState,
    createPositionModalIsOpen,
    setCreatePositionModalIsOpen,
    renderedPositions,
    hasReloaded,
    setHasReloaded,
  } = useContext(HeadcountContext);
  const newDashboard = useFeatureFlag("newDashboard");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activeScenarioUuid, activeScenarioData, selectedScenarioUuids } =
    useSelector((state: State) => state.scenario);
  const { isOpen } = useSelector((state: State) => state.chat);
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const sideMenuExpanded = useSelector(
    (state: State) => state.user.preferences.sideMenuExpanded,
  );
  const userUuid = useSelector((state: State) => state.user.uuid);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
    window.innerWidth < 2600,
  );
  const [hideInteractiveSection, setHideInteractiveSection] =
    useState<boolean>(false);

  useEffect(() => {
    const handleResize = (): void => {
      setIsSmallScreen(window.innerWidth < 2600);
      if (window.innerWidth <= 1000) {
        setHideInteractiveSection(true);
      } else if (sideMenuExpanded && window.innerWidth <= 1080) {
        setHideInteractiveSection(true);
      } else if (!sideMenuExpanded && window.innerWidth > 1000) {
        setHideInteractiveSection(false);
      } else {
        setHideInteractiveSection(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [sideMenuExpanded]);

  useEffect(() => {
    reload();
  }, [hasReloaded, renderedPositions]);

  const handleCreateQuickScenario = async (): Promise<void> => {
    try {
      setScenarioCreationLoading(true);
      const response = (await request({
        method: "POST",
        url: `/organizations/${organizationUuid}/scenarios`,
        body: {
          type: "default",
        },
      })) as ICreateScenarioResponse;

      if (response.status === StatusCodes.CREATED) {
        dispatch(
          update({
            activeScenarioUuid: response.data.data.uuid,
            activeScenarioData: response.data.data,
            activeScenarioHasChanges: false,
            leverChanges: [],
            cashBalanceLockedIndexes: [],
            cashBalanceSelectedPoint: null,
            selectedScenarioUuids,
            isTrayCollapsed: false,
          }),
        );
        await reload();
        dispatch(closeConversationBox());
      } else {
        throw Error("Failed to create scenario");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setScenarioCreationLoading(false);
    }
  };

  if (!newDashboard) {
    navigate("/dashboard");
  }

  const selectedScenarioDisplay = (
    <div className="flex flex-row gap-2">
      {scenarioCreationLoading && (
        <div
          className="flex justify-center items-center gap-2"
          data-testid="scenario-creation-loading"
        >
          <Typography size="sm" color="secondary">
            Generating Scenario
          </Typography>
          <Spinner strokeColor="#5A8496" />
        </div>
      )}
      {selectedScenarioUuids.map((scenario, index) => {
        const scenarioForUuid = scenarios.find((s) => s.uuid === scenario);
        if (!scenarioForUuid) {
          return null;
        }

        return (
          <SelectedScenarioContainer
            scenario={scenarioForUuid}
            key={scenario}
            index={index}
          />
        );
      })}
      {activeScenarioUuid && activeScenarioData && (
        <SelectedScenarioTextEdit
          key={activeScenarioUuid}
          scenario={activeScenarioData}
        />
      )}
    </div>
  );

  const dashboardHeader = (
    <Header
      title="Dashboard"
      startChildren={
        <div className="flex flex-row gap-2">
          <ScenarioMenuDropdown
            scenarios={scenarios}
            createQuickScenario={handleCreateQuickScenario}
          />
          {selectedScenarioDisplay}
        </div>
      }
      endChildren={
        <div className="w-full flex flex-row justify-between items-center">
          {userUuid && <UserDateRange />}
        </div>
      }
    />
  );

  /*
   * return (
   *   <div>
   *     <DraggableGraph />
   *   </div>
   * );
   */

  return (
    <div
      className={`transition-all duration-500 ${
        isOpen && isSmallScreen ? "pr-[25%]" : ""
      } w-full flex flex-col max-sm:items-center max-sm:pb-16`}
    >
      {dashboardHeader}
      <div className="flex flex-row justify-center px-10 pt-10 gap-4">
        <div className="w-full flex flex-col gap-4 max-w-[1000px]">
          {reports.static && (
            <DashboardMetrics staticMetrics={reports.static} />
          )}
          {reports.consolidated && (
            <DashboardGraphs consolidatedReports={reports.consolidated} />
          )}
        </div>
        {!hideInteractiveSection && (
          <div className="sticky top-24 border border border-green-50 rounded-2xl p-4 flex flex-col flex-grow w-[35vw] max-w-[426px] min-w-[365px] max-h-[90dvh] h-fit">
            <div className="mb-3">
              <Typography className="!text-green-400 flex flex-row gap-1 justify-center items-center">
                <UserPlusIcon className="size-5" />
                Hires
              </Typography>
            </div>
            <HeadcountTimeline.component
              onlyNewHires
              addNewPosition={() => setCreatePositionModalIsOpen(true)}
            />
          </div>
        )}
      </div>
      <CreatePosition
        isOpen={createPositionModalIsOpen}
        setModal={setCreatePositionModalIsOpen}
        positionFormState={positionFormState}
        reload={() => {
          reload();
          reloadHeadcount();
        }}
        setHasReloaded={setHasReloaded}
      />
    </div>
  );
};

const Dashboard = (): React.ReactNode => {
  return (
    <DashboardPageContextProvider>
      <HeadcountProvider>
        <DashboardContainer />
      </HeadcountProvider>
    </DashboardPageContextProvider>
  );
};

export default Dashboard;

import { State } from "~/store";
import { authSlice } from "~/store/authSlice";
import { organizationSlice } from "~/store/organizationSlice";
import { hydrationSlice } from "~/store/hydrationSlice";
import { userSlice } from "~/store/userSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Logout = (): null => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state: State) => state.auth);

  useEffect(() => {
    dispatch(userSlice.actions.reset());
    dispatch(authSlice.actions.reset());
    dispatch(organizationSlice.actions.reset());
    dispatch(hydrationSlice.actions.reset());
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/auth/login");
    }
  }, [isLoggedIn]);

  return null;
};

export default Logout;

import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IConversation } from "~/pages/OldDashboard/entity/types";
import request from "~/utils/request";
import { ZConversation } from "~/pages/OldDashboard/entity/schemas";
import { z } from "zod";

interface IConversationBoxData {
  isLoading: boolean;
  unreadCount: number;
  conversations: IConversation[];
}

export interface ChatState {
  conversationUuid: string | null;
  isOpen: boolean;
  data: IConversationBoxData;
}

const initialState = {
  conversationUuid: null,
  isOpen: false,
  data: {
    isLoading: false,
    unreadCount: 0,
    conversations: [],
  },
} as ChatState;

const ZAPIArrayResponse = z.object({
  data: z.object({
    data: z.array(z.unknown()),
  }),
  status: z.number(),
});

const ZAPIResponse = z.object({
  data: z.object({
    data: z.number(),
  }),
  status: z.number(),
});

// Async thunk to fetch conversations
export const fetchConversations = createAsyncThunk(
  "chat/fetchConversations",
  async () => {
    const response = ZAPIArrayResponse.parse(
      await request({
        url: "/chats/conversations",
        method: "GET",
      }),
    );
    const parsedData = z.array(ZConversation).parse(response.data.data);
    return parsedData;
  },
);

export const fetchUnreadCount = createAsyncThunk(
  "chat/fetchUnreadCount",
  async () => {
    const response = ZAPIResponse.parse(
      await request({
        url: "/chats/conversations/unread",
        method: "GET",
      }),
    );
    return response.data.data;
  },
);

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    selectConversation: (state, action: PayloadAction<string | null>) => {
      state.conversationUuid = action.payload;
      action.payload
        ? localStorage.setItem("conversationUuid", action.payload)
        : localStorage.removeItem("conversationUuid");
    },
    openConversationBox: (state) => {
      state.isOpen = true;
    },
    closeConversationBox: (state) => {
      state.isOpen = false;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConversations.pending, (state) => {
        state.data.isLoading = true;
      })
      .addCase(
        fetchConversations.fulfilled,
        (state, action: PayloadAction<IConversation[]>) => {
          state.data.conversations = action.payload;
          state.data.isLoading = false;
        },
      )
      .addCase(fetchConversations.rejected, (state) => {
        state.data.isLoading = false;
      })
      .addCase(fetchUnreadCount.pending, (state) => {
        state.data.isLoading = true;
      })
      .addCase(
        fetchUnreadCount.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.data.unreadCount = action.payload;
          state.data.isLoading = false;
        },
      )
      .addCase(fetchUnreadCount.rejected, (state) => {
        state.data.isLoading = false;
      });
  },
});

export const {
  selectConversation,
  openConversationBox,
  closeConversationBox,
  reset,
} = chatSlice.actions;
export default chatSlice.reducer;

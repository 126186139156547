import { useState, useEffect, useCallback } from "react";
import pageData from "./pageData";

const useRatioData = (): {
  loading: boolean;
  data: unknown | null;
  revalidate: () => void;
} => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<unknown | null>(null);

  const fetchData = useCallback(async (): Promise<void> => {
    try {
      const result = await pageData();
      setData(result);
    } catch (error) {
      throw Error("Failed to fetch page data");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { loading, data, revalidate: fetchData };
};

export default useRatioData;

import { z } from "zod";
import { IMessageRoleEnum } from "./types";

export const ZMessage = z.object({
  content: z.string(),
  name: z.string().optional(),
  role: z.nativeEnum(IMessageRoleEnum),
  toolId: z.string().optional(),
});

export const ZConversation = z.object({
  uuid: z.string(),
  title: z.string(),
  isRead: z.boolean(),
  createdBy: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  messages: z.array(ZMessage),
});

export const ZConversationResponse = z.object({
  data: z.object({
    data: ZConversation,
  }),
  status: z.number(),
});

export const ZScenario = z.object({
  uuid: z.string(),
  changeDescription: z.string(),
  type: z.string(),
  createdBy: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  organizationUuid: z.string(),
  markedAsStaleAt: z.string().nullable(),
});

export enum ReportDataTypeEnum {
  Static = "static",
  Timeseries = "timeseries",
  Consolidated = "consolidated",
  ItemizedExpense = "itemizedExpense",
}

export const ZTimeseriesReportData = z.object({
  date: z.union([
    z.string().transform((val) => new Date(val).getTime()),
    z.number(),
  ]),
  value: z.number().nullable(),
});

export const ZStaticReportData = z.object({
  value: z.number().nullable(),
  isTrendingPositive: z.boolean().optional(),
});

const ZDateForConsolidatedReportData = z.object({
  date: z.number(),
});
const ZRecordForConsolidatedReportData = z.record(z.number().nullable());
export const ZConsolidatedReportData = ZDateForConsolidatedReportData.and(
  ZRecordForConsolidatedReportData,
);

export const ZConsolidatedReport = z.object({
  title: z.string(),
  type: z.literal(ReportDataTypeEnum.Consolidated),
  data: z.array(ZConsolidatedReportData),
});

export const ZStaticReport = z.object({
  title: z.string(),
  type: z.literal(ReportDataTypeEnum.Static),
  data: ZStaticReportData,
});

export const ZTimeseriesReport = z.object({
  title: z.string(),
  type: z.literal(ReportDataTypeEnum.Timeseries),
  data: z.array(ZTimeseriesReportData),
});

export const ZItemizedExpenseByMonthData = z.object({
  monthEnd: z.union([
    z.string().transform((val) => new Date(val).getTime()),
    z.number(),
  ]),
  impact: z.number(),
  monthOverMonthDollar: z.number(),
  monthOverMonthPercent: z.number(),
  itemized: z.array(
    z.object({
      name: z.string().nullable(),
      impact: z.number(),
      monthOverMonthDollar: z.number(),
      monthOverMonthPercent: z.number(),
    }),
  ),
});

export const ZFormulaExpensesByMonth = z.object({
  name: z.string(),
  impact: z.number(),
  monthOverMonthDollar: z.number(),
  monthOverMonthPercent: z.number(),
  date: z.union([z.string(), z.date()]),
});

export const ZItemizedExpenseReportData = z.object({
  name: z.string(),
  reportStartDate: z.union([
    z.string().transform((val) => new Date(val).getTime()),
    z.number(),
  ]),
  reportEndDate: z.union([
    z.string().transform((val) => new Date(val).getTime()),
    z.number(),
  ]),
  impact: z.number(),
  percentOfRequestedTotalImpact: z.number().nullable(),
  byMonth: z.array(ZItemizedExpenseByMonthData),
});

export const ZGroupedExpensesReport = z.object({
  title: z.string(),
  type: z.literal(ReportDataTypeEnum.ItemizedExpense),
  data: z.array(ZItemizedExpenseReportData),
});

export const ZReportCollection = z.object({
  cashBalance: ZTimeseriesReport.optional(),
  cashflow: ZTimeseriesReport.optional(),
  expenses: ZTimeseriesReport.optional(),
  revenue: ZTimeseriesReport.optional(),
  runway: ZStaticReport.optional(),
  groupedExpenses: ZGroupedExpensesReport.optional(),
  filteredExpenses: ZTimeseriesReport.optional(),
});

export const ZConsolidatedReportCollection = z.object({
  cashBalance: ZConsolidatedReport.optional(),
  cashflow: ZConsolidatedReport.optional(),
  expenses: ZConsolidatedReport.optional(),
  revenue: ZConsolidatedReport.optional(),
  runway: z.array(ZStaticReport).optional(),
  filteredExpenses: ZConsolidatedReport.optional(),
});

export const ZScenarioPageRequest = z.object({
  scenarios: z.array(ZScenario),
  cwmReports: ZReportCollection,
});

export const ZConsolidatedData = ZDateForConsolidatedReportData.and(
  z.record(z.string(), z.number()),
);

export const ZConsolidatedGraphBody = z.object({
  title: z.string(),
  type: z.string(),
  data: z.array(ZConsolidatedData),
});

export const ZStaticGraphBody = z.object({
  title: z.string(),
  type: z.string(),
  data: z.record(z.string(), ZStaticReportData),
});

export const ZStaticGraphData = z.record(z.string(), ZStaticGraphBody);

export const ZConsolidatedGraphData = z.record(
  z.string(),
  ZConsolidatedGraphBody,
);

export const ZGraphData = z.object({
  consolidated: ZConsolidatedGraphData.optional(),
  static: ZStaticGraphData.optional(),
});

export const ZDashboardLoader = z.object({
  reportData: ZGraphData,
  scenarios: z.array(ZScenario),
});

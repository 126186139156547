import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Login from "./pages/Auth/Login";
import LinkSent from "./pages/Auth/LinkSent";
import * as SignUp from "./pages/Auth/SignUp";
import Logout from "./pages/Auth/Logout";
import Oauth from "./pages/Auth/OAuth";
import Otp from "./pages/Auth/Otp";
import ErrorBoundary from "./pages/ErrorBoundary";
import * as ProtectedRoute from "~/pages/ProtectedRoute";
import * as Headcount from "./pages/Headcount";
import * as Expenses from "./pages/Expenses";
import * as OldDashboard from "./pages/OldDashboard";
import * as Dashboard from "./pages/Dashboard";
import * as FinancialModel from "./pages/FinancialModel";
import * as Settings from "./pages/Settings";
import basicLoader from "~/utils/basicLoader";
import * as Ratios from "./pages/Ratios";

export default createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route
        path="/"
        element={<Navigate replace to="/auth/login" />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="auth/login"
        element={<Login />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="auth/link-sent"
        element={<LinkSent />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="auth/signup"
        element={<SignUp.page />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="auth/forgot-password"
        element={<ForgotPassword />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="auth/logout"
        element={<Logout />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="auth/otp"
        element={<Otp />}
        errorElement={<ErrorBoundary />}
      />
      <Route element={<ProtectedRoute.page />}>
        <Route
          path="oauth"
          element={<Oauth />}
          errorElement={<ErrorBoundary />}
          loader={basicLoader}
        />
        <Route
          path="dashboard"
          element={<OldDashboard.page />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="new-dashboard"
          element={<Dashboard.page />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="headcount"
          element={<Headcount.page />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="settings"
          element={<Settings.page />}
          errorElement={<ErrorBoundary />}
          loader={Settings.loader}
        />
        <Route
          path="expenses"
          element={<Expenses.page />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="financial-model"
          element={<FinancialModel.page />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="ratios"
          element={<Ratios.page />}
          errorElement={<ErrorBoundary />}
        />
      </Route>
      {/*Handles 404 error, must be last route*/}
      <Route path="*" element={<ErrorBoundary is404={true} />} />
    </Route>,
  ),
  {
    future: {
      v7_normalizeFormMethod: true,
    },
  },
);

import { addYears, format } from "date-fns";
import { store } from "~/store";
import date from "~/utils/dates/date";
import serverRequest from "~/utils/request";
import waitForStoreRehydration from "~/utils/waitForStoreRehydration";
import { ZReportCollection } from "./entity/schemas";
import { IReportCollection } from "./entity/types";
import combineReportsIntoOne from "./utils/combineReportsIntoOne";

interface IAPIResponse {
  data: {
    data: unknown[];
  };
  status: number;
}

export default async (): Promise<unknown> => {
  await waitForStoreRehydration(store);
  const { defaultGraphStartDate, defaultGraphEndDate } =
    store.getState().user.preferences;
  const startDate = defaultGraphStartDate;
  const endDate = defaultGraphEndDate;
  const { activeScenarioUuid, selectedScenarioUuids } =
    store.getState().scenario;
  const { uuid: organizationUuid } = store.getState().organization;

  const promises = [];
  const reportsPromises = [];
  const reportsToInclude = [
    "cashBalance",
    "cashflow",
    "expenses",
    "revenue",
    "runway",
  ];

  reportsPromises.push(
    serverRequest({
      url: `/reports`,
      method: "GET",
      params: {
        include: reportsToInclude,
        startDate: startDate,
        endDate: endDate,
        scenarioUuid: undefined,
        compareData: false,
      },
      headers: { "Organization-Uuid": organizationUuid },
    }),
  );

  if (activeScenarioUuid) {
    reportsPromises.push(
      serverRequest({
        url: `/reports`,
        method: "GET",
        params: {
          include: reportsToInclude,
          startDate: startDate ? startDate : format(date(), "yyyy-MM-dd"),
          endDate: endDate
            ? endDate
            : format(addYears(date(), 2), "yyyy-MM-dd"),
          scenarioUuid: activeScenarioUuid,
          compareData: false,
        },
        headers: { "Organization-Uuid": organizationUuid },
      }),
    );
  }

  selectedScenarioUuids.forEach((scenario) => {
    reportsPromises.push(
      serverRequest({
        url: `/reports`,
        method: "GET",
        params: {
          include: reportsToInclude,
          startDate: startDate,
          endDate: endDate,
          scenarioUuid: scenario,
          compareData: false,
        },
        headers: { "Organization-Uuid": organizationUuid },
      }),
    );
  });

  promises.push(
    serverRequest({
      url: `/organizations/${organizationUuid}/scenarios`,
      method: "GET",
    }),
  );

  const result = await Promise.all(promises);

  const [scenariosResponse] = result as unknown as [IAPIResponse];
  const reportsResult = await Promise.all(reportsPromises);
  const parsedReports: IReportCollection[] = [];
  reportsResult.forEach((report: IAPIResponse) => {
    const parsedReport = ZReportCollection.parse(report.data.data);
    parsedReports.push(parsedReport);
  });

  const consolidatedReports = combineReportsIntoOne({
    reports: parsedReports,
  });

  return {
    reportData: consolidatedReports,
    scenarios: scenariosResponse.data.data,
  };
};

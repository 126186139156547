import { IDepartmentDict } from "~/pages/Settings/Departments/entity/types";

export const convertDepartmentUuidsToDisplayName = ({
  departmentUuids,
  departmentDict,
  scenarioDiffingEnabled,
}: {
  departmentUuids: string[];
  departmentDict: IDepartmentDict;
  scenarioDiffingEnabled: boolean;
}): string => {
  if (scenarioDiffingEnabled) {
    if (departmentUuids.length === 1 && departmentUuids[0] === "ALL") {
      return "All";
    }

    const departmentGroupingUuids = Object.values(departmentDict).map(
      ({ departmentUuid }) => departmentUuid,
    );

    return departmentUuids
      .map((departmentGroupingUuid) => {
        if (departmentGroupingUuids.includes(departmentGroupingUuid)) {
          return (
            Object.values(departmentDict).find(
              ({ departmentUuid }) => departmentGroupingUuid === departmentUuid,
            )?.name ?? ""
          );
        }
        return "";
      })
      .sort((a, b) => a.localeCompare(b))
      .join(", ");
  } else {
    if (departmentUuids.length === 1 && departmentUuids[0] === "ALL") {
      return "All";
    }

    return departmentUuids
      .map((departmentUuid) => {
        if (departmentUuid in departmentDict) {
          return departmentDict[departmentUuid].name;
        }
        return "";
      })
      .sort((a, b) => a.localeCompare(b))
      .join(", ");
  }
};

import React from "react";
import Typography from "~/components/Typography";
import EllipsisDropdown from "~/components/EllipsisDropdown";
import LegacyTable from "~/components/LegacyTable";

interface IDropdownOption {
  label: string;
  onClick: () => void;
  className?: string;
}

interface Props {
  uuid: string;
  name: string;
  email: string;
  status: string;
  isUser?: boolean;
  options: IDropdownOption[];
}

const FormatTableData = (
  rows: Props[],
  isLoading: boolean,
): React.ReactNode => {
  return (
    <LegacyTable
      id="org-users-table"
      className="w-full"
      loadingState={{ isLoading, skeletonRows: 15 }}
      headers={[
        <div key="import-header-file-name" id="importFileName" className="p-4">
          Name
        </div>,
        <div key="import-header-created-by" id="importStatus" className="p-4">
          Status
        </div>,
        <div key="options" />,
      ]}
      autoSpacing={false}
      data={rows.map(({ uuid, name, email, status, isUser, options }) => ({
        id: uuid,
        values: [
          {
            value: (
              <div
                key={uuid}
                title={name}
                className="flex flex-col p-4 truncate"
              >
                <Typography>
                  {name}{" "}
                  <span className="!text-green-300">
                    {isUser ? " (you)" : null}
                  </span>
                </Typography>
                <Typography size="xs" color="empty">
                  {email}
                </Typography>
              </div>
            ),
          },
          {
            value: (
              <div key={`${uuid}-status`} className="p-4">
                <Typography
                  color={status === "Pending Approval" ? "special" : "primary"}
                >
                  {status}
                </Typography>
              </div>
            ),
          },
          {
            value: (
              <div key={`${uuid}-options`}>
                {options.length > 0 && (
                  <EllipsisDropdown id={`${email}-options`} options={options} />
                )}
              </div>
            ),
          },
        ],
      }))}
    />
  );
};

export default FormatTableData;

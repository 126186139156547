import { useCallback, useEffect, useState } from "react";
import pageData from "./pageData";
import useQueryParams from "~/utils/hooks/useQueryParams";
import { ZExpensesPageRequest } from "./components/Expenses/entity/schemas";
import { IExpense } from "./components/Expenses/types";
import { IPositionDetails } from "../Headcount/entity/types";
import { IConsolidatedReportCollection } from "../OldDashboard/entity/types";
import { fetchFilteredExpensesReport } from "./fetchFilteredExpensesReport";
import { useSelector } from "react-redux";
import { State } from "~/store";

export const useExpensesData = (): {
  loading: boolean;
  initialExpenses: IExpense[];
  initialReports: IConsolidatedReportCollection;
  initialPositions: IPositionDetails[];
  revalidate: () => void;
  filteredExpensesReportLoading: boolean;
  revalidateFilteredExpensesReport: () => void;
} => {
  const [queryParams] = useQueryParams();
  const frequencyFilter = queryParams.get("frequencyFilter") ?? "all";
  const tagFilter = queryParams.get("tagFilter") ?? "all";
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredExpensesReportLoading, setFilteredExpensesReportLoading] =
    useState<boolean>(true);
  const [initialExpenses, setInitialExpenses] = useState<IExpense[]>([]);
  const [initialReports, setInitialReports] =
    useState<IConsolidatedReportCollection>({});
  const [initialPositions, setInitialPositions] = useState<IPositionDetails[]>(
    [],
  );
  const activeScenarioUuid = useSelector(
    (state: State) => state.scenario.activeScenarioUuid,
  );

  const fetchData = useCallback(async (): Promise<void> => {
    const response = await pageData({ frequencyFilter, tagFilter });
    const parsedResponse = ZExpensesPageRequest.parse(response);
    setInitialExpenses(parsedResponse.expenses);
    setInitialReports(parsedResponse.reports);
    setInitialPositions(parsedResponse.positions);
    setLoading(false);
    setFilteredExpensesReportLoading(false);
  }, [frequencyFilter, tagFilter]);

  useEffect(() => {
    fetchData();
  }, [fetchData, activeScenarioUuid]);

  const revalidateFilteredExpensesReport = async (): Promise<void> => {
    setFilteredExpensesReportLoading(true);
    const response = await fetchFilteredExpensesReport({
      frequencyFilter,
      tagFilter,
    });
    setInitialReports(response);
    setFilteredExpensesReportLoading(false);
  };

  return {
    loading,
    initialExpenses,
    initialReports,
    initialPositions,
    revalidate: fetchData,
    filteredExpensesReportLoading,
    revalidateFilteredExpensesReport,
  };
};

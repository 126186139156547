import Pusher from "pusher-js";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPusher } from "~/store/socketSlice";

const useInitializePusher = (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    const pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
      cluster: "us2",
    });

    dispatch(setPusher(pusher));

    return () => {
      pusher.disconnect();
    };
  }, [dispatch]);
};

export default useInitializePusher;

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Header from "~/components/Header";
import FormulaBuilder from "./components/FormulaBuilder/FormulaBuilder";
import FinancialModelTable from "./components/FinancialModelTable/FinancialModelTable";
import Button from "~/components/Button";
import {
  IFormula,
  IFormulaActual,
  IFormulaOverride,
  IVariables,
} from "./entity/types";
import FinancialModelProvider, {
  FinancialModelContext,
} from "./context/FinancialModelContext";
import request from "~/utils/request";
import { useDispatch, useSelector } from "react-redux";
import { State } from "~/store";
import isEqual from "lodash.isequal";
import updateScenarioTray from "~/components/ScenarioTray/updateScenarioTray";
import Divider from "~/components/Divider";
import {
  IManageGroupModalState,
  ManageGroupModal,
} from "./components/ManageGroupModal";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import HoverPopover from "~/components/HoverPopover";
import FinancialModelTooltip from "./components/InfoTooltip/FinancialModelTooltip";
import FinancialModelOptions from "./components/FinancialModelOptions";
import FinancialModelLegend from "./components/FinancialModelLegend";
import UserDateRange from "~/components/UserDateRange";
import { useFeatureFlag } from "~/utils/hooks/useFeatureFlag";
import Modal from "~/components/Modal";
import { PullQuickbooksActuals } from "./components/PullQuickbooksActuals";
import { IAPIResponse } from "~/utils/types";
import {
  IIntegration,
  IIntegrationMapping,
} from "~/utils/schemas/integrations";
import Skeleton from "react-loading-skeleton";
import { IFormattingEnum } from "./entity/schemas";
import { settingsSlice } from "~/store/settingsSlice";

const FinancialModelContainer = (): React.ReactNode => {
  const dispatch = useDispatch();
  const integrationsEnabled = useFeatureFlag("integrations");
  const firstRender = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isPullQuickbooksActualsOpen, setIsPullQuickbooksActualsOpen] =
    useState(false);
  const {
    overridesList,
    setOverridesList,
    parsedFormulas,
    overridesHaveChanges,
    actualsList,
    setActualsList,
    actualsHaveChanges,
    setSelectedMonthCell,
    loading,
    revalidate,
    revalidateLoading,
  } = useContext(FinancialModelContext);
  const {
    user: {
      preferences: { defaultGraphStartDate, defaultGraphEndDate },
    },
    scenario: { activeScenarioUuid, isTrayCollapsed },
    organization: { uuid: organizationUuid },
    settings: { financialModelExpand },
  } = useSelector((state: State) => state);
  const [integrationsData, setIntegrationsData] = useState<{
    integrations: IIntegration[];
    mappings: IIntegrationMapping[];
  }>({
    integrations: [],
    mappings: [],
  });

  const [formulaBuilderState, setFormulaBuilderState] = useState<{
    isOpen: boolean;
    mode: "create" | "edit";
    formulaTitle?: string;
    formulaUuid?: string;
    formulaData?: {
      topLevelFormulaUuid?: string;
      formula: string;
      variables: IVariables;
      formulaList: IFormula[];
      editable?: boolean;
      isProtected?: boolean;
    };
    dataSourceUuid: string | null;
    variables?: IVariables;
    formatting?: IFormattingEnum | null;
  }>({
    isOpen: false,
    mode: "create",
    dataSourceUuid: null,
  });
  const [manageGroupState, setManageGroupState] =
    useState<IManageGroupModalState>({
      groupNameToEdit: null,
      isOpen: false,
    });

  useEffect(() => {
    if (!revalidateLoading) {
      setIsLoading(false);
    }
  }, [revalidateLoading]);

  useEffect(() => {
    const fetchIntegrationsData = async (): Promise<void> => {
      if (!organizationUuid) return;

      const integrations: IAPIResponse<IIntegration[]> = await request({
        url: `/integrations`,
        method: "GET",
        headers: { "Organization-Uuid": organizationUuid },
      });

      const accountingIntegration = integrations.data.data.find(
        (integration) => integration.category === "accounting",
      );

      if (accountingIntegration) {
        const mappingsResponse: IAPIResponse<IIntegrationMapping[]> =
          await request({
            url: `/integrations/${accountingIntegration.uuid}/mappings`,
            method: "GET",
            headers: { "Organization-Uuid": organizationUuid },
          });

        if (mappingsResponse.data.data.length > 0) {
          setIntegrationsData({
            integrations: integrations.data.data,
            mappings: mappingsResponse.data.data,
          });
        }
      }
    };
    fetchIntegrationsData();
  }, [organizationUuid]);

  const handleUpdateMonthValues = async (): Promise<void> => {
    setIsLoading(true);

    const updates: Record<
      string,
      { overrides?: IFormulaOverride[]; actuals?: IFormulaActual[] }
    > = {};

    const overridesToUpdate = Object.keys(overridesList).reduce(
      (acc, key) => {
        if (!isEqual(overridesList[key], originalOverridesList[key])) {
          acc[key] = overridesList[key];
        }
        return acc;
      },
      {} as Record<string, IFormulaOverride[]>,
    );

    const actualsToUpdate = Object.keys(actualsList).reduce(
      (acc, key) => {
        if (!isEqual(actualsList[key], originalActualsList[key])) {
          acc[key] = actualsList[key];
        }
        return acc;
      },
      {} as Record<string, IFormulaActual[]>,
    );

    Object.keys(overridesToUpdate).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!updates[key]) {
        updates[key] = { overrides: [], actuals: [] };
      }
      updates[key].overrides = overridesToUpdate[key];
    });

    Object.keys(actualsToUpdate).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!updates[key]) {
        updates[key] = { overrides: [], actuals: [] };
      }
      updates[key].actuals = actualsToUpdate[key];
    });

    Object.keys(updates).forEach((key) => {
      const shouldDeleteOverride =
        overridesList[key].length > 0 &&
        originalOverridesList[key].length > 0 &&
        !updates[key].overrides?.length;

      const shouldDeleteActual =
        actualsList[key].length > 0 &&
        originalActualsList[key].length > 0 &&
        !updates[key].actuals?.length;

      if (shouldDeleteOverride) {
        delete updates[key].overrides;
      }
      if (shouldDeleteActual) {
        delete updates[key].actuals;
      }

      if (!updates[key].overrides && !updates[key].actuals) {
        delete updates[key];
      }
    });

    if (!Object.keys(updates).length) {
      setIsLoading(false);
      return;
    }

    await request({
      url: "/formulas/batch",
      method: "PATCH",
      body: updates,
      headers: {
        "Organization-Uuid": organizationUuid,
      },
    });

    setOverridesList({});
    setActualsList({});
    setSelectedMonthCell(null);
    revalidate();
    if (activeScenarioUuid) updateScenarioTray();
    setIsLoading(false);
  };

  const openGroupModal = useCallback(
    (groupName?: string): void => {
      setManageGroupState({
        groupNameToEdit: groupName ?? null,
        isOpen: true,
      });
    },
    [setManageGroupState],
  );

  const deleteGroup = useCallback(
    async (groupName: string): Promise<void> => {
      const formulasToUngroup = parsedFormulas.sorting.find(
        (group) => group.name === groupName,
      );

      const newSortOrder = parsedFormulas.sorting
        .filter((group) => group.name !== groupName)
        .map((group) => {
          if (group.name === "Ungrouped Attributes") {
            return {
              name: group.name,
              sortOrder: group.sortOrder.concat(
                formulasToUngroup?.sortOrder ?? [],
              ),
            };
          }
          return group;
        });

      await request({
        url: "/formulas/sorting",
        method: "PATCH",
        body: {
          groups: newSortOrder,
        },
        headers: { "Organization-Uuid": organizationUuid },
        params: {
          scenarioUuid: activeScenarioUuid,
        },
      });

      // Update store setting and remove the removed group from the array
      dispatch(
        settingsSlice.actions.update({
          financialModelExpand: financialModelExpand.filter(
            (group) => group.name !== groupName,
          ),
        }),
      );

      revalidate();
    },
    [activeScenarioUuid, organizationUuid, parsedFormulas],
  );

  const parentClass = useMemo(() => {
    if (activeScenarioUuid) {
      return isTrayCollapsed
        ? "max-h-[calc(100vh-106px)]"
        : "max-h-[calc(100vh-450px)]";
    } else {
      return "max-h-[100vh]";
    }
  }, [activeScenarioUuid, isTrayCollapsed]);

  const childClass = useMemo(() => {
    if (activeScenarioUuid) {
      return isTrayCollapsed
        ? "max-h-[calc(92vh-10px)]"
        : "max-h-[calc(92vh-310px)]";
    } else {
      return "max-h-[92vh]";
    }
  }, [activeScenarioUuid, isTrayCollapsed]);

  useEffect(() => {
    if (!firstRender.current) {
      revalidate();
    } else {
      firstRender.current = false;
    }
  }, [defaultGraphStartDate, defaultGraphEndDate]);

  const originalActualsList = useMemo(() => {
    return parsedFormulas.list.reduce(
      (acc, formula) => ({
        ...acc,
        [formula.uuid]: formula.actuals
          ? formula.actuals.reduce((acc, actual) => {
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              if (actual) {
                acc.push({
                  date: actual.date,
                  value: actual.value,
                });
              }
              return acc;
            }, [] as IFormulaActual[])
          : [],
      }),
      {} as Record<string, IFormulaActual[]>,
    );
  }, [parsedFormulas]);

  const originalOverridesList = useMemo(() => {
    return parsedFormulas.list.reduce(
      (acc, formula) => ({
        ...acc,
        [formula.uuid]: formula.overrides
          ? formula.overrides.reduce((acc, override) => {
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              if (override) {
                acc.push({
                  date: override.date,
                  value: override.value,
                });
              }
              return acc;
            }, [] as IFormulaOverride[])
          : [],
      }),
      {} as Record<string, IFormulaOverride[]>,
    );
  }, [parsedFormulas]);

  if (loading) {
    return (
      <div className={`max-w-full w-full max-sm:pb-32 ${parentClass}`}>
        <Header
          title="Financial Model"
          startChildren={
            <HoverPopover
              buttonContent={
                <InformationCircleIcon className="size-4 text-neutral-500" />
              }
              panelContent={<FinancialModelTooltip />}
              anchor="bottom"
              panelClassName="shadow-md z-50"
            />
          }
        />
        <div
          className={`pt-10 px-12 max-w-full w-full ${childClass} flex flex-col gap-4 mt-10`}
        >
          <Skeleton className="w-full h-[45px] mb-2" count={40} />
        </div>
      </div>
    );
  }

  return (
    <div className={`max-w-full w-full max-sm:pb-32 ${parentClass}`}>
      <Header
        title="Financial Model"
        startChildren={
          <HoverPopover
            buttonContent={
              <InformationCircleIcon className="size-4 text-neutral-500" />
            }
            panelContent={<FinancialModelTooltip />}
            anchor="bottom"
            panelClassName="shadow-md z-50"
          />
        }
        endChildren={
          <div className="flex items-center gap-2">
            {(overridesHaveChanges || actualsHaveChanges) && (
              <div className="flex flex-row gap-2">
                <Button
                  onClick={() => {
                    setOverridesList(originalOverridesList);
                    setActualsList(originalActualsList);
                  }}
                  fill="destructiveOutline"
                  className="!w-fit"
                  disabled={isLoading}
                  id="revert-overrides-button"
                >
                  Revert
                </Button>
                <Button
                  onClick={handleUpdateMonthValues}
                  className="!w-fit"
                  loading={isLoading}
                  id="save-overrides-button"
                >
                  Save
                </Button>
              </div>
            )}
            {!(overridesHaveChanges || actualsHaveChanges) &&
              integrationsEnabled && (
                <>
                  {integrationsData.mappings.length > 0 && (
                    <Button
                      fill="outline"
                      className="!px-4"
                      onClick={() => setIsPullQuickbooksActualsOpen(true)}
                    >
                      Pull Actuals
                    </Button>
                  )}
                  <Modal
                    isOpen={isPullQuickbooksActualsOpen}
                    onClose={() => setIsPullQuickbooksActualsOpen(false)}
                    size="xxs"
                  >
                    <PullQuickbooksActuals
                      onClose={() => setIsPullQuickbooksActualsOpen(false)}
                    />
                  </Modal>
                </>
              )}
            <UserDateRange pickerAlignment="right" />
            <FinancialModelOptions />
          </div>
        }
      />
      <div
        className={`pt-10 pl-12 max-w-full w-full ${childClass} flex flex-col gap-4`}
      >
        {parsedFormulas.list.length ? (
          <FinancialModelTable
            setFormulaBuilderState={setFormulaBuilderState}
            deleteGroup={deleteGroup}
            updateGroup={openGroupModal}
            integrationsData={integrationsData}
          />
        ) : (
          <div className="w-full text-center py-10">
            There are no formulas to display
          </div>
        )}
        <div className="flex items-center justify-between pb-20">
          <div className="flex gap-3 items-center">
            <Button
              className="!w-fit !p-0"
              fill="clear"
              onClick={() => {
                setFormulaBuilderState((prev) => ({
                  ...prev,
                  isOpen: true,
                  mode: "create",
                  formula: undefined,
                  formulaData: undefined,
                  variables: {},
                  formulaTitle: "",
                  formulaUuid: "",
                  formatting: IFormattingEnum.Number,
                }));
              }}
              id="new-attribute-button"
            >
              New Attribute
            </Button>
            <Divider orientation="vertical" className="text-green" />
            <Button
              id="new-group-button"
              className="!w-fit !p-0"
              fill="clear"
              onClick={() => openGroupModal()}
            >
              New Group
            </Button>
          </div>
          <FinancialModelLegend />
        </div>
      </div>
      <ManageGroupModal
        state={manageGroupState}
        setState={setManageGroupState}
        sortOrder={parsedFormulas.sorting}
      />
      <FormulaBuilder
        state={formulaBuilderState}
        cancel={() =>
          setFormulaBuilderState((prev) => ({
            ...prev,
            isOpen: false,
            variables: {},
            formula: undefined,
            formulaTitle: "",
            formatting: IFormattingEnum.Number,
          }))
        }
        confirm={() =>
          setFormulaBuilderState((prev) => ({
            ...prev,
            isOpen: false,
            variables: {},
            formula: undefined,
            formulaTitle: "",
            formatting: IFormattingEnum.Number,
          }))
        }
        formulas={parsedFormulas}
      />
    </div>
  );
};

const FinancialModel = (): React.ReactNode => {
  return (
    <FinancialModelProvider>
      <FinancialModelContainer />
    </FinancialModelProvider>
  );
};

export default FinancialModel;

import Modal from "~/components/Modal";
import React from "react";
import Button from "~/components/Button";
import Input from "~/components/Input/InputWrapper";
import InputState = Types.InputState;

interface Props {
  isOpen: boolean;
  setModal: () => void;
  createInvitation: () => void;
  userName: InputState;
  setUserName: React.Dispatch<React.SetStateAction<InputState>>;
  userEmail: InputState;
  setUserEmail: React.Dispatch<React.SetStateAction<InputState>>;
  resetFormState: () => void;
}

const UserPermissions = ({
  isOpen,
  setModal,
  createInvitation,
  userName,
  userEmail,
  setUserName,
  setUserEmail,
  resetFormState,
}: Props): React.ReactNode => (
  <Modal title="User Information" isOpen={isOpen} size="md">
    <div
      data-testid="add-new-user-modal"
      className="flex flex-col gap-4 w-full"
    >
      <Input
        id="user-name-input"
        label="Name"
        state={userName}
        setState={setUserName}
        placeholder="Name"
      />
      <Input
        id="user-email-input"
        label="Email"
        state={userEmail}
        setState={setUserEmail}
        placeholder="Email"
      />
      <div className="flex flex-row justify-between">
        <Button
          onClick={() => {
            setModal();
            resetFormState();
          }}
          fill="outline"
          className="!w-fit mt-2"
        >
          Cancel
        </Button>
        <Button
          id="attempt-create-new-user"
          onClick={createInvitation}
          className="!w-fit mt-2"
          disabled={!userName.value || !userEmail.value}
        >
          Submit
        </Button>
      </div>
    </div>
  </Modal>
);

export default UserPermissions;

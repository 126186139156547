import React, { useState, useEffect } from "react";
import Typography from "~/components/Typography";

const IndicateDrilldownClickable = (): React.ReactNode => {
  const [opacityClass, setOpacityClass] = useState<string>("opacity-0");

  useEffect(() => {
    const opacityLevels = [
      "opacity-0",
      "opacity-10",
      "opacity-20",
      "opacity-30",
      "opacity-40",
      "opacity-50",
      "opacity-60",
      "opacity-70",
      "opacity-80",
      "opacity-90",
      "opacity-100",
    ];

    let currentLevel = 0;

    const interval = setInterval(() => {
      if (currentLevel < opacityLevels.length - 1) {
        currentLevel++;
        setOpacityClass(opacityLevels[currentLevel]);
      } else {
        clearInterval(interval);
      }
    }, 10);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={`w-full h-20 dashed-green-border flex items-center justify-center transition-opacity duration-500 ${opacityClass}`}
      data-testid="indicate-drilldown-clickable"
    >
      <Typography color="empty">Click to see monthly expenses</Typography>
    </div>
  );
};

export default IndicateDrilldownClickable;

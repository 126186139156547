import React, { ReactElement, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "~/components/Button";
import { useInput } from "~/components/Input";
import InputWrapper from "~/components/Input/InputWrapper";
import Modal from "~/components/Modal";
import { State } from "~/store";
import request from "~/utils/request";
import { FinancialModelContext } from "../context/FinancialModelContext";
import { settingsSlice } from "~/store/settingsSlice";

export interface IManageGroupModalState {
  isOpen: boolean;
  groupNameToEdit: string | null;
}

interface IProps {
  state: IManageGroupModalState;
  setState: React.Dispatch<React.SetStateAction<IManageGroupModalState>>;
  sortOrder: { name: string; sortOrder: string[] }[];
}

export const ManageGroupModal = ({
  state,
  setState,
  sortOrder,
}: IProps): ReactElement => {
  const dispatch = useDispatch();
  const { revalidate } = useContext(FinancialModelContext);
  const {
    scenario: { activeScenarioUuid },
    organization: { uuid: organizationUuid },
    settings: { financialModelExpand },
  } = useSelector((state: State) => state);
  const [groupNameInput, setGroupNameInput] = useInput({
    value: state.groupNameToEdit ?? "",
  });

  useEffect(() => {
    setGroupNameInput((prevState) => ({
      ...prevState,
      value: state.groupNameToEdit ?? "",
      touched: false,
      pristine: true,
      valid: true,
    }));
  }, [state.groupNameToEdit]);

  const closeModal = (): void => {
    setState((prevState) => ({
      ...prevState,
      isOpen: false,
      groupNameToEdit: null,
    }));
    setGroupNameInput((prevState) => ({ ...prevState, value: "" }));
  };

  const groupExists = (): boolean => {
    return (
      sortOrder.some(({ name }) => name === groupNameInput.value) &&
      groupNameInput.value !== state.groupNameToEdit
    );
  };

  const saveChanges = async (): Promise<void> => {
    if (groupExists()) {
      setGroupNameInput((prevState) => ({
        ...prevState,
        valid: false,
        touched: true,
        pristine: false,
        error: "Group already exists",
      }));
      return;
    }

    const newSortOrder = sortOrder;
    if (state.groupNameToEdit !== null) {
      // Edit Mode
      if (groupNameInput.value === state.groupNameToEdit) {
        // No changes, exit modal
        closeModal();
        return;
      }
      newSortOrder.map((group) => {
        if (group.name === state.groupNameToEdit) {
          group.name = groupNameInput.value;
        }
        return group;
      });
    } else {
      // Create Mode, inject prior to last index so that "Ungrouped Attributes" is always last
      newSortOrder.splice(-1, 0, { name: groupNameInput.value, sortOrder: [] });
    }
    await request({
      url: "/formulas/sorting",
      method: "PATCH",
      body: { groups: newSortOrder },
      headers: {
        "Organization-Uuid": organizationUuid,
      },
      params: {
        scenarioUuid: activeScenarioUuid,
      },
    });

    const newFinancialModelExpand = [...financialModelExpand]; // Create a new array
    newFinancialModelExpand.splice(-1, 0, {
      name: groupNameInput.value,
      expanded: false,
    });

    dispatch(
      settingsSlice.actions.update({
        financialModelExpand: newFinancialModelExpand,
      }),
    );

    revalidate();
    closeModal();
  };

  return (
    <Modal
      title="Group Name"
      isOpen={state.isOpen}
      onClose={closeModal}
      size="sm"
    >
      <div className="flex flex-col w-full gap-4 mt-2">
        <InputWrapper
          id="manageGroupName"
          type="text"
          state={groupNameInput}
          setState={setGroupNameInput}
          placeholder="Enter group name"
        />
        <div className="flex justify-between">
          <Button
            onClick={closeModal}
            id="cancel-group-edit-button"
            fill="clear"
            className="!w-fit !px-0"
          >
            Cancel
          </Button>
          <Button
            onClick={saveChanges}
            id="save-group-edit-button"
            className="!w-fit"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

import {
  addMonths,
  differenceInCalendarMonths,
  endOfMonth,
  format,
  parseISO,
} from "date-fns";

interface ICreateMonthArrayOptions {
  format?: string;
  coerceToMonthEnd?: boolean;
}

export const createMonthArrayBetweenDates = (
  startDate: Date,
  endDate: Date,
  options?: ICreateMonthArrayOptions,
): Date[] => {
  const numberOfMonths = differenceInCalendarMonths(endDate, startDate) + 1;
  return Array.from({ length: numberOfMonths }, (_, i) => {
    let newMonth = addMonths(new Date(startDate), i);
    if (options?.coerceToMonthEnd) {
      newMonth = endOfMonth(newMonth);
    }
    if (options?.format) {
      newMonth = parseISO(format(newMonth, options.format));
    }
    return newMonth;
  });
};

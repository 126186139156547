import React from "react";
import Input from "~/components/Input/InputWrapper";
import Button from "~/components/Button";
import InputState = Types.InputState;
import { SelectState } from "~/components/Select/Select.types";
import SelectDepartment from "~/components/SelectDepartment";

interface Props {
  onClose: () => void;
  employeeName: InputState;
  setEmployeeName: React.Dispatch<React.SetStateAction<InputState>>;
  title: InputState;
  setTitle: React.Dispatch<React.SetStateAction<InputState>>;
  selectDepartment: SelectState;
  setSelectDepartment: React.Dispatch<React.SetStateAction<SelectState>>;
  errorMessage: string;
  createPosition: () => void;
  isSubmitting: boolean;
}

const EditPositionForm = ({
  onClose,
  employeeName,
  setEmployeeName,
  title,
  setTitle,
  selectDepartment,
  setSelectDepartment,
  errorMessage,
  createPosition,
  isSubmitting,
}: Props): React.ReactNode => {
  return (
    <div className="container px-0">
      <div className="w-full flex flex-col">
        <div className="w-full flex flex-col gap-4 mb-2">
          <Input
            id="employeeName"
            label="Name"
            state={employeeName}
            setState={setEmployeeName}
            placeholder="Employee Name"
            optional
          />
          <Input
            id="title"
            label="Title"
            state={title}
            setState={setTitle}
            placeholder="Title"
            required
          />
          <SelectDepartment
            label="Department"
            departmentOptions={selectDepartment}
            setDepartmentOptions={setSelectDepartment}
            required
          />
        </div>
      </div>
      <div className="flex flex-col mt-6 gap-5">
        {errorMessage && (
          <div className="text-red-500 text-sm">{errorMessage}</div>
        )}
        <div className="flex justify-between gap-5">
          <Button
            className="!w-auto px-6"
            id="cancel-create-position"
            fill="clear"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            id="continue-create-position"
            onClick={createPosition}
            className="!w-auto"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditPositionForm;

export default ({
  value,
  decimalToRound,
}: {
  value: number;
  decimalToRound?: number;
}): string =>
  value.toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: decimalToRound ?? 2,
    maximumFractionDigits: decimalToRound ?? 2,
  });

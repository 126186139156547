import React from "react";
import Typography from "../Typography";
import { IDataArrayDictionary, ILineProps } from "./entity/types";

interface IProps {
  xFormatter: (value: number | null) => string;
  yFormatter: (value: number | null) => string;
  active?: boolean;
  payload?: { payload: IDataArrayDictionary }[];
  label?: number;
  lines: ILineProps[];
}

const getTextColor = (
  stroke: string,
): "blue" | "orange" | "purple" | "green" | undefined => {
  if (stroke === "#406F83") return "blue";
  if (stroke === "#EBA61E") return "orange";
  if (stroke === "#8A6190") return "purple";
  if (stroke === "#64755C") return "green";

  return undefined;
};

const LineGraphTooltip = ({
  xFormatter,
  yFormatter,
  active,
  payload,
  label,
  lines,
}: IProps): React.ReactElement | null => {
  if (active && payload?.length) {
    return (
      <div className="bg-white ml-2 rounded-lg border border-neutral-75 py-1 px-4">
        <Typography color="secondary" size="xs">
          {xFormatter(label ?? null)}
        </Typography>
        <div className="flex items-center justify-between">
          {lines.map((line: ILineProps, index: number) => (
            <React.Fragment key={line.dataKey}>
              <Typography
                size="sm"
                weight="bold"
                color={getTextColor(line.stroke)}
                className="mr-1"
              >
                {yFormatter(
                  payload[index] ? payload[index].payload[line.dataKey] : null,
                )}
              </Typography>
              {index !== lines.length - 1 && (
                <Typography size="sm" color="disabled" className="mr-1">
                  |
                </Typography>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

export default LineGraphTooltip;

import React from "react";
import Typography from "~/components/Typography";
import { IScenario } from "~/pages/OldDashboard/entity/types";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { State } from "~/store";
import { useSelector, useDispatch } from "react-redux";
import { removeScenarioUuid } from "~/store/scenarioSlice";

const SelectedScenarioContainer = ({
  scenario,
  index,
}: {
  scenario: IScenario;
  index: number;
}): React.ReactElement => {
  const dispatch = useDispatch();
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);

  const handleClick = (): void => {
    dispatch(removeScenarioUuid(scenario.uuid));
  };

  const showXIcon = scenario.uuid !== activeScenarioUuid;

  return (
    <div className="border-[1px] border-neutral-75 flex items-center justify-center gap-1 px-2 py-1 rounded">
      <Typography
        color={index === 0 ? "orange" : index === 1 ? "purple" : "turquoise"}
        className="max-w-[105px] truncate"
      >
        {scenario.changeDescription}
      </Typography>
      {showXIcon && (
        <XMarkIcon
          height={16}
          width={16}
          className="text-neutral-100 cursor-pointer"
          onClick={handleClick}
          data-testid={`remove-scenario-${scenario.uuid}`}
        />
      )}
    </div>
  );
};

export default SelectedScenarioContainer;
